import { AssetType, Company, CompanyType, Developer, Employee, ServiceProviderDataFragment, UserRole, UserType} from "@/graphql/__generated__/graphql-operations";
import { typeNavLink } from "@/types";
import { BanknotesIcon, BuildingOfficeIcon, ChartBarIcon, CloudArrowUpIcon, CommandLineIcon, ComputerDesktopIcon, InboxStackIcon, LinkIcon, Square3Stack3DIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
  user: Employee | Developer,
  company: Company | null,
  path: { pathname: string },
  sideMenuOpen: boolean,
  spdetails: ServiceProviderDataFragment | null,
  setSideMenuOpen: Dispatch<SetStateAction<boolean>>
}

function Navlinks(props: Props) {
  // props
  const { user, path, sideMenuOpen, setSideMenuOpen, company, spdetails } = props;

  // Hooks
  const { t } = useTranslation(["components"]);

  const navLinks: typeNavLink[] = [
    {name: t("header.navlinks.dashboard"), link: "/user/dashboard", visibility: [UserType.Employee, UserType.Financier], icon: <ComputerDesktopIcon className="mf-navlink-icon" />},
    {name: t("header.navlinks.makeinvestment"), link: "/user/investment", visibility:[UserType.Financier], icon: <BanknotesIcon className="mf-navlink-icon" />},
    {name: t("header.navlinks.portfolio"), link: "/user/portfolio", visibility:[UserType.Financier], icon: <ChartBarIcon className="mf-navlink-icon" />},
    {name: t("header.navlinks.assetsoverview"), link: "/user/assetoverview", visibility: [UserType.Employee, CompanyType.Serviceprovider], icon: <Square3Stack3DIcon className="mf-navlink-icon" />},
    {name: t("header.navlinks.adminpage"), link: "/admin/dashboard", visibility: [UserType.Developer], icon: <CommandLineIcon className="mf-navlink-icon" />},
    {name: t("header.navlinks.creditlimitrequests"), link: "/admin/creditlimitrequests", visibility: [UserType.Developer], icon: <InboxStackIcon className="mf-navlink-icon" />},
    // {name: t("header.navlinks.manageusers"), link: user.__typename === "Developer"? "/admin/manageusers": "/user/manageusers", visibility: [UserType.Developer, UserRole.Superuser], icon: <UserGroupIcon className="mf-navlink-icon" />},
    {name: t("header.navlinks.customers"), link: "/user/customersoverview", visibility: [CompanyType.Serviceprovider, AssetType.Receivable], icon: <UserCircleIcon className="mf-navlink-icon" />},
    {name: t("header.navlinks.createcompany"), link: "/admin/createcompany", visibility: [UserType.Developer], icon: <BuildingOfficeIcon className="mf-navlink-icon" />},
    {name: t("header.navlinks.deploy"), link: "/admin/deploy", visibility: [UserType.Developer], icon: <CloudArrowUpIcon className="mf-navlink-icon" />},
    {name: t("header.navlinks.tokentransactions"), link: user.role === UserRole.Admin ? "/admin/transactions": "/user/transactions", visibility: [UserType.Developer, UserRole.Superuser], icon: <LinkIcon className="mf-navlink-icon" />},
  ];

  return (
    <div
      className={`mf-navlinks-container md:opacity-100
      ${sideMenuOpen? "top-[90px] left-0 right-0 opacity-100 ": "top-[-500px] opacity-0"}
      `}
    >
      {
        navLinks.map(({name, link, visibility, icon}) => (
          (
            visibility.includes(user.role) ||
            visibility.includes(user.usertype) ||
              (visibility.includes(company?.companytype as string) && visibility.includes(spdetails?.assetfinancingpool.assetType as string))
          ) &&
          <button
            onClick={() => setSideMenuOpen(false)}
            key={`link-${name}`}
          >
            <Link
              to={link}
              className={`mf-navlink group transition-animation
                ${path.pathname === link ? "text-mforange ":""}
              `}
            >
              <div className="md:hidden lg:flex">
                {icon}
              </div>
              <div
                className={`group-hover:flex flex ${path.pathname === link ? "flex":""} font-semibold`}
              >
                {name}
              </div>
            </Link>
          </button>
        ))
      }
    </div>
  );
}

export default Navlinks;
