import { getIFPSmartContract} from "@/blockchain/connectors";
import { blockchainNumberToClient } from "@/utils/helpers";
import { approve } from "../tokens/ERC20";
import { fromUnixTime } from "date-fns";
import { cacheEURMBalance, cacheIsEndPaymentDue } from "@/cache/Mutations";
import { findEvent, isConnected, getTransactionHash } from "../helpers";

export async function makePayment(amount: number, from: string, to: string): Promise<[boolean, string]> {
  // Connect Contract
  if(!isConnected()) {
    return [false, ""];
  }
  const { contract, gasPrice } = await getIFPSmartContract(to);
  // Check if approval is already there
  const approved = await approve(from, to, amount);
  if(!approved) return [false, ""];

  let tx: any;

  // Make Payment
  try {
    tx = await contract.methods.handleIncomingPayment()
      .send({
        from, gasPrice
      });

    // Find event in receipt
    const event = findEvent("IncomingPaymentSuccessful", tx);
    if(!event) {
      return [false , tx.transactionHash];
    }
    await cacheEURMBalance(from);
    await cacheIsEndPaymentDue(to);
    return [true , tx.transactionHash];
  } catch (error) {
    return [false, getTransactionHash(tx, error)];
  }
}

export async function financed(address: string): Promise<boolean> {
  const { contract } = await getIFPSmartContract(address);
  return await contract.methods.financed().call();
}

export async function criticalInventoryValue(address: string) {
  const { contract } = await getIFPSmartContract(address);
  return blockchainNumberToClient(await contract.methods.getCriticalInventoryValue().call());
}

export async function lastInventoryValue(address: string) {
  const { contract } = await getIFPSmartContract(address);
  const index = await contract.methods.currentInventoryIndex().call();
  if(Number(index) === 0) return 0;
  const inventory = await contract.methods.inventoryOn(Number(index) - 1).call();
  return blockchainNumberToClient(inventory.inventory);
}

export async function isEndPaymentDue(address: string) {
  const { contract } = await getIFPSmartContract(address);
  const endDate = await contract.methods.endDate().call();
  const end = new Date(fromUnixTime(Number(endDate)));
  let endPaymentDue = false;
  if(end.getTime() < Date.now()) {
    endPaymentDue = await financed(address);
  }
  return endPaymentDue;
}
