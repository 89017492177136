import { useForgotPasswordMutation } from "@/graphql/__generated__/graphql-operations";

export default function useForgotPasswordMutationHook() {
  const [ forgotPasswordMutation, { loading, error }] = useForgotPasswordMutation({
    onCompleted() {},
  });

  return {
    forgotPasswordMutation,
    loading,
    error
  };
}
