import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { TextInfoGroup } from "@/components/shared";
import { CLRPageModals, CustomerPageModals } from "@/constants/enums";
import { CreditLimitRequest } from "@/graphql/__generated__/graphql-operations";
import { formatNumberLocale } from "@/utils/helpers";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";

type Props = {
  setShowModal: Dispatch<SetStateAction<CLRPageModals|CustomerPageModals>>;
  currentCLR: CreditLimitRequest;
}

/**
 * A modal component that displays the details of a Credit Limit Request
 * @param {Props} props
 * @returns {JSX.Element} CLRDetailsModal component
 */
function CLRDetailsModal(props: Props): JSX.Element {

  // Props
  const { setShowModal, currentCLR } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["modals", "common"]);

  return (
    <BlankModal
      setShowModal={() => {
        setShowModal(CLRPageModals.NoModal);
      }}
      title={t("clrdetails.title")}
      className="px-6"
      customContentCSS="mf-modal-content lg:min-w-[580px]"
    >
      <BlankModalContent>
        <div className="flex flex-wrap gap-2">
          <TextInfoGroup label={t("clrdetails.labels.serviceprovider")} content={currentCLR.serviceprovider.name} />
          <TextInfoGroup label={t("clrdetails.labels.customer")} content={currentCLR.customer.name} />
          <TextInfoGroup label={t("clrdetails.labels.amount")} content={formatNumberLocale(intl, currentCLR.requestedAmount, "currency")} />
          <TextInfoGroup label={t("clrdetails.labels.reasonrequest")} content={currentCLR.reasonForRequest ?? "Not available"} />
          <TextInfoGroup label={t("clrdetails.labels.reasondenial")} content={currentCLR.reasonForDenial ?? "Not available"} />
        </div>
      </BlankModalContent>

      <BlankModalFooter>
        {/* Action Buttons */}
        <div className="mt-4">
          <button
            onClick={() => {
              setShowModal(CLRPageModals.NoModal);
            }}
            className="mf-btn-action-small-primary-outline"
          >
            {t("common:buttons.close")}
          </button>
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default CLRDetailsModal;
