import { UserRole, UserType } from "@/graphql/__generated__/graphql-operations";
import { ArrowLeftStartOnRectangleIcon, Cog8ToothIcon, LanguageIcon, MoonIcon, PaintBrushIcon, SunIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { isDarkModeVar } from "@/client";
import { useWeb3 } from "@/context/Web3Context";
import { logout } from "@/utils/authentication";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { propsProfile } from "./props";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuPortal, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger } from "@/components/ui/DropdownMenu";
import { typeDropdownLink, typeSublink } from "@/types";
import { AvatarComponent } from "@/components/shared";

function Profile(props: propsProfile) {

  const {
    setDarkMode,
    darkMode,
    company,
    user,
  } = props;

  const userName = `${user.firstname} ${user.lastname}`;

  const {
    disconnectWallet
  } = useWeb3();

  const { t, i18n } = useTranslation(["components"]);
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const darkModeClass = darkMode ? "text-white" : "text-gray-600";
  const backgroundColour = darkMode ? "bg-mfdarklighter text-white border-transparent shadow-gray-900": "bg-white";
  const hoverColour = darkMode ? "hover:bg-white/20": "hover:bg-gray-100";

  const languages: typeSublink[] = [
    { name: t("header.languages.en"), code: "en", icon: "🇬🇧"},
    { name: t("header.languages.de"), code: "de", icon: "🇩🇪"},
  ];

  const themes: typeSublink[] = [
    { name: "Dark", icon: <MoonIcon className={`mf-dropdown-link w-5 h-5 -mr-1 ${darkModeClass}`} />},
    { name: "Light", icon: <SunIcon className={`mf-dropdown-link w-5 h-5 -mr-1 ${darkModeClass}`} />},
  ];

  const handleSignoutClick = () => {
    logout();
    navigate("/login");
    disconnectWallet();
  };

  const dropdownLinksAlt: typeDropdownLink[] = [
    {name: t("header.dropdownlinks.language"), link: "", type: "Both", icon: <LanguageIcon className={`${darkModeClass} mf-dropdown-link`} />, sublinks: languages},
    {name: t("header.dropdownlinks.darkmode"), link: "", type: "Both", icon: <PaintBrushIcon className={`${darkModeClass} mf-dropdown-link`} />, sublinks: themes},
    {name: t("header.navlinks.manageusers"), link: user.__typename === "Developer"? "/admin/manageusers": "/user/manageusers", visibility: [UserType.Developer, UserRole.Superuser], type: "Both", icon: <UserGroupIcon className={`${darkModeClass} mf-dropdown-link`} />},
    {name: t("header.dropdownlinks.settings"), link: "/user/settings", type: "Both", icon: <Cog8ToothIcon className={`${darkModeClass} mf-dropdown-link`} />},
    {name: t("header.dropdownlinks.signout"), link: "/login", type: "Both", icon: <ArrowLeftStartOnRectangleIcon className={`${darkModeClass} mf-dropdown-link`} />, onClick: handleSignoutClick},
  ];

  const handleSubmenuOnClick = (linkData: any, sublink: any) => {
    if (linkData.name === t("header.dropdownlinks.language")) {
      const selectedLang = languages?.find(lang => lang.name === sublink.name);
      if (selectedLang) {
        i18n.changeLanguage(selectedLang.code); // Change language
        window.dispatchEvent(new Event("languagechange"));
      }
    } else if (linkData.name === t("header.dropdownlinks.darkmode")) {
      const isDark = sublink.name === "Dark";
      isDarkModeVar(isDark.toString());
      setDarkMode(isDark);
      localStorage.setItem("darkMode", isDark.toString());
    }
  };

  const UserInfo = ({userName, companyName, dropdownOnly}: {userName: string, companyName?: string, dropdownOnly?: boolean}) => {
    if(!userName) return null;
    return (
      <div className={`${dropdownOnly ? "block xl:hidden": "hidden xl:block ml-2"}`}>
        <p className={`mf-profile-name ${darkModeClass}`}>
          {`${userName}`}
        </p>
        {
          companyName ?
            <p className={`mf-profile-company ${darkModeClass}`}>
              {companyName}
            </p>
            :
            <p className={`mf-profile-company ${darkModeClass}`}>
              {t("header.developer")}
            </p>
        }
      </div>
    );
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="mf-profile-dropdown">
        <AvatarComponent size="28" userName={userName} logo={company?.logo} />
        <UserInfo userName={userName} companyName={company?.name} />
      </DropdownMenuTrigger>

      {/* Menu content */}
      <DropdownMenuContent
        align="end"
        avoidCollisions
        className={`mf-dropdown-menu min-w-[200px] pb-2 ${backgroundColour}`}
      >
        <div className="flex xl:hidden gap-2 items-center mb-2 px-2 pt-2">
          <AvatarComponent size="40" userName={userName} logo={company?.logo} />
          <UserInfo userName={userName} companyName={company?.name} dropdownOnly />
        </div>

        {/* Menu items */}
        <DropdownMenuGroup className="px-2">
          {
            dropdownLinksAlt.map((linkData) => (
              (linkData.type.includes(user.role) || linkData.type.includes(user.usertype) || linkData.type === "Both") &&
                    linkData.sublinks ?(
              // Main dropdown item (trigger for submenu)
                  <DropdownMenuSub key={`dropdown-sub-${linkData.name}`}>
                    <DropdownMenuSubTrigger
                      className={`mf-dropdown-link-container ${hoverColour}`}
                    >
                      {linkData.icon}
                      {linkData.name}
                      {
                        linkData.name === t("header.dropdownlinks.language") &&
                        <div className="ml-1">{languages?.find((lang) => lang.code === currentLanguage)?.icon}</div>
                      }
                    </DropdownMenuSubTrigger>

                    {/* Submenu content */}
                    <DropdownMenuPortal>
                      <DropdownMenuSubContent
                        className={`px-2 py-1 rounded-md focus:outline-none min-w-[120px] shadow-md border-0 ${backgroundColour}
                                  max-md:data-[state=open]:translate-x-[50px] max-[329px]:data-[state=open]:-translate-x-[50px]`}
                      >
                        {linkData.sublinks?.map((sublink: any) => (
                          <DropdownMenuItem
                            key={`sublink-${sublink.name}`}
                            className={`cursor-pointer mf-dropdown-link-container ${hoverColour}`}
                            onClick={() => handleSubmenuOnClick(linkData, sublink)}
                          >
                            {sublink.icon}
                            <p className="ml-2">
                              {sublink.name}
                            </p>
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                  </DropdownMenuSub>
                ) : ( // ***
                  <DropdownMenuItem
                    key={`dropdown-${linkData.name}`}
                    className={`focus:outline-none rounded-md flex items-center px-2 py-1 cursor-pointer ${hoverColour}`}
                    onClick={linkData?.onClick ?? (() => navigate(linkData.link))}
                  >
                    <div className="">{linkData.icon}</div>
                    <div className="">{linkData.name}</div>
                  </DropdownMenuItem>
                )
            ))
          }
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default Profile;
