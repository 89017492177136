import { CompanyAddressFragmentDoc, CompanyDetailsOnlyFragmentDoc, CompanyPublicKeyFragmentDoc, EditCompanyMutationVariables, useEditCompanyMutation } from "@/graphql/__generated__/graphql-operations";
import { t } from "i18next";
import { toast } from "react-toastify";
import { client } from "@/client";
import merge from "lodash.merge";
import { useCompanyData } from "../cache/useCompanyData";

export default function useEditCompanyMutationHook() {
  const [ editCompanyMutation, { loading, error }] = useEditCompanyMutation();
  const company = useCompanyData();

  const editCompany = (variables: EditCompanyMutationVariables, newData: any, type: "Settings" | "PublicKey" | "", publicKeyInput?: any) => {

    return editCompanyMutation({
      variables: {
        ...variables
      },
      onCompleted(result) {
        if(!result.editCompany) return;
        toast.success(t("toasts:editCmpnyStngsSuccess"), {toastId: "editCmpnyStngsSuccess"});

        // Seperate WebPresence from CompanyDetails
        const { facebook, twitter, linkedin, website, ...companyDetails } = newData?.serviceproviderinput?.companydetails ?? {};

        if(type === "Settings") {
          // Update cache
          if(newData?.serviceproviderinput) {
            //  Get exisiting data from cache
            const { companydetails } = client.readFragment({
              id: `${company?.__typename}:${company?._id}`,
              fragment: CompanyDetailsOnlyFragmentDoc,
            });

            const webpresence = companydetails.webpresence;

            client.writeFragment({
              id: `${company?.__typename}:${company?._id}`,
              fragment: CompanyDetailsOnlyFragmentDoc,
              data: {
                __typename: "ServiceProvider",
                companydetails: {
                  __typename: "CompanyDetails",
                  ...merge({}, companydetails, companyDetails),
                  webpresence: {
                    __typename: "WebPresence",
                    ...merge({}, {
                      facebook: webpresence.facebook,
                      twitter: webpresence.twitter,
                      linkedin: webpresence.linkedin,
                      website: webpresence.website
                    }, {facebook: facebook, twitter: twitter, linkedin: linkedin, website: website})
                  }
                },
              },
            });
          }

          if(newData?.address) {
            client.writeFragment({
              id: `${company?.__typename}:${company?._id}`,
              fragment: CompanyAddressFragmentDoc,
              data: {
                __typename: company?.__typename,
                address: {
                  __typename: company?.address.__typename,
                  ...merge({}, company?.address, newData.address)
                }
              }
            });
          }
        }

        if(type === "PublicKey") {
          client.writeFragment({
            id: `${company?.__typename}:${company?._id}`,
            data: {
              publickey: publicKeyInput ?? company?.publickey
            },
            fragment: CompanyPublicKeyFragmentDoc,
          });
        }
      },
    });};

  return {
    editCompanyMutation,
    editCompany,
    loading,
    error
  };
}
