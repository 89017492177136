import { BlankModal, BlankModalContent } from "@/components/modals";
import { SetWalletSetupModals } from "@/constants/enums";
import { Dispatch, SetStateAction } from "react";
import myronLogo from "@/assets/MYRON-icon-transparent.webp";
import { useTranslation } from "react-i18next";
import { toNormalCase } from "@/utils/helpers";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

type Props = {
  setShowModal: Dispatch<SetStateAction<SetWalletSetupModals>>;
  username: string;
}

function SetWalletWelcomeModal({ setShowModal, username }: Props) {
  const { t } = useTranslation(["modals"]);

  return (
    <BlankModal
      setShowModal={() => {setShowModal(SetWalletSetupModals.NoModal);}}
      className="md:px-10 lg:px-40 xl:px-72"
      doNotShowCloseIcon
      doNotShowTopContainer
    >
      <BlankModalContent>
        {/* Image container */}
        <div className="flex justify-center mb-8 py-8">
          <LazyLoadImage
            alt={"Myron logo"}
            className="h-16"
            src={myronLogo}
            effect="opacity"
          />
        </div>

        {/* Content container */}
        <div className="mb-4">
          <h1 className="font-bold text-xl mb-4">{t("initialisepublickey.welcome.title")}&nbsp;
            <span className="text-mforange">{t("initialisepublickey.welcome.myron")}</span>, {toNormalCase(username)}!
          </h1>
          <p>
            {t("initialisepublickey.welcome.text")}
          </p>
        </div>

        {/* Buttons container */}
        <div className="flex justify-end">
          <button
            onClick={() => setShowModal(SetWalletSetupModals.Setup)}
            className="mf-btn-action-large-primary-filled"
          >
            {t("common:buttons.getstarted")}
          </button>
        </div>

        {/* Stepper */}
        <div className="flex justify-center mt-8">
          <div className="flex items-center">
            <div className="h-2 w-2 bg-mforange rounded-full" />
            <div className="h-2 w-2 bg-gray-300 rounded-full mx-2" />
            <div className="h-2 w-2 bg-gray-300 rounded-full" />
          </div>
        </div>
      </BlankModalContent>
    </BlankModal>
  );
}

export default SetWalletWelcomeModal;
