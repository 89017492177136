import { InputGroup } from "@/types/shared";

export const inputFields: InputGroup[] = [
  {
    placeholder: "Company",
    type: "text",
    required: true,
    name: "employerID",
    className: "mf-input-field",
    errorMessage: "Employer ID is invalid",
    inputType: "dropdown"
  },
  {
    placeholder: "User Role",
    type: "text",
    required: true,
    name: "role",
    className: "mf-input-field",
    errorMessage: "User role is invalid",
    inputType: "dropdown"
  },
  {
    placeholder: "First Name",
    type: "text",
    required: true,
    name: "firstname",
    className: "mf-input-field",
    errorMessage: "First name is invalid"
  },
  {
    placeholder: "Last Name",
    type: "text",
    required: true,
    name: "lastname",
    className: "mf-input-field",
    errorMessage: "Last name is invalid"
  },
  {
    placeholder: "Email",
    type: "email",
    required: true,
    name: "mail",
    className: "mf-input-field",
    errorMessage: "Email is invalid"
  },
  {
    placeholder: "Password",
    type: "password",
    required: true,
    name: "password",
    className: "mf-input-field",
    errorMessage: "Password is invalid",
    doNotUseRegex: true
  },
];
