
type Props = {
  label: string;
  content: any;
  contentColour?: string;
  subText?: string;
  className?: string;
  isLink?: boolean;
  linkText?: string;
  wrapTitle?: boolean;
}

/**
 * A React component that displays a labeled text information group, which can optionally be a link.
 * The component is flexible, allowing for customization of styles, subtext, and the ability to wrap the title.
 *
 * @param {Object} props - The props object.
 * @param {string} props.label - The label text displayed above the content.
 * @param {any} props.content - The main content displayed. This can be text, a URL, or other JSX content.
 * @param {string} [props.contentColour] - Optional CSS class to apply custom color to the content text.
 * @param {string} [props.subText] - Optional subtext displayed next to the content in a smaller font.
 * @param {string} [props.className] - Optional additional CSS classes to apply to the root element.
 * @param {boolean} [props.isLink=false] - If true, renders the content as a clickable link.
 * @param {string} [props.linkText] - Optional text to display for the link; if not provided, content is used.
 * @param {boolean} [props.wrapTitle=false] - If true, allows the label text to wrap to the next line.
 *
 * @returns {JSX.Element} The rendered TextInfoGroup component.
 *
 * @example
 * // Usage of TextInfoGroup with a link
 * <TextInfoGroup
 *   label="Website"
 *   content="https://example.com"
 *   isLink={true}
 *   linkText="Visit Example"
 *   className="custom-class"
 * />
 *
 * @example
 * // Usage of TextInfoGroup with plain text content
 * <TextInfoGroup
 *   label="Name"
 *   content="John Doe"
 *   contentColour="text-green-500"
 *   subText="(Admin)"
 * />
 */
function TextInfoGroup(props: Props) {
  const {
    label,
    content,
    contentColour,
    subText,
    className,
    isLink,
    linkText,
    wrapTitle = false
  } = props;

  return (
    <div data-cy="textinfogroup" className={`space-y-1 flex flex-col ${wrapTitle ? "flex-shrink" : "flex-shrink-0"} w-[200px] 2xl:w-auto ${className}`}>
      <p data-cy="textinfogroup-label" className={`text-xs text-gray-400 dark:text-gray-300 select-none ${wrapTitle ? "whitespace-normal" : "whitespace-nowrap"}`}>
        {label}
      </p>
      {
        isLink ?
          <a className="text-sm text-blue-400"
            data-cy="textinfogroup-link"
            href={content}
            target="_blank"
            rel="noreferrer"
          >
            {linkText ?? content}
          </a>:
          <div>
            <p data-cy="textinfogroup-content" className={`font-semibold ${contentColour ? contentColour : "dark:text-white"}`}>
              {content}
              <span data-cy="textinfogroup-subtext" className="ml-2 font-normal text-sm whitespace-nowrap">
                {subText}
              </span>
            </p>
          </div>
      }
    </div>
  );
}

export default TextInfoGroup;
