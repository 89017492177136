import { useBlockchainData } from "@/hooks/cache/useBlockchainData";
import FinancingPoolStats from "../FinancingPoolStats";
import { FinancialStatsGraph } from "./Graph";
import { AssetFinancingRoundEdge, AssetType, GetPaginatedAssetFinancingRoundsQuery, GetPaginatedAssetFinancingRoundsQueryVariables, useGetPaginatedAssetFinancingRoundsQuery } from "@/graphql/__generated__/graphql-operations";
import { useServiceProviderData } from "@/hooks/cache/useServiceProviderData";
import { Pagination } from "@/components/shared";
import InventoryValuesCard from "./InventoryValuesCard";
import RecentTransactions from "./RecentTransactions";
import ReceivablesStats from "./ReceivablesStats";
import InterestPayment from "./InterestPayment";
import useScrollToTop from "@/hooks/useScrollToTop";
import { useRef } from "react";
import { ScrollToTopButton } from "@/components/shared";

export function ServiceProviderDashboard() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Hooks
  const blockchainData = useBlockchainData();
  const serviceProvider = useServiceProviderData();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });

  const isInventory = serviceProvider?.assetfinancingpool.assetType === AssetType.Inventory;

  // Pagination config
  const paginationName = "paginatedAssetFinancingRounds";

  if(!serviceProvider) {
    return (
      <p>We are currently loading....</p>
    );
  }

  const { data } = useGetPaginatedAssetFinancingRoundsQuery({
    variables: {
      first: 5,
      after: null,
      poolId: null
    },
    fetchPolicy: "cache-first"
  });

  const previousDayInventoryValue = data?.paginatedAssetFinancingRounds.edges[1]?.node.amountFinanced ?? 0;

  // *** Props
  const paymentDataProps = {
    amount: blockchainData.serviceProvider?.nextOwedInterestAmount,
    paymentDate: blockchainData.serviceProvider?.nextPaymentDate,
    isLoading: blockchainData.loading,
    financingStatus: serviceProvider.assetfinancingpool.status,
  };

  return (
    <div
      ref={scrollRef}
      className="md:fixed w-screen left-0 flex-col px-4 md:px-5 mb-[8rem] md:mb-0 select-none"
    >
      <FinancingPoolStats
        financingPoolStatus={serviceProvider.assetfinancingpool.status}
        poolAddress={serviceProvider.assetfinancingpool.pooladdress ?? ""}
        alreadyDeposited={serviceProvider.assetfinancingpool.amountinvested}
      />
      {/* Main layout */}
      <div className="md:grid grid-cols-3 md:space-x-4 pt-2">
        {/* Left side */}
        <div className="flex flex-col col-span-2 space-y-4 pt-2">
          {/* Cards */}
          <div className="flex flex-col space-y-4 md:space-y-0 md:space-x-4 md:grid grid-cols-3">
            {/* Inventory related stats */}
            {
              isInventory &&
                <div className="col-span-3 w-full flex flex-col lg:grid grid-cols-5 lg:space-x-4 space-y-4 lg:space-y-0">
                  <InventoryValuesCard
                    isLoading={blockchainData.loading}
                    currentInventoryValue={blockchainData.inventoryProvider?.lastInventoryValue}
                    criticalInventoryValue={blockchainData.inventoryProvider?.criticalInventoryValue}
                    previousDayValue={previousDayInventoryValue}
                  />
                  <InterestPayment {...paymentDataProps} />
                </div>
            }
            {
              !isInventory &&
                <div className="col-span-3 w-full flex flex-col lg:grid grid-cols-5 lg:space-x-4 space-y-4 lg:space-y-0">
                  <ReceivablesStats
                    isLoading={blockchainData.loading}
                    currentlyWithdrawableCash={blockchainData?.balance ?? 0}
                    residualCash={blockchainData.receivablesProvider?.residualCash ?? 0}
                  />
                  <InterestPayment {...paymentDataProps} />
                </div>
            }
          </div>
          {/* Graph */}
          <div className="hidden md:inline-flex bg-white p-2 rounded-xl shadow-xl dark:bg-mfdarklight">
            <Pagination<AssetFinancingRoundEdge, GetPaginatedAssetFinancingRoundsQuery, GetPaginatedAssetFinancingRoundsQueryVariables>
              props={{
                queryHook: useGetPaginatedAssetFinancingRoundsQuery,
                paginationName,
                reversePagination: true
              }}
            >
              {(dataSlice) => (
                <>
                  <FinancialStatsGraph
                    assetType={serviceProvider.assetfinancingpool.assetType}
                    data={dataSlice.map((edge) => edge.node)}
                  />
                </>
              )}
            </Pagination>
          </div>
        </div>
        {/* Right side */}
        <div className="col-span-1 mt-4 md:pt-2 md:mt-0 md:mb-0 flex">
          {/* Recent transactions */}
          <RecentTransactions
            isInventory={isInventory}
            transactions={data?.paginatedAssetFinancingRounds.edges.map((edge) => edge.node).slice(0,5) ?? []}
          />
        </div>
      </div>
      <div />
      <ScrollToTopButton scrollToTop={scrollToTop} isVisible={isVisible} />
    </div>
  );
}
