import ResetPasswordComponent from "./ResetPasswordComponent";
import ActivateAccountComponent from "./ActivateAccountComponent";
import { SetPasswordResult, SetPasswordType } from "@/constants/enums";
import { useEffect, useState } from "react";
import ResultComponent from "./ResultComponent";
import { useSetPasswordMutationHook } from "@/hooks/mutations";
import merge from "lodash.merge";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { PreloginLayout, PreloginLayoutContent, PreloginLayoutFooter} from "@/layouts/PreloginLayout";

type PasswordState = {
  newPassword: string;
  confirmNewPassword: string;
}

function SetPassword() {
  // States
  const [type, setType] = useState<SetPasswordType>(SetPasswordType.ResetPassword);
  const [token, setToken] = useState<string>("");
  const [result, setResult] = useState<SetPasswordResult>(SetPasswordResult.None);
  const [hasErrors, setHasErrors] = useState(true);
  const [loading, setLoading] = useState(false);
  const [passwordData, setPasswordData] = useState<PasswordState | undefined>(undefined);
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);

  // Hooks
  const { setPasswordMutation } = useSetPasswordMutationHook();

  // Fetch URL params
  const parseUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const typeParam = urlParams.get("type");
    const tokenParam = urlParams.get("token");

    if (tokenParam) {
      setToken(tokenParam);
    }

    if (typeParam) {
      setType(typeParam as SetPasswordType);
    }
  };

  // Fetch params on load
  useEffect(() => {
    parseUrlParams();
  }, []);

  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    const { name, value, isValid } = event;

    setHasErrors(!isValid);

    if(isValid) {
      const toWrite = merge({}, passwordData, { [name]: value });
      setPasswordData(toWrite);

      // check if confirm password exists
      if (!toWrite.confirmNewPassword) {
        setHasErrors(true);
        return;
      }

      // Check if new password fields match
      if ( name === "confirmNewPassword" && toWrite &&
        toWrite.confirmNewPassword !== toWrite.newPassword
      ) {
        setHasErrors(true);
        setConfirmPasswordError(true);
        return;
      }
      setConfirmPasswordError(false);
      setHasErrors(false);
      return;
    }

    setHasErrors(true);
  }

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (passwordData && !hasErrors) {
      setLoading(true);

      try {
        await setPasswordMutation({ variables: { id: token, newPassword: passwordData.newPassword } });
        setResult(SetPasswordResult.Successful);
      } catch (error) {
        const invalidTokenMessages = ["Invalid session ID", "The session has expired!"];

        if (invalidTokenMessages.some(message => error.message.includes(message))) {
          setResult(SetPasswordResult.Invalid);
        } else {
          setResult(SetPasswordResult.Failed);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <PreloginLayout>
      {/* Form Container */}
      <PreloginLayoutContent>
        {
          result === SetPasswordResult.None && (
            type === SetPasswordType.ResetPassword ?
              <ResetPasswordComponent
                handleOnChange={handleOnChange}
                handleFormSubmit={handleFormSubmit}
                hasErrors={hasErrors}
                loading={loading}
                confirmPasswordError={confirmPasswordError}
              /> :
              <ActivateAccountComponent
                handleOnChange={handleOnChange}
                handleFormSubmit={handleFormSubmit}
                hasErrors={hasErrors}
                loading={loading}
                confirmPasswordError={confirmPasswordError}
              />
          )
        }

        {
          result !== SetPasswordResult.None &&
          <ResultComponent
            result={result}
          />
        }
      </PreloginLayoutContent>

      <PreloginLayoutFooter />
    </PreloginLayout>
  );
}

export default SetPassword;
