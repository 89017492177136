import Password from "./forms/Password";
import { Profile, PublicKey, CompanyDetails, Notifications } from "./forms";
import { useTranslation } from "react-i18next";
import Bank from "./forms/companydetails/Bank";
import { UserType } from "@/graphql/__generated__/graphql-operations";
import { useUserData } from "@/hooks";

function Settings() {

  // Hooks
  const { t } = useTranslation();
  const user = useUserData();

  const notDeveloper = user?.usertype !== UserType.Developer;

  return (
    // Main card container
    <div className="mt-4 mx-4 md:mx-0 mb-[8rem] items-center justify-center flex dark:text-white">
      {/* Card content */}
      <div className="flex flex-col space-y-8 rounded-lg shadow-lg py-4 px-6
                    bg-white dark:bg-mfdarklight w-full"
      >
        <h1 className='font-bold text-xl'>{t("settings.title")}</h1>

        {/* //** To be used for KYC issue  */}
        {/* <p className="text-red-500 font-semibold">Your profile is incomplete. Please fill in all required information.</p> */}

        <Profile />

        {
          notDeveloper &&
          <>
            <PublicKey />
            <Bank />
            <Notifications user={user} />
          </>
        }

        <Password />
        {
          notDeveloper &&
            <CompanyDetails />
        }
      </div>
    </div>
  );
}

export default Settings;
