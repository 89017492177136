import { AssetEdge, AssetType, CompanyType, GetPaginatedAssetsQuery, GetPaginatedAssetsQueryVariables, InventoryAsset, ReceivableAsset, useGetPaginatedAssetsQuery } from "@/graphql/__generated__/graphql-operations";
import { useRef, useState } from "react";
import { AssetsFilterComponent, SumOfAssets } from "./";
import { Pagination, PaginationContentScrollable, PaginationContentWrapper } from "@/components/shared";
import { AssetsPageModals } from "@/constants/enums";
import { TwTableCardList, DataTable } from "@/components/shared/table";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import { AssetsDetailsModal } from "./modals";
import { ScrollToTopButton } from "@/components/shared";
import useScrollToTop from "@/hooks/useScrollToTop";
import { useTranslation } from "react-i18next";
import { getColumns } from "./columns";
import { useIntl } from "react-intl";
import { useCompanyData, useServiceProviderDetails } from "@/hooks";
import { getColumnHeaderValues } from "@/components/shared/table/utils";
import { useSearchParams } from "react-router-dom";
import { ActionButton } from "@/components/shared/table/Table";

interface PaginationRef {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedAssetsQueryVariables>) => void;
}

function AssetOverview() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // States
  const [showModal, setShowModal]  = useState<AssetsPageModals>(AssetsPageModals.NoModal);
  const [currentAsset, setCurrentAsset] = useState<InventoryAsset | ReceivableAsset | undefined>(undefined);

  // URL params
  const [searchParams] = useSearchParams();
  const spID = searchParams.get("spID");

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["translation", "tables"]);
  const mdBreakpoint = useMdBreakpoint();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });
  const { companytype: currentCompanyType } = useCompanyData() || {};
  const serviceProviderDetails = useServiceProviderDetails(spID as string);
  const currentAssetType = serviceProviderDetails?.assettype as AssetType;

  // Pagination config
  const paginationRef = useRef<PaginationRef | null>(null);
  const paginationName = "paginatedAssets";

  /**
   * Handler function for filter submit for pagination
   * @param {GetPaginatedAssetsQueryVariables} variables
   */
  const onFilterSubmit = (variables: GetPaginatedAssetsQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    paginationRef.current?.triggerHandleFilterSubmit(variables);
  };

  /**
   * OnClick handler for the details button
   * @param {InventoryAsset | ReceivableAsset} asset
   */
  const handleOnClickDetails = (asset: InventoryAsset | ReceivableAsset) => {
    setCurrentAsset(asset);
    setShowModal(AssetsPageModals.Details);
  };

  /**
   * Get action buttons for TwTableCardList
   * @param {number} index
   * @param {InventoryAsset | ReceivableAsset} dataObj
   * @returns {JSX.Element[]} - Array of action buttons
   */
  const getActionButtons = (index: number, dataObj: InventoryAsset | ReceivableAsset): JSX.Element[] => {
    return [
      <ActionButton key={`assets-action-btn-${index}`} dataObj={dataObj} fn={handleOnClickDetails} text={t("common:buttons.details")} />
    ];
  };

  // Table headers and sort fields for TwTableCardList
  const columnHeaderValues = getColumnHeaderValues(getColumns, intl, currentCompanyType as CompanyType, currentAssetType, t, handleOnClickDetails);

  return (
    <div className="mf-table-container mf-flex-y-fill overflow-visible">
      {/* Assets table */}
      <Pagination<AssetEdge, GetPaginatedAssetsQuery, GetPaginatedAssetsQueryVariables>
        ref={paginationRef} props={{queryHook: useGetPaginatedAssetsQuery, paginationName, className: "mx-4 mb-4"}}
      >
        {(dataSlice, loading, displayAmount) => (
          <PaginationContentWrapper>
            <div className="mx-4 mf-flex-y-fill">
              {/* Assets filter */}
              <AssetsFilterComponent onFilterSubmit={onFilterSubmit} displayAmount={displayAmount as number} />
              {/* // TODO Move to aggregate row */}
              <SumOfAssets dataSlice={dataSlice.map((edge) => edge.node)} loading={loading} />
              <PaginationContentScrollable ref={scrollRef}>
                {
                  mdBreakpoint ?
                    <DataTable
                      loading={loading}
                      displayAmount={displayAmount as number}
                      data={dataSlice.map(edge => edge.node) ?? []}
                      columns={getColumns(intl, currentCompanyType as CompanyType, currentAssetType, t, handleOnClickDetails)}
                    />
                    :
                    <TwTableCardList
                      dataArray={dataSlice.map(edge => edge.node)}
                      displayAmount={displayAmount as number}
                      tableHeaders={columnHeaderValues}
                      defaultSortField="createdAt"
                      dataLoading={loading}
                      dataType="Asset"
                      actionButtons={getActionButtons}
                    />
                }
              </PaginationContentScrollable>
            </div>
          </PaginationContentWrapper>
        )}
      </Pagination>
      {/* Modals */}
      {
        showModal === AssetsPageModals.Details &&
          <AssetsDetailsModal
            asset={currentAsset}
            setShowModal={setShowModal}
          />
      }
      <ScrollToTopButton scrollToTop={scrollToTop} isVisible={isVisible} />
    </div>
  );
}
export default AssetOverview;
