import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import commonEN from "./locales/en/common.json";
import commonDE from "./locales/de/common.json";
import tablesEN from "./locales/en/tables.json";
import tablesDE from "./locales/de/tables.json";
import modalsEN from "./locales/en/modals.json";
import modalsDE from "./locales/de/modals.json";
import componentsEN from "./locales/en/components.json";
import componentsDE from "./locales/de/components.json";
import toastsEN from "./locales/en/toasts.json";
import toastsDE from "./locales/de/toasts.json";
import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";

const resources = {
  en: {
    translation: translationEN,
    common: commonEN,
    tables: tablesEN,
    modals: modalsEN,
    components: componentsEN,
    toasts: toastsEN,
  },
  de: {
    translation: translationDE,
    common: commonDE,
    tables: tablesDE,
    modals: modalsDE,
    components: componentsDE,
    toasts: toastsDE,
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https:dd//www.i18next.com/overview/configuration-options
  .init({
    ns: ["common", "modals", "tables", "components", "toasts", "translation"],
    defaultNS: "translation",
    lng: localStorage.getItem("i18nextLng") || "en",
    resources: resources,
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language detection configuration
    detection: {
      order: ["navigator"],
      lookupQuerystring: "lng",
      lookupCookie: "i18next",
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      caches: ["localStorage", "cookie"]
    },
  });

export default i18n;
