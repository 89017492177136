import { logout, userExists, ValidateToken } from "@/utils/authentication";
import MainLayout from "@/layouts/MainLayout";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type Props = {
  roles: string[],
  usertypes: string[],
}

/**
 * This component is a protected route that can only be accessed by logged-in users with specific roles and user types.
 * If the user is not logged in or does not have the required roles and user types, they will be redirected to the login page.
 * Otherwise, the requested page will be rendered within the MainLayout component.
 *
 * @param {Object} props - The component props.
 * @param {string[]} props.roles - The roles that are allowed to access the protected route.
 * @param {string[]} props.usertypes - The user types that are allowed to access the protected route.
 * @returns {JSX.Element} The requested page if the user is logged in, otherwise the login page.
 *
 * @component
 * @example
 * ```javascript
 * // Usage:
 * <Route path="admin/" element={<ProtectedRoute roles={[UserRole.User, UserRole.Admin]} usertypes={[UserType.Developer]} />}>
 *  <Route path="dashboard" element={<Admin />} />
 * </Route>
 * ```
 */
function ProtectedRoute ( { roles, usertypes }: Props): JSX.Element {
  // Hooks
  const { t } = useTranslation();

  if(!userExists()) {
    return <Navigate to="/login" replace />;
  }
  if (!ValidateToken(roles, usertypes)) {
    toast.error(t("protectedroute.loggedout"), {toastId: "loggedOutErrorToast"});
    logout();
    return <Navigate to="/login" replace />;
  }
  else {
    return <MainLayout />;
  }
}

export default ProtectedRoute;
