import { InputGroup } from "@/types/shared";

export const inputFields: InputGroup[] = [
  {
    placeholder: "Enter your email",
    type: "email",
    required: true,
    name: "mail",
    title: "Email",
    className: "mf-input-field",
    errorMessage: "Email is invalid",
    autoFocus: true,
    autoComplete: "email"
  },
  {
    placeholder: "Enter your password",
    type: "password",
    required: true,
    name: "password",
    title: "Password",
    className: "mf-input-field",
    errorMessage: "Password is invalid",
    doNotUseRegex: true,
    autoComplete: "current-password"
  },
];
