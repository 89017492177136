import { client } from "@/client";
import { DeveloperDetailsFragment, DeveloperDetailsFragmentDoc } from "@/graphql/__generated__/graphql-operations";

/**
 * Hook for reading the details of the logged in User from Cache
 * @returns the data of the logged in User
 */
export function useDeveloperData(id?: string) {
  const userId = id ? id : sessionStorage.getItem("userId");
  const user: DeveloperDetailsFragment | null = client.readFragment({
    fragment: DeveloperDetailsFragmentDoc,
    id: `Developer:${userId}`,
  });

  return user;
}
