import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import { ArrowUpIcon } from "lucide-react";

function ScrollToTopButton({scrollToTop, isVisible}: {scrollToTop: () => void, isVisible: boolean}){
  const isMdBreakpoint = useMdBreakpoint();
  // Hide button on medium and larger screens
  if(isMdBreakpoint || !isVisible) return null;

  return (
    <button
      className="fixed bottom-4 right-4 bg-mfpurple p-2 rounded-md active:scale-95 shadow-md shadow-gray-600 dark:shadow-gray-950"
      onClick={scrollToTop}
    >
      <ArrowUpIcon className="w-6 h-6 text-white" />
    </button>
  );
}

export default ScrollToTopButton;
