import { InvestmentTransactionModals, TxResult } from "@/constants/enums";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals/BlankModal";
import { ServiceProviderDetails } from "@/components/shared";
import { cacheEURMBalance } from "@/cache/Mutations";
import { deposit } from "@/blockchain";
import { formatNumberLocale } from "@/utils/helpers";
import { useTranslation } from "react-i18next";
import { useAddInvestmentMutationHook } from "@/hooks/mutations";
import { useCompanyData } from "@/hooks";
import { useIntl } from "react-intl";
import { propsInvestmentTransactionSummaryModal } from "../props";

function InvestmentTransactionSummaryModal(props: propsInvestmentTransactionSummaryModal) {

  // Props
  const {
    state,
    assetFinancingPool,
    setShowModal,
    setTransactionHash,
    setResultModalType
  } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["modals"]);
  const company = useCompanyData();
  const { addInvestmentMutation } = useAddInvestmentMutationHook({financierID: company?._id as string});

  // Handle the investment into a Service Provider
  async function handleMakeInvestmentClick() {
    if (!state || !assetFinancingPool) return;

    //  show pending modal
    setShowModal(InvestmentTransactionModals.Loading);

    const [success, transactionHash] = await deposit(company!.publickey, state.pooladdress, state.amount);
    // If transaction fails
    if(!success) {
      setResultModalType(TxResult.ERROR);
      // Modals logic
      setShowModal(InvestmentTransactionModals.InvestmentTransactionResult);
      // Store transaction hash if available --> depends on type of error
      setTransactionHash(transactionHash);
      return;
    }

    // If transaction passess
    setResultModalType(TxResult.SUCCESS);
    // Store transaction hash
    setTransactionHash(transactionHash);
    // Modals logic
    setShowModal(InvestmentTransactionModals.InvestmentTransactionResult);

    addInvestmentMutation({
      variables: {
        id: company?._id as string,
        investmentInput: {
          amount: state.amount,
          assetfinancingpoolID: assetFinancingPool._id
        }
      }
    }).then(() => {
      cacheEURMBalance(company!.publickey);
    });
  }

  return (
    <BlankModal
      setShowModal={() => { setShowModal(InvestmentTransactionModals.NoModal); }}
      className="px-6"
      customContentCSS="mf-modal-content"
      title={t("investmenttransactionsummary.title")}
      titleCSS="text-lg dark:text-white"
    >
      <BlankModalContent>
        {/* Subtext */}
        <div className="mb-4">
          <p className="dark:text-white">
            {t("investmenttransactionsummary.textbefore")}
            <span className="pl-1 font-semibold">
              {formatNumberLocale(intl, state.amount, "currency")}
            </span> {t("investmenttransactionsummary.textafter")}:
          </p>
        </div>

        {/* SP Details */}
        <ServiceProviderDetails
          serviceProviderId={assetFinancingPool!.spdetails._id}
        />

        {/* Disclaimer */}
        <div className="rounded-md bg-mfpurple p-4 mt-4 mb-8 text-sm">
          <p className="text-white font-semibold">
            {t("investmenttransactionsummary.note.title")}:
          </p>
          <p className="text-white">
            {t("investmenttransactionsummary.note.text")}
          </p>
        </div>
      </BlankModalContent>

      <BlankModalFooter>
        {/* Buttons */}
        <div className="flex items-center justify-between">
          <button
            onClick={() => {
              setShowModal(InvestmentTransactionModals.MakeInvestment);
            }}
            className="mf-btn-action-small-primary-outline"
          >
            {t("common:buttons.back")}
          </button>
          <button
            onClick={() => handleMakeInvestmentClick()}
            className="mf-btn-action-small-primary-filled"
          >
            {t("common:buttons.submit")}
          </button>
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default InvestmentTransactionSummaryModal;
