import { client } from "@/client";
import { EmployeeDetailsFragment, EmployeeDetailsFragmentDoc } from "@/graphql/__generated__/graphql-operations";

/**
 * Hook for reading the details of the logged in Employee from Cache
 * @returns the data of the logged in User
 */
export function useEmployeeData(id?: string) {
  const userId = id ? id : sessionStorage.getItem("userId");
  const data: EmployeeDetailsFragment | null = client.readFragment({
    id: `Employee:${userId}`,
    fragment: EmployeeDetailsFragmentDoc
  });

  if(!data) return null;

  return data as EmployeeDetailsFragment;
}
