type Props = {
  className?: string;
  count?: number;
}

function TextInfoGroupSkeletons({ className, count = 1 }: Props) {
  const skeletons = Array.from({ length: count }, (_, i) =>
    (<div className={`flex flex-col gap-2 my-2 ${className ? className : ""}`} key={i}>
      {/* Header */}
      <div className="mf-skeleton-base h-[16px] w-[100px]" />
      {/* Content */}
      <div className="mf-skeleton-base h-[20px] w-[200px]" />
    </div>)
  );

  return <>{skeletons}</>;
}

export default TextInfoGroupSkeletons;
