import { useBlockchainData } from "@/hooks";
import { formatNumberLocale } from "@/utils/helpers";
import { ArrowTrendingUpIcon, BanknotesIcon, ChartBarIcon, WalletIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";

type StatCardProps = {
  icon: ReactNode;
  titleKey: string;
  value: string;
};

const StatCard = ({ icon, titleKey, value }: StatCardProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-x-2 bg-white dark:bg-mfdarklight rounded-md shadow-lg p-2">
      <div>
        {icon}
      </div>
      <div className="flex flex-col">
        <p className="text-sm font-thin text-gray-600 dark:text-gray-200">
          {t(titleKey)}
        </p>
        <p className="text-xl md:text-xl lg:text-xl font-bold text-mfpurple dark:text-mforange">
          {value}
        </p>
      </div>
    </div>
  );
};

type Props = {
  totalInvestedAmount: number;
  currentWorthIncludingInterest: number;
  averageInterestRate: number;
}

function InvestmentStatsBar(props: Props) {
  // Hooks
  const intl = useIntl();
  const blockchainData = useBlockchainData();

  const stats = [
    {
      icon: <BanknotesIcon data-cy="investment-stats-bar-banknotes-icon" className="w-6 h-6 text-gray-600 dark:text-gray-400" />,
      titleKey: "dashboard.financecardsfin.eurminvested",
      value: formatNumberLocale(intl, props.totalInvestedAmount, "decimal", false, false, true),
    },
    {
      icon: <ArrowTrendingUpIcon data-cy="investment-stats-bar-arrowtrendingup-icon" className="w-6 h-6 text-gray-600 dark:text-gray-400" />,
      titleKey: "dashboard.financecardsfin.interestearned",
      value: formatNumberLocale(intl, props.averageInterestRate, "percent"),
    },
    {
      icon: <ChartBarIcon data-cy="investment-stats-bar-chartbar-icon" className="w-6 h-6 text-gray-600 dark:text-gray-400" />,
      titleKey: "dashboard.financecardsfin.totalvalueearned",
      value: formatNumberLocale(intl, props.currentWorthIncludingInterest - props.totalInvestedAmount, "currency"),
    },
    {
      icon: <WalletIcon data-cy="investment-stats-bar-wallet-icon" className="w-6 h-6 text-gray-600 dark:text-gray-400" />,
      titleKey: "dashboard.financecardsfin.eurmbalance",
      value: formatNumberLocale(intl, blockchainData.balance, "decimal", false, false, true),
    },
  ];

  return (
    <div className="flex rounded-md select-none dark:text-gray-100">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 w-full justify-evenly gap-x-4 gap-y-4">
        {stats.map((stat) => (
          <StatCard key={stat.titleKey} icon={stat.icon} titleKey={stat.titleKey} value={stat.value} />
        ))}
      </div>
    </div>
  );
}

export default InvestmentStatsBar;
