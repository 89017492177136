import { TwInput, TwSelect, TwSelectOption } from "@/components/shared";
import { t } from "i18next";
import countriesData from "@/assets/country-data/country-codes.json";
import { FormEvent, useState } from "react";
import FormWrapper from "../FormWrapper";
import { toast } from "react-toastify";
import { useEditCompanyMutationHook } from "@/hooks/mutations";
import { useCompanyData } from "@/hooks";
import { clearForm } from "@/utils/helpers";
import merge from "lodash.merge";
import { CompanyAddressFragmentDoc } from "@/graphql/__generated__/graphql-operations";
import { client } from "@/client";

export type AddressState = {
  address?: {
    street: string,
    housenumber: string,
    zipcode: string,
    city: string,
    country: string
  },
}

function AddressForm() {

  // States
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [newData, setNewData] = useState<AddressState|undefined>(undefined);

  // Hooks
  const company = useCompanyData();

  // Read from cache
  const { address: addressCache} = client.readFragment({
    id: `${company?.__typename}:${company?._id}`,
    fragment: CompanyAddressFragmentDoc,
  }) ?? {};

  // Mutation hooks
  const { editCompany } = useEditCompanyMutationHook();

  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    const { name, value, isValid } = event;
    setHasErrors(isValid ? false: true);

    if(isValid) {
      const toWrite = merge({}, newData, { address: { [name]: value }});
      setNewData(toWrite);
    }
  }

  /**
  * Handler for address form submit event
  * @param event: FormEvent<Element>
  */
  function handleAddressSubmit(event: FormEvent<Element>) {
    event.preventDefault();
    const hasNewValues = newData?.address;

    if(!company || hasErrors || !hasNewValues) {
      return toast.error("Unable to sumbit data", { toastId: "addressSubmitError"});
    }

    toast.loading(t("toasts:companySubmitLoading"), {toastId:"companySubmitLoading"});

    if (hasNewValues) {
      editCompany({
        id: company._id,
        companyInput: {
          address: newData?.address as object,
        },
      }, newData, "Settings");
    }

    // Reset state
    setNewData(undefined);
    setHasErrors(true);
    toast.dismiss("companySubmitLoading");
  }

  return (
    // {/* Address */}
    <FormWrapper
      id="addressForm"
      className="mt-4"
      onClickReset={() => { clearForm("addressForm"); setNewData(undefined); }}
      handleSubmit={handleAddressSubmit}
    >
      <div>
        <h3 className="font-semibold text-sm mb-4 select-none">{t("settings.address.title")}</h3>
        <div>

          <div className="flex flex-col md:flex-row md:gap-2">

            {/* Street */}
            <div className="w-full md:w-1/2 md:pr-2">
              <TwInput
                type="text"
                required
                name="street"
                onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
                className="mf-input-field min-w-[150px]"
                defaultValue={addressCache?.street ?? ""}
                label={t("settings.address.street")}
                labelPlacement="stacked"
                useErrorText
                errorMessage={t("settings.address.streeterror").toString()}
              />
            </div>

            {/* House number */}
            <div className="w-1/2 md:w-1/4">
              <TwInput
                type="text"
                required
                name="housenumber"
                onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
                className="mf-input-field"
                defaultValue={addressCache?.housenumber ?? ""}
                label={t("settings.address.housenumber")}
                labelPlacement="stacked"
                useErrorText
                errorMessage={t("settings.address.housenumbererror").toString()}
              />
            </div>
          </div>

          {/* City */}
          <div className="flex flex-col md:flex-row md:gap-2">
            <div className="w-full md:w-1/2 md:pr-2">
              <TwInput
                type="text"
                required
                name="city"
                onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
                className="mf-input-field min-w-[150px]"
                defaultValue={addressCache?.city ?? ""}
                label={t("settings.address.city")}
                labelPlacement="stacked"
                useErrorText
                errorMessage={t("settings.address.cityerror").toString()}
              />
            </div>

            {/* Zip code */}
            <div className="w-1/2 md:w-1/4">
              <TwInput
                type="text"
                required
                name="zipcode"
                onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
                className="mf-input-field"
                defaultValue={addressCache?.zipcode ?? ""}
                label={t("settings.address.zipcode")}
                labelPlacement="stacked"
                useErrorText
                errorMessage={t("settings.address.zipcodeerror").toString()}
              />
            </div>
          </div>

          {/* Country */}
          <div className="w-full md:w-1/2 md:pr-2">
            <TwSelect
              name="country"
              className="dark:bg-mfdarklight"
              defaultValue={addressCache?.country ?? ""}
              label={t("settings.address.country")}
              labelPlacement="stacked"
              onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
            >
              <TwSelectOption
                value=""
                text={t("settings.address.country.option").toString()}
                disabled
              />
              {countriesData?.map(({ code, name }) => (
                <TwSelectOption
                  key={code}
                  value={name}
                  text={name}
                />
              ))}
            </TwSelect>
          </div>
        </div>
      </div>
    </FormWrapper>
  );
}

export default AddressForm;
