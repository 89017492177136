export const requiredEnvVars = [
  "VITE_CREDIT_MANAGER_ADDRESS",
  "VITE_EURO_MYRON_ADDRESS",
  "VITE_PROOF_OF_ASSET_ADDRESS",
  "VITE_OWNER_ADDRESS",
  "VITE_NETWORK_ID",
  "VITE_RPC_URL",
  "VITE_BACKEND_URL",
  "VITE_WS_URL",
  "VITE_POLYGON_SCAN",
  "VITE_MAGIC_API_KEY",
  "VITE_MYRON_IBAN",
  "VITE_MYRON_BIC",
  "VITE_MYRON_RECIPIENT"
];
