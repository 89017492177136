import { CLIENT_GET_BLOCKCHAIN } from "@/graphql/operations/client.queries";
import { useQuery } from "@apollo/client";

/**
 * Simple hook for reading the Blockchain data from Cache
 * @returns the data of the Blockchain from Cache
 */
export function useBlockchainData() {
  if (window.Cypress) {
    window.useBlockchainData = useBlockchainData;
  }

  const { data: { blockchainData: blockchain} } = useQuery(CLIENT_GET_BLOCKCHAIN, { fetchPolicy: "cache-only" });
  return blockchain;
}
