import { CreateCompanyFormField } from "@/types";
import { bankDetailsForm } from "../../inputfields";
import { TwInput} from "@/components/shared";
import { t } from "i18next";
import { useCompanyData } from "@/hooks";
import { client } from "@/client";
import { BankAccountFragmentDoc } from "@/graphql/__generated__/graphql-operations";

function Bank() {

  // Hooks
  const company = useCompanyData();

  const { bankaccount } = client.readFragment({
    id: `${company?.__typename}:${company?._id}`,
    fragment: BankAccountFragmentDoc,
  }) ?? {};

  function getBankAccountValue(fieldName: string | number) {

    if (!bankaccount) {
      return null; // If bankaccount is not defined, return null
    }
    // Check if the field name corresponds to a property in companydetails
    if (bankaccount[fieldName] !== undefined) {
      return bankaccount[fieldName];
    }

    return null; // If the field name doesn't match any property, return null
  }

  return (
    <div>
      <div className="flex space-x-2 items-center mb-4">
        {/* <CheckCircleIcon className={`h-6 w-6 ${state.bankaccount.verified ? "text-green-500" : "text-gray-400"}`} /> */}
        <h2 className="font-semibold text-lg mb-2 select-none">{t("settings.bankdetails.title")}</h2>
        <div className={`rounded-sm text-white px-2 py-[2px] text-xs select-none
                        ${company?.bankaccount.verified ? "bg-green-500" : "bg-red-500"}`}
        >
          {company?.bankaccount.verified ? "Verified" : "Unverified"}
        </div>
      </div>
      <div>
        {bankDetailsForm.map((field: CreateCompanyFormField) => {
          return (
            <div
              className="w-full md:w-1/2 md:pr-2"
              key={field.name}
            >
              <TwInput
                className="mf-input-field mb-2"
                onChange={() => { }}
                // onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event, "bankaccount")}
                required={field.required}
                name={field.name}
                defaultValue={getBankAccountValue(field.name)}
                type={field.type}
                label={t(field.placeholder ?? "")}
                labelPlacement="stacked"
                errorMessage={t(field.errorMessage)}
                additionalValidation={field.additionalValidation}
                disabled
                useErrorText
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Bank;
