
function DeclareDescent() {
  return (
    <div className="flex w-full text-center justify-center h-screen items-center">
      <p className="text-2xl text-mforange font-semibold">
        You have declared your descent. Enforcement will follow
      </p>
    </div>
  );
}

export default DeclareDescent;
