import { blockchainNumberToClient, clientNumberToBlockchain, convertBpToPercentage } from "@/utils/helpers";
import { cacheEURMBalance, cacheInterestData } from "@/cache/Mutations";
import { findEvent, getTransactionHash, isConnected } from "../helpers";
import { format, fromUnixTime } from "date-fns";
import { approve } from "../tokens/ERC20";
import { getAFPSmartContract } from "@/blockchain/connectors";

/**
 * Handles approval, deposit and toast messages for depositing into an AFP
 * @param from address from which the deposit is send
 * @param to address of the pool
 * @param amount to deposit
 * @returns true if the deposit was successful, false otherwise
 */
export async function deposit(from: string, to: string, amount: number): Promise<[boolean, string]> {
  if(!isConnected()) {
    return [false, ""];
  }
  const { contract, gasPrice } = await getAFPSmartContract(to);
  // Check approval
  const approved = await approve(from, to, amount);
  if(!approved) return [false, ""];

  let tx: any;

  // Deposit
  try {
    tx = await contract?.methods.deposit(clientNumberToBlockchain(amount))
      .send({
        from,
        gasPrice
      });
    // Find the "DepositSuccessful" event in the receipt
    const event = findEvent("DepositSuccessful", tx);
    if (!event) {
      return [false , tx.transactionHash];
    }
    return [true , tx.transactionHash];
  } catch (error) {
    return [false, getTransactionHash(tx, error)];
  }
}

/**
 * Handles making an interest payment
 * @param amount to pay
 * @param from address that needs to pay
 * @param to address of the pool
 * @returns true if the payment was successful, false otherwise
 */
export async function makeInterestPayment(amount: number, from: string, to: string): Promise<[boolean, string]> {
  // Connect Contract
  if(!isConnected()) {
    return [false, ""];
  }
  const { contract, gasPrice } = await getAFPSmartContract(to);

  // Check if approval is already there
  const approved = await approve(from, to, amount);
  if(!approved) return [false, ""];

  let tx: any;

  // Make interest payment
  try {
    tx = await contract.methods.handleIncomingInterestPayment()
      .send({
        from, gasPrice
      });

    // Find the "IncomingInterestPaymentSuccessful" event in the receipt
    const event = findEvent("IncomingInterestPaymentSuccessful", tx);
    if (!event) {
      return [false , tx.transactionHash];
    }
    await cacheInterestData(from, to, true);
    return [true , tx.transactionHash];
  } catch (error) {
    return [false, getTransactionHash(tx, error)];
  }
}

/**
 * Executes the claim interest function of an AFP
 * @param from address that sends the transaction
 * @param address address of the pool
 * @returns true if the claim was successful, false otherwise
 */
export async function claimInterest(from: string, address: string): Promise<[boolean, string]> {
  // Connect Contract
  if(!isConnected()) {
    return [false, ""];
  }
  const { contract, gasPrice } = await getAFPSmartContract(address);

  let tx: any;

  try {
    // Execute claim interest
    tx = await contract.methods.claimInterest()
      .send({
        from, gasPrice
      });
    // Find the "ClaimSuccessful" event in the receipt
    const event = findEvent("ClaimSuccessful", tx);
    if (!event) {
      return [false, tx.transactionHash];
    }
    await cacheEURMBalance(from);
    return [true, tx.transactionHash];
  } catch (error) {
    return [false, getTransactionHash(tx, error)];
  }
}

export async function withdraw(from: string, address: string, amount: number): Promise<[boolean, string]> {
  // Connect Contract
  if(!isConnected()) {
    return [false, ""];
  }
  const { contract, gasPrice } = await getAFPSmartContract(address);

  let tx: any;

  try {
    const AFTAddress = await contract.methods.AFT().call();
    // Check if approval of AFT is already there
    const approved = await approve(from, address, amount, AFTAddress);
    if(!approved) return [false, ""];

    // Execute withdrawal
    tx = await contract.methods.withdraw()
      .send({
        from, gasPrice
      });
    // Find the "WithdrawalSuccessful" event in the receipt
    const event = findEvent("WithdrawalSuccessful", tx);
    if (!event) {
      return [false, tx.transactionHash];
    }
    await cacheEURMBalance(from);
    return [true, tx.transactionHash];
  } catch (error) {
    return [false, getTransactionHash(tx, error)];
  }
}

/**
 * Helper function that fetches an AFP's owed interest
 * @param address Address of AFP contract
 * @returns currently owed amount and the next owed amount
 */
export async function getCurrentAndNextOwedInterestAmount(
  address: string
): Promise<{ currentOwedAmount: number, nextOwedAmount: number }> {
  if(address === "0x0" || address === "") return { currentOwedAmount: 0, nextOwedAmount: 0 };
  // Connect Contract
  const { contract } = await getAFPSmartContract(address);
  // Get Blockchain data
  const lastInterestPaid = await contract.methods.lastInterestPaid().call();
  const cumulativeInterest = await contract.methods.getCumulativeInterestPerToken().call();
  const financingLimit = await contract.methods.financingLimit().call();
  const interest = await contract.methods.interest().call();

  // Format data
  const currentOwedInterest = Number(cumulativeInterest) - Number(lastInterestPaid);

  const nextOwedInterest = currentOwedInterest + Number(interest);
  const currentOwedAmount = convertBpToPercentage(currentOwedInterest) * blockchainNumberToClient(Number(financingLimit));
  const nextOwedAmount = convertBpToPercentage(nextOwedInterest) * blockchainNumberToClient(Number(financingLimit));
  return { currentOwedAmount, nextOwedAmount };
}

/**
 * Helper for getting the next date on which an SP has to make an interest payment
 * @param address Address of AFP contract
 * @returns the date on which the next interest payment is due
 */
export async function getPaymentDates(address: string): Promise<{ lastPaymentDate: number, nextPaymentDate: number }> {
  const empty = { lastPaymentDate: 0, nextPaymentDate: 0, isEndPaymentDue: false };
  if(address === "0x0" || address === "") return empty;
  // Connect Contract
  const { contract } = await getAFPSmartContract(address);
  // Get Blockchain data
  const startDate = await contract.methods.startDate().call();
  // Exit if contract not yet active
  if(Number(startDate) === 0) return empty;

  const payoutPeriod = await contract.methods.payoutPeriod().call();
  const payoutPeriodMillis = Number(payoutPeriod) * 1000;
  const now = new Date();
  const start = new Date(fromUnixTime(Number(startDate)));

  // Calculate the time difference since the start date and divide by the payout period
  const periodsSinceStart = Math.ceil((now.getTime() - start.getTime()) / payoutPeriodMillis);
  // Calculate the next payout date
  const nextPayoutDate = new Date(start.getTime() + periodsSinceStart * payoutPeriodMillis);
  // Get the just passed payment date
  const lastPaymentDate = new Date(start.getTime() + (periodsSinceStart - 1) * payoutPeriodMillis);
  return {
    lastPaymentDate: lastPaymentDate.getTime(),
    nextPaymentDate: nextPayoutDate.getTime(),
  };
}

export async function getEndDate(address: string) {
  const { contract } = await getAFPSmartContract(address);
  const endDate = await contract.methods.endDate().call();
  return format(new Date(fromUnixTime(Number(endDate))), "dd/MM/yyy");
}

export async function getStartDate(address: string) {
  const { contract } = await getAFPSmartContract(address);
  const startDate = await contract.methods.startDate().call();
  return format(new Date(fromUnixTime(Number(startDate))), "dd/MM/yyy");
}

export async function getFinancingLimit(address: string) {
  const { contract } = await getAFPSmartContract(address);
  const financingLimit = await contract.methods.financingLimit().call();
  return blockchainNumberToClient(financingLimit);
}
