import { AssetFinancingStatus, AssetType, Investment } from "@/graphql/__generated__/graphql-operations";
import DoughnutChart from "./DoughnutGraph";
import { ProgressBar } from "@/components/shared";
import { formatNumberLocale } from "@/utils/helpers";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";
import { useFinancierData } from "@/hooks";

function PortfolioDiversification() {

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();
  const financierData = useFinancierData();

  const investments = financierData?.investments as Investment[] ?? [];

  // Function: Consolidates investments by serviceproviderID and sums their amounts.
  const mergeInvestments = (investments: Investment[]) => {
    return investments.reduce((acc: Investment[], investment) => {
      const existingInvestment = acc.find((item) => item.serviceproviderID === investment.serviceproviderID);
      if (existingInvestment) {
        existingInvestment.amount += investment.amount;
      } else {
        acc.push({ ...investment });
      }
      return acc;
    }, []);
  };

  // Function: Sums the amounts of an array of investments.
  const calculateTotalAmount = (investments: Investment[]) => {
    return investments.reduce((total, investment) => total + investment.amount, 0);
  };

  // Function: Calculates the percentage of an investment total relative to the total invested amount.
  const calculatePercentage = (investmentTotal: number, totalAmount: number) => {
    if (totalAmount === 0 || isNaN(totalAmount)) return 0;
    return (investmentTotal / totalAmount) * 100;
  };

  const filteredInvestments = mergeInvestments(investments);

  // Function: Filters investments by asset type.
  const investmentsByType = (type: AssetType) => {
    return filteredInvestments.filter(investment => investment.assetfinancingpool.assetType === type);
  };

  // Investments by type
  const receivableInvestments = investmentsByType(AssetType.Receivable);
  const inventoryInvestments = investmentsByType(AssetType.Inventory);
  const dryPowderInvestments = filteredInvestments.filter(investment => investment.assetfinancingpool.status === AssetFinancingStatus.Open);

  // Total invested amounts
  const totalInvestedAmount = calculateTotalAmount(filteredInvestments);
  const receivableInvestmentsTotal = calculateTotalAmount(receivableInvestments);
  const inventoryInvestmentsTotal = calculateTotalAmount(inventoryInvestments);
  const dryPowderTotal = calculateTotalAmount(dryPowderInvestments);

  const diversityStats = [
    {
      title: t("dashboard.financiergraph.inventory"),
      total: inventoryInvestmentsTotal,
      progressBarVariant: "primary",
    },
    {
      title: t("dashboard.financiergraph.receivables"),
      total: receivableInvestmentsTotal,
      progressBarVariant: "secondary",
    },
    {
      title: t("dashboard.financiergraph.drypowder"),
      total: dryPowderTotal,
      progressBarVariant: "tertiary",
    },
  ];

  return (
    <div className="bg-white dark:bg-mfdarklight rounded-md shadow-lg">
      <h1 className="px-4 pt-4 font-semibold text-lg select-none dark:text-white">
        {t("dashboard.financiergraph.title")}
      </h1>

      <div className="flex flex-wrap gap-8">
        <DoughnutChart
          receivableInvestments={receivableInvestments}
          inventoryInvestments={inventoryInvestments}
        />

        <div className="flex flex-col gap-2 flex-1 p-4 select-none" data-cy="portfolio-diverse-stats-container">
          {diversityStats.map(({ title, total, progressBarVariant }) => (
            <div className="flex items-center" key={title}>
              <div className="flex flex-col flex-1 mr-4">
                <div className="flex justify-between gap-4 text-gray-500 dark:text-gray-300">
                  <p>{title}</p>
                  <p>{formatNumberLocale(intl, total, "currency")}</p>
                </div>
                <ProgressBar
                  percentage={calculatePercentage(total, totalInvestedAmount)}
                  label=""
                  variant={progressBarVariant}
                  showPercentage={false}
                />
              </div>
              <p className="text-mfpurple dark:text-mforange w-[60px] text-center">
                {calculatePercentage(total, totalInvestedAmount).toFixed(2)}%
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PortfolioDiversification;
