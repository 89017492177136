import { ReactNode } from "react";

export type SelectState = {
  name?: string;
  isValid?: boolean;
  value?: string | undefined;
}

type SelectProps = {
  id?: string;
  className?: string;
  optionValue?: string;
  optionText?: string;
  code?: string;
  label?: string;
  name?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  children?: ReactNode | ReactNode[] | undefined;
  defaultValue?: string | undefined;
  labelPlacement?: string;
  onChange: (_event: SelectState) => void;
};

/**
 * Reusable `Select` component styled with Tailwind CSS.
 * Can be used to render a dropdown selection menu.
 *
 * @param {SelectProps} props - The properties for the select component.
 * @returns {JSX.Element} The rendered select component.
 *
 * @example
 * ```tsx
 * import TwSelect from "./TwSelect";
 * import TwSelectOption from "./TwSelectOption";
 *
 * function MyComponent() {
 *   const handleSelectChange = (state: SelectState) => {
 *     console.log(state);
 *   };
 *
 *   return (
 *     <TwSelect
 *       label="Select an option"
 *       onChange={handleSelectChange}
 *       defaultValue="option1"
 *     >
 *       <TwSelectOption value="option1" text="Option 1" />
 *       <TwSelectOption value="option2" text="Option 2" />
 *     </TwSelect>
 *   );
 * }
 * ```
 */
function TwSelect(props: SelectProps): JSX.Element {
  /**
   * Handles the change event for the select element.
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} event - The change event from the select element.
   */
  function handleOnChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value;

    // Replace this with a proper validation function if needed
    const isValid = true;

    const state: SelectState = {
      name: props.name,
      value,
      isValid,
    };

    props.onChange(state);
  }

  return (
    <div data-cy="twselect" className="relative">
      {
        props.label && props.labelPlacement !== "stacked" &&
        <label data-cy="twselect-label" htmlFor="inputSelect" className="mf-select-label">
          {props.label}
        </label>
      }
      {
        props.labelPlacement === "stacked" &&
        <label data-cy="twselect-label-stacked" className="select-none">
          {props.label}
        </label>
      }
      <select
        data-cy="twselect-select"
        id={props.id}
        onChange={handleOnChange}
        className={`mf-select ${props.className}`}
        name={props.name}
        defaultValue={props.defaultValue}
        required={props.required}
        disabled={props.disabled}
      >
        {props.children}
      </select>
    </div>
  );
}

export default TwSelect;
