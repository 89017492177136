/**
 * A functional React component that displays a tag-like UI element with a label.
 * This component renders a small, styled `div` containing the provided label.
 *
 * @param {Object} props - The component props.
 * @param {string} props.label - The text label to be displayed inside the tag.
 *
 * @returns {JSX.Element} The rendered tag item component.
 *
 * @example
 * // Usage of the TagItem component
 * <TagItem label="New" />
 */
function TagItem(props: {label: string}) {
  const { label } = props;
  return(
    <div data-cy="tagitem" className="text-xs border-[1px] py-1 rounded-sm bg-mforange border-mforange text-white px-1">
      {label}
    </div>
  );
}

export default TagItem;
