import { Pagination, TwTableCardList, ScrollToTopButton, PaginationContentScrollable } from "@/components/shared";
import { DeployPageModals } from "@/constants/enums";
import {
  ServiceProvider,
  CompanyEdge,
  GetPaginatedCompaniesQuery,
  GetPaginatedCompaniesQueryVariables,
  useGetPaginatedCompaniesQuery,
} from "@/graphql/__generated__/graphql-operations";
import { useRef, useState } from "react";
import CompanyFilter from "./CompanyFilter";
import ServiceProviderDetailsModal from "./modals/ServiceProviderDetailsModal";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import useScrollToTop from "@/hooks/useScrollToTop";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import { getColumns } from "./columns";
import { getColumnHeaderValues } from "@/components/shared/table/utils";
import { DataTable } from "@/components/shared/table";
import { ActionButton } from "@/components/shared/table/Table";

interface PaginationRef {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedCompaniesQueryVariables>) => void;
}

function DeploySmartContracts() {
  // States

  const [currentSpId, setCurrentSpId] = useState<string>("");
  const [showModal, setShowModal] = useState<DeployPageModals>(DeployPageModals.NoModal);

  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();
  const mdBreakpoint = useMdBreakpoint();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });

  // Pagination config
  const paginationRef = useRef<PaginationRef | null>(null);
  const paginationName = "paginatedCompanies";

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onFilterSubmit = (variables: GetPaginatedCompaniesQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    paginationRef.current?.triggerHandleFilterSubmit(variables);
  };

  const handleServiceProviderClick = (company: ServiceProvider) => {
    setCurrentSpId(company._id);
    setShowModal(DeployPageModals.ServiceProviderDetails);
  };

  const getActionButtons = (index: number, dataObj: ServiceProvider) => {
    return [
      <ActionButton key={"user-action-btn-"+index} dataObj={dataObj} fn={handleServiceProviderClick} text={t("common:buttons.details")} />
    ];
  };

  // Table headers and sort fields for TwTableCardList
  const columnHeaderValues = getColumnHeaderValues(getColumns, intl, t);

  return (
    <div className="mf-table-container mf-flex-y-fill overflow-visible pt-4">
      <Pagination<CompanyEdge, GetPaginatedCompaniesQuery, GetPaginatedCompaniesQueryVariables>
        ref={paginationRef} props={{queryHook: useGetPaginatedCompaniesQuery, paginationName, className: "mx-4 mb-4"}}
      >
        {(dataSlice, loading, displayAmount) => (
          <PaginationContentScrollable>
            {/* Companies filter */}
            <div className="mf-flex-y-fill mx-4">
              <div className="mb-4">
                <CompanyFilter
                  displayAmount={displayAmount as number}
                  onFilterSubmit={onFilterSubmit}
                />
              </div>
              <PaginationContentScrollable ref={scrollRef}>
                {
                  mdBreakpoint ?
                    <DataTable
                      loading={loading}
                      displayAmount={displayAmount as number}
                      data={dataSlice.map(edge => edge.node) ?? []}
                      columns={getColumns(intl, undefined, handleServiceProviderClick)}
                    />
                    :
                    <TwTableCardList
                      dataArray={dataSlice.map((edge) => edge.node)}
                      displayAmount={displayAmount as number}
                      actionButtons={getActionButtons}
                      tableHeaders={columnHeaderValues}
                      defaultSortField="name"
                      dataLoading={loading}
                      dataType="Company"
                    />
                }
              </PaginationContentScrollable>
            </div>
          </PaginationContentScrollable>
        )}
      </Pagination>

      {
        showModal === DeployPageModals.ServiceProviderDetails &&
          <ServiceProviderDetailsModal
            setShowModal={setShowModal}
            currentSpId={currentSpId}
          />
      }

      <ScrollToTopButton scrollToTop={scrollToTop} isVisible={isVisible} />
    </div>
  );
}

export default DeploySmartContracts;
