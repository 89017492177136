import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { WithdrawalCardModals } from "@/constants/enums";
import WithdrawalDetailsCard from "../WithdrawalDetailsCard";
import { useWeb3 } from "@/context/Web3Context";
import { propsWithdrawalModal } from "../props";
import { useTranslation } from "react-i18next";

function MakeWithdrawalModal(props: propsWithdrawalModal) {
  // Props
  const {
    setActiveGroupedWithdrawals,
    groupedAvailableWithdrawals,
    setShowModal,
    setActiveTotalWithdrawalAmount
  } = props;

  // Hooks
  const {
    connectWallet,
    isConnected,
  } = useWeb3();
  const { t } = useTranslation(["modals", "components", "common"]);

  return (
    <BlankModal
      setShowModal={() => { setShowModal(WithdrawalCardModals.NoModal); }}
      className="md:px-10 lg:px-40 xl:px-72"
      customContentCSS="pt-3 w-screen bg-white rounded-md p-4 dark:bg-mfdarklight mx-4 md:mx-0 overflow-y-visible"
      title={t("availablewithdrawals.title")}
    >
      <BlankModalContent>
        <div data-cy="withdrawal-makewithdrawal-modal">
          {/* Connect wallet */}
          {!isConnected && (
            <div className="flex justify-between items-center my-4 space-x-2">
              <p className="text-sm font-semibold dark:text-white">
                {t("common:text.withdrawconnectwallet")}.
              </p>
              <button
                data-cy="withdrawal-connectwallet-button"
                className="mf-btn-action-small-primary-filled"
                onClick={connectWallet}
              >
                {t("common:text.connectwallet")}
              </button>
            </div>
          )}
          {/* Card container */}
          <div className="space-y-4 max-h-[500px] overflow-y-auto">
            {groupedAvailableWithdrawals.length === 0 && (
              <p className="text-sm dark:text-white">
                {t("availablewithdrawals.noavailablewithdrawals")}
              </p>
            )}
            {/* SP withdrawal Card */}
            {
              groupedAvailableWithdrawals.map((groupedWithdrawals) => (
                <WithdrawalDetailsCard
                  key={`spWithdrawal:${groupedWithdrawals.investmentID}`}
                  groupedWithdrawals={groupedWithdrawals}
                  setShowModal={setShowModal}
                  setActiveGroupedWithdrawals={setActiveGroupedWithdrawals}
                  disabled={!isConnected}
                  setActiveTotalWithdrawalAmount={setActiveTotalWithdrawalAmount}
                />
              ))
            }
          </div>
        </div>
      </BlankModalContent>
      <BlankModalFooter>
        <div>
          <button
            data-cy="withdrawal-makewithdrawal-cancel-button"
            className="mf-btn-action-small-primary-outline mt-4"
            onClick={() => setShowModal(WithdrawalCardModals.NoModal)}
          >
            {t("common:buttons.cancel")}
          </button>
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default MakeWithdrawalModal;
