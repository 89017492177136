import { ChevronDownIcon, ChevronUpIcon, QueueListIcon, Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { AssetFinancingStatus, Investment } from "@/graphql/__generated__/graphql-operations";
import ServiceProviderDetails from "../../components/shared/ServiceProviderDetails";
import { useState } from "react";
import TextInfoGroup from "@/components/shared/common/TextInfoGroup";
import TextInfoGroupAlt from "@/components/shared/common/TextInfoGroupAlt";
import { IoLogoFacebook, IoLogoLinkedin, IoLogoTwitter } from "react-icons/io5";
import { calculateAnnualizedInterest, formatDateLocale, formatDaysString, formatNumberLocale, generateInterestString } from "@/utils/helpers";
import { useIntl } from "react-intl";
import { handleBlockchainExplorerLink } from "@/blockchain/helpers";
import { useNavigate } from "react-router-dom";
import { calculateClosingDate, calculatePercentageOwned, calculatePoolCloseTime } from "./utils";
import { useTranslation } from "react-i18next";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/Popover";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/Collapsible";
import { isDarkModeVar } from "@/client";

type Props = {
  investment: Investment;
}

function ServiceProviderPortfolioCard({ investment }: Props) {

  const backgroundColour = isDarkModeVar() === "true" ? "bg-mfdarklighter text-white border-transparent shadow-gray-900": "bg-white";
  const hoverColour = isDarkModeVar() === "true" ? "hover:bg-white/20": "hover:bg-gray-100";

  // States
  const [open, setOpen] = useState(false);

  // Hooks
  const intl = useIntl();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Constants
  const afp = investment.assetfinancingpool;
  const activationDate = afp?.activationdate;
  const financingLimit = afp?.financinglimit;
  const dayslocked = afp?.dayslocked;
  const afpStatus = afp?.status;
  const payoutPeriod = afp?.rewardpayoutperiod;
  const interestRate = afp?.interest;
  const isActive = afpStatus === AssetFinancingStatus.Active;
  const isOnboarding = afpStatus === AssetFinancingStatus.Onboarding;
  const isOpen = afpStatus === AssetFinancingStatus.Open;
  const isClosed = afpStatus === AssetFinancingStatus.Closed;

  const handlePoolActivityHyperlink = () => {
    navigate(`/user/poolactivity?poolID=${afp?._id}&spID=${afp?.spdetails?._id}&poolStatus=${afp?.status}&financingLimit=${afp?.financinglimit}&poolAddress=${afp?.pooladdress}`);
  };

  const handleAssetsHyperlink = () => {
    navigate(`/user/assetoverview?poolID=${afp?._id}&spID=${afp?.spdetails?._id}&poolStatus=${afp?.status}`);
  };

  const getFundsLockedKPIDetails = (status: AssetFinancingStatus) => {
    switch (status) {
    case AssetFinancingStatus.Open:
      return [t("portfoliopage.portfoliocard.fundslockedfor"), formatDaysString(dayslocked)];
    case AssetFinancingStatus.Active:
      return [t("portfoliopage.portfoliocard.fundsunlockin"), calculatePoolCloseTime(activationDate, dayslocked)];
    case AssetFinancingStatus.Closed:
      return [t("portfoliopage.portfoliocard.fundsunlockedsince"), calculateClosingDate(intl, activationDate ?? "", dayslocked)];
    default:
      return ["", ""];
    }
  };

  // HTML Stuff
  const financialDetails = [
    {
      label: getFundsLockedKPIDetails(afpStatus)![0] as string,
      content: getFundsLockedKPIDetails(afpStatus)![1] as string
    },
    {
      label: t("portfoliopage.portfoliocard.interestpa"),
      content: calculateAnnualizedInterest(interestRate, payoutPeriod)
    },
    {
      label: t("portfoliopage.portfoliocard.yourinvestment"),
      content: formatNumberLocale(intl, investment.amount, "currency"),
      subText: calculatePercentageOwned(investment.amount, financingLimit)
    }
  ];

  const companyDetails = [
    {
      label: t("portfoliopage.portfoliocard.foundingyear"),
      content: afp?.spdetails?.companydetails?.foundingyear ?? t("portfoliopage.portfoliocard.notavailable")
    },
    {
      label: t("portfoliopage.portfoliocard.yearlyrevenue"),
      content: !isNaN(afp?.spdetails?.companydetails?.yearlyrevenue as number) ? formatNumberLocale(intl, afp.spdetails.companydetails?.yearlyrevenue as number, "currency") : t("portfoliopage.portfoliocard.notavailable")
    },
    {
      label: t("portfoliopage.portfoliocard.website"),
      content: afp.spdetails?.companydetails?.webpresence?.website ?? t("portfoliopage.portfoliocard.notavailable"),
      linkText: afp.spdetails?.companydetails?.webpresence?.website ?? t("portfoliopage.portfoliocard.notavailable"),
      isLink: afp.spdetails?.companydetails?.webpresence?.website
    }
  ];

  const poolDetails = [
    {
      label: t("portfoliopage.portfoliocard.poolsize"),
      content: formatNumberLocale(intl, financingLimit, "currency"),
      visibility: ["ALL"]
    },
    {
      label: t("portfoliopage.portfoliocard.totalinvested"),
      content: formatNumberLocale(intl, afp?.amountinvested, "currency"),
      visibility: [AssetFinancingStatus.Closed, AssetFinancingStatus.Onboarding, AssetFinancingStatus.Open]
    },
    {
      label: t("portfoliopage.portfoliocard.investmentreward"),
      content: generateInterestString((afp.interest/100).toString()+"%", investment.assetfinancingpool.rewardpayoutperiod),
      visibility: ["ALL"]
    },
    {
      label: t("portfoliopage.portfoliocard.dayslocked"),
      content: formatDaysString(dayslocked),
      visibility: [AssetFinancingStatus.Closed, AssetFinancingStatus.Onboarding, AssetFinancingStatus.Active]
    }
  ];

  const poolDates = [
    {
      label: t("portfoliopage.portfoliocard.creationdate"),
      content: formatDateLocale(intl, afp?.createdAt)
    },
    {
      label: t("portfoliopage.portfoliocard.activationdate"),
      content: (isOpen || isOnboarding) ? t("portfoliopage.portfoliocard.notavailable") : formatDateLocale(intl, activationDate)
    },
    {
      label: t("portfoliopage.portfoliocard.closedate"),
      content: calculateClosingDate(intl, activationDate ?? "", dayslocked)
    }
  ];

  const poolAddresses = [
    {
      label: t("portfoliopage.portfoliocard.pooladdress"),
      content: handleBlockchainExplorerLink("address", afp?.pooladdress as string),
      linkText: afp?.pooladdress as string,
      isLink: true
    },
    {
      label: t("portfoliopage.portfoliocard.tokenaddress"),
      content: handleBlockchainExplorerLink("token", afp?.tokenaddress as string),
      linkText: afp?.tokenaddress as string,
      isLink: true
    }
  ];

  const socialLinks = [
    {
      href: afp?.spdetails?.companydetails?.webpresence?.facebook,
      icon: <IoLogoFacebook className="mf-social-icon" />
    },
    {
      href: afp?.spdetails?.companydetails?.webpresence?.linkedin,
      icon: <IoLogoLinkedin className="mf-social-icon" />
    },
    {
      href: afp?.spdetails?.companydetails?.webpresence?.twitter,
      icon: <IoLogoTwitter className="mf-social-icon" />
    }
  ];

  const viewMorePopoverLinks: any[] = [
    {
      name: t("portfoliopage.portfoliocard.poolactivity"),
      icon: <QueueListIcon className="w-6 h-6 dark:text-white" />,
      onClick: () => {
        handlePoolActivityHyperlink();
      }
    },
    {
      name: t("portfoliopage.portfoliocard.assets"),
      icon: <Square3Stack3DIcon className="w-6 h-6 dark:text-white" />,
      onClick: () => {
        handleAssetsHyperlink();
      }
    }
  ];

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="bg-white px-4 py-4 shadow-lg rounded-md select-none lg:m-0 dark:bg-mfdarklight">
        {/* Top container */}
        <div className="flex justify-between items-start w-full flex-wrap lg:flex-nowrap relative">
          <div className="flex gap-2 flex-wrap lg:flex-nowrap mr-6">
            <div className="">
              <ServiceProviderDetails
                financingStatus={afpStatus}
                serviceProviderId={afp?.spdetails?._id}
                className="lg:w-[280px]"
              />
            </div>
            {/* Details */}
            <div className="flex flex-wrap gap-y-2 gap-x-10 mb-2 lg:mb-0 mr-6">
              {
                financialDetails.map((detail) => (
                  <TextInfoGroup
                    key={`financialdetail:${detail.label}`}
                    label={detail.label}
                    content={detail.content}
                    subText={detail.subText}
                  />
                ))
              }
            </div>
          </div>

          {/* Buttons */}
          <div className="flex gap-2 justify-end lg:justify-normal w-full lg:w-auto">
            <CollapsibleTrigger data-cy="spPortfolioCardExpandBtn">
              <div className="mf-btn-action-large-outline">
                {
                  open ?
                    <div className="flex items-center dark:text-white">
                      <ChevronUpIcon className="w-5 h-5 mr-[2px]" />
                      <p className="hidden md:flex">
                        {t("portfoliopage.portfoliocard.collapse")}
                      </p>
                    </div>:
                    <div className="flex items-center dark:text-white">
                      <ChevronDownIcon className="w-5 h-5 mr-[2px]" />
                      <p className="hidden md:flex">
                        {t("portfoliopage.portfoliocard.expand")}
                      </p>
                    </div>
                }
              </div>
            </CollapsibleTrigger>
            {/* Main button */}
            <Popover>
              <PopoverTrigger>
                <button
                  data-cy="spPortfolioCardViewMoreBtn"
                  disabled={(isOpen || isOnboarding)}
                  className={`${(isOpen || isOnboarding) ? "mf-btn-action-large-disabled-filled":"mf-btn-action-large-primary-filled"} whitespace-nowrap`}
                >
                  {t("portfoliopage.portfoliocard.viewmore")}
                </button>
              </PopoverTrigger>
              <PopoverContent
                align="end"
                className={`${backgroundColour} shadow-lg rounded-md p-2 pt-3
                          flex flex-col max-w-[200px] border-0`}
              >
                {
                  viewMorePopoverLinks.map(({name, icon, onClick}) => (
                    <button
                      onClick={onClick}
                      key={`viewmore-popover-${name}`}
                      className={`flex items-center gap-2 py-1 px-2 rounded-md
                              ${hoverColour}`}
                    >
                      <div className="">{icon}</div>
                      <div className="whitespace-nowrap">{name}</div>
                    </button>
                  ))
                }
              </PopoverContent>
            </Popover>
          </div>
        </div>

        {/* Bottom container */}
        {/* Expanded Content */}
        <div>
          <CollapsibleContent>
            <div className="lg:ml-[81px]">
              {/* Pool details */}
              <div className="flex items-center gap-x-10 my-4 flex-wrap">
                {
                  poolDetails.map((detail) => (
                    ((isActive || isClosed || isOpen) && detail.visibility.includes(afpStatus)|| detail.visibility.includes("ALL")) &&
                      <TextInfoGroup
                        key={`pooldetail:${detail.content}`}
                        label={detail.label}
                        content={detail.content}
                      />
                  ))
                }
              </div>

              {/* Pool dates */}
              <div className="flex flex-wrap items-center gap-x-10 gap-y-2 my-4">
                {
                  poolDates.map((detail) => {
                    return (
                      <TextInfoGroupAlt
                        key={`pool-date-${detail.label}`}
                        label={detail.label}
                        content={detail.content}
                      />
                    );
                  })
                }
              </div>

              {/* Pool and Token addresses */}
              <div className="flex flex-col gap-4 mb-4">
                {
                  poolAddresses.map((address) => {
                    return (
                      <TextInfoGroupAlt
                        contentCSS="break-all"
                        key={`pool-address-${address.label}`}
                        label={address.label}
                        content={address.content}
                        linkText={address.linkText}
                        isLink={address.isLink}
                      />
                    );
                  })
                }
              </div>

              {/* Description */}
              <div className="text-sm mb-4 dark:text-white">
                <p className="font-semibold">
                  {t("portfoliopage.portfoliocard.description")}
                </p>
                <p>
                  {afp?.spdetails?.companydetails?.description}
                </p>
              </div>

              {/* Company details */}
              <div className="md:flex space-y-2 md:space-y-0 md:gap-x-8">
                {
                  companyDetails.map((detail) => {
                    return (
                      <TextInfoGroupAlt
                        key={`company-detail-${detail.label}`}
                        label={detail.label}
                        content={detail.content}
                        linkText={detail.content}
                        isLink={detail.isLink}
                      />
                    );
                  })
                }
                {/* Social icons */}
                <div className="flex gap-x-3 min-h-max items-center">
                  {
                    socialLinks.map((link) => {
                      return (
                        link.href && // Only show icon if link is available
                          <a
                            key={`social-link-${link.href}`}
                            className="group cursor-pointer active:scale-95"
                            target="_blank" rel="noreferrer"
                            href={link.href}
                          >
                            {link.icon}
                          </a>
                      );
                    })
                  }
                </div>
              </div>
            </div>
          </CollapsibleContent>
        </div>
      </div>
    </Collapsible>
  );
}

export default ServiceProviderPortfolioCard;
