import { ActionButton, TableColumnHeader } from "@/components/shared/table/Table";
import { Company } from "@/graphql/__generated__/graphql-operations";
import { ColumnDef } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { IntlShape } from "react-intl";

export const getColumns = (intl: IntlShape, _t?: TFunction | undefined, actionFn?: any): ColumnDef<Company, string>[] => {
  return (
    [
      {
        accessorKey: "name",
        header: ({ column }) => <TableColumnHeader text="Service Provider" column={column} />,
      },
      {
        accessorKey: "assetfinancingpool.status",
        header: ({ column }) => <TableColumnHeader text="Status" column={column} />,
      },
      {
        accessorKey: "_id",
        header: ({ column }) => <TableColumnHeader text="ID" column={column} />,
      },
      {
        accessorKey: "assetfinancingpool.pooladdress",
        header: ({ column }) => <TableColumnHeader text="Pool Address" column={column} />,
      },
      {
        id: "actions",
        cell: ({ row }) => <ActionButton dataObj={row.original} fn={actionFn} />,
      },
    ]
  );
};
