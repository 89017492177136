import { FunnelIcon } from "@heroicons/react/24/outline";
import { CreditLimitRequestStatus, GetPaginatedCreditLimitRequestsQueryVariables } from "@/graphql/__generated__/graphql-operations";
import { updatePaginationState } from "@/cache/appstate/WriteQueries";
import { usePaginationState } from "@/hooks/cache/appState/usePaginationState";
import { PaginationState } from "@/types";
import { TagItem, RadioButton } from "@/components/shared";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/Popover";
import { isDarkModeVar } from "@/client";

type Props = {
  className?: string;
  onFilterSubmit: (_variables: GetPaginatedCreditLimitRequestsQueryVariables) => void;
}

type RadioOption = {
  label: string;
  value: CreditLimitRequestStatus;
}

/**
 * Filter component for Credit Limit Requests
 * @param {Props} props
 * @returns {JSX.Element} CLRFilterComponent component
 */
function CLRFilterComponent({onFilterSubmit}: Props): JSX.Element {
  // Pagination
  const paginationState: PaginationState["paginatedCreditLimitRequests"] = usePaginationState("paginatedCreditLimitRequests");
  const { filters, first } = paginationState;

  // Constants
  const backgroundColour = isDarkModeVar() === "true" ? "bg-mfdarklighter text-white border-transparent shadow-gray-900": "bg-white";

  // Hooks
  const { t } = useTranslation();

  const statusOptions: RadioOption[] = [
    { label: "Pending", value: CreditLimitRequestStatus.Pending },
    { label: "Approved", value: CreditLimitRequestStatus.Approved },
    { label: "Denied", value: CreditLimitRequestStatus.Denied },
  ];

  /**
   * OnChange handler for radio buttons
   * @param {string} key
   * @param {string} value
   */
  const onChangeRadioButton = (key: string, value: string) => {
    if (key === "status") {
      updatePaginationState({paginatedCreditLimitRequests: {filters: {status: value as CreditLimitRequestStatus}}});
    }
  };

  /**
   * OnClick handler for reset button
   */
  function handleResetClick() {
    // Update cache first
    updatePaginationState({
      paginatedCreditLimitRequests: {
        filters: {
          status: null
        }
      }
    });
    // Call the parent function
    onFilterSubmit({
      first,
      status: null,
    });
    toast.success(t("common:text.filterremoved"), {toastId: "investmentFilterRemoved"});
  }

  /**
   * OnClick handler for submit button
   */
  function handleSubmitClick() {
    onFilterSubmit({
      first,
      status: filters.status,
    });
    toast.success(t("common:text.filterapplied"), {toastId: "investmentFilterApplied"});
  }

  return (
    <div className="flex items-center justify-between mx-4 mb-2 mt-2">
      {/* Filter box */}
      <Popover>
        <PopoverTrigger
          data-cy="clr-filter-popover-trigger"
          className="border-[1px] border-gray-200 rounded-md flex item-center h-[40px] w-[300px] px-2 py-1
                  dark:border-white relative select-none"
        >
          <div className="mr-2 h-full items-center flex">
            <FunnelIcon
              className="w-5 h-5 dark:text-white flex-shrink-0"
            />
            {
              filters.status === null &&
              <p className="text-sm text-gray-400 pl-2">
                {t("tokentransactions.filters.title")}
              </p>
            }
          </div>
          <div className="flex items-center space-x-2 select-none">
            {
              filters.status !== null &&
              <TagItem label={filters.status as CreditLimitRequestStatus} />
            }
          </div>
        </PopoverTrigger>
        <PopoverContent
          data-cy="clr-filter-popover-content"
          className={`shadow-lg h-auto rounded-md p-4 font-Montserrat z-[200] md:min-w-[300px] mt-1 ${backgroundColour}`}
        >
          {/* Status filters */}
          <p className="font-semibold text-sm mb-1">{t("tokentransactions.filters.status")}</p>
          {statusOptions.map(option => (
            <RadioButton
              key={option.value}
              option={option}
              checked={filters.status === option.value}
              onChange={(value) => onChangeRadioButton("status", value.toString())}
            />
          ))}

          {/* Buttons */}
          <div className="flex items-center justify-between mt-4">
            <button
              onClick={handleResetClick}
              className="px-2 py-1 rounded-sm active:scale-95 border-[1px] border-gray-800 text-sm dark:border-white"
            >
              {t("tokentransactions.filters.reset")}
            </button>
            <button
              onClick={handleSubmitClick}
              className="px-2 py-1 rounded-sm active:scale-95 bg-mforange text-white text-sm"
            >
              {t("tokentransactions.filters.submit")}
            </button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default CLRFilterComponent;
