import { TwInput } from "@/components/shared";
import { InputGroup } from "@/types/shared";
import { inputFields } from "@/pages/Login/inputfields";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { propsLoginComponent } from "./props";
import { useNavigate } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { PreloginLayout, PreloginLayoutContent, PreloginLayoutFooter} from "@/layouts/PreloginLayout";

function UserLogin(props: propsLoginComponent) {
  const navigate = useNavigate();

  return (
    <PreloginLayout>
      {/* Form container */}
      <PreloginLayoutContent>
        {/* Header wrapper */}
        <div className="mb-4">
          <p className="text-xl md:text-2xl font-semibold mb-1">Welcome back</p>
          <p className="text-sm md:text-base">Please enter your details to sign in.</p>
        </div>
        <form
          className="flex flex-col gap-2"
          onSubmit={props.handleFormSubmit}
          id="loginForm"
          noValidate
        >
          {
            inputFields.map((field: InputGroup) => {
              return (
                <TwInput
                  key={`loginInput${field.name}`}
                  className={`${field.className} text-gray-800`}
                  required={field.required}
                  onChange={props.handleOnChange}
                  placeholder={field.placeholder}
                  type={field.type}
                  name={field.name}
                  data-cy={field.name+"LoginInput"}
                  label={field.title}
                  errorMessage={field.errorMessage}
                  doNotUseRegex={field.doNotUseRegex}
                  labelPlacement="stacked"
                  autoFocus={field.autoFocus}
                  useErrorText
                  autoComplete={field.autoComplete}
                />
              );
            })
          }

          <button
            data-cy="signInButton"
            className={`
              rounded-sm mf-primary text-white mf-button w-full ${props.loading ? "bg-opacity-50 cursor-wait" : "bg-mfpurple hover:bg-opacity-70"}
              ${props.hasErrors ? "cursor-not-allowed bg-gray-400" : ""}
            `}
            type={props.hasErrors ? "button" : "submit"}
            disabled={props.hasErrors}
          >
            {
              props.loading ?
                <div className="flex justify-center items-center">
                  <div className="w-4 h-4 border-2 border-gray-400 border-t-white mr-2 rounded-full animate-spin" />
                  Signing In
                </div>
                : <>Sign In</>
            }
          </button>

          {
            props.error && <p className="flex items-center text-red-500 mt-2"><ExclamationTriangleIcon className="w-5 h-5 mr-2" />{props.error.message}</p>
          }

          {/* Forgot password */}
          <p data-cy="forgotPasswordLink" className="mt-4 text-sm md:text-base">
            <span onClick={() => {navigate("/forgotpassword");}} className="text-blue-400 cursor-pointer">Forgot password?</span>
          </p>
        </form>
      </PreloginLayoutContent>

      {/* Request demo */}
      <PreloginLayoutFooter>
        <h1 className="text-base md:text-lg font-semibold mb-2">Don&apos;t have an account?</h1>
        <a data-cy="requestDemoButton"
          href="https://calendly.com/myron-finance/myron-demo"
          target="_blank" rel="noreferrer"
          className="inline-block rounded-sm mf-secondary text-white mf-button hover:bg-opacity-70"
        >
          Request Demo
        </a>
      </PreloginLayoutFooter>
    </PreloginLayout>
  );
}

export default UserLogin;
