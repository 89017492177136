import CompanyInformation from "./CompanyInformation";
import WebPresence from "./WebPresence";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { useCompanyData, useUserData } from "@/hooks";
import { t } from "i18next";
import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { useEditCompanyMutationHook } from "@/hooks/mutations";
import { CompanyType } from "@/constants/enums";
import FormWrapper from "../FormWrapper";
import { UserRole } from "@/graphql/__generated__/graphql-operations";
import AddressForm from "./Address";
import { clearForm } from "@/utils/helpers";

export type EditCompanyVariables = {
  companyInput: {
    address: object,
    serviceproviderinput?: {
      companydetails?: object
    }
  }
}

export type CompanyDetailsState = {
  serviceproviderinput?: {
    companydetails: {
      industry: string;
      description: string,
      yearlyrevenue: number,
      foundingyear: number
      website: string;
      facebook: string;
      twitter: string;
      linkedin: string;
    },
  },
}

function CompanyDetails() {

  // States
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [newData, setNewData] = useState<CompanyDetailsState|undefined>(undefined);

  // Hooks
  const company = useCompanyData();
  const user = useUserData();

  // Mutation hooks
  const { editCompany } = useEditCompanyMutationHook();

  /**
  * Handler for company details form submit event
  * @param event: FormEvent<Element>
  */
  function handleEditCompanySubmit(event: FormEvent<Element>) {
    event.preventDefault();
    const hasNewValues = newData?.serviceproviderinput;

    if(!company || hasErrors || !hasNewValues) {
      return toast.error("Unable to sumbit data", { toastId: "addressSubmitError"});
    }

    toast.loading(t("toasts:companySubmitLoading"), {toastId:"companySubmitLoading"});

    if (hasNewValues) {
      editCompany({
        id: company._id,
        companyInput: {
          serviceproviderinput: {
            companydetails: newData?.serviceproviderinput?.companydetails
          }
        },
      }, newData, "Settings");
    }

    // Reset state
    setNewData(undefined);
    setHasErrors(true);
    toast.dismiss("companySubmitLoading");
  }

  return (
    <div>
      <div className="mb-4 select-none">
        <div className="flex space-x-2 items-center mb-2">
          <CheckCircleIcon className="h-6 w-6" />
          <h2 className="font-semibold text-lg">{t("settings.companydetails.title")}</h2>
        </div>
      </div>

      {/* Company details + web presence */}
      {

        ((company?.__typename === (CompanyType.ServiceProvider)) && user!.role === UserRole.Superuser) &&
        <>
          <p className="-mt-2 mb-4">{t("settings.companydetails.subtitle")}</p>
          <FormWrapper
            id="companyDetailsForm"
            onClickReset={() => {clearForm("companyDetailsForm"); setNewData(undefined);}}
            handleSubmit={handleEditCompanySubmit}
          >
            {/* Form content */}
            <div className="flex flex-col md:flex-row justify-between md:gap-4 gap-y-8">
              <CompanyInformation setHasErrors={setHasErrors} newData={newData} setNewData={setNewData} />
              <WebPresence setHasErrors={setHasErrors} newData={newData} setNewData={setNewData} />
            </div>
          </FormWrapper>
        </>
      }

      {/* Address */}
      <div className="mt-4">
        <AddressForm />
      </div>
    </div>
  );
}

export default CompanyDetails;
