import ServiceProviderPortfolioCard from "./ServiceProviderPortfolioCard";
import { GetPaginatedInvestmentsQuery, GetPaginatedInvestmentsQueryVariables, Investment, InvestmentEdge, useGetPaginatedInvestmentsQuery } from "@/graphql/__generated__/graphql-operations";
import PoolsFilter from "./PoolsFilter";
import { Pagination, PaginationContentScrollable, PaginationContentWrapper } from "@/components/shared";
import SPCardSkeleton from "@/components/skeletons/SPCardSkeleton";
import { useRef } from "react";
import { ScrollToTopButton, WithdrawalCard } from "@/components/shared";
import useScrollToTop from "@/hooks/useScrollToTop";

interface PaginationRef {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedInvestmentsQueryVariables>) => void;
}

function Portfolio() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Pagination config
  const paginationRef = useRef<PaginationRef | null>(null);
  const paginationName = "paginatedInvestments";

  // Hooks
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });

  const onFilterSubmit = (variables: GetPaginatedInvestmentsQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    paginationRef.current?.triggerHandleFilterSubmit(variables);
  };

  return (
    <div className="mf-flex-y-fill m-4 md:mx-0">
      <Pagination<InvestmentEdge, GetPaginatedInvestmentsQuery, GetPaginatedInvestmentsQueryVariables>
        ref={paginationRef}
        props={{queryHook: useGetPaginatedInvestmentsQuery, paginationName, className: "mt-2"}}
      >
        {(dataSlice, loading, displayAmount) => (
          <PaginationContentWrapper>
            {/* Withdrawal card  */}
            <WithdrawalCard />
            <PoolsFilter
              onFilterSubmit={onFilterSubmit}
              displayAmount={displayAmount as number}
            />
            <PaginationContentScrollable ref={scrollRef}>
              {
                loading ?
                // Show loading skeletons if data loading
                  [...Array(displayAmount).keys()].map((index) => {
                    return (
                      <SPCardSkeleton
                        key={`spSkeleton:${index}`}
                      />
                    );
                  })
                  :
                // Otherwise show data
                  dataSlice.length > 0 ? dataSlice.map((investment) => {
                    return(
                      <ServiceProviderPortfolioCard
                        key={`${investment.node._id}`}
                        investment={investment.node as Investment}
                      />
                    );
                  }): <p className="text-center text-gray-500 dark:text-gray-400">No investments found</p>
              }
            </PaginationContentScrollable>
          </PaginationContentWrapper>
        )}
      </Pagination>
      <ScrollToTopButton scrollToTop={scrollToTop} isVisible={isVisible} />
    </div>
  );
}

export default Portfolio;
