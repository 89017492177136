import { CLIENT_CREDIT_DATA } from "@/graphql/operations/client.queries";
import { useEffect, useRef, useState } from "react";
import {
  CustomerEdge,
  CustomerOnServiceProvider,
  GetPaginatedCustomersQuery,
  GetPaginatedCustomersQueryVariables,
  useGetPaginatedCustomersQuery} from "@/graphql/__generated__/graphql-operations";
import { Pagination, PaginationContentScrollable, ScrollToTopButton } from "@/components/shared";
import { formatNumberLocale } from "@/utils/helpers";
import { CustomerDetailsModal, CustomerFilterComponent } from "@/pages/CustomerOverview";
import { CustomerPageModals } from "@/constants/enums";
import { TwTableCardList, DataTable } from "@/components/shared/table";
import { client } from "@/client";
import { useIntl } from "react-intl";
import { creditLimitOf, creditOf } from "@/blockchain/CreditManager";
import { useEmployeeData } from "@/hooks";
import { CustomersPageState, PaginationState } from "@/types";
import { useCustomersPageState } from "@/hooks/cache/appState";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import { useTranslation } from "react-i18next";
import { usePaginationState } from "@/hooks/cache/appState/usePaginationState";
import { getColumns } from "./columns";
import { getColumnHeaderValues } from "@/components/shared/table/utils";
import { ActionButton } from "@/components/shared/table/Table";
import useScrollToTop from "@/hooks/useScrollToTop";

interface PaginationRefCustomers {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedCustomersQueryVariables>) => void;
  getData: () => CustomerEdge[];
  getLoading: () => boolean;
}
export interface EnhancedCustomerEdge extends CustomerEdge {
  _id: string;
  credit: number;
  creditLimit: number;
}

function Customers() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Hooks
  const state: CustomersPageState = useCustomersPageState();
  const paginationState: PaginationState["paginatedCustomers"] = usePaginationState("paginatedCustomers");
  const mdBreakpoint = useMdBreakpoint();
  const user = useEmployeeData();
  const { t } = useTranslation(["tables"]);
  const intl = useIntl();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });

  if(!user) {
    return (
      <p>We are currently loading....</p>
    );
  }
  // Pagination config
  const paginationRefCustomers = useRef<PaginationRefCustomers|null>(null);
  const customerPaginationName = "paginatedCustomers";

  // States
  const [currentCustomer, setCurrentCustomer] = useState<CustomerOnServiceProvider|EnhancedCustomerEdge>();
  const [showModal, setShowModal]  = useState<CustomerPageModals>(CustomerPageModals.NoModal);
  const [tryCacheUpdateAgain, setTryCacheUpdateAgain] = useState<boolean>(false);
  const [newDataSlice, setNewDataSlice] = useState<EnhancedCustomerEdge[]>([]);

  function handleModalOpen(customer?: EnhancedCustomerEdge | CustomerOnServiceProvider) {
    setCurrentCustomer(customer);
    setShowModal(CustomerPageModals.CustomerDetails);
  }

  function handleCreditLimit(customer: EnhancedCustomerEdge) {
    const creditData = client.readQuery({
      query: CLIENT_CREDIT_DATA,
      variables: { id: customer._id },
    });
    // Constants
    const credit = creditData?.company?.credit ?? state[customer._id]?.credit;
    const creditLimit = creditData?.company?.creditLimit ?? state[customer._id]?.creditLimit;
    const creditUsage = credit/creditLimit;

    if(!credit && !creditLimit) {
      return formatNumberLocale(intl, 0, "percent");
    }
    else {
      return (creditUsage*100).toFixed(2)+"%";
    }
  }

  const onFilterSubmitCustomers = (variables: GetPaginatedCustomersQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    paginationRefCustomers.current?.triggerHandleFilterSubmit(variables);
  };

  /**
   * Handler for fetching credit and credit limit from blockchain for each customer
   * and writing it to cache
   */
  async function getCreditDataFromBlockchain(loading: boolean, dataSlice: any[]) {

    if (!loading && dataSlice.length > 0) {
      const creditData: { id: string, credit: number , creditLimit: number }[] = [];

      for(const customer of dataSlice) {
        // Check if we have the data cached already
        const customerCreditData = client.readQuery({
          query: CLIENT_CREDIT_DATA,
          variables: { id: customer.node._id },
        });

        if(!customerCreditData) {
          // Fetch data if we don't have it in cache
          const credit = await creditOf(customer.node.publickey);
          const creditLimit = await creditLimitOf(customer.node.publickey);

          // Write data to cache
          client.writeQuery({
            query: CLIENT_CREDIT_DATA,
            variables: { id: customer.node._id },
            data: { company: { credit, creditLimit } },
          });
          creditData.push({ id: customer.node._id, credit, creditLimit });
        }
        else { // Return the already cached data
          const credit = await customerCreditData.company.credit;
          const creditLimit = await customerCreditData.company.creditLimit;
          creditData.push({ id: customer.node._id, credit, creditLimit });
        }
      }
      return creditData;
    } else {
      return [];
    }
  }

  // Effect to fetch and store credit data for customers
  useEffect(() => {

    if(paginationRefCustomers.current?.getLoading() && !tryCacheUpdateAgain) {
      setTryCacheUpdateAgain(true);
      return;
    }

    const fetchData = async () => {
      const loading = paginationRefCustomers.current?.getLoading() as boolean;
      const dataSlice = paginationRefCustomers?.current?.getData();
      if (!dataSlice) return;

      const creditData = await getCreditDataFromBlockchain(loading, dataSlice);
      const newDataSlice: CustomerEdge[] = dataSlice.map((data, index) => ({
        ...data,
        node: {
          ...data.node,
          _id: data.node._id,
          credit: creditData[index]?.credit || 0,
          creditLimit: creditData[index]?.creditLimit || 0
        }
      }));

      // Update state
      setNewDataSlice(newDataSlice as EnhancedCustomerEdge[]);
      setTryCacheUpdateAgain(false);
    };

    fetchData();
  },[
    tryCacheUpdateAgain,
    paginationState.currentPage,
    paginationRefCustomers.current?.getLoading(),
  ]);

  const getActionButtonsCustomers = (index: number, dataObj: CustomerOnServiceProvider | EnhancedCustomerEdge) => {
    return [
      <ActionButton key={"customer-action-btn-"+index} dataObj={dataObj} fn={handleModalOpen} text={t("common:buttons.details")} />
    ];
  };

  // Table headers and sort fields for TwTableCardList
  const columnHeaderValues = getColumnHeaderValues(getColumns, intl, t, handleModalOpen);

  return (
    <>
      <div className="mf-table-container mf-flex-y-fill">
        {/* Filter component */}
        <CustomerFilterComponent onFilterSubmitCustomers={onFilterSubmitCustomers} />

        <div className="mf-text-primary transition-all flex">
          {/* MODALS */}
          {
            showModal === CustomerPageModals.CustomerDetails &&
            <CustomerDetailsModal
              setShowModal={setShowModal}
              currentCustomer={currentCustomer as CustomerOnServiceProvider}
            />
          }
        </div>

        {/* Customers Table */}
        <div className={"mf-customers-table-2 flex-col flex-grow mt-2 -mb-2 flex w-full"}>
          <Pagination<CustomerEdge, GetPaginatedCustomersQuery, GetPaginatedCustomersQueryVariables>
            ref={paginationRefCustomers} props={{queryHook: useGetPaginatedCustomersQuery, paginationName: customerPaginationName, className: "mx-4 mb-4"}}
          >
            {(_dataSlice, loading, displayAmount) => {
              const loadingCondition = newDataSlice && newDataSlice?.length > 0 ? loading : true;
              return (
                <PaginationContentScrollable ref={scrollRef}>
                  <div className="mx-4 mt-4">
                    {
                      mdBreakpoint ?
                        <DataTable
                          loading={loadingCondition}
                          displayAmount={displayAmount as number}
                          data={newDataSlice.map((edge) => edge.node) ?? []}
                          columns={getColumns(intl, t, handleModalOpen, handleCreditLimit)}
                        />
                        :
                        <TwTableCardList
                          dataArray={newDataSlice.map((edge) => edge.node) ?? []}
                          displayAmount={displayAmount as number}
                          actionButtons={getActionButtonsCustomers}
                          defaultSortField="createdAt"
                          tableHeaders={columnHeaderValues}
                          dataType="Customer"
                          dataLoading={loadingCondition}
                        />
                    }
                  </div>
                </PaginationContentScrollable>
              );
            }}
          </Pagination>
          <ScrollToTopButton scrollToTop={scrollToTop} isVisible={isVisible} />
        </div>
      </div>
    </>
  );
}

export default Customers;
