import { copyTextToClipboard } from "@/utils/helpers";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { TwInput } from "@/components/shared";
import { useState } from "react";
import Girocode from "react-girocode";
import myronLogo from "@/assets/MYRON-icon-transparent.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

type Props = {
  referencenumber: string;
}

function DepositBankDetailsComponent({ referencenumber }: Props) {
  // States
  const [amount, setAmount] = useState<number | null>(null);

  // Hooks
  const { t } = useTranslation(["modals"]);

  // Bank Account details
  const paymentDetails = {
    iban: import.meta.env.VITE_MYRON_IBAN,
    bic: import.meta.env.VITE_MYRON_BIC,
    recipient: import.meta.env.VITE_MYRON_RECIPIENT,
    reference: referencenumber,
  };

  const bankDetails = [
    {
      title: t("depositsummary.bankdetails.accountholder"),
      value: paymentDetails.recipient
    }
  ];

  const accountDetails = [
    {
      title: "IBAN",
      value: paymentDetails.iban
    },
    {
      title: "BIC",
      value: paymentDetails.bic
    },
    {
      title: t("depositsummary.bankdetails.reference"),
      value: referencenumber
    }
  ];

  // Function to handle change in amount input for qrcode
  function handleChange(event: { name: string, value: string, isValid: boolean }) {
    const amount = parseFloat(event.value);
    setAmount(amount);
  }

  return (
    <div>
      {/* Subtext */}
      <div className="mb-4 dark:text-gray-100">
        <p>
          {t("depositsummary.depositnote")}
        </p>
      </div>

      {/* Bank details */}
      <div>
        <p className="text-xl font-semibold mb-2 dark:text-gray-100">
          {t("depositsummary.bankdetails.title")}
        </p>
        <div className="flex flex-wrap">
          <div className="flex-1 rounded-md bg-gray-100 dark:bg-mfdarklighter p-4">
            {/* Bank details */}
            {
              bankDetails.map((item) => (
                <div key={`bankdetails:${item.title}`}>
                  <p className="text-sm text-gray-400 pb-1">{item.title}</p>
                  <p className="dark:text-gray-100 select-text">{item.value}</p>
                </div>
              ))
            }
            {/* Account details */}
            {
              accountDetails.map((item) => (
                <div
                  key={`accountdetails:${item.title}`}
                  className="flex items-end justify-between"
                >
                  {/* Details */}
                  <div>
                    <p className="text-sm text-gray-400 pb-1">{item.title}</p>
                    <p className="text-lg font-semibold dark:text-gray-100 select-text break-all sm:break-normal">{item.value}</p>
                  </div>
                  {/* Copy to clipboard */}
                  <div>
                    <button
                      onClick={() => copyTextToClipboard(item.value)}
                    >
                      <ClipboardIcon
                        className="w-6 h-6 cursor-pointer active:scale-95 dark:text-gray-100"
                      />
                    </button>
                  </div>
                </div>
              ))
            }
          </div>

          {/* QR Code container */}
          <div className="flex flex-1 flex-col p-4 gap-y-2">
            {/* Amount input */}
            <div>
              <TwInput
                type="number"
                name="amount"
                onChange={handleChange}
                className="mf-input-field min-w-[150px]"
                label={t("depositsummary.amountinput.label")}
                labelPlacement="stacked"
                placeholder="0"
                autoFocus
              />
            </div>
            {/* QR Code */}
            <div className="flex justify-center">
              {
                !amount || amount === 0 ?
                  <div className="flex items-center justify-center h-[200px] w-[200px] border-[1px] border-gray-200 dark:border-mfdarklighter">
                    <LazyLoadImage
                      alt={"Myron's logo"}
                      className="w-[80px]"
                      src={myronLogo}
                      effect="opacity"
                    />
                  </div>
                  :
                  <div className="qr-code-container">
                    <Girocode
                      recipient={paymentDetails.recipient}
                      iban={paymentDetails.iban}
                      amount={amount}
                      bic={paymentDetails.bic}
                      reference={paymentDetails.reference}
                    />
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
      {/* Disclaimer */}
      <div className="my-4 dark:text-gray-100">
        <p>
          {t("depositsummary.disclaimer")}
        </p>
      </div>
    </div>
  );
}

export default DepositBankDetailsComponent;
