import { FunnelIcon } from "@heroicons/react/24/outline";
import { GetPaginatedTokenTransactionsQueryVariables, TokenTransactionStatus, TokenTransactionType } from "@/graphql/__generated__/graphql-operations";
import { usePaginationState } from "@/hooks/cache/appState/usePaginationState";
import { PaginationState } from "@/types";
import { toast } from "react-toastify";
import { updatePaginationState } from "@/cache/appstate/WriteQueries";
import { useTranslation } from "react-i18next";
import { RadioButton, TagItem } from "@/components/shared";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/Popover";
import { isDarkModeVar } from "@/client";

type Props = {
  className?: string;
  onFilterSubmit: (_variables: GetPaginatedTokenTransactionsQueryVariables) => void;
}

type RadioOption = {
  label: string;
  value: TokenTransactionStatus | TokenTransactionType;
}

function TokenTransactionsFilter(props: Props) {

  const backgroundColour = isDarkModeVar() === "true" ? "bg-mfdarklighter text-white border-transparent shadow-gray-900": "bg-white";

  // Hooks
  const { t } = useTranslation();

  // Pagination
  const paginationState: PaginationState["paginatedTokenTransactions"] = usePaginationState("paginatedTokenTransactions");
  const { first, filters: { status, type } } = paginationState;

  /**
   * OnChange handler for radio buttons
   * @param key string
   * @param value string
   */
  const onChangeRadioButton = (key: string, value: string) => {
    if (key === "status") {
      updatePaginationState({paginatedTokenTransactions: {filters: {status: value as TokenTransactionStatus}}});
    } else if (key === "type") {
      updatePaginationState({paginatedTokenTransactions: {filters: {type: value as TokenTransactionType}}});
    }
  };

  const statusOptions: RadioOption[] = [
    { label: t("tokentransactions.filters.pending"), value: TokenTransactionStatus.Pending },
    { label: t("tokentransactions.filters.confirmed"), value: TokenTransactionStatus.Confirmed },
    { label: t("tokentransactions.filters.failed"), value: TokenTransactionStatus.Failed },
  ];

  const typeOptions: RadioOption[] = [
    { label: t("tokentransactions.filters.deposit"), value: TokenTransactionType.Buy },
    { label: t("tokentransactions.filters.withdrawal"), value: TokenTransactionType.Sell },
  ];

  const getStatusTranslation = (status: TokenTransactionStatus) => {
    switch (status) {
    case TokenTransactionStatus.Pending:
      return t("tokentransactions.filters.pending");
    case TokenTransactionStatus.Confirmed:
      return t("tokentransactions.filters.confirmed");
    case TokenTransactionStatus.Failed:
      return t("tokentransactions.filters.failed");
    }
  };

  /**
   * OnClick handler for reset button
   */
  function handleResetClick() {
    // Update cache first
    updatePaginationState({
      paginatedTokenTransactions: {
        filters: {
          type: null,
          status: null
        }
      }
    });
    // Call the parent function
    props.onFilterSubmit({
      first,
      status: null,
      type: null
    });
    toast.success(t("common:text.filterremoved"), {toastId: "investmentFilterRemoved"});
  }

  /**
   * OnClick handler for submit button
   */
  function handleSubmitClick() {
    props.onFilterSubmit({
      first,
      status: status,
      type: type
    });
    toast.success(t("common:text.filterapplied"), {toastId: "investmentFilterApplied"});
  }

  return (
    <div className="flex items-center justify-between mx-4 mb-2 mt-2">
      <Popover>
        <PopoverTrigger data-cy="transactions-filter-popover-trigger">
          <div className="border-[1px] border-gray-200 rounded-md flex item-center h-[40px] max-md:w-full md:w-[300px] px-2 py-1
                        dark:border-white relative select-none"
          >
            <div className="mr-2 h-full items-center flex">
              <FunnelIcon
                className="w-5 h-5 dark:text-white  flex-shrink-0"
              />
              {
                status === null && type === null &&
              <p className="text-sm text-gray-400 pl-2">
                {t("tokentransactions.filters.title")}
              </p>
              }
            </div>
            <div className="flex items-center space-x-2 select-none">
              {
                status !== null &&
              <TagItem label={getStatusTranslation(status as TokenTransactionStatus)} />
              }
              {
                type !== null &&
              <TagItem label={type ===TokenTransactionType.Buy? t("tokentransactions.filters.deposit"): t("tokentransactions.filters.withdrawal")} />
              }
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent
          data-cy="transactions-filter-popover-content"
          align="start"
          className={`mf-filter-container min-w-[300px] ${backgroundColour}`}
        >
          {/* Status filters */}
          <p className="font-semibold text-sm mb-1">{t("tokentransactions.filters.status")}</p>
          {statusOptions.map(option => (
            <RadioButton
              key={option.value}
              option={option}
              checked={status === option.value}
              onChange={(value) => onChangeRadioButton("status", value.toString())}
            />
          ))}
          {/* Type filters */}
          <p className="font-semibold text-sm mb-1 mt-2">{t("tokentransactions.filters.type")}</p>
          {typeOptions.map(option => (
            <RadioButton
              key={option.value}
              option={option}
              checked={type === option.value}
              onChange={(value) => onChangeRadioButton("type", value.toString())}
            />
          ))}

          {/* Buttons */}
          <div className="flex items-center justify-between mt-4">
            <button
              onClick={handleResetClick}
              className="px-2 py-1 rounded-sm active:scale-95 border-[1px] border-gray-800 text-sm dark:border-white"
            >
              {t("tokentransactions.filters.reset")}
            </button>
            <button
              onClick={handleSubmitClick}
              className="px-2 py-1 rounded-sm active:scale-95 bg-mforange text-white text-sm"
            >
              {t("tokentransactions.filters.submit")}
            </button>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default TokenTransactionsFilter;
