import { client } from "@/client";
import { ServiceProviderDataFragment, ServiceProviderDataFragmentDoc } from "@/graphql/__generated__/graphql-operations";

/**
 * Hook for reading the details of the logged in Service Provider from Cache
 * @returns the data of the logged in Service Provider
 */
export function useServiceProviderData() {
  const companyId = sessionStorage.getItem("companyId");
  const data: ServiceProviderDataFragment | null = client.readFragment({
    id: `ServiceProvider:${companyId}`,
    fragment: ServiceProviderDataFragmentDoc
  });
  return data;
}
