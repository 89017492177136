/**
 ** Entry point file for the frontend.
 ** Routes logic sits in 'App.tsx' and is rendered here
 **/

import ReactDOM from "react-dom/client";
import App from "./App";

const root = document.getElementById("root") as HTMLElement;
const rootElement = ReactDOM.createRoot(root);

rootElement.render(<App />);
