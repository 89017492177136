import { AssetFinancingPool } from "@/graphql/__generated__/graphql-operations";
import { calculateAnnualizedInterest, formatNumberLocale } from "@/utils/helpers";
import { useIntl } from "react-intl";
import { ServiceProviderDetails } from "@/components/shared";
import TextInfoGroup from "@/components/shared/common/TextInfoGroup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  assetFinancingPool: AssetFinancingPool;
}

function PortfolioCard(props: Props) {

  // Props
  const { assetFinancingPool } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();
  const navigate = useNavigate();

  function handleInvestHyperlink(assetFinancingPool: AssetFinancingPool) {
    if (assetFinancingPool.spdetails.name) {
      navigate(`/user/investment?afpId=${assetFinancingPool._id}`);
    }
  }

  return(
    <div
      data-cy="portfolio-card"
      onClick={() => handleInvestHyperlink(assetFinancingPool)}
      className="mf-dashboard-sp-card"
    >
      <div className="grid grid-cols-12 gap-4 h-full">
        {/* Logo and name */}
        <div className="col-span-12 sm:col-span-6">
          <ServiceProviderDetails
            serviceProviderId={assetFinancingPool.spdetails._id}
            className="lg:flex-grow-1"
            titleClassName="text-sm"
            logoSize="small"
          />
        </div>
        {/* Lower container */}
        <div className="col-span-6 sm:col-span-3">
          {/* Open to invest */}
          <TextInfoGroup
            className="max-w-[100px]"
            label={t("dashboard.portfoliocard.opentoinvest")}
            content={formatNumberLocale(
              intl,
              assetFinancingPool.financinglimit-assetFinancingPool.amountinvested,
              "currency", true, false,
            )}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          {/* Interest p.a. --> */}
          <TextInfoGroup
            className="max-w-[100px]"
            label={t("dashboard.portfoliocard.interest")}
            content={calculateAnnualizedInterest(assetFinancingPool.interest, assetFinancingPool.rewardpayoutperiod)}
          />
        </div>
      </div>
    </div>
  );
}

export default PortfolioCard;
