import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { TransactionsModals } from "@/constants/enums";
import { useWeb3 } from "@/context/Web3Context";
import { useBlockchainData, useCompanyData } from "@/hooks";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import DepositBankDetailsComponent from "./DepositBankDetailsComponent";
import WithdrawalTransactionComponent from "./WithdrawalTransactionComponent";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";

type Props = {
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
  setShowModal: Dispatch<SetStateAction<TransactionsModals>>;
  referencenumber: string;
};

function AddTransactionModal(props: Props) {
  // Props
  const {
    amount,
    setAmount,
    setShowModal,
    referencenumber,
  } = props;

  // States
  const [inputErrorText, setInputErrorText] = useState("");
  const [currentTab, setCurrentTab] = useState("deposit");

  // Hooks
  const company = useCompanyData();
  const { t } = useTranslation(["modals"]);
  const blockchainData = useBlockchainData();

  // Magic SDK
  const {
    isConnected,
  } = useWeb3();

  // Constants
  const bankAccountIsVerified = company?.bankaccount?.verified;
  const walletConnectedAndBankVerified = isConnected && bankAccountIsVerified;
  const notConnectedAndBankVerified = !isConnected && bankAccountIsVerified;

  return (
    <BlankModal
      setShowModal={() => {
        setShowModal(TransactionsModals.NoModal);
        setAmount(0);
        setInputErrorText("");
      }}
      className="px-6"
      customContentCSS="mf-modal-content lg:min-w-[640px] md:max-h-[800px]"
    >
      <BlankModalContent>
        {/* Main Content Container*/}
        <div>
          <Tabs defaultValue="deposit" onValueChange={value => setCurrentTab(value)}>
            <div className="flex justify-center">
              <TabsList>
                <TabsTrigger value="deposit">{t("addtransaction.buttons.deposit")}</TabsTrigger>
                <TabsTrigger value="withdraw">{t("addtransaction.buttons.withdraw")}</TabsTrigger>
              </TabsList>
            </div>
            <TabsContent value="deposit">
              <DepositBankDetailsComponent referencenumber={referencenumber} />
            </TabsContent>
            <TabsContent value="withdraw">
              <WithdrawalTransactionComponent
                amount={amount}
                setAmount={setAmount}
                inputErrorText={inputErrorText}
                setInputErrorText={setInputErrorText}
                bankAccountIsVerified={bankAccountIsVerified}
                walletConnectedAndBankVerified={walletConnectedAndBankVerified}
                notConnectedAndBankVerified={notConnectedAndBankVerified}
                balance={blockchainData.balance}
              />
            </TabsContent>
          </Tabs>
        </div>
      </BlankModalContent>
      <BlankModalFooter>
        <div className="flex items-center justify-between mt-4">
          <button
            onClick={() => {
              setShowModal(TransactionsModals.NoModal);
              setAmount(0);
            }}
            className="mf-btn-action-small-primary-outline"
          >
            {t("common:buttons.cancel")}
          </button>
          {
            walletConnectedAndBankVerified && currentTab !== "deposit" &&
            <button
              onClick={() => {
                setShowModal(TransactionsModals.WithdrawalSummary);
              }}
              className={`${blockchainData.balance <= 0 ? "mf-btn-action-small-disabled" : "mf-btn-action-small-primary-filled"}`}
              disabled={blockchainData.balance <= 0 || inputErrorText.length > 0}
            >
              {t("common:buttons.continue")}
            </button>
          }
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default AddTransactionModal;
