import TwTableCard from "./TwTableCard";
import { useIntl } from "react-intl";
import { getTags, getTextInfoGroups, getDate } from "./utils";
import { ArrowPathIcon, BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { sortByField } from "@/utils/helpers";
import { CardContents } from "@/types";
import { propsTwTableCardList } from "./props";
import { useTranslation } from "react-i18next";

/**
 * TwTableCardList Component
 *
 * This component generates a list of `TwTableCard` components based on provided data.
 * It includes sorting functionality for the table headers and handles data loading states.
 * The data array received as props is processed and cleaned up before being passed to the `TwTableCard` component.
 *
 * @component
 * @param {propsTwTableCardList} props - Props object containing necessary data and configuration.
 * @param {any[]} props.dataArray - Array of data items to be displayed in cards.
 * @param {number} props.displayAmount - Number of items to display when loading.
 * @param {boolean} props.dataLoading - Boolean indicating if data is currently loading.
 * @param {Array<{ accessorKey: string; headerText: string }>} props.tableHeaders - Array of table headers with sorting information.
 * @param {string} props.dataType - Type of data being displayed to determine specific configurations.
 * @param {(index: number, item: any) => JSX.Element[]} [props.actionButtons] - Optional function to generate action buttons for each item.
 * @param {string} [props.defaultSortField] - Default field to sort data by initially.
 * @returns {JSX.Element} - A React JSX Element containing the rendered `TwTableCardList` component.
 *
 * @example
 * const data = [
 *   { id: 1, name: 'Item 1', date: '2024-01-01' },
 *   { id: 2, name: 'Item 2', date: '2024-01-02' },
 * ];
 * const headers = [
 *   { accessorKey: 'name', headerText: 'Name' },
 *   { accessorKey: 'date', headerText: 'Date' },
 * ];
 * const actionButtons = (index, item) => [
 *   <button key={`edit-${index}`}>Edit</button>,
 *   <button key={`delete-${index}`}>Delete</button>,
 * ];
 *
 * <TwTableCardList
 *   dataArray={data}
 *   displayAmount={5}
 *   dataLoading={false}
 *   tableHeaders={headers}
 *   dataType="example"
 *   actionButtons={actionButtons}
 *   defaultSortField="date"
 * />
 */
function TwTableCardList(props: propsTwTableCardList): JSX.Element {

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["tables"]);

  // Props
  const {
    dataArray,
    displayAmount,
    dataLoading,
    tableHeaders,
    dataType,
    actionButtons,
    defaultSortField,
    // intl
  } = props;
  // States
  const [sortField, setSortField] = useState<string>(defaultSortField ?? "");
  const [sortOrder, setSortOrder] = useState<"asc"|"desc">("desc");

  // Columns to hide in mobile sorting
  const hiddenMobileSortColumns = [
    { dataType: "Asset", columns: ["idFromDocumentary", "date"] },
    // Add other dataType configurations here as needed
  ];

  // Get the hidden columns for the current dataType
  const currentHiddenColumns = hiddenMobileSortColumns.find(item => item.dataType === dataType)?.columns || [];

  /**
   * Creates a new array from the dataArray that is used by the TwTableCard
   * The data array gets cleaned up and reshaped to fit the TwTableCard component
   * @param dataArray any[]
   * @param dataType string
   * @returns
   */
  const cleanupDataArray = (dataArray: any[], dataType: string): CardContents[] => {
    const cleanedDataArray: any[] = [];
    dataArray.map((item, index) => {
      const currentDataItem: any = {};
      // *** Get Tags
      currentDataItem.tags = getTags(item, dataType);
      // *** Get Date
      currentDataItem.date = getDate(item, dataType);
      // *** Get TextInfoGroups
      currentDataItem.TextInfoGroups = getTextInfoGroups(item, dataType, intl);
      // *** Get Buttons
      if (actionButtons) {
        currentDataItem.buttons = actionButtons(index, item);
      }
      // *** Push entire object to cleanedDataArray
      cleanedDataArray.push(currentDataItem);
    });
    return cleanedDataArray;
  };

  return (
    <div>
      {/* Mobile only sorting */}
      <div className="flex flex-wrap gap-2">
        {
          // Hides empty sort button if action buttons are present
          (actionButtons ? tableHeaders.slice(0,tableHeaders.length-1) : tableHeaders)
            .filter(header => !currentHiddenColumns.includes(header.accessorKey))
            .map((header) => (
              <div
                className={`border-[1px] border-mfpurple dark:border-mforange rounded-md px-2 py-1
                          cursor-pointer active:scale-95 select-none flex items-center text-sm
                          ${sortField === header.accessorKey ? "text-white bg-mfpurple dark:bg-mforange" : "text-mfpurple dark:text-mforange"}`}
                key={`transaction-sorting-${header.accessorKey}`}
                onClick={() => {
                  if (sortField === header.accessorKey) {
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                  } else {
                    setSortField(header.accessorKey as string);
                  }
                }}
              >
                {
                  (sortField === header.accessorKey && sortOrder === "asc" )?
                    <BarsArrowUpIcon className="w-5 h-5 mr-1" /> :
                    <BarsArrowDownIcon className="w-5 h-5 mr-1" />
                }
                {header.headerText}
              </div>
            ))
        }
        <button
          className="border-[1px] text-mforange border-mforange dark:border-white rounded-md px-2 py-1
                      cursor-pointer active:scale-95 select-none flex items-center text-sm dark:text-white"
          onClick={() => {
            setSortField(defaultSortField as string);
            setSortOrder("desc");
          }}
        >
          <ArrowPathIcon className="w-5 h-5 mr-1" />
          {t("common:buttons.reset")}
        </button>
      </div>
      {/* // Cards list container */}
      <div className="flex flex-col gap-4 py-4">
        {/* Card */}
        {
          dataLoading ?
            [...Array(displayAmount).keys()].map((index) => {
              return (
                <div
                  className="mf-skeleton-base min-h-[200px]"
                  key={`spSkeleton:${index}`}
                />
              );
            }):
            dataArray.length > 0 ?
            // Show TwTableCards if array contains data
              cleanupDataArray(sortByField(dataArray, sortField, sortOrder), dataType).map((cardObject) => {
                return (
                  <div key={`cardItem-${cardObject?.date}`}>
                    <TwTableCard
                      key={`cardItem-${cardObject.date}`}
                      cardContents={cardObject}
                      dataType={dataType}
                    />
                  </div>
                );
              }):
            // Show no data available message if array is empty
              <div className="flex items-center justify-center py-10 dark:text-white">
                {t("common:text.nodataavailable")}
              </div>
        }
      </div>
    </div>
  );
}

export default TwTableCardList;
