import { CreditLimitRequestStatus, TokenTransactionStatus, AssetFinancingStatus } from "@/graphql/__generated__/graphql-operations";
import { t } from "i18next";
import { FormatDateOptions, IntlShape } from "react-intl";
import { toast } from "react-toastify";

/**
 * Multiplies a number by a factor suitable for blockchain transactions.
 *
 * @param {number|bigint} clientNumber - The client number to be multiplied.
 * @returns {number} The multiplied number.
 *
 * @example
 * ```javascript
 * const result = clientNumberToBlockchain(123);
 * console.log(result); // 12300000
 * ```
 */
export function clientNumberToBlockchain(clientNumber: number | bigint): number {
  return Number(clientNumber ?? 0) * (10 ** 5);
}

/**
 * Divides a number from the blockchain to convert it back to client format.
 *
 * @param {number|bigint} result - The number from the blockchain.
 * @returns {number} The divided number.
 *
 * @example
 * ```javascript
 * const result = blockchainNumberToClient(12300000);
 * console.log(result); // 123
 * ```
 */
export function blockchainNumberToClient(result: number | bigint): number {
  return Number(result ?? 0) / (10 ** 5);
}

/**
 * Converts a number in basis points (bps) to a percentage.
 *
 * @param {number} input - The input number in basis points.
 * @returns {number} The percentage as a decimal.
 *
 * @example
 * ```javascript
 * const percentage = convertBpToPercentage(2500);
 * console.log(percentage); // 0.25
 * ```
 */
export function convertBpToPercentage(input: number): number {
  return input / (10 ** 4); // Divide by 10000 to convert to percentage
}

/**
 * Manually removes a toast notification after a specified timeout.
 *
 * @param {Toast} toast - The 'react-toastify' toast object.
 * @param {string} id - The ID of the toast to be removed.
 * @param {number} timeout - The timeout in milliseconds after which the toast should be removed.
 *
 * @example
 * ```javascript
 * import toast from 'react-hot-toast';
 *
 * // Assuming a toast notification has been created with an ID of "myToast"
 * removeToast(toast, "myToast", 5000); // Remove the toast after 5 seconds
 * ```
 */
export function removeToast(toast: any, id: string, timeout: number): void {
  setTimeout(() => {
    toast.dismiss(id);
  }, timeout);
}

/**
 * Sorts an array of objects by a given field and order.
 *
 * @template T
 * @param {T[]} sortedData - The array of objects to be sorted.
 * @param {string} sortField - The field to sort by (supports nested fields separated by dots).
 * @param {string} sortOrder - The order of sorting, either "asc" or "desc".
 * @returns {T[]} The sorted array.
 *
 * @example
 * ```javascript
 * const sortedArray = sortByField(dataArray, "name.first", "asc");
 * ```
 */
export function sortByField<T extends object>(sortedData: T[], sortField: string, sortOrder: string): T[] {
  if (sortField && sortOrder) {
    sortedData.sort((a, b) => {
      const fieldArray = sortField.split(".");
      let aValue = a;
      let bValue = b;

      // Traverse the nested properties
      for (const field of fieldArray) {
        aValue = aValue ? aValue[field] : undefined;
        bValue = bValue ? bValue[field] : undefined;
      }

      let comparison = 0;

      if (aValue > bValue) {
        comparison = 1;
      } else if (aValue < bValue) {
        comparison = -1;
      }

      if (sortOrder === "desc") {
        comparison *= -1;
      }

      return comparison;
    });
  }
  return sortedData;
}

/**
 * Returns a background color based on either a percentage or a status.
 *
 * @param {string} type - The type of the input, either "PERCENTAGE", "CLR", "ASSET", "TOKEN", or "AFC_STATUS".
 * @param {number} percentage - The percentage value if type is "PERCENTAGE".
 * @param {boolean | TokenTransactionStatus | AssetFinancingStatus | CreditLimitRequestStatus} status - The status if type is "CLR", "ASSET", "TOKEN", or "AFC_STATUS".
 * @returns {string | undefined} The Tailwind CSS background color.
 *
 * @example
 * ```javascript
 * const color = handleCircleRobotColour("PERCENTAGE", 45);
 * console.log(color); // "bg-green-500 dark:bg-green-500"
 * ```
 */
export function handleCircleRobotColour(type: string, percentage: number, status?: boolean | TokenTransactionStatus | AssetFinancingStatus | CreditLimitRequestStatus): string | undefined {
  switch (type) {
  case "PERCENTAGE":
    if (percentage == 0) {
      return "bg-green-500 dark:bg-green-500";
    }
    if (percentage < 50) {
      return "bg-green-500 dark:bg-green-500";
    } else if (percentage >= 75) {
      return "bg-red-500 dark:bg-red-500";
    } else {
      return "bg-orange-400 dark:bg-orange-400";
    }
  case "CLR":
    switch (status) {
    case "APPROVED":
      return "bg-green-600 dark:bg-green-600";
    case "DENIED":
      return "bg-red-600 dark:bg-red-600";
    case "PENDING":
      return "bg-orange-400 dark:bg-orange-400";
    default:
      break;
    }
    break;
  case "ASSET":
    if (status) {
      return "bg-green-500 dark:bg-green-500";
    } else if (!status) {
      return "bg-red-500 dark:bg-red-500";
    }
    break;
  case "TOKEN":
    switch (status) {
    case TokenTransactionStatus.Confirmed:
      return "bg-green-600 dark:bg-green-600";
    case TokenTransactionStatus.Failed:
      return "bg-red-600 dark:bg-red-600";
    case TokenTransactionStatus.Pending:
      return "bg-orange-400 dark:bg-orange-400";
    default:
      break;
    }
    break;
  case "AFC_STATUS":
    switch (status) {
    case AssetFinancingStatus.Open:
      return "bg-orange-500 dark:bg-orange-500";
    case AssetFinancingStatus.Closed:
      return "bg-red-500 dark:bg-red-500";
    case AssetFinancingStatus.Active:
      return "bg-green-500 dark:bg-green-500";
    case AssetFinancingStatus.Onboarding:
      return "bg-yellow-500 dark:bg-yellow-500";
    default:
      break;
    }
    break;
  default:
    break;
  }
}

/**
 * Formats a number based on the provided options and locale.
 *
 * @param {IntlShape} intl - The `IntlShape` object used for number formatting.
 * @param {string | number} amount - The amount to be formatted. Can be a string or a number.
 * @param {"currency" | "decimal" | "percent" | "unit"} style - The style of formatting to be applied. Can be "currency", "decimal", "percent", or "unit".
 * @param {boolean} [showShortScale=false] - Optional. Specifies whether to add short scale suffixes for large numbers.
 * @param {boolean} [disableDecimalPlaces=false] - Optional. Specifies whether to disable decimal places in the formatted number.
 * @param {boolean} [isEurm=false] - Optional. Specifies whether to format the number as EURM.
 * @returns {string} The formatted number as a string.
 *
 * @example
 * ```javascript
 * const formattedNumber = formatNumberLocale(intl, 123456789, "currency", true, false, true);
 * console.log(formattedNumber); // "€123.5M"
 * ```
 */
export function formatNumberLocale(
  intl: IntlShape,
  amount: string | number,
  style: "currency" | "decimal" | "percent" | "unit",
  showShortScale: boolean = false,
  disableDecimalPlaces: boolean = false,
  isEurm: boolean = false
): string {
  const numericAmount = typeof amount === "string" ? parseFloat(amount) : amount;

  if (showShortScale) {
    let suffix = "";
    let divisor = 1;

    if (numericAmount >= 1_000_000) {
      suffix = "M";
      divisor = 1_000_000;
    } else if (numericAmount >= 1_000) {
      suffix = "K";
      divisor = 1_000;
    }

    const formattedWithSuffix = (numericAmount / divisor).toFixed(
      disableDecimalPlaces ? 0 : 1
    ) + suffix;
    return "€" + formattedWithSuffix;
  }

  const formattedNumber = intl.formatNumber(numericAmount, {
    style: isEurm ? "decimal" : style,
    currency: "EUR",
    currencyDisplay: "symbol",
    minimumFractionDigits: disableDecimalPlaces ? 0 : undefined,
    maximumFractionDigits: disableDecimalPlaces ? 0 : 2,
  });

  return isEurm ? `EURM ${formattedNumber}` : formattedNumber;
}

/**
 * Helper function that formats a given date according to the user's locale.
 * @param {IntlShape} intl - The internationalization object containing the user's locale.
 * @param {Date | string} date - The date to be formatted, either as a Date object or a string.
 * @param {FormatDateOptions} [options] - Optional configuration for formatting the date.
 * @returns {string} - The formatted date string.
 * @example
 * ```javascript
 * import { useIntl } from 'react-intl';
 *
 * const intl = useIntl();
 * const formattedDate = formatDateLocale(intl, '2024-08-27');
 * console.log(formattedDate); // Output: "27/08/2024" (depending on the locale)
 * ```
 *
 */
export function formatDateLocale(intl: IntlShape, date: Date | string, options?: FormatDateOptions): string {
  if (!date) return "";

  const userLocale = intl.locale;

  // Parse the date if it's a string
  const dateObj = typeof date === "string" ? new Date(date) : date;

  // Format the date using Intl.DateTimeFormat with day and month options
  const formattedDate = Intl.DateTimeFormat(userLocale, {
    day: options?.day ?? "2-digit",
    month: options?.month ?? "2-digit",
    year: options?.year ?? "numeric",
    ...options
  }).format(dateObj);

  return formattedDate;
}

/**
 * Copies text to the clipboard and displays a success toast.
 * @param {string} text - The text to copy to the clipboard.
 * @returns {Promise<void>} - A promise that resolves when the text is successfully copied.
 * @example
 * ```javascript
 * copyTextToClipboard('Hello World')
 *   .then(() => console.log('Text copied!'))
 *   .catch(() => console.error('Failed to copy text.'));
 * ```
 */
export async function copyTextToClipboard(text: string): Promise<void> {
  const writeTextToClipboard = await navigator.clipboard.writeText(text);
  toast.success(t("common:text.copyTextToClipboard"), {toastId: "copiedToClipboardSuccess"});
  return writeTextToClipboard;
}

/**
 * Function used to clear a form after submission.
 * @param {string} elementID - The ID of the form element to be cleared.
 * @example
 * ```html
 * <form id="myForm"></form>
 * ```
 *
 * ```javascript
 * clearForm('myForm');
 * ```
 * // The form with ID 'myForm' is reset.
 */
export function clearForm(elementID: string): void {
  const formElement = document.getElementById(elementID) as HTMLFormElement;

  formElement.reset();
}

/**
 * Checks if the current breakpoint is at least "md" based on Tailwind CSS.
 * @returns {boolean} - Returns true if the current breakpoint is "md" or larger, otherwise false.
 * @example
 * ```javascript
 * const isDesktop = isMdBreakpoint();
 * console.log(isDesktop); // Output: true or false depending on the window width.
 * ```
 */
export const isMdBreakpoint = (): boolean => {
  const mdBreakpointMin = 768;
  const windowWidth = window.innerWidth;

  return windowWidth >= mdBreakpointMin;
};

/**
 * Returns a string containing either "day" or "days" based on the number of days locked.
 * @param {number} daysLocked - The number of days locked.
 * @returns {string} - A string representing the number of days, e.g., "5 days".
 * @example
 * ```javascript
 * const daysString = formatDaysString(5);
 * console.log(daysString); // Output: "5 days"
 * ```
 */
export const formatDaysString = (daysLocked: number): string => {
  return `${daysLocked} ${t("common:text.days")}`;
};

/**
 * Converts a string to normal case (first letter uppercase, rest lowercase).
 * @param {string} input - The string to be converted.
 * @returns {string | undefined} - The converted string or undefined if input is falsy.
 * @example
 * ```javascript
 * const normalCase = toNormalCase('hello world');
 * console.log(normalCase); // Output: "Hello world"
 * ```
 */
export function toNormalCase(input: string): string | undefined {
  if (!input) return;
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

/**
 * Converts a string to Pascal Case (each word capitalized).
 * @param {string} input - The string to be converted.
 * @returns {string | undefined} - The converted string or undefined if input is falsy.
 * @example
 * ```javascript
 * const pascalCase = toPascalCase('hello world');
 * console.log(pascalCase); // Output: "Hello World"
 * ```
 */
export function toPascalCase(input: string): string | undefined {
  if (!input) return;

  const words = input.split(" ");
  const camelCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return camelCaseWords.join(" ");
}

/**
 * Generates a string for the interest reward based on the interest and reward payout period.
 * @param {string} interest - The interest rate as a string.
 * @param {number} rewardPayoutPeriod - The number of days for the reward payout period.
 * @returns {string} - A formatted string for the interest reward.
 * @example
 * ```javascript
 * const interestString = generateInterestString('5%', 30);
 * console.log(interestString); // Output: "5% every 30 days"
 * ```
 */
export function generateInterestString(interest: string, rewardPayoutPeriod: number): string {
  return `${interest} ${t("components:serviceproviderinfo.rewarddays", {days: rewardPayoutPeriod})}`;
}

/**
 * Calculates the annualized interest rate based on the interest rate and reward payout period.
 * @param {number} interestRate - The interest rate as a number.
 * @param {number} rewardPayoutPeriod - The reward payout period in days.
 * @returns {string} - The annualized interest rate formatted as a percentage.
 * @example
 * ```javascript
 * const annualizedInterest = calculateAnnualizedInterest(500, 30);
 * console.log(annualizedInterest); // Output: "6.00%"
 * ```
 */
export function calculateAnnualizedInterest(interestRate: number, rewardPayoutPeriod: number): string {
  const periodsPerYear = 360 / rewardPayoutPeriod;

  // Calculate interest per annum based on days locked
  const interestPerAnnum = (interestRate / 100) * periodsPerYear;

  return `${(interestPerAnnum).toFixed(2)}%`;
}

/**
 * Returns the text color for the Asset Financing Status.
 * @param {AssetFinancingStatus} status - The status of the asset financing.
 * @returns {string} - A string representing the Tailwind CSS text color.
 * @example
 * ```javascript
 * const textColor = handleAFPTextColour(AssetFinancingStatus.Active);
 * console.log(textColor); // Output: "text-green-500 dark:text-green-500 border-green-500"
 * ```
 */
export const handleAFPTextColour = (status: AssetFinancingStatus): string => {
  switch (status) {
  case AssetFinancingStatus.Active:
    return "text-green-500 dark:text-green-500 border-green-500";
  case AssetFinancingStatus.Closed:
    return "text-red-500 dark:text-red-500 border-red-500";
  case AssetFinancingStatus.Open:
    return "text-orange-500 dark:text-orange-500 border-orange-500";
  default:
    return "text-gray-800 dark:text-gray-100 border-gray-800";
  }
};
