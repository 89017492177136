import { useCreateCompanyMutation } from "@/graphql/__generated__/graphql-operations";
import { clearForm } from "@/utils/helpers";
import { toast } from "react-toastify";


export default function useCreateCompanyMutationHook(formID?: string) {
  const [createCompanyMutation, { loading, error }] = useCreateCompanyMutation({
    onCompleted() {
      toast.success("Successfully created company", {toastId: "createCompanySuccess"});
      clearForm(formID ?? "");
    },
    onError(error) {
      toast.error(error.toString(), {toastId: "createCompanyError"});
    }
  });

  return {
    createCompanyMutation,
    loading,
    error
  };
}
