import { TwInput, TwTextArea } from "@/components/shared";
import { companyDetailsForm } from "../../inputfields";
import { CreateCompanyFormField } from "@/types";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { CompanyDetailsState } from "./CompanyDetails";
import merge from "lodash.merge";
import { CompanyDetailsOnlyFragmentDoc } from "@/graphql/__generated__/graphql-operations";
import { useCompanyData } from "@/hooks";
import { client } from "@/client";

type Props = {
  setHasErrors: Dispatch<SetStateAction<boolean>>;
  newData: CompanyDetailsState|undefined;
  setNewData: Dispatch<SetStateAction<CompanyDetailsState|undefined>>;
}

function CompanyInformation({ setHasErrors, newData, setNewData }: Props) {

  // Hooks
  const { t } = useTranslation();
  const company = useCompanyData();

  // Read from cache
  const companyDetails = client.readFragment({
    id: `${company?.__typename}:${company?._id}`,
    fragment: CompanyDetailsOnlyFragmentDoc,
  });

  /**
  * Handler for company details form submit event
  * @param event: FormEvent<Element>
  */
  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    let dataToWrite: SetStateAction<CompanyDetailsState | undefined>;

    const { name, value, isValid } = event;
    setHasErrors(isValid ? false: true);

    if(isValid) {
      if(name === "yearlyrevenue" || name === "foundingyear") {
        dataToWrite = merge({}, newData, { serviceproviderinput: { companydetails: { [name]: parseInt(value) }}});
      }
      dataToWrite = merge({}, newData, { serviceproviderinput: { companydetails: { [name]: value }}});
      setNewData(dataToWrite);
    }
  }

  function getCompanyDetailValue(fieldName: string | number) {

    if (!companyDetails?.companydetails) {
      return null; // If companydetails is not defined, return null
    }
    // Check if the field name corresponds to a property in companydetails
    if (companyDetails?.companydetails[fieldName] !== undefined) {
      return companyDetails.companydetails[fieldName];
    }

    return null; // If the field name doesn't match any property, return null
  }

  return (
    <div className="w-full">
      <h3 className="font-semibold text-sm mb-4 select-none">{t("settings.companydetails.title")}</h3>
      <div>
        {companyDetailsForm.map((field:CreateCompanyFormField) => {
          if(field.isMultiline) {
            return (
              <div key={field.name}>
                <TwTextArea
                  key={field.name}
                  className="mf-input-field rounded-sm mb-2"
                  onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
                  required={field.required}
                  name={field.name}
                  type={field.type}
                  label={t(field.placeholder ?? "")}
                  labelPlacement="stacked"
                  maxLength={200}
                  defaultValue={getCompanyDetailValue(field.name)}
                  errorMessage={t(field.errorMessage)}
                  useErrorText
                />
              </div>
            );
          }
          else {
            return (
              <div key={field.name}>
                <TwInput
                  key={field.name}
                  className="mf-input-field mb-2"
                  onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
                  required={field.required}
                  name={field.name}
                  type={field.type}
                  label={t(field.placeholder ?? "")}
                  placeholder={t(field.placeholder ?? "")}
                  labelPlacement="stacked"
                  errorMessage={t(field.errorMessage ?? "TEST")}
                  useErrorText
                  autoFocus={false}
                  doNotUseRegex={false}
                  defaultValue={getCompanyDetailValue(field.name)}
                  // defaultValue={state?.companydetails?.[field.name]}
                  additionalValidation={field.additionalValidation}
                />
              </div>
            );
          }
        })
        }
      </div>
    </div>
  );
}

export default CompanyInformation;
