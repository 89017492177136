import { ActionButton, TableColumnHeader } from "@/components/shared/table/Table";
import { User, UserRole } from "@/graphql/__generated__/graphql-operations";
import { useUserData } from "@/hooks";
import { ColumnDef } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { IntlShape } from "react-intl";

export const getColumns = (intl: IntlShape, t: TFunction, actionFn?: any): ColumnDef<User, string>[] => {
  const user = useUserData();

  return (
    [
      {
        accessorKey: "firstname",
        header: ({ column }) => <TableColumnHeader text={t("manageusers.headers.firstname", { ns: "tables" })} column={column} />,
      },
      {
        accessorKey: "lastname",
        header: ({ column }) => <TableColumnHeader text={t("manageusers.headers.lastname", { ns: "tables" })} column={column} />,
      },
      {
        accessorKey: "mail",
        header: ({ column }) => <TableColumnHeader text={t("manageusers.headers.email", { ns: "tables" })} column={column} />,
      },
      {
        accessorKey: "active",
        header: ({ column }) => <TableColumnHeader text={t("manageusers.headers.active", { ns: "tables" })} column={column} />,
        cell: ({ row }) => {
          const value = row.getValue("active");
          return value ? "Yes" : "No";
        },
      },
      {
        id: "actions",
        cell: ({ row }) => {
          const btnText = user?.role === UserRole.Admin ? "Details" : "Remove";
          return <ActionButton dataObj={row.original} fn={actionFn} text={btnText} />;
        },
      }
    ]
  );
};
