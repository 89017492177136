type Props = {
  colour?: "green" | "red" | string;
  custom?: string;
}

/**
 * A React component that renders a small circle with customizable color and additional CSS classes.
 * The circle's color can be specified as either "green" or "red". Additional styles can be applied via the `custom` prop.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.colour] - The color of the circle, either "green" or "red".
 * @param {string} [props.custom] - Additional custom CSS classes to apply to the circle.
 *
 * @returns {JSX.Element} The rendered TwCircle component.
 *
 * @example
 * // Render a green circle
 * <TwCircle colour="green" />
 *
 * @example
 * // Render a red circle with custom CSS classes
 * <TwCircle colour="red" custom="m-2 border border-gray-500" />
 */
function TwCircle(props: Props): JSX.Element {
  return (
    <div data-cy="twcircle"
      className={`w-[8px] h-[8px] rounded-full
        ${props.custom ?? ""}
        ${props.colour === "green" && "bg-green-500"}
        ${props.colour === "red" && "bg-red-500"}`}
    />
  );
}

export default TwCircle;
