import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useLoginUserLazyQueryHook } from "@/hooks/queries";
import { RotatingLines } from "react-loader-spinner";

function TokenLogin() {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const { loginQuery, loading } = useLoginUserLazyQueryHook(true);

  useEffect(() => {
    // Early exit if no token is present
    if (!token) {
      navigate("/login", { replace: true });
      return;
    }

    try {
      // Decode the base64 token
      const decodedToken = atob(token);
      loginQuery({ variables: { token: decodedToken } }); // Use decoded token
    } catch (err) {
      // Handle base64 decoding errors
      navigate("/login", { replace: true });
    }
  }, [token, navigate, loginQuery]);

  return loading ? (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="flex flex-col items-center">
        <p className="text-2xl mb-2">
          Verifying token...
        </p>
        <RotatingLines
          width="60"
          strokeColor="#EE5300"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
      </div>
    </div>
  ) : null;
}

export default TokenLogin;
