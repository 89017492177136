import { AssetFinancingStatus, AssetType, useGetServiceProviderDetailsByIdQuery } from "@/graphql/__generated__/graphql-operations";
import { handleAFPTextColour } from "@/utils/helpers";
import { SPDetailsSkeleton } from "../skeletons";
import myronLogoAlt from "@/assets/MYRON-icon-allwhite.webp";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { useServiceProviderDetails } from "@/hooks/cache/useServiceProviderDetails";

type Props = {
  financingStatus?: AssetFinancingStatus;
  serviceProviderId: string;
  className?: string;
  titleClassName?: string;
  logoSize?: "default" | "small";
}

function ServiceProviderDetails(props: Props) {
  const {
    financingStatus,
    serviceProviderId,
    className,
    titleClassName,
    logoSize = "default",
  } = props;

  // Hooks
  const { t } = useTranslation(["components", "common"]);

  const SPDetailFragment = useServiceProviderDetails(serviceProviderId as string);

  const { data, loading } = useGetServiceProviderDetailsByIdQuery({
    variables: {
      serviceproviderId: serviceProviderId || ""
    },
    fetchPolicy: "cache-first",
    skip: !!SPDetailFragment,
  });

  const SPDetails = SPDetailFragment || data?.getServiceProviderDetailsByID;

  if (loading) {
    // Render skeleton when loading is true
    return (
      <SPDetailsSkeleton />
    );
  }

  return (
    <div className={`flex justify-start gap-4 mr-4 ${className}`} data-cy="sp-details-component">
      {/* Left container */}
      <div className="h-full">
        {/* Logo */}
        <LazyLoadImage
          alt={"Company logo"}
          className={`
            rounded-md object-contain bg-gray-100
            ${logoSize === "default" && "w-[65px] h-[65px] min-h-[65px] min-w-[65px]"}
            ${logoSize === "small" && "w-[40px] h-[40px] min-h-[40px] min-w-[40px]"}
          `}
          src={!SPDetails?.logo ?
            myronLogoAlt :
            SPDetails?.logo?.replace(".cloud.google.", ".googleapis.")}
          effect="opacity"
        />
      </div>
      {/* Right container */}
      <div>
        <div className="mb-1">
          {/* SP Name */}
          <p data-cy="sp-name" className={`font-bold text-base break-words dark:text-white ${titleClassName ? titleClassName : ""}`}>
            {SPDetails?.name ?? t("common:text.notavailable")}
          </p>
          {/* Industry */}
          <p data-cy="sp-industry" className="text-xs text-gray-400 pt-1 dark:text-gray-300">
            {SPDetails?.companydetails?.industry ?? t("common:text.notavailable")}
          </p>
        </div>
        {/* Tags container */}
        <div className="flex items-center space-x-1">
          {/* Asset type tag */}
          <div
            className="border-[1px] border-mfpurple dark:border-mforange py-[2px] px-2 text-center rounded-full w-fit"
          >
            <p data-cy="sp-asset-type" className="text-[10px] text-mfpurple dark:text-white whitespace-nowrap font-semibold">
              {
                SPDetails?.assettype === AssetType.Inventory ?
                  t("serviceproviderinfo.inventory") :
                  t("serviceproviderinfo.receivable")
              }
            </p>
          </div>
          {/* Asset financing status tag */}
          {
            financingStatus &&
              <div
                className={`border-[1px] py-[2px] px-2 text-center rounded-full w-fit
                ${handleAFPTextColour(financingStatus)}`}
              >
                <p data-cy="sp-financing-status" className="text-[10px] whitespace-nowrap font-semibold">
                  {financingStatus}
                </p>
              </div>
          }
        </div>
      </div>
    </div>
  );
}

export default ServiceProviderDetails;
