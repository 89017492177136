import { BlankModal, BlankModalContent } from "@/components/modals";
import { SetWalletSetupModals } from "@/constants/enums";
import { useWeb3 } from "@/context/Web3Context";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import myronLogo from "@/assets/MYRON-icon-transparent.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

type Props = {
  setShowModal: Dispatch<SetStateAction<SetWalletSetupModals>>;
}

const steps = [
  "stepone",
  "steptwo",
  "stepthree",
  "stepfour",
  "stepfive",
  "stepsix"
];

function SetWalletSetupModal({ setShowModal }: Props) {
  const {
    isConnected,
    connectWallet,
  } = useWeb3();

  const { t } = useTranslation(["modals"]);

  useEffect(() => {
    if(isConnected) {
      setShowModal(SetWalletSetupModals.PublicKey);
    }
  }, [isConnected]);

  return (
    <BlankModal
      setShowModal={() => {setShowModal(SetWalletSetupModals.NoModal);}}
      className="md:px-10 lg:px-40 xl:px-72"
      doNotShowCloseIcon
      doNotShowTopContainer
    >
      <BlankModalContent>
        {/* Image container */}
        <div className="flex justify-center mb-8 py-8">
          <LazyLoadImage
            alt={"Myron logo"}
            className="h-16"
            src={myronLogo}
            effect="opacity"
          />
        </div>

        {/* Content container */}
        <div className="mb-4">
          <p className="mb-2 font-semibold">{t("initialisepublickey.setup.title")}</p>
          <ol className="list-decimal ml-8">
            {steps.map((step) => (
              <li key={step} className="mb-1">
                <p className="font-semibold">{t(`initialisepublickey.setup.${step}title`)}</p>{t(`initialisepublickey.setup.${step}text`)}
              </li>
            ))}
          </ol>
        </div>

        {/* Warning container */}
        <div className="p-4 rounded-md bg-mfpurple text-white mb-4">
          <p>
            <span className="font-bold">{t("initialisepublickey.setup.notetitle")}:&nbsp;</span>
            {t("initialisepublickey.setup.note")}
          </p>
        </div>

        {/* Support container */}
        <div className="mb-4">
          <p>{t("initialisepublickey.setup.support")} <a href="mailto:contact@myron.finance" className="text-blue-400">contact@myron.finance</a></p>
        </div>

        {/* Buttons container */}
        <div className="flex justify-between">
          <button
            onClick={() => setShowModal(SetWalletSetupModals.Welcome)}
            className="mf-btn-action-large-primary-outline"
          >
            {t("common:buttons.back")}
          </button>
          <button
            onClick={connectWallet}
            className="mf-btn-action-large-primary-filled"
          >
            {t("common:buttons.connect")}
          </button>
        </div>

        {/* Stepper */}
        <div className="flex justify-center mt-8">
          <div className="flex items-center">
            <div className="h-2 w-2 bg-gray-300 rounded-full" />
            <div className="h-2 w-2 bg-mforange rounded-full mx-2" />
            <div className="h-2 w-2 bg-gray-300 rounded-full" />
          </div>
        </div>
      </BlankModalContent>
    </BlankModal>
  );
}

export default SetWalletSetupModal;
