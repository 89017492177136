import { TwCard } from "@/components/shared";
import { convertBpToPercentage, formatNumberLocale } from "@/utils/helpers";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
  BanknotesIcon,
  ArrowTrendingUpIcon,
  CalendarDaysIcon} from "@heroicons/react/24/outline";
import { ValueSkeleton } from "@/components/skeletons";
import { FormattedDate, useIntl } from "react-intl";
import { useTranslation } from "react-i18next";

type Props = {
  cardTitle?: string;
  currency?: string;
  amount?: string | number;
  className?: string;
  tooltipText?: string;
  percentage?: number | null;
  increase?: boolean;
  date?: string | null;
  daysLeft?: number;
  isLoading?: boolean;
  usePercentageAsIs?: boolean;
}

// Get user locale --> not yet used but will be useful
// in future for date-fns
// const userLocale  = navigator.language || navigator.languages[0];

/**
 * Multi-purpose Finance Card that can show monetary values, percentages or
 * dates based on the input
 * @param props
 * @returns a different Finance Card depending on the input given
 */
function FinanceCard(props: Props) {

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["components"]);

  return (
    <TwCard className={`${props.className} mf-finance-card`}>
      {/* Tooltip */}
      <div className="hidden md:flex relative justify-end">
        <div className="group">
          <InformationCircleIcon data-cy="informationCircleIcon" className="w-6 h-6 cursor-pointer" />
          <p className="mf-card-tooltip">
            {props.tooltipText}
          </p>
        </div>
      </div>
      {/* Card title */}
      <div className="flex justify-between">
        <p
          className="font-light md:text-lg mf-text-secondary"
        >
          {props.cardTitle}
        </p>
        <div />
      </div>

      <div className="w-full flex text-2xl md:text-3xl mf-text-primary">
        {/* Monetary value card */}
        {
          props.amount != undefined &&
          // Main container
            <div className="w-full flex items-center justify-between">
              {/* Label and Value container */}
              <div className="flex items-center min-w-[20%] w-full">
                {
                  !props.isLoading ?
                    formatNumberLocale(intl, props.amount, "currency", false, false, true)
                    :
                    <ValueSkeleton className="ml-1 max-w-[50%]" />
                }
              </div>
              <div>
                <BanknotesIcon data-cy="banknotesIcon" className="hidden xl:flex w-10 h-10 text-gray-300" />
              </div>
            </div>
        }
        {/* Withdrawal card */}
        <div className="flex flex-1 flex-col">
          {
            props.date != null && props.date != undefined &&
            <div className="w-full flex items-center justify-between">
              <p className="font-semibold text-3xl mf-text-primary">
                <FormattedDate
                  value={Number(new Date(props.date))}
                />
              </p>
              <CalendarDaysIcon data-cy="calendarDaysIcon" className="hidden xl:flex w-10 h-10 text-gray-300" />
            </div>
          }
          {
            props.daysLeft && props.date != null &&
              <div className="flex">
                <p
                  className="md:text-lg font-light text-mforange"
                >
                  {`${t("financecard.daysleft")} ${props.daysLeft}`}
                </p>
              </div>
          }
          {
            props.date === null && !props.amount &&
              <p
                className="md:text-2xl font-light text-mforange"
              >
                {t("financecard.nodateavailable")}
              </p>
          }
        </div>
        {/* Interest percentage card */}
        {
          props.percentage === 0 &&
          <div className="flex w-full items-center text-3xl justify-between">
            <div className="flex justify-start">
              <p>{formatNumberLocale(intl, 0, "percent")}</p>
            </div>
            <ArrowTrendingUpIcon data-cy="arrowTrendingUpIconInterest" className="hidden xl:flex w-10 h-10 text-gray-300" />
          </div>
        }
        {
          props.percentage ?
            <div className="w-full flex items-center justify-between mf-text-primary text-2xl md:text-3xl">
              <div className="flex justify-start">
                {
                  props.percentage && props.percentage >= 0 ?
                    <ChevronUpIcon data-cy="chevronUpIcon" className="w-7 md:w-9 md:h-9 text-green-500" /> :
                    <ChevronDownIcon data-cy="chevronDownIcon" className="w-7 md:w-9 md:h-9 text-red-500" />
                }
                {
                  props.usePercentageAsIs ?
                    <p>{formatNumberLocale(intl, props.percentage, "percent")}</p>:
                    <p>{formatNumberLocale(intl, convertBpToPercentage(props.percentage), "percent").toString()}</p>
                }
              </div>
              <ArrowTrendingUpIcon data-cy="arrowTrendingUpIconPercent" className="hidden xl:flex w-10 h-10 text-gray-300" />
            </div>: null
        }
      </div>
    </TwCard>
  );
}

export default FinanceCard;
