import { client } from "@/client";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { TwInput } from "@/components/shared/form";
import { TwSelect, TwSelectOption } from "@/components/shared/selector";
import { handleTableCardTagColour } from "@/components/shared/table/utils";
import { Switch } from "@/components/ui/Switch";
import { DeployPageModals } from "@/constants/enums";
import { AssetFinancingPool, AssetFinancingPoolFragment, AssetFinancingPoolInput, AssetFinancingStatus, AssetType, ServiceProviderDataFragment, ServiceProviderDataFragmentDoc } from "@/graphql/__generated__/graphql-operations";
import { useDeployAssetFinancingPoolContractMutationHook, useEditAssetFinancingPoolMutationHook, useAddAssetFinancingPoolMutationHook } from "@/hooks/mutations";
import { calculateClosingDate } from "@/pages/Portolio/utils";
import { convertBpToPercentage, formatDateLocale, formatDaysString, formatNumberLocale, toPascalCase } from "@/utils/helpers";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";

type Props = {
  currentSpId: string;
  setShowModal: Dispatch<SetStateAction<DeployPageModals>>;
}

function ServiceProviderDetailsModal(props: Props) {
  // Props
  const {
    setShowModal, currentSpId
  } = props;

  const spData = client.readFragment({
    id: `ServiceProvider:${currentSpId}`,
    fragment: ServiceProviderDataFragmentDoc
  }) as ServiceProviderDataFragment;

  const [input, setInput] = useState<AssetFinancingPoolInput|{ [x: string]: string | number | boolean; }| null>(null);

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();

  // Mutation hooks
  const { deployMutation } = useDeployAssetFinancingPoolContractMutationHook();
  const { editAssetFinancingPoolMutation }  = useEditAssetFinancingPoolMutationHook();
  const { addAssetFinancingPoolMutation } = useAddAssetFinancingPoolMutationHook();

  // Constants
  const afp = spData?.assetfinancingpool;
  const isClosed = afp?.status === AssetFinancingStatus.Closed;
  const isOpen = afp?.status === AssetFinancingStatus.Open;
  const isOnboarding = afp?.status === AssetFinancingStatus.Onboarding;
  const isActive = afp?.status === AssetFinancingStatus.Active;
  const isReceivable = afp?.assetType === AssetType.Receivable;
  const poolAddress = afp?.pooladdress;

  // States
  const [editMode, setEditMode] = useState<boolean>(false);
  const [hasUniqueItems, setHasUniqueItems] = useState<boolean>(afp?.uniqueitems ?? false);

  const infoFields = [
    {
      label: t("deployafp.labels.amountinvested"),
      value: formatNumberLocale(intl, afp?.amountinvested, "currency"),
      visibility: [AssetType.Inventory, AssetType.Receivable],
    },
    {
      label: "Activation date",
      value: isOpen || isOnboarding ? "Not available": formatDateLocale(intl, afp?.activationdate ?? null),
      visibility: [AssetType.Inventory, AssetType.Receivable],
    },
    {
      label: "Closing date",
      value: calculateClosingDate(intl, afp?.activationdate, afp?.dayslocked),
      visibility: [AssetType.Inventory, AssetType.Receivable],
    },
    {
      label: t("deployafp.labels.assetType"),
      visibility: [AssetType.Inventory, AssetType.Receivable],
      value: afp?.assetType,
      editComponent:
        <TwSelect
          required
          name="assetType"
          onChange={handleOnChange}
          defaultValue={isClosed ? "" : afp?.assetType ?? ""}
          className="mf-input-field max-w-[200px] md:max-w-[300px] lg:min-w-[400px]"
        >
          <TwSelectOption
            disabled
            value={""}
            text={"Select an option"}
          />
          {
            [AssetType.Receivable, AssetType.Inventory].map((option: string) => (
              <TwSelectOption
                key={option}
                value={option}
                text={option}
              />
            ))
          }
        </TwSelect>
    },
    {
      label: t("deployafp.labels.ceiling"),
      visibility: [AssetType.Inventory],
      value: formatNumberLocale(intl,  convertBpToPercentage(afp?.ceiling ?? 0), "percent"),
      editComponent:
        <TwInput
          type="number"
          required={input?.assetType === AssetType.Inventory ? true : false}
          name="ceiling"
          min={afp?.floor ?
            (afp?.floor + 1).toString() : undefined
          }
          onChange={handleOnChange}
          className="mf-input-field max-w-[200px] md:max-w-[300px] lg:min-w-[400px]"
          defaultValue={isClosed && !isReceivable ? "" : afp?.ceiling ?? 0}
          errorMessage={t("deployafp.inputfields.ceiling.errormsg").toString()}
        />
    },
    {
      label: t("deployafp.labels.floor"),
      visibility: [AssetType.Inventory],
      value: formatNumberLocale(intl,  convertBpToPercentage(afp?.floor ?? 0), "percent"),
      editComponent:
        <TwInput
          type="number"
          required={input?.assetType === AssetType.Inventory ? true : false}
          name="floor"
          min={"10000"}
          onChange={handleOnChange}
          className="mf-input-field max-w-[200px] md:max-w-[300px] lg:min-w-[400px]"
          defaultValue={isClosed && !isReceivable ? "" : afp?.floor ?? 0}
          errorMessage={t("deployafp.inputfields.floor.errormsg").toString()}
        />
    },
    {
      label: "Unique Items",
      visibility: [AssetType.Inventory],
      value: afp?.uniqueitems ? "Yes" : "No",
      editComponent:
      <Switch
        checked={hasUniqueItems}
        onClick={() => setHasUniqueItems((prev) => !prev)}
        onCheckedChange={(checked) => setInput({...input, uniqueitems: checked})}
      />
    },
    {
      label: t("deployafp.labels.financinglimit"),
      visibility: [AssetType.Inventory, AssetType.Receivable],
      value: formatNumberLocale(intl, afp?.financinglimit, "currency"),
      editComponent:
        <TwInput
          type="number"
          required
          name="financinglimit"
          onChange={handleOnChange}
          className="mf-input-field max-w-[200px] md:max-w-[300px] lg:min-w-[400px]"
          defaultValue={isClosed ? "" : afp?.financinglimit}
          errorMessage={t("deployafp.inputfields.financinglimit.errormsg").toString()}
        />
    },
    {
      label: t("deployafp.labels.interestrate"),
      visibility: [AssetType.Inventory, AssetType.Receivable],
      value: (convertBpToPercentage(afp?.interest ?? 0)*100).toFixed(2)+"%",
      editComponent:
        <TwInput
          type="number"
          required
          name="interest"
          onChange={handleOnChange}
          className="mf-input-field max-w-[200px] md:max-w-[300px] lg:min-w-[400px]"
          defaultValue={isClosed ? "" : afp?.interest}
          errorMessage={t("deployafp.inputfields.interest.errormsg").toString()}
        />
    },
    {
      label: t("deployafp.labels.lockedfor"),
      visibility: [AssetType.Inventory, AssetType.Receivable],
      value: formatDaysString(afp?.dayslocked),
      editComponent:
        <TwInput
          type="number"
          required
          name="dayslocked"
          onChange={handleOnChange}
          className="mf-input-field max-w-[200px] md:max-w-[300px] lg:min-w-[400px]"
          defaultValue={isClosed ? "" : afp?.dayslocked}
          // TODO Update translation key
          errorMessage={t("deployafp.inputfields.dayslocked.errormsg").toString()}
        />
    },
    {
      label: t("deployafp.labels.interestpayout"),
      visibility: [AssetType.Inventory, AssetType.Receivable],
      value: `Every ${afp?.rewardpayoutperiod} days`,
      editComponent:
        <TwInput
          type="number"
          required
          name="rewardpayoutperiod"
          onChange={handleOnChange}
          className="mf-input-field max-w-[200px] md:max-w-[300px] lg:min-w-[400px]"
          defaultValue={isClosed ? "" : afp?.rewardpayoutperiod}
          errorMessage={t("deployafp.inputfields.rewardpayoutperiod.errormsg").toString()}
        />
    }
  ];

  /**
   * OnClick handler for Deploying smart contract
   */
  async function handleOnDeployClick(afp: AssetFinancingPoolFragment) {
    // Prevent Deployment until AFP details has been updated in the DB
    if(afp.pooladdress !== "0x0" && afp.status === AssetFinancingStatus.Closed) {
      return null;
    }

    if(currentSpId !== "" ){
      toast.loading(t("toasts:deployLoadingToast"), {toastId: "deployLoadingToast"});
      await deployMutation(afp._id, currentSpId);
    }
    toast.dismiss("deployLoadingToast");
  }

  /**
   * OnSubmit handler for Asset Financing Pool
   * @param company Current ServiceProvider
   * @returns
   */
  function onSubmitClick(afpStatus: AssetFinancingStatus) {
    // Check if AFC exists in state
    if(input) {
      toast.loading("Submitting data", {toastId: "editLoadingToast"});
      // Remove Inventory only fields incase user has changed type
      if(input.assetType === AssetType.Receivable) {
        delete input.ceiling;
        delete input.floor;
        delete input.uniqueitems;
      }
      // If AFC is closed, add new AFC
      if (afpStatus === AssetFinancingStatus.Closed) {
        addAssetFinancingPoolMutation({
          variables: {
            id: currentSpId,
            assetFinancingPoolInput: input as AssetFinancingPoolInput
          }
        });
      }
      // Edit existing AFC if it is onboarding
      else {
        editAssetFinancingPoolMutation({
          variables: {
            id: currentSpId,
            assetFinancingPoolInput: { ...input }
          }
        });
      }
      toast.dismiss("editLoadingToast");
      setInput(null);
    }
    return;
  }

  /**
  * onChange handler for input fields
  * @param event: { name: string, value: string, isValid: boolean }
  */
  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    // Handle input field change
    const { name, value, isValid } = event;

    if (isValid) {
      if (["ceiling", "floor", "interest"].includes(name)) {
        setInput({...input, [name]: parseInt(value)});
      } else {
        setInput({...input, [name]: value});
      }
    }
  }

  const handleEditSaveClick = (e: FormEvent) => {
    e.preventDefault();
    if (editMode) {
      // Save changes
      setEditMode(false);
      onSubmitClick(spData.assetfinancingpool.status);
    } else {
      // Edit mode
      setEditMode(true);
    }
  };

  return (
    <BlankModal
      setShowModal={() => {
        setShowModal(DeployPageModals.NoModal);
      }}
      title={spData.name}
      titleCSS="text-lg font-semibold"
      className="px-6"
      customContentCSS="mf-modal-content max-md:w-full md:min-w-[400px] lg:min-w-[580px]"
    >
      <BlankModalContent>
        {/* Top container */}
        <div className="flex items-center justify-between -mt-2">
          {/* Tags */}
          <div className="flex items-center gap-2">
            <div className={`rounded-md text-sm border-[1px] p-1 select-none ${handleTableCardTagColour(afp.status, "Company")}`}>
              {toPascalCase(afp.status)}
            </div>
            <div className={`rounded-md text-sm border-[1px] p-1 select-none ${handleTableCardTagColour(afp.assetType as string, "")}`}>
              {toPascalCase(afp.assetType as string)}
            </div>
          </div>
        </div>

        {/* Addresses */}
        <div className="my-2">
          <div>
            <p className="text-sm dark:text-gray-400">Pool Address</p>
            <p className="break-all">{afp.pooladdress}</p>
          </div>
          <div>
            <p className="text-sm dark:text-gray-400">Token Address</p>
            <p className="break-all">{afp.tokenaddress}</p>
          </div>
        </div>

        {/* AFP Details Form */}
        <form onSubmit={handleEditSaveClick} className="space-y-2">
          {
            // Action buttons
            <div className="flex space-x-2 justify-end mb-4">
              {/* Cancel button */}
              {
                editMode &&
                    <button
                      onClick={() => setEditMode(false)}
                      className="mf-btn-action-small-primary-outline"
                    >
                      Cancel
                    </button>
              }
              {/* Edit Button */}
              <button
                type={"submit"}
                // Button is disabled if AFP is ACTIVE or OPEN
                disabled={isActive || isOpen}
                className={`${isActive || isOpen ? "mf-btn-action-small-disabled" : "mf-btn-action-small-primary-filled"}`}
              >
                {editMode ? "Save" : "Edit"}
              </button>
            </div>
          }
          {
            infoFields.map((field) => {
              return (
                (field.visibility.includes(afp.assetType as AssetType)) &&
                  <div key={`spInfoField-${field.label}`} className="flex items-center justify-between">
                    <p className="text-sm font-semibold pr-2 text-gray-800 dark:text-gray-100">
                      {field.label}
                    </p>
                    {
                      editMode ?
                      // If editMode, show the input field
                        field.editComponent ??
                        // Show normal value if no edit component
                          <p className="text-sm text-gray-800 dark:text-gray-100">{field.value}</p>
                        :
                        <p className="text-sm text-gray-800 dark:text-gray-100">{field.value}</p>
                    }
                  </div>
              );
            })
          }
        </form>
      </BlankModalContent>

      <BlankModalFooter>
        {
          // Only show button if AFP Status is Closed or Onboarding
          // Hide button during edit mode
          (isClosed || isOnboarding) && !editMode &&
            // Action buttons
            <div className="flex w-full items-center justify-center mt-4">
              <button
                disabled={isClosed && poolAddress !== "0x0"}
                onClick={() => handleOnDeployClick(spData.assetfinancingpool as AssetFinancingPool)}
                className={`${isClosed && poolAddress !== "0x0" ?"mf-btn-action-small-disabled":"mf-btn-action-small-primary-filled"}`}
              >
                {"Deploy"}
              </button>
            </div>
        }
      </BlankModalFooter>
    </BlankModal>
  );
}

export default ServiceProviderDetailsModal;
