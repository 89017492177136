import { ClipboardIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { useCompanyData } from "@/hooks";

function PublicKey() {
  // Hooks
  const company = useCompanyData();

  return (
    <div>
      <div className="mb-4">
        <p className="font-semibold">{t("settings.publickey.label")}</p>
        <div className="flex justify-between rounded-sm bg-gray-100 dark:bg-mfdarklighter px-4 py-2">
          <span className="text-gray-500 dark:text-white break-all">{company?.publickey}</span>
          <ClipboardIcon
            className="min-w-[24px] min-h-[24px] w-6 h-6 cursor-pointer"
            onClick={() => navigator.clipboard.writeText(company?.publickey || "")}
          />
        </div>
      </div>
    </div>
  );
}

export default PublicKey;
