/**
 * Util functions for TwTableCard and TwTableCardList
 */

import { TokenTransactionType, AssetType, TokenTransactionStatus, CreditLimitRequestStatus, AssetFinancingStatus } from "@/graphql/__generated__/graphql-operations";
import { formatNumberLocale, formatDateLocale } from "@/utils/helpers";
import { IntlShape } from "react-intl";
import { t } from "i18next";

/**
 * Helper function to populate the Tags array based on the data item and its type.
 * This function is used in `TwTableCard` to generate relevant tags for different data types.
 *
 * @param {any} dataItem - The data item used to populate the tags. This object structure varies based on the `dataType`.
 * @param {string} dataType - The type of data item, which determines the tags to generate. Possible values include:
 *   - "TokenTransaction"
 *   - "Asset"
 *   - "User"
 *   - "CreditLimitRequest"
 *   - "Company"
 *
 * @returns {Array<{ label: string; value: string }>} - An array of tag objects, each containing a `label` and `value`.
 *
 * @example
 * const dataItem = {
 *   status: "Completed",
 *   type: TokenTransactionType.Buy
 * };
 * const dataType = "TokenTransaction";
 *
 * const tags = getTags(dataItem, dataType);
 * // Output: [
 * //   { label: "Completed", value: "Completed" },
 * //   { label: "Deposit", value: "Buy" }
 * // ]
 */
export const getTags = (dataItem: any, dataType: string): {
  label: string;
  value: string;
}[] => {
  // Initialise tags array
  const tags: any[] = [];
  switch (dataType) {
  case "TokenTransaction":
    tags.push({ value: dataItem.status, label: dataItem.status });
    tags.push({
      value: dataItem.type,
      label: dataItem.type === TokenTransactionType.Buy ? t("tables:tokentransactions.tablecard.tags.deposit") : t("tables:tokentransactions.tablecard.tags.withdrawal")
    });
    break;
  case "Asset":
    if (dataItem.type === AssetType.Inventory) {
      tags.push({
        value: dataItem.isCollateral,
        label: dataItem.isCollateral ? t("tables:assetsoverview.tablecard.tags.ininventory") : t("tables:assetsoverview.tablecard.tags.notininventory")
      });
    } else {
      tags.push({
        value: dataItem.isFinanced,
        label: dataItem.isFinanced ? t("tables:assetsoverview.tablecard.tags.financed") : t("tables:assetsoverview.tablecard.tags.notfinanced")
      });
    }
    break;
  case "User":
    tags.push({
      value: dataItem.active,
      label: dataItem.active ? t("tables:manageusers.tablecard.tags.active") : t("tables:manageusers.tablecard.tags.inactive")
    });
    break;
  case "CreditLimitRequest":
    tags.push({ value: dataItem.status, label: dataItem.status });
    break;
  case "Company":
    tags.push({ value: dataItem?.assetfinancingpool?.status, label: dataItem?.assetfinancingpool?.status });
    break;
  default:
    break;
  }
  return tags;
};

/**
 * Retrieves the date object for the given `dataItem` based on the `dataType`.
 *
 * @param {any} dataItem - The data item containing the date information.
 * @param {string} dataType - The type of data used to determine which date to retrieve.
 * @returns {Date|string|undefined} - The date of the data item, or `undefined` if not applicable.
 *
 * @example
 * const dataItem = { createdAt: '2024-08-27' };
 * const dataType = "TokenTransaction";
 * const date = getDate(dataItem, dataType);
 * // date will be '2024-08-27'
 */
export const getDate = (dataItem: any, dataType: string): Date | string | undefined => {
  switch (dataType) {
  case "TokenTransaction":
  case "AssetFinancingRound":
  case "CreditLimitRequest":
    return dataItem.createdAt;
  default:
    break;
  }
};

/**
 * Populates an array of text info groups based on the `dataItem`, `dataType`, and `intl`.
 *
 * @param {any} dataItem - The data item used to generate text info groups.
 * @param {string} dataType - The type of data which determines the text info groups generation logic.
 * @param {IntlShape} intl - The internationalization object for formatting.
 * @returns {Array<{ label: string, content: any, isLink?: boolean, linkText?: string }>} - An array of text info groups.
 *
 * @example
 * const dataItem = {
 *   referencenumber: '12345',
 *   amount: 1000,
 *   type: AssetType.Inventory,
 *   value: 2000,
 *   updatedAt: '2024-08-27',
 *   firstname: 'John',
 *   lastname: 'Doe',
 *   mail: 'john.doe@example.com'
 * };
 * const dataType = "TokenTransaction";
 * const intl = useIntl();
 * const textInfoGroups = getTextInfoGroups(dataItem, dataType, intl);
 * // Output:
 * // [
 * //   { label: 'Reference', content: '12345' },
 * //   { label: 'Amount', content: '$1,000.00' }
 * // ]
 */
export const getTextInfoGroups = (dataItem: any, dataType: string, intl: IntlShape): {
  label: string;
  content: any;
  isLink?: boolean;
  linkText?: string;
}[] => {
  // Initialise tags array
  const textInfoGroups: any[] = [];
  switch (dataType) {
  case "TokenTransaction":
    textInfoGroups.push(
      {
        label: t("tables:tokentransactions.tablecard.textinfogroups.reference"),
        content: dataItem.referencenumber,
      },
      {
        label: t("tables:tokentransactions.tablecard.textinfogroups.amount"),
        content: formatNumberLocale(intl, dataItem.amount, "currency"),
      }
    );
    break;
  case "Asset":
    if (dataItem.type === AssetType.Inventory) {
      textInfoGroups.push(
        {
          label: t("tables:assetsoverview.tablecard.textinfogroups.value"),
          content: formatNumberLocale(intl, dataItem.value, "currency")
        },
        {
          label: t("tables:assetsoverview.tablecard.textinfogroups.financedon"),
          content: formatDateLocale(intl, dataItem.updatedAt)
        },
      );
    } else {
      textInfoGroups.push(
        {
          label: t("tables:assetsoverview.tablecard.textinfogroups.value"),
          content: formatNumberLocale(intl, dataItem.value, "currency")
        },
        {
          label: t("tables:assetsoverview.tablecard.textinfogroups.financedon"),
          content: formatDateLocale(intl, dataItem.updatedAt)
        }
      );
    }
    break;
  case "AssetFinancingRound":
    // Receivables
    if(dataItem.spdetails.assettype === AssetType.Receivable) {
      textInfoGroups.push(
        {
          label: t("tables:poolactivity.headers.amountfinanced"),
          content: formatNumberLocale(intl, dataItem.amountFinanced, "currency"),
        },
        {
          label: t("tables:poolactivity.headers.amountnotfinanced"),
          content: formatNumberLocale(intl, dataItem.amountNotFinanced, "currency"),
        }
      );
    }
    // Inventory
    else {
      textInfoGroups.push(
        {
          label: t("tables:poolactivity.headers.inventoryvalue"),
          content: formatNumberLocale(intl, dataItem.amountFinanced, "currency"),
        },
        {
          label: t("tables:poolactivity.headers.inventorybought"),
          content: formatNumberLocale(intl, dataItem.inventoryBought, "currency"),
        },
        {
          label: t("tables:poolactivity.headers.inventorysold"),
          content: formatNumberLocale(intl, dataItem.inventorySold, "currency"),
        }
      );
    }


    break;
  case "User":
    textInfoGroups.push(
      {
        label: t("tables:manageusers.tablecard.textinfogroups.firstname"),
        content: dataItem.firstname,
      },
      {
        label: t("tables:manageusers.tablecard.textinfogroups.lastname"),
        content: dataItem.lastname,
      },
      {
        label: t("tables:manageusers.tablecard.textinfogroups.email"),
        content: dataItem.mail,
      }
    );
    break;
  case "CreditLimitRequest":
    textInfoGroups.push(
      {
        label: t("tables:creditlimitrequests.headers.serviceprovider"),
        content: dataItem.serviceprovider.name,
      },
      {
        label: t("tables:creditlimitrequests.headers.customer"),
        content: dataItem.customer.name,
      },
      {
        label: t("tables:creditlimitrequests.headers.amount"),
        content: formatNumberLocale(intl, dataItem.requestedAmount, "currency"),
      },
      {
        label: t("tables:creditlimitrequests.tablecard.textinfogroups.reasonForReq"),
        content: dataItem.reasonForRequest ?? "Not available",
      },
      {
        label: t("tables:creditlimitrequests.tablecard.textinfogroups.reasonForDenial"),
        content: dataItem.reasonForRequest ?? "Not available",
      },
    );
    break;
  case "Customer":
    textInfoGroups.push(
      {
        label: "ID from Documentary",
        content: dataItem.idFromDocumentary,
      },
      {
        label: "Name",
        content: dataItem.name,
      },
      {
        label: "Public Key",
        content: dataItem.publickey,
      },
      {
        label: "Credit",
        content: formatNumberLocale(intl, dataItem.credit, "currency"),
      },
      {
        label: "Credit Limit",
        content: formatNumberLocale(intl, dataItem.creditLimit, "currency"),
      },
    );
    break;
  case "Company":
    textInfoGroups.push(
      {
        label: "Name",
        content: dataItem.name,
      },
      {
        label: "Pool Address",
        content: dataItem?.assetfinancingpool?.pooladdress,
      },
      {
        label: "Public Key",
        content: dataItem.publickey,
      },
    );
    break;
  default:
    break;
  }
  return textInfoGroups;
};

/**
 * Returns the text and border color for tags used in TwTableCard based on the value and type.
 *
 * @param {string} value - The value to be checked for the tag.
 * @param {string} type - The type of data used to determine the color.
 * @returns {string} - The text and border color class for the tag.
 *
 * @example
 * const colorClass = handleTableCardTagColour(TokenTransactionStatus.Confirmed, "TokenTransaction");
 * // Output: "text-green-500 dark:text-green-500 border-green-500"
 */
export const handleTableCardTagColour = (value: string, type: string): string => {
  const green = "text-green-500 dark:text-green-500 border-green-500";
  const red = "text-red-500 dark:text-red-500 border-red-500";
  const orange = "text-orange-500 dark:text-orange-500 border-orange-500";
  const gray = "text-gray-800 dark:text-gray-100 border-gray-800 dark:border-gray-100";

  switch (type) {
  case "TokenTransaction":
    return value === TokenTransactionStatus.Confirmed
      ? green
      : value === TokenTransactionStatus.Failed
        ? red
        : value === TokenTransactionStatus.Pending
          ? orange
          : gray;
  case "Asset":
    return value
      ? green
      : !value
        ? red
        : gray;
  case "User":
    return value
      ? green
      : red;
  case "CreditLimitRequest":
    return value === CreditLimitRequestStatus.Approved
      ? green
      : value === CreditLimitRequestStatus.Denied
        ? red
        : value === CreditLimitRequestStatus.Pending
          ? orange
          : gray;
  case "Company":
    return value === AssetFinancingStatus.Active
      ? green
      : value === AssetFinancingStatus.Closed
        ? red
        : value === AssetFinancingStatus.Open
          ? orange
          : gray;
  default:
    return gray;
  }
};

/**
 * Generates column header values based on the provided columns and parameters.
 *
 * @param {Function} getColumns - A function to retrieve columns based on parameters.
 * @param {...any} params - Additional parameters used to retrieve columns.
 * @returns {Array<{ accessorKey: string, headerText: string }>} - An array of objects containing accessorKey and headerText for each column.
 *
 * @example
 * const getColumns = (type) => {
 *   return [
 *     { accessorKey: 'name', header: () => <div>Name</div> },
 *     { accessorKey: 'age', header: () => <div>Age</div> }
 *   ];
 * };
 * const columnHeaderValues = getColumnHeaderValues(getColumns, intl, t, actionFn);
 * // Output:
 * // [
 * //   { accessorKey: 'name', headerText: 'Name' },
 * //   { accessorKey: 'age', headerText: 'Age' }
 * // ]
 */
export const getColumnHeaderValues = (getColumns: any, ...params: any): { accessorKey: string, headerText: string }[] => {
  // Retrieve columns based on the provided parameters
  const columns = getColumns(...params);

  // Map columns to extract accessorKey and headerText
  return columns.map((column: any) => ({
    accessorKey: column["accessorKey"],
    // Extract headerText from column if it exists
    headerText: (column.header as (_props: any) => JSX.Element)?.({ column: {} })?.props.text
  }));
};
