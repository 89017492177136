import { FinancierDetailsFragmentDoc } from "@/graphql/__generated__/graphql-operations";
import { useFragment } from "@apollo/client";

/**
 * Custom hook for fetching the data of the logged in Financier
 * @returns the data of the logged in Financier
 */
export function useFinancierData() {
  const companyId = sessionStorage.getItem("companyId");

  const { complete, data } = useFragment({
    fragment: FinancierDetailsFragmentDoc,
    fragmentName: "FinancierDetails",
    from: {
      __typename: "Financier",
      id: companyId,
    }
  });

  return complete ? data : null;
}
