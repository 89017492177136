import { TwInput } from "@/components/shared";
import { useWeb3 } from "@/context/Web3Context";
import { formatNumberLocale } from "@/utils/helpers";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";

type Props = {
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
  inputErrorText: string;
  setInputErrorText: Dispatch<SetStateAction<string>>;
  bankAccountIsVerified: boolean | undefined;
  walletConnectedAndBankVerified: boolean;
  notConnectedAndBankVerified: boolean | undefined;
  balance: number;
}

function WithdrawalTransactionComponent(props: Props) {
  // Props
  const {
    amount,
    setAmount,
    inputErrorText,
    setInputErrorText,
    bankAccountIsVerified,
    walletConnectedAndBankVerified,
    notConnectedAndBankVerified,
    balance,
  } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["modals"]);

  // Magic SDK
  const {
    connectWallet,
  } = useWeb3();


  /**
   * OnChange handler for investment amount input field
   */
  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    const { value, isValid } = event;
    let errorText = "";

    const parsedValue = parseFloat(value);

    if (!isValid) {
      errorText = t("makeinvestment.input.error.invalid");
    } else if (parsedValue < 0) {
      errorText = t("makeinvestment.input.error.zero");
    } else if (parsedValue > balance) {
      errorText = t("makeinvestment.input.error.insufficient");
    }

    setInputErrorText(errorText);

    if (isValid || value.length === 0) {
      const finalValue = value.length === 0 ? 0 : parsedValue;
      setAmount(finalValue);
    }
  }

  return (
    <div>
      {//When wallet is connected
        walletConnectedAndBankVerified &&
        <>
          {/* Withdraw content */}
          <div className="mt-4">
            <p className="text-sm text-gray-400 text-center font-semibold dark:text-gray-100">
              {t("addtransaction.makewithdrawal")}
            </p>
            {/* Available balance */}
            <div className="flex items-center mt-4">
              <p className="text-sm font-semibold w-[150px] text-gray-800 dark:text-white">
                {t("makeinvestment.availablebalance")}:
              </p>
              <p className="text-mforange font-semibold">
                {formatNumberLocale(intl, balance, "currency")}
              </p>
            </div>
          </div>
          <div className="flex w-full">
            <div className="bg-gray-100 dark:bg-mfdarklighter rounded-sm p-2 w-full">
              {/* Currency symbol */}
              <div className="flex items-center">
                <p className="font-semibold text-4xl text-gray-500 dark:text-white pl-2">
                  EURM
                </p>
                {/* Input box */}
                <div className="w-full">
                  <TwInput
                    id="investmentAmount"
                    onChange={handleOnChange}
                    type="float"
                    name="amount"
                    placeholder={"0.00"}
                    labelAfter="hidden"
                    defaultValue={amount > 0 ? amount.toString() : ""}
                    labelBefore={amount && amount > 0 ? "hidden" : "text-4xl font-bold -top-1 dark:text-gray-100"}
                    className="mf-input-field-large"
                  />
                </div>
              </div>
            </div>
          </div>
          {amount > 0 && inputErrorText.length === 0 &&
            <p className="text-sm text-gray-400 dark:text-gray-100 pt-1">
              {t("addtransaction.receive")} &nbsp;
              {formatNumberLocale(intl, amount, "currency")}
            </p>
          }
          {
            inputErrorText.length > 0 &&
            <p className="h-4 text-xs text-red-600 mt-1">{inputErrorText}</p>
          }
        </>
      }
      {// For Withdrawal, when bank account is not verified
        !bankAccountIsVerified &&
          <div className="mt-8 select-none dark:text-white">
            <p className="text-lg font-semibold mb-4">
              {t("addtransaction.notverified")}
            </p>
            <p className="mb-2">
              {t("addtransaction.contactmyronbank")}
            </p>
            <p className="">
              {t("addtransaction.contact")} &nbsp;
              <a href="mailto:contact@myron.finance" className="text-blue-400">
                contact@myron.finance
              </a>
            </p>
          </div>
      }
      {// For Withdrawal, when wallet is not connected but bank account is verified
        notConnectedAndBankVerified &&
          <div className="flex items-center mt-8 space-x-2">
            <p className="text-sm font-semibold dark:text-white">
              {t("addtransaction.connectwallet")}
            </p>
            <button
              className="mf-btn-action-small-primary-filled"
              onClick={connectWallet}
            >
              {t("common:text.connectwallet")}
            </button>
          </div>
      }
    </div>
  );
}

export default WithdrawalTransactionComponent;
