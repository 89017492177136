
export const optionsDark = {
  responsive: true,
  resizeDelay: 0,
  maintainAspectRatio: true,
  plugins: {
    tooltip: {
      titleColor: "#fff",
      bodyColor: "#374151",
      borderColor: "#374151",
      borderWidth: 1,
      backgroundColor: "#374151",
    },
  },
  legend: {
    display: true,
    position: "bottom",
    labels: {
      fontColor: "#fff"
    }
  },
  layout: {
    autoPadding: false,
    padding: 4
  }
};
