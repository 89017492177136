import { CompanyType } from "@/graphql/__generated__/graphql-operations";
import { CreateCompanyFormField } from "@/types/createcompany";
import { InputGroup } from "@/types/shared";

export const passwordInputFields: InputGroup[] = [
  {
    title: "Current Password",
    type: "password",
    required: true,
    name: "password",
    className: "mf-input-field",
    label: "settings.password.currentpassword.label",
    placeholder: "settings.password.currentpassword.placeholder",
    errorMessage: "settings.password.currentpassword.errormsg",
    autoComplete: "current-password"
  },
  {
    title: "New Password",
    type: "password",
    required: true,
    name: "newPassword",
    className: "mf-input-field",
    label: "settings.password.newpassword.label",
    placeholder: "settings.password.newpassword.placeholder",
    errorMessage: "New password is invalid",
    usePasswordRequirements: true,
    autoComplete: "new-password"
  },
  {
    title: "Confirm New Password",
    type: "password",
    required: true,
    name: "confirmNewPassword",
    className: "mf-input-field",
    label: "settings.password.confirmpassword.label",
    placeholder: "settings.password.confirmpassword.placeholder",
    errorMessage: "input.password.enterconfirmpassword",
    autoComplete: ""
  },
];

export const companyDetailsForm: CreateCompanyFormField[]  = [
  {
    name: "industry",
    placeholder: "settings.companydetails.industry",
    errorMessage: "settings.companydetails.industryerror",
    type: "text",
    required: true,
    visibility: [CompanyType.Serviceprovider]
  },
  {
    name: "yearlyrevenue",
    placeholder: "settings.companydetails.yearlyrevenue",
    errorMessage: "settings.companydetails.yearlyrevenueerror",
    type: "number",
    required: true,
    visibility: [CompanyType.Serviceprovider],
  },
  {
    name: "foundingyear",
    placeholder: "settings.companydetails.foundingyear",
    errorMessage: "settings.companydetails.foundingyearerror",
    type: "number",
    required: true,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: number) => (
      value > 0 &&
      (new Date()).getFullYear() >= value
    )
  },
  {
    name: "description",
    placeholder: "settings.companydetails.description",
    errorMessage: "settings.companydetails.descriptionerror",
    type: "text",
    isMultiline: true,
    required: true,
    visibility: [CompanyType.Serviceprovider]
  }];
export const webPresenceForm: CreateCompanyFormField[]  = [
  {
    name: "website",
    placeholder: "settings.webpresence.website",
    errorMessage: "settings.webpresence.websiteerror",
    type: "text",
    required: false,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(https:|http:|www\.)\S*/.test(value)
    ),
  },
  {
    name: "facebook",
    placeholder: "settings.webpresence.facebook",
    errorMessage: "settings.webpresence.facebookerror",
    type: "text",
    required: false,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(?:https?:\/\/)?(?:[^.]+\.)?facebook\.com(\/.*)?$/.test(value)
    ),
  },
  {
    name: "twitter",
    placeholder: "settings.webpresence.twitter",
    errorMessage: "settings.webpresence.twittererror",
    type: "text",
    required: false,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(?:https?:\/\/)?(?:[^.]+\.)?(twitter|x)\.com(\/.*)?$/.test(value)
    ),
  },
  {
    name: "linkedin",
    placeholder: "settings.webpresence.linkedin",
    errorMessage: "settings.webpresence.linkedinerror",
    type: "text",
    required: false,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(?:https?:\/\/)?(?:[^.]+\.)?linkedin\.com(\/.*)?$/.test(value)
    ),
  }];
export const addressForm: CreateCompanyFormField[]  = [
  {
    name: "street",
    placeholder: "settings.address.street",
    errorMessage: "settings.address.streeterror",
    type: "text",
    required: true,
    visibility: ["ALL"]
  },
  {
    name: "housenumber",
    placeholder: "settings.address.housenumber",
    errorMessage: "settings.address.housenumbererror",
    type: "number",
    required: true,
    visibility: ["ALL"]
  },
  {
    name: "zipcode",
    placeholder: "settings.address.zipcode",
    errorMessage: "settings.address.zipcodeerror",
    type: "number",
    required: true,
    visibility: ["ALL"]
  },
  {
    name: "country",
    placeholder: "settings.address.country",
    errorMessage: "settings.address.countryerror",
    type: "text",
    required: true,
    visibility: ["ALL"]
  },
  {
    name: "city",
    placeholder: "settings.address.city",
    errorMessage: "settings.address.cityerror",
    type: "text",
    required: true,
    visibility: ["ALL"]
  }];
export const bankDetailsForm: CreateCompanyFormField[]  = [
  {
    name: "iban",
    placeholder: "settings.bankdetails.iban",
    errorMessage: "settings.bankdetails.ibanerror",
    type: "text",
    required: true,
    visibility: ["ALL"]
  },
  {
    name: "bic",
    placeholder: "settings.bankdetails.bic",
    errorMessage: "settings.bankdetails.bicerror",
    type: "text",
    required: true,
    visibility: ["ALL"]
  }
];
