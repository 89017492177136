import { balanceOf, getCurrentAndNextOwedInterestAmount, getPaymentDates, isEndPaymentDue } from "@/blockchain";
import { client } from "@/client";
import { CLIENT_GET_BLOCKCHAIN } from "@/graphql/operations/client.queries";
import { DocumentNode } from "graphql";
import merge from "lodash/merge";

/**
 * Merges new data with existing cached data to ensure the cache remains consistent and valid.
 *
 * @param {DocumentNode} query - The GraphQL query to identify the cached data.
 * @param {string} key - The key within the cache where the new data should be merged.
 * @param {any} newData - The new data to merge into the existing cache.
 *
 * @example
 * updateCache(CLIENT_GET_BLOCKCHAIN, "blockchainData", { loading: true });
 */
export function updateCache(query: DocumentNode, key: string, newData: any) {
  const existingData = client.readQuery({ query });

  const mergedData = merge({}, existingData, {
    [key]: newData
  });

  client.writeQuery({
    query,
    data: mergedData
  });
}

/**
 * Updates the cache to set the `loading` state of blockchain data.
 * This is typically used before fetching new data to indicate the data is being loaded.
 *
 * @example
 * setBlockchainDataLoading();
 */
export function setBlockchainDataLoading() {
  updateCache(CLIENT_GET_BLOCKCHAIN, "blockchainData", {
    loading: true
  });
}

/**
 * Retrieves and optionally caches interest payment data for a specific user and pool.
 * The function fetches the current and next owed interest amounts, payment dates, and balance,
 * then merges this data into the cache if the `write` flag is true.
 *
 * @param {string} userPublicKey - The public key of the user's company.
 * @param {string} poolAddress - The smart contract address of the asset pool.
 * @param {boolean} [write=false] - Whether to write the fetched data into the cache.
 *
 * @returns {Promise<Object>} A promise that resolves to an object containing the service provider's payment and balance information.
 *
 * @example
 * const data = await cacheInterestData(userPublicKey, poolAddress, true);
 * console.log(data.currentlyOwedInterestAmount);
 */
export async function cacheInterestData(userPublicKey: string, poolAddress: string, write: boolean = false): Promise<{
  interestPaymentDue: boolean,
  currentlyOwedInterestAmount: number,
  nextOwedInterestAmount: number,
  nextPaymentDate: number,
  lastPaymentDate: number,
}> {
  setBlockchainDataLoading();

  // Fetch the interest data
  const { currentOwedAmount, nextOwedAmount } = await getCurrentAndNextOwedInterestAmount(poolAddress);
  const { lastPaymentDate, nextPaymentDate } = await getPaymentDates(poolAddress);
  const balance = await balanceOf(userPublicKey);

  const serviceProvider = {
    interestPaymentDue: currentOwedAmount > 0,
    currentlyOwedInterestAmount: currentOwedAmount,
    nextOwedInterestAmount: nextOwedAmount,
    nextPaymentDate: nextPaymentDate,
    lastPaymentDate: lastPaymentDate,
  };

  // Only write to cache if flag is true
  if(write) {
    updateCache(CLIENT_GET_BLOCKCHAIN, "blockchainData", {
      loading: false,
      serviceProvider,
      balance
    });
  }

  return serviceProvider;
}

/**
 * Fetches the end payment due status and updates the cache.
 * This function is typically used to check whether the end payment is due for a specific pool.
 *
 * @param {string} address - The address of the asset pool.
 *
 * @returns {Promise<void>} A promise that resolves when the cache has been updated.
 *
 * @example
 * await cacheIsEndPaymentDue(poolAddress);
 */
export async function cacheIsEndPaymentDue(address: string): Promise<void> {
  setBlockchainDataLoading();
  const isDue = await isEndPaymentDue(address);

  updateCache(CLIENT_GET_BLOCKCHAIN, "blockchainData", {
    loading: false,
    inventoryProvider: {
      isEndPaymentDue: isDue
    }
  });
}

/**
 * Fetches the EURM balance for the given address and updates the cache.
 * This function is useful for keeping the user's balance information up to date.
 *
 * @param {string} address - The address to check the balance of.
 *
 * @returns {Promise<void>} A promise that resolves when the balance has been fetched and the cache updated.
 *
 * @example
 * await cacheEURMBalance(userAddress);
 */
export async function cacheEURMBalance(address: string): Promise<void> {
  setBlockchainDataLoading();
  const balance = await balanceOf(address);
  updateCache(CLIENT_GET_BLOCKCHAIN, "blockchainData", {
    loading: false,
    balance
  });
}
