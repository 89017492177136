import magic from "@/libs/magic";
import Web3 from "web3";

class Web3Singleton {
  private static instance: Web3 | null = null;

  private constructor() {}

  public static async getInstance(): Promise<Web3> {
    if (!Web3Singleton.instance) {
      const provider = await Web3Singleton.getProvider();
      Web3Singleton.instance = new Web3(provider);
    }
    return Web3Singleton.instance;
  }

  public static async resetInstance() {
    Web3Singleton.instance = null;
  }

  private static async getProvider() {
    return (magic.wallet as any).getProvider();
  }
}

export default Web3Singleton;
