import { useState, useEffect } from "react";
import { isMdBreakpoint } from "@/utils/helpers";

function useMdBreakpoint() {
  const [mdBreakpoint, setMdBreakpoint] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (isMdBreakpoint()) {
        setMdBreakpoint(true);
      } else {
        setMdBreakpoint(false);
      }
    };
    // Call handleResize once to set the initial state
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return mdBreakpoint;
}

export default useMdBreakpoint;
