import { TwCircle } from "@/components/shared";
import { ActionButton, TableColumnHeader } from "@/components/shared/table/Table";
import { TokenTransaction, TokenTransactionType } from "@/graphql/__generated__/graphql-operations";
import { formatDateLocale, formatNumberLocale, handleCircleRobotColour } from "@/utils/helpers";
import { ColumnDef, Row } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { IntlShape } from "react-intl";

export const getColumns = (intl: IntlShape, t: TFunction, actionFn?: any): ColumnDef<TokenTransaction, string>[] => {

  const CircleAndValueWrapper = (value: any, row: Row<TokenTransaction>) => {
    return (
      <div className="flex items-center">
        <TwCircle
          custom={`${handleCircleRobotColour("TOKEN", 0, row.original.status)} mr-2 w-[15px] h-[15px] flex-shrink-0`}
        />
        {value}
      </div>
    );
  };

  return (
    [
      {
        accessorKey: "status",
        header: ({ column }) => (
          <TableColumnHeader text={t("tokentransactions.headers.status")} column={column} />
        ),
        cell: ({ row }) => CircleAndValueWrapper(row.original.status, row),
      },
      {
        accessorKey: "createdAt",
        header: ({ column }) => (
          <TableColumnHeader text={t("tokentransactions.headers.createdAt")} column={column} />
        ),
        cell: ({ row }) => formatDateLocale(intl, row.getValue("createdAt")),
      },
      {
        accessorKey: "type",
        header: ({ column }) => (
          <TableColumnHeader text={t("tokentransactions.headers.type")} column={column} />
        ),
        cell: ({ row }) => {
          const value = row.getValue("type");
          return value === TokenTransactionType.Buy ? "Deposit" : "Withdraw";
        },
      },
      {
        accessorKey: "referencenumber",
        header: ({ column }) => (
          <TableColumnHeader text={t("tokentransactions.headers.reference")} column={column} />
        ),
      },
      {
        accessorKey: "amount",
        header: ({ column }) => (
          <TableColumnHeader text={t("tokentransactions.headers.amount")} column={column} />
        ),
        cell: ({ row }) => formatNumberLocale(intl, row.getValue("amount"), "currency"),
      },
      {
        id: "actions",
        cell: ({ row }) => <ActionButton dataObj={row.original} fn={actionFn} />,
      },
    ]
  );
};
