type Props = {
  className?: string;
}

function ValueSkeleton(props: Props) {
  return (
    <div
      data-cy="valueSkeleton"
      className={`${props.className}
        flex w-full flex-1 h-[25px] p-1 mf-skeleton-base
      `}
    />
  );
}

export default ValueSkeleton;
