import { isConnectedVar } from "@/client";
import { useCompanyData } from "@/hooks";
import { TransactionReceipt } from "web3";
import { OWNER_ADDRESS } from "./connectors";
const POLYGAN_SCAN_URL = import.meta.env.VITE_POLYGON_SCAN;

export const isConnected = () => {
  return isConnectedVar() === "true";
};

/**
 * Searches an event in a transaction receipt.
 * @param eventName name of the event to be found
 * @param tx transaction to search through
 * @returns the event if found, undefined otherwise
 */
export const findEvent = (eventName: string, tx: TransactionReceipt ) => {
  const events = tx.events;
  if(events?.[eventName]) {
    return events[eventName].returnValues;
  }
  return undefined;
};

/**
 * This function checks if the provided Ethereum wallet address is valid.
 *
 * @param {string} address The Ethereum wallet address to be validated.
 * @returns {boolean} True if the address is valid, false otherwise.
 */
export function isValidWalletAddress(address: string): boolean {
  const company = useCompanyData();

  // If the company exists and its public key is "0x0", we allow any public key.
  if (company?.publickey === "0x0") {
    return true;
  }

  // If the company exists, check if the provided address matches the company's public key.
  // If the company doesn't exist, check if the provided address matches the owner's address.
  return company
    ? address.toLowerCase() === company.publickey.toLowerCase()
    : address.toLowerCase() === OWNER_ADDRESS.toLowerCase();
}

/**
 * This function returns an Explorer URL for Polygon Scan based on the input type and pool address.
 * @param type "blockchain" or "tx"
 * @param value string - public key or transaction hash
 * @returns
 */
export function handleBlockchainExplorerLink(type: string, value: string) {
  return `${POLYGAN_SCAN_URL}/${type}/${value}`;
}

/**
 * Helper to get the transaction hash from a transaction or error object
 * @param tx
 * @param error
 * @returns Transaction Hash
 */
export function getTransactionHash(tx: any, error: any) {
  if (!tx) return "";
  if (tx.transactionHash) return tx.transactionHash;
  if (error.transactionHash) return error.transactionHash;
  const hashRegex = /transactionHash":"(0x[a-fA-F0-9]{64})"/;
  const matches = hashRegex.exec(error);

  // If a match is found, `matches[1]` will contain the transaction hash
  return matches ? matches[1] : "";
}
