import { useDeveloperData } from "./useDeveloperData";
import { useEmployeeData } from "./useEmployeeData";

/**
 * Helper Hook for reading a user with a specific id from Cache
 * @returns the data of the user
 */
export function useUserDataById(id: string) {
  const employee = useEmployeeData(id);
  const developer = useDeveloperData(id);

  return employee || developer;
}
