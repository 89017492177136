import { TwCircle } from "@/components/shared";
import { ActionButton, TableColumnHeader } from "@/components/shared/table/Table";
import { Asset, AssetType, CompanyType, InventoryAsset, ReceivableAsset } from "@/graphql/__generated__/graphql-operations";
import { formatDateLocale, formatNumberLocale, handleCircleRobotColour } from "@/utils/helpers";
import { ColumnDef, Row } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { IntlShape } from "react-intl";

export const getColumns = (intl: IntlShape, companyType: CompanyType, spAssetType: AssetType, t: TFunction, actionFn?: any, ): ColumnDef<Asset, string>[] => {
  const isInventory = spAssetType === AssetType.Inventory;
  const isServiceProvider = companyType === CompanyType.Serviceprovider;

  const CircleAndValueWrapper = (value: any, row: Row<Asset>) => {
    return (
      <div className="flex items-center">
        <TwCircle
          custom={`${handleCircleRobotColour("ASSET", 0, isInventory ? (row.original as InventoryAsset).isCollateral: row.original.isFinanced)} mr-2 w-[15px] h-[15px] flex-shrink-0`}
        />
        {value}
      </div>
    );
  };

  return [
    // Show customer name only to Receivables SP
    isServiceProvider && !isInventory ?
      {
        accessorKey: "customer.name",
        header: ({ column }) => <TableColumnHeader text={t("tables:assetsoverview.headers.name")} column={column} />,
        cell: ({ row }) => CircleAndValueWrapper((row.original as ReceivableAsset).customer.name, row),
      }
      :
      {
        accessorKey: "idFromDocumentary",
        header: ({ column }) => <TableColumnHeader text={t("tables:assetsoverview.headers.assetID")} column={column} />,
        cell: ({ row }) => CircleAndValueWrapper(row.original.idFromDocumentary, row),
      },
    isInventory ?
      {
        accessorKey: "isCollateral",
        header: ({ column }) => <TableColumnHeader text={t("tables:assetsoverview.headers.inventorystatus")} column={column} />,
        cell: ({ row }) => row.getValue("isCollateral") ? t("tables:assetsoverview.tablecard.tags.ininventory") : t("tables:assetsoverview.tablecard.tags.notininventory"),
      }
      :
      {
        accessorKey: "isFinanced",
        header: ({ column }) => <TableColumnHeader text={t("tables:assetsoverview.headers.status")} column={column} />,
        cell: ({ row }) => row.getValue("isFinanced") ? t("tables:assetsoverview.tablecard.tags.financed") : t("tables:assetsoverview.tablecard.tags.notfinanced"),
      },
    {
      accessorKey: "value",
      header: ({ column }) => <TableColumnHeader text={t("tables:assetsoverview.headers.value")} column={column} />,
      cell: ({ row }) => formatNumberLocale(intl, row.getValue("value") as number, "currency"),
    },
    {
      accessorKey: "date",
      header: ({ column }) => <TableColumnHeader text={t("tables:assetsoverview.headers.originatedOn")} column={column} />,
      cell: ({ row }) =>  formatDateLocale(intl, row.getValue("date") as string),
    },
    {
      accessorKey: "updatedAt",
      header: ({ column }) => <TableColumnHeader text={t("tables:assetsoverview.headers.financedOn")} column={column} />,
      cell: ({ row }) => formatDateLocale(intl, row.getValue("updatedAt") as string),
    },
    {
      id: "actions",
      cell: ({ row }) => <ActionButton dataObj={row.original} fn={actionFn} />,
    },
  ];
};
