import { useSetPasswordMutation } from "@/graphql/__generated__/graphql-operations";

export default function useSetPasswordMutationHook() {
  const [ setPasswordMutation, { loading, error }] = useSetPasswordMutation({
    onCompleted() {},
  });

  return {
    setPasswordMutation,
    loading,
    error
  };
}
