import { client } from "@/client";
import { ServiceProviderDetailsFragment, ServiceProviderDetailsFragmentDoc } from "@/graphql/__generated__/graphql-operations";

/**
 * Hook for reading the details of the logged in Service Provider from Cache
 * @returns the data of the logged in Service Provider
 */
export function useServiceProviderDetails(spID?: string) {
  const companyId = spID ?? sessionStorage.getItem("companyId");
  const data: ServiceProviderDetailsFragment | null = client.readFragment({
    id: `ServiceProviderDetails:${companyId}`,
    fragment: ServiceProviderDetailsFragmentDoc
  });

  return data;
}
