import { ActionButton, TableColumnHeader } from "@/components/shared/table/Table";
import { formatNumberLocale } from "@/utils/helpers";
import { ColumnDef } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { IntlShape } from "react-intl";
import { CustomerOnServiceProvider } from "@/graphql/__generated__/graphql-operations";

// Reusable Table Cell Component
const TableCell = ({ row, accessorKey, intl, cellFn }) => {
  const value = row.getValue(accessorKey);
  if (cellFn) {
    return formatNumberLocale(intl, cellFn(row.original), "percent");
  } else {
    return formatNumberLocale(intl, value as number, "currency");
  }
};

export const getColumns = (intl: IntlShape, t: TFunction, actionFn?: any, cellFn?: any): ColumnDef<CustomerOnServiceProvider, string>[] => {
  return (
    [
      {
        accessorKey: "name",
        header: ({ column }) => <TableColumnHeader text={t("customers.header.name")} column={column} />,
      },
      {
        accessorKey: "credit",
        header: ({ column }) => <TableColumnHeader text={t("customers.header.credit")} column={column} />,
        cell: ({ row }) => <TableCell row={row} accessorKey="credit" cellFn={undefined} intl={intl} />,
      },
      {
        accessorKey: "creditLimit",
        header: ({ column }) => <TableColumnHeader text={t("customers.header.creditlimit")} column={column} />,
        cell: ({ row }) => <TableCell row={row} accessorKey="creditLimit" cellFn={undefined} intl={intl} />,
      },
      {
        accessorKey: "_id",
        header: ({ column }) => <TableColumnHeader text={t("customers.header.creditused")} column={column} />,
        cell: ({ row }) => <TableCell row={row} accessorKey="_id" intl={intl} cellFn={cellFn} />,
      },
      {
        id: "actions",
        cell: ({ row }) => <ActionButton dataObj={row.original} fn={actionFn} />,
      },
    ]
  );
};
