import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { ChangeEventHandler } from "react";

type Props = {
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  value?: any;
};

/**
 * A text input component with a search icon, designed for global filtering.
 *
 * @param {Props} props - The properties for the global filter component.
 * @returns {JSX.Element} The rendered global filter input component.
 *
 * @example
 * ```tsx
 * import { useState } from "react";
 * import TwGlobalFilter from "./TwGlobalFilter";
 *
 * function MyComponent() {
 *   const [filterValue, setFilterValue] = useState<string>("");
 *
 *   const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (event) => {
 *     setFilterValue(event.target.value);
 *   };
 *
 *   return (
 *     <TwGlobalFilter
 *       placeholder="Search..."
 *       value={filterValue}
 *       onChange={handleFilterChange}
 *     />
 *   );
 * }
 * ```
 */
function TwGlobalFilter(props: Props): JSX.Element {
  // Defaulting value to an empty string if it's undefined
  const value = props.value !== undefined ? props.value : "";

  return (
    <div data-cy="twglobalfilter" className={`${props.className} bg-transparent flex items-center border-[1px] rounded-md p-2 px-3 border-gray-400 dark:border-gray-300 h-[40px]`}>
      <MagnifyingGlassIcon className="w-5 h-5 text-gray-400 mr-2" />
      <input
        data-cy="twglobalfilter-input"
        autoComplete="off"
        className="placeholder:text-xs placeholder:md:text-sm w-full focus:outline-none bg-transparent dark:text-white"
        type="text"
        id="textFilterInput"
        value={value}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </div>
  );
}

export default TwGlobalFilter;
