
type Props = {
  percentage: number;
  label: string;
  className?: string;
  variant?: "primary" | "secondary" | "tertiary" | string;
  showPercentage?: boolean;
  percentageDecimalPlaces?: number;
}

function ProgressBar(props: Props) {
  const {
    percentage,
    label,
    variant = "primary",
    showPercentage = "true",
    percentageDecimalPlaces = 2
  } = props;

  return (
    <div data-cy="progress-bar" className={`${props.className}`}>
      <p data-cy="progress-bar-label" className="text-xs text-gray-400 dark:text-gray-300">
        {label}
      </p>
      <div className="bg-gray-300 h-[16px] w-full rounded-full dark:bg-mfdarklighter">
        <div
          data-cy="progress-bar-fill"
          className={`h-[16px] rounded-full relative
            ${variant === "primary" && "bg-mfpurple dark:bg-mforange"}
            ${variant === "secondary" && "bg-mforange dark:bg-mfpurple"}
            ${variant === "tertiary" && "bg-mfdarkbase dark:bg-gray-200"}
          `}
          style={{width: `${percentage}%`}}
        >
          {
            showPercentage && percentage >= 5 &&
                <p data-cy="progress-bar-percentage" className="text-xs text-white absolute right-1">
                  {percentage.toFixed(percentageDecimalPlaces)}%
                </p>
          }
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
