import ConstructionIllustration from "@/assets/under_construction.svg";

function PlaceholderComponent() {
  return (
    <div
      className="fixed flex flex-col bg-mfpurple h-screen w-screen text-white justify-center font-Montserrat"
    >

      {/* Content */}
      <div className="flex flex-col items-center justify-center mx-10">
        {/* Left side */}
        <div className="flex flex-col mb-6 md:mb-10 text-center">
          {/* Header */}
          <p className="font-base text-4xl">
            Oh no! You&apos;re too early.
          </p>

          {/* Paragraph text */}
          <p className="font-thin text-2xl">
            This website is currently under construction. Our developers are working hard
            to bring you our new and exciting landing page!
          </p>
        </div>

        {/* Right side */}
        <div className="flex flex-1 flex-col w-full items-center">
          <img
            alt="Construction illustration"
            className="w-full min-h-[300px] md:max-h-[400px]"
            src={ConstructionIllustration}
          />
        </div>
      </div>
    </div>
  );
}

export default PlaceholderComponent;
