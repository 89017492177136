import { LazyLoadImage } from "react-lazy-load-image-component";
import myronLogoText from "@/assets/MYRON-full-orange.webp";
import myronLogoDefaultIcon from "@/assets/MYRON-icon-transparent.webp";

type Props = {
  children?: React.ReactNode | React.ReactNode[];
};

/**
 * Layout component for the main content area of the pre-login screen.
 *
 * This component is used to wrap the main content of the pre-login layout,
 * providing consistent margin and spacing.
 *
 * @component
 * @example
 * ```tsx
 * <PreloginLayoutContent>
 *   <p>Your content goes here.</p>
 * </PreloginLayoutContent>
 * ```
 */
function PreloginLayoutContent({ children }: Props) {
  return (
    <div className="mb-[50px] md:mb-0 mt-4 md:mt-8">
      {children}
    </div>
  );
}

/**
 * Layout component for the footer area of the pre-login screen.
 *
 * This component is used to wrap the footer content of the pre-login layout,
 * providing consistent margin and spacing.
 *
 * @component
 * @example
 * ```tsx
 * <PreloginLayoutFooter>
 *   <p>Footer content here.</p>
 * </PreloginLayoutFooter>
 * ```
 */
function PreloginLayoutFooter({ children }: Props) {
  return (
    <div className="mb-12 md:mb-0">
      {children}
    </div>
  );
}

/**
 * Main layout component for the pre-login screen.
 *
 * This component arranges the layout into a left and right section,
 * with lazy-loaded images and a responsive design.
 *
 * @component
 * @example
 * ```tsx
 * <PreloginLayout>
 *   <PreloginLayoutContent>
 *     <p>Main content goes here.</p>
 *   </PreloginLayoutContent>
 *   <PreloginLayoutFooter>
 *     <p>Footer content goes here.</p>
 *   </PreloginLayoutFooter>
 * </PreloginLayout>
 * ```
 */
function PreloginLayout({ children }: Props) {
  return (
    <div className="fixed flex h-screen w-screen font-Montserrat text-gray-800 select-none">
      {/* Left container */}
      <div className="p-8 sm:px-16 flex flex-col justify-between w-full md:min-w-[480px] md:max-w-[550px] max-h-[1080px]">
        {/* Logo container */}
        <div className="flex justify-center md:block">
          <LazyLoadImage
            alt="Myron logo"
            className="w-[200px]"
            src={myronLogoText}
            effect="opacity"
          />
        </div>

        {/* Children */}
        {children}
      </div>

      {/* Right container */}
      <div className="hidden md:visible bg-mfpurple h-screen w-screen md:flex items-center justify-center p-8">
        <div className="flex flex-col items-center gap-10">
          <LazyLoadImage
            alt="Myron logo"
            className="w-full md:max-w-[500px]"
            src={myronLogoDefaultIcon}
            effect="opacity"
          />
          <div className="font-MontserratAlt text-3xl text-mforange text-center">
            <h1 className="mb-1">Resolving the Trilemma of Finance</h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export { PreloginLayout, PreloginLayoutContent, PreloginLayoutFooter };
