
/**
 * Checks if the required environment variables are present in the given environment object.
 * Throws an error if any of the required variables are missing.
 *
 * @param {ImportMetaEnv} env - The environment object containing the variables.
 * @param {string[]} requiredVars - An array of strings representing the names of the required variables.
 * @throws {Error} - If any of the required variables are missing.
 *
 * @example
 * ```javascript
 * import { requiredEnvVars } from "@/constants/requiredenvvars";
 *
 * // Check for required environment variables
 * checkEnvVars(import.meta.env, requiredEnvVars);
 * ```
 */
export function checkEnvVars(env: ImportMetaEnv, requiredVars: string[]): void {
  const missingVars: string[] = requiredVars.filter(varName => !env[varName]);

  if (missingVars.length > 0) {
    // Replace alert with a console error or logging mechanism if preferred
    console.error(`ERROR: The following required environment variables are missing: ${missingVars.join(", ")}`);
    throw new Error(`The following required environment variables are missing: ${missingVars.join(", ")}`);
  }
}
