import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { formatDaysString, formatNumberLocale, generateInterestString } from "@/utils/helpers";
import { AssetFinancingPool, AssetFinancingStatus } from "@/graphql/__generated__/graphql-operations";
import { ServiceProviderDetails } from "@/components/shared";
import { useIntl } from "react-intl";
import { useState } from "react";
import { ProgressBar } from "@/components/shared";
import { IoLogoFacebook, IoLogoLinkedin, IoLogoTwitter } from "react-icons/io5";
import TextInfoGroup from "@/components/shared/common/TextInfoGroup";
import TextInfoGroupAlt from "@/components/shared/common/TextInfoGroupAlt";
import { AFPDetailsGroup, CompanyDetailsGroup, SocialLinkGroup } from "@/types";
import { useTranslation } from "react-i18next";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/Collapsible";

type Props = {
  assetFinancingPool: AssetFinancingPool;
  handleAFPClick: (_assetFinancingPool: AssetFinancingPool) => void;
}

function ServiceProviderInvestCard({ assetFinancingPool, handleAFPClick }: Props) {
  const intl = useIntl();
  const { t } = useTranslation(["components", "common"]);
  const [open, setOpen] = useState(false);

  // Constants
  const isActive = assetFinancingPool.status === AssetFinancingStatus.Active;
  const isClosed = assetFinancingPool.status === AssetFinancingStatus.Closed;

  const AFPDetails: AFPDetailsGroup[] = [
    {
      className: "min-w-[70px]",
      label: t("serviceproviderinfo.poolsize"),
      content: formatNumberLocale(intl, assetFinancingPool.financinglimit, "currency", true, false),
      visibility: Object.values(AssetFinancingStatus)
    },
    {
      className: "min-w-[180px]",
      label: t("serviceproviderinfo.investmentreward"),
      content: generateInterestString((assetFinancingPool.interest/100).toFixed(2)+"%", assetFinancingPool.rewardpayoutperiod),
      visibility: Object.values(AssetFinancingStatus)
    },
    {
      // TODO Update this key
      label: t("serviceproviderinfo.lockedfor"),
      content: formatDaysString(assetFinancingPool.dayslocked),
      visibility: Object.values(AssetFinancingStatus)
    },
    {
      label: t("serviceproviderinfo.totalinvested"),
      content: formatNumberLocale(intl, assetFinancingPool.amountinvested, "currency", false, false),
      visibility: [AssetFinancingStatus.Open] // Open Only
    }
  ];

  const companyDetails: CompanyDetailsGroup[] = [
    {
      label: t("serviceproviderinfo.foundingyear"),
      content: assetFinancingPool.spdetails.companydetails?.foundingyear ?? "Not available",
    },
    {
      label: t("serviceproviderinfo.yearlyrevenue"),
      content: formatNumberLocale(intl, assetFinancingPool.spdetails.companydetails?.yearlyrevenue  as number, "currency"),
    },
    {
      label: t("serviceproviderinfo.website"),
      content: assetFinancingPool.spdetails.companydetails?.webpresence?.website ?? "Not available",
      isLink: assetFinancingPool.spdetails.companydetails?.webpresence?.website,
    }
  ];

  const socialLinks: SocialLinkGroup[] = [
    {
      icon: <IoLogoFacebook className="mf-social-icon" />,
      href: assetFinancingPool.spdetails.companydetails?.webpresence?.facebook
    },
    {
      icon: <IoLogoLinkedin className="mf-social-icon" />,
      href: assetFinancingPool.spdetails.companydetails?.webpresence?.linkedin
    },
    {
      icon: <IoLogoTwitter className="mf-social-icon" />,
      href: assetFinancingPool.spdetails.companydetails?.webpresence?.twitter
    }
  ];

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div
        className={`bg-white px-4 pt-4 shadow-lg rounded-md select-none lg:m-0 dark:bg-mfdarklight
                    ${open ? "pb-4": ""}`}
      >
        {/* Top container */}
        <div className="flex justify-between items-start w-full flex-wrap lg:flex-nowrap">
          <div className="flex gap-2 flex-wrap lg:flex-nowrap">
            <div className="">
              <ServiceProviderDetails
                financingStatus={assetFinancingPool.status}
                serviceProviderId={assetFinancingPool.spdetails._id}
                className="lg:w-[280px]"
              />
            </div>
            {/* Details */}
            <div
              className="flex flex-wrap gap-x-8 mb-2 lg:mb-0"
            >
              {
                AFPDetails.map((item) => (
                  item.visibility.includes(assetFinancingPool.status) &&
                    <TextInfoGroup
                      key={`companydetails:${item.label}`}
                      className={item.className}
                      label={item.label}
                      content={item.content}
                    />
                ))
              }
            </div>
          </div>
          <div className="lg:hidden w-full">
            <ProgressBar
              className="lg:ml-[81px] my-4 col-span-6"
              label={t("serviceproviderinfo.totalinvested")}
              percentage={Math.round(assetFinancingPool.amountinvested/assetFinancingPool.financinglimit*100)}
            />
          </div>
          {/* Buttons */}
          <div
            className="flex gap-2 justify-end lg:justify-normal w-full lg:w-auto mb-4 lg:mb-0"
          >
            <CollapsibleTrigger data-cy="spInvestCardExpandBtn">
              <div className="mf-btn-action-large-outline">
                {
                  open ?
                    <div className="flex items-center dark:text-white">
                      <ChevronUpIcon className="w-5 h-5 mr-[2px]" />
                      <p className="hidden md:flex">
                        {t("common:buttons.collapse")}
                      </p>
                    </div>:
                    <div className="flex items-center dark:text-white">
                      <ChevronDownIcon className="w-5 h-5 mr-[2px]" />
                      <p className="hidden md:flex">
                        {t("common:buttons.expand")}
                      </p>
                    </div>
                }
              </div>
            </CollapsibleTrigger>
            <button
              data-cy="spInvestCardInvestBtn"
              disabled={(isActive || isClosed)}
              onClick={() => handleAFPClick(assetFinancingPool)}
              className={`${(isActive || isClosed) ? "mf-btn-action-large-disabled-filled": "mf-btn-action-large-primary-filled"}`}
            >
              {t("common:buttons.invest")}
            </button>
          </div>
        </div>
        {/* Bottom container */}
        {/* Progress bar */}
        <div className="lg:grid grid-cols-8 hidden">
          <ProgressBar
            className="lg:ml-[81px] my-4 col-span-6"
            label={t("serviceproviderinfo.totalinvested")}
            percentage={Math.round(assetFinancingPool.amountinvested/assetFinancingPool.financinglimit*100)}
          />
        </div>
        {/* Expanded Content */}
        <CollapsibleContent>
          <div className="lg:ml-[81px]">
            {/* Description */}
            <div className="text-sm mb-4 dark:text-white">
              <p className="font-semibold">
                {t("serviceproviderinfo.description")}
              </p>
              {assetFinancingPool.spdetails.companydetails?.description ?? t("common:text.notavailable")}
            </div>

            {/* Company details */}
            <div className="md:flex space-y-2 md:space-y-0 md:gap-x-8">
              {
                companyDetails.map((item) => (
                  <TextInfoGroupAlt
                    key={`companydetails:${item.content}`}
                    label={item.label}
                    content={item.content}
                    isLink={item.isLink}
                  />
                ))
              }
              {/* Social icons */}
              <div className="flex gap-x-3 min-h-max items-center">
                {
                  socialLinks.map((item) => (
                    item.href && // Only show icon if link is available
                    <a
                      key={`sociallink:${item.href}`}
                      className="group cursor-pointer active:scale-95"
                      target="_blank" rel="noreferrer"
                      href={item.href}
                    >
                      {item.icon}
                    </a>
                  ))
                }
              </div>
            </div>
          </div>
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
}

export default ServiceProviderInvestCard;
