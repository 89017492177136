import { TwInput, TwTextArea } from "@/components/shared";
import { CreateCreditLimitRequestInput } from "@/graphql/__generated__/graphql-operations";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { useCreateCreditLimitRequestMutationHook } from "@/hooks/mutations";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { CLRPageModals, CustomerPageModals } from "@/constants/enums";
import { useTranslation } from "react-i18next";

type Props = {
  setShowModal: Dispatch<SetStateAction<CLRPageModals|CustomerPageModals>>;
  serviceProviderID: string;
  clrInput: CreateCreditLimitRequestInput|{ [x: string]: string|undefined; }|null;
  setInput: (_value: CreateCreditLimitRequestInput|{ [x: string]: string|undefined; }|null) => void;
}

/**
 * A modal component that allows the user to create a new Credit Limit Request
 * @param {Props} props
 * @returns {JSX.Element} CreditLimitNewRequest component
 */
function CreditLimitNewRequest(props: Props): JSX.Element {
  // Props
  const { setShowModal, serviceProviderID, clrInput, setInput } = props;

  // States
  const [hasErrors, setHasErrors] = useState(false);

  // Hooks
  const { createCreditLimitRequestMutation } = useCreateCreditLimitRequestMutationHook();
  const { t } = useTranslation(["modals", "common"]);

  /**
  * onChange handler for input fields
  * @param {object} event - input event
  */
  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    // get the updated user input
    const { name, value, isValid } = event;

    if(isValid) {
      setInput({...clrInput, [name]: value});
      setHasErrors(false);
    }
    else {
      setHasErrors(true);
    }
  }

  /**
   * Handler function for form submission
   * @param {FormEvent<Element>} e - form event
   */
  function handleFormSubmit(e: FormEvent<Element>){
    e.preventDefault();
    createCreditLimitRequestMutation({
      variables: {
        creditLimitRequestInput: {
          ...clrInput as CreateCreditLimitRequestInput,
          serviceproviderID: serviceProviderID,
        }
      }
    });
    setShowModal(CLRPageModals.NoModal);
  }

  return (
    <BlankModal
      setShowModal={() => setShowModal(CLRPageModals.NoModal)}
      className="px-6"
      customContentCSS="mf-modal-content min-w-[80%] md:min-w-[400px] lg:min-w-[580px]"
      title="New Request"
      titleCSS="text-lg dark:text-white"
    >
      <form
        onSubmit={handleFormSubmit} // editUserMutation
      >
        {/* // TODO Clean up translations */}
        {/* Main container */}
        <BlankModalContent>
          <div className="flex flex-col select-none">
            <TwTextArea
              className="mf-input-field rounded-sm"
              onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
              placeholder={t("customeroverview.labels.reasonForReq").toString()}
              name="reasonForRequest"
              type="text"
              labelBefore="dark:text-gray-200"
              errorMessage={t("customeroverview.errorMessages.reasonInvalid").toString()}
              required={false}
            />
            <TwInput
              className="mf-input-field"
              onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
              placeholder={t("customeroverview.labels.requestedAmount").toString()}
              name="requestedAmount"
              type="number"
              labelBefore="dark:text-gray-200"
              errorMessage={t("customeroverview.errorMessages.amountInvalid").toString()}
              required
            />
          </div>
        </BlankModalContent>

        <BlankModalFooter>
          <div className="flex justify-end mt-4">
            <button
              disabled={hasErrors}
              className="mf-btn-action-large-primary-filled"
            >
              {t("common:buttons.submit")}
            </button>
          </div>
        </BlankModalFooter>
      </form>
    </BlankModal>
  );
}

export default CreditLimitNewRequest;
