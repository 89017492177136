type Props = {
  className?: string;
  colSpan: number;
}

function TableRowSkeleton({colSpan, className}: Props) {
  return(
    <tr className="w-full">
      {
        [...Array(colSpan).keys()].map((i) => {
          return (
            <td key={`skeleton-${i}`} className="py-2 px-4">
              <div
                className={`min-w-max h-[30px] mf-skeleton-base ${className}`}
              />
            </td>
          );
        })
      }
    </tr>
  );
}

export default TableRowSkeleton;
