import { formatNumberLocale } from "@/utils/helpers";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";
import { ValueSkeleton } from "@/components/skeletons";

type Props = {
  currentInventoryValue: number;
  criticalInventoryValue: number;
  isLoading: boolean;
  previousDayValue: number;
}

type DifferenceResult = {
  amountDifference: number;
  sign: "+" | "-";
}

function InventoryValuesCard(props:Props) {

  // Props
  const { previousDayValue, isLoading, currentInventoryValue, criticalInventoryValue } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();

  // *** Calculate difference between current and previous day value
  const calculateInventoryDifference = (
    currentInventoryValue: number,
    previousInventoryValue: number
  ): DifferenceResult => {
    const difference = currentInventoryValue - previousInventoryValue;
    const sign: "+" | "-" = difference >= 0 ? "+" : "-";

    return {
      amountDifference: Math.abs(difference),
      sign,
    };
  };

  const inventoryDifference = calculateInventoryDifference(currentInventoryValue, previousDayValue);

  return (
    <div className="h-full rounded-xl shadow-lg bg-white dark:bg-mfdarklight p-4 dark:text-white
                    col-span-3 flex flex-col"
    >
      {/* Inventory Title */}
      <p className="font-semibold lg:text-lg mb-2 md:mb-0">
        {t("dashboard.serviceprovider.inventorytitle")}
      </p>
      {/* Content */}
      <div className="flex flex-col md:flex-row justify-between space-y-2 md:space-y-0">
        {/* Current */}
        <div className="">
          <p className="text-xs lg:text-sm text-gray-400 dark:text-gray-300 font-thin">
            {t("dashboard.serviceprovider.itemstotalvalue")}
          </p>
          {
            (isLoading) ? (
              <ValueSkeleton className="min-w-[100px] max-h-[20px]" />
            ) : (
              <p className="text-base lg:text-lg">
                {formatNumberLocale(intl, currentInventoryValue, "currency", false, false)}
              </p>
            )
          }
        </div>
        {/* Daily change in value */}
        <div className="">
          <p className="text-xs lg:text-sm text-gray-400 dark:text-gray-300 font-thin">
            {t("dashboard.serviceprovider.dailychange")}
          </p>
          {
            (isLoading) ? (
              <ValueSkeleton className="min-w-[100px] max-h-[20px]" />
            ) : (
              <div className={`flex items-center ${inventoryDifference.sign === "+" ? "text-green-500": "text-red-500"}`}>
                <p className="text-base lg:text-lg">
                  {
                    inventoryDifference.sign === "+" ?
                      <PlusIcon className="h-5 w-5" /> :
                      <MinusIcon className="h-5 w-5" />
                  }
                </p>
                <p className="text-base lg:text-lg">
                  {
                    formatNumberLocale(
                      intl,
                      inventoryDifference.amountDifference,
                      "currency"
                    )
                  }
                </p>
              </div>
            )
          }
        </div>
        {/* Critical */}
        <div className="">
          <p className="text-xs lg:text-sm text-gray-400 dark:text-gray-300 font-thin">
            {t("dashboard.serviceprovider.criticalvalue")}
          </p>
          {
            (isLoading) ? (
              <ValueSkeleton className="min-w-[100px] max-h-[20px]" />
            ) : (
              <p className="text-base lg:text-lg">
                {formatNumberLocale(intl, criticalInventoryValue, "currency", false, false)}
              </p>
            )
          }
        </div>
      </div>
    </div>
  );
}

export default InventoryValuesCard;
