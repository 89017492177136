import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";
import { cn } from "@/libs/utils";
import { Button } from "@/components/ui/Button";
import { Calendar } from "@/components/ui/Calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/Popover";
import { Dispatch, SetStateAction } from "react";
import { formatDateLocale } from "@/utils/helpers";
import { useIntl } from "react-intl";

/**
 * Props for the `DatePickerWithRange` component.
 */
type Props = {
  /**
   * Optional class name to apply custom styling.
   */
  className?: string;
  /**
   * The currently selected date range.
   */
  dateRange: DateRange | undefined;
  /**
   * Function to update the selected date range.
   */
  setDateRange: Dispatch<SetStateAction<DateRange | undefined>>;
};

/**
 * A component that renders a date picker with a range selection using a popover.
 *
 * @param {Object} props - The props for the component.
 * @param {string} [props.className] - Optional class name for custom styling.
 * @param {DateRange | undefined} props.dateRange - The currently selected date range.
 * @param {Dispatch<SetStateAction<DateRange | undefined>>} props.setDateRange - Function to update the selected date range.
 *
 * @returns {JSX.Element} The rendered `DatePickerWithRange` component.
 *
 * @example
 * ```tsx
 * import { useState } from "react";
 * import { DatePickerWithRange } from "./DatePickerWithRange";
 *
 * function MyComponent() {
 *   const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
 *
 *   return (
 *     <DatePickerWithRange
 *       dateRange={dateRange}
 *       setDateRange={setDateRange}
 *       className="my-custom-class"
 *     />
 *   );
 * }
 * ```
 */
export function DatePickerWithRange({
  className,
  dateRange,
  setDateRange,
}: Props): JSX.Element {
  // Hooks
  const intl = useIntl();

  return (
    <div data-cy="daterangepicker" className={cn("grid gap-2 relative", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "justify-center text-center font-normal dark:text-white",
              !dateRange && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4 dark:text-white" />
            {dateRange?.from ? (
              dateRange.to ? (
                <>
                  {formatDateLocale(intl, dateRange.from)} -{" "}
                  {formatDateLocale(intl, dateRange.to)}
                </>
              ) : (
                formatDateLocale(intl, dateRange.from)
              )
            ) : (
              <span className="dark:text-white">Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="absolute w-auto p-2 dark:bg-mfdarklighter" align="start">
          <Calendar
            className=""
            initialFocus
            mode="range"
            defaultMonth={dateRange?.from}
            selected={dateRange}
            onSelect={setDateRange}
            numberOfMonths={2}
            modifiersClassNames={{
              selected: "bg-mforange text-white",
              today: "bg-gray-200 text-gray-800",
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
