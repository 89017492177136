import { useLoginUserLazyQuery } from "@/graphql/__generated__/graphql-operations";
import { login } from "@/utils/authentication";
import { useNavigate } from "react-router-dom";

export default function useLoginUserLazyQueryHook(redirect?: boolean) {
  const navigate = useNavigate();
  const [ loginQuery, { loading, error }] = useLoginUserLazyQuery({
    onCompleted(result) {
      sessionStorage.setItem("token", result.loginUser.token);
      login(result.loginUser);
      if(result.loginUser?.user.__typename === "Developer") {
        navigate("/admin/dashboard");
      } else {
        navigate("/user/dashboard");
      }
    },
    onError() {
      if(redirect) {
        navigate("/login");
      }
    },
    fetchPolicy: "cache-first"
  });

  return {
    loginQuery,
    loading,
    error
  };
}
