import Header from "@/components/header/Header";
import { Outlet } from "react-router-dom";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Analytics } from "@vercel/analytics/react";

/**
 * Main layout component for the application.
 *
 * This component manages the overall layout including dark mode and prevents
 * right-click context menu within the app. It also integrates Vercel analytics
 * and renders the `Header` and `Outlet` components.
 *
 * @component
 * @example
 * ```tsx
 * import MainLayout from "./layouts/MainLayout";
 *
 * function App() {
 *   return (
 *     <BrowserRouter>
 *       <Routes>
 *         <Route path="/" element={<MainLayout />}>
 *           <Route index element={<HomePage />} />
 *         </Route>
 *       </Routes>
 *     </BrowserRouter>
 *   );
 * }
 * ```
 */
function MainLayout() {
  // State to manage dark mode
  const [darkMode, setDarkMode] = useState(false);

  // Initialize dark mode from local storage or set to light mode if not present
  useEffect(() => {
    if (localStorage.getItem("darkMode") === null) {
      localStorage.setItem("darkMode", "false");
    } else {
      setDarkMode(localStorage.getItem("darkMode") === "true");
    }
  }, []);

  // Disable right-click context menu
  const handleContextMenu = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
  }, []);

  return (
    <div
      onContextMenu={handleContextMenu}
      className={`fixed flex w-screen flex-col h-screen bg-mfpurple/5 dark:bg-gray-800 font-Montserrat mf-text-primary
                  ${darkMode ? "dark" : ""}`}
    >
      {/* Analytics for Vercel deployment */}
      <Analytics />
      <Header darkMode={darkMode} setDarkMode={setDarkMode} />
      <div className="mf-flex-y-fill flex-shrink overflow-y-auto paragraph md:px-[20px] scrollbar-hide dark:bg-mfdarkbase">
        <Outlet />
      </div>
    </div>
  );
}

export default MainLayout;
