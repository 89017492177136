import Avatar from "react-avatar";

type Props = {
  userName: string;
  logo: string | null | undefined;
  size: string;
}

function AvatarComponent({userName, logo, size}: Props) {
  return (
    <div data-cy="avatar" className="ml-2">
      <Avatar
        name={userName}
        round
        size={size}
        color="#EE5300"
        src={logo?.replace(".cloud.google.", ".googleapis.") ?? undefined}
      />
    </div>
  );
}

export default AvatarComponent;
