import { InputReducerActionType } from "@/constants/enums";
import { AssetType, CompanyType } from "@/graphql/__generated__/graphql-operations";
import { CreateCompanyFormField } from "@/types/createcompany";

export const generalInformationForm: CreateCompanyFormField[] = [
  {
    name: "companytype",
    placeholder: "Company type",
    errorMessage: "Company type is invalid",
    type: "select",
    required: true,
    options: [CompanyType.Financier, CompanyType.Serviceprovider],
    dispatchType: InputReducerActionType.EMPTY,
    visibility: ["ALL"]
  },
  {
    name: "name",
    placeholder: "Company name",
    errorMessage: "Company name is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.EMPTY,
    visibility: ["ALL"]
  },
  {
    name: "phone",
    placeholder: "Phone number",
    errorMessage: "Phone number is invalid",
    type: "phone",
    required: true,
    dispatchType: InputReducerActionType.EMPTY,
    visibility: ["ALL"]
  }];
export const companyDetailsForm: CreateCompanyFormField[]  = [
  {
    name: "industry",
    placeholder: "Industry",
    errorMessage: "Industry is invalid",
    type: "text",
    required: false,
    dispatchType: InputReducerActionType.COMPANY_DETAILS,
    visibility: [CompanyType.Serviceprovider]
  },
  {
    name: "description",
    placeholder: "Description",
    errorMessage: "Description is invalid",
    type: "text",
    required: false,
    dispatchType: InputReducerActionType.COMPANY_DETAILS,
    visibility: [CompanyType.Serviceprovider],
    isMultiline: true
  },
  {
    name: "yearlyrevenue",
    placeholder: "Yearly revenue",
    errorMessage: "Yearly revenue is invalid",
    type: "number",
    required: false,
    dispatchType: InputReducerActionType.COMPANY_DETAILS,
    visibility: [CompanyType.Serviceprovider],
  },
  {
    name: "foundingyear",
    placeholder: "Founding year",
    errorMessage: "Founding year is invalid",
    type: "number",
    required: false,
    dispatchType: InputReducerActionType.COMPANY_DETAILS,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: number) => (
      value > 0 &&
      (new Date()).getFullYear() >= value
    )
  }];
export const webPresenceForm: CreateCompanyFormField[]  = [
  {
    name: "website",
    placeholder: "Website",
    errorMessage: "Website is invalid",
    type: "text",
    required: false,
    dispatchType: InputReducerActionType.COMPANY_DETAILS,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(https:|http:|www\.)\S*/.test(value)
    ),
  },
  {
    name: "facebook",
    placeholder: "Facebook",
    errorMessage: "Facebook is invalid",
    type: "text",
    required: false,
    dispatchType: InputReducerActionType.COMPANY_DETAILS,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(?:https?:\/\/)?(?:[^.]+\.)?facebook\.com(\/.*)?$/.test(value)
    ),
  },
  {
    name: "twitter",
    placeholder: "Twitter",
    errorMessage: "Twitter is invalid",
    type: "text",
    required: false,
    dispatchType: InputReducerActionType.COMPANY_DETAILS,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(?:https?:\/\/)?(?:[^.]+\.)?twitter\.com(\/.*)?$/.test(value)
    ),
  },
  {
    name: "linkedin",
    placeholder: "LinkedIn",
    errorMessage: "LinkedIn is invalid",
    type: "text",
    required: false,
    dispatchType: InputReducerActionType.COMPANY_DETAILS,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(?:https?:\/\/)?(?:[^.]+\.)?linkedin\.com(\/.*)?$/.test(value)
    ),
  }];
export const addressForm: CreateCompanyFormField[]  = [
  {
    name: "street",
    placeholder: "Street",
    errorMessage: "Street is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.ADDRESS,
    visibility: ["ALL"]
  },
  {
    name: "housenumber",
    placeholder: "House number",
    errorMessage: "House number is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.ADDRESS,
    visibility: ["ALL"]
  },
  {
    name: "zipcode",
    placeholder: "Zip code",
    errorMessage: "Zip code is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.ADDRESS,
    visibility: ["ALL"]
  },
  {
    name: "country",
    placeholder: "Country",
    errorMessage: "Country is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.ADDRESS,
    visibility: ["ALL"]
  },
  {
    name: "city",
    placeholder: "City",
    errorMessage: "City is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.ADDRESS,
    visibility: ["ALL"]
  }];
export const bankDetailsForm: CreateCompanyFormField[]  = [
  {
    name: "vatnumber",
    placeholder: "VAT number",
    errorMessage: "VAT number is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.EMPTY,
    visibility: ["ALL"]
  },
  {
    name: "name",
    placeholder: "Bank name",
    errorMessage: "Bank name is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.BANK_ACCOUNT,
    visibility: ["ALL"]
  },
  {
    name: "iban",
    placeholder: "Bank account IBAN",
    errorMessage: "Bank account IBAN is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.BANK_ACCOUNT,
    visibility: ["ALL"]
  },
  {
    name: "bic",
    placeholder: "Bank account BIC",
    errorMessage: "Bank account BIC is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.BANK_ACCOUNT,
    visibility: ["ALL"]
  },
];

export const assetFinancingDetailsForm: CreateCompanyFormField[]  = [

  {
    name: "assetType",
    placeholder: "Asset type",
    errorMessage: "Asset type is invalid",
    type: "select",
    required: true,
    options: [AssetType.Receivable, AssetType.Inventory],
    dispatchType: InputReducerActionType.ASSET_FINANCING_POOL,
    visibility: [CompanyType.Serviceprovider]
  },
  {
    name: "uniqueitems",
    placeholder: "Unique items",
    errorMessage: "Unique items is invalid",
    type: "select",
    required: true,
    options: ["Yes", "No"],
    dispatchType: InputReducerActionType.ASSET_FINANCING_POOL,
    visibility: [CompanyType.Serviceprovider, AssetType.Inventory]
  },
  {
    name: "ceiling",
    placeholder: "Ceiling (BPS)",
    errorMessage: "Ceiling is invalid",
    type: "number",
    required: true,
    dispatchType: InputReducerActionType.ASSET_FINANCING_POOL,
    visibility: [CompanyType.Serviceprovider, AssetType.Inventory]
  },
  {
    name: "floor",
    placeholder: "Floor (BPS)",
    errorMessage: "Floor is invalid",
    type: "number",
    required: true,
    dispatchType: InputReducerActionType.ASSET_FINANCING_POOL,
    visibility: [CompanyType.Serviceprovider, AssetType.Inventory]
  },
  {
    name: "financinglimit",
    placeholder: "Financing limit",
    errorMessage: "Financing limit is invalid",
    type: "number",
    required: true,
    dispatchType: InputReducerActionType.ASSET_FINANCING_POOL,
    visibility: [CompanyType.Serviceprovider, AssetType.Receivable, AssetType.Inventory]
  },
  {
    name: "interest",
    placeholder: "Interest (BPS)",
    errorMessage: "Interest is invalid",
    type: "number",
    required: true,
    dispatchType: InputReducerActionType.ASSET_FINANCING_POOL,
    visibility: [CompanyType.Serviceprovider, AssetType.Receivable, AssetType.Inventory]
  },
  {
    name: "rewardpayoutperiod",
    placeholder: "Reward payout period",
    errorMessage: "Reward payout period is invalid",
    type: "number",
    required: true,
    dispatchType: InputReducerActionType.ASSET_FINANCING_POOL,
    visibility: [CompanyType.Serviceprovider, AssetType.Receivable, AssetType.Inventory]
  },
  {
    name: "dayslocked",
    placeholder: "Days locked",
    errorMessage: "Days locked is invalid",
    type: "number",
    required: true,
    dispatchType: InputReducerActionType.ASSET_FINANCING_POOL,
    visibility: [CompanyType.Serviceprovider, AssetType.Receivable, AssetType.Inventory]
  }];
export const assetDocumentaryForm: CreateCompanyFormField[]  = [
  {
    name: "endpoint",
    placeholder: "Asset Documentary endpoint",
    errorMessage: "Asset Documentary endpoint is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.ASSET_DOCUMENTARY,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(https:|http:|www\.)\S*/.test(value)
    ),
  },

  {
    name: "authendpoint",
    placeholder: "Asset Documentary auth endpoint",
    errorMessage: "Asset Documentary auth endpoint is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.ASSET_DOCUMENTARY,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(https:|http:|www\.)\S*/.test(value)
    ),
  },
  {
    name: "username",
    placeholder: "Asset Documentary username",
    errorMessage: "Asset Documentary username is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.ASSET_DOCUMENTARY,
    visibility: [CompanyType.Serviceprovider]
  },
  {
    name: "password",
    placeholder: "Asset Documentary password",
    errorMessage: "Asset Documentary password is invalid",
    type: "text",
    required: true,
    dispatchType: InputReducerActionType.ASSET_DOCUMENTARY,
    visibility: [CompanyType.Serviceprovider]
  },
  {
    name: "endpointType",
    placeholder: "Asset Documentary endpoint type",
    errorMessage: "Asset Documentary endpoint type is invalid",
    type: "select",
    required: true,
    options: ["Coredinate", "POP", "Plentymarkets"],
    dispatchType: InputReducerActionType.ASSET_DOCUMENTARY,
    defaultValue:"",
    visibility: [CompanyType.Serviceprovider]
  },
  // Breakpoint 7 --> Documentaries (SP Only)
];

export const invoiceDocumentaryForm: CreateCompanyFormField[]  = [
  {
    name: "endpoint",
    placeholder: "Invoice Documentary endpoint",
    errorMessage: "Invoice Documentary endpoint is invalid",
    type: "text",
    required: false,
    dispatchType: InputReducerActionType.INVOICE_DOCUMENTARY,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(https:|http:|www\.)\S*/.test(value)
    ),
  },

  {
    name: "authendpoint",
    placeholder: "Invoice Documentary auth endpoint",
    errorMessage: "Invoice Documentary auth endpoint is invalid",
    type: "text",
    required: false,
    dispatchType: InputReducerActionType.INVOICE_DOCUMENTARY,
    visibility: [CompanyType.Serviceprovider],
    additionalValidation: (value: string) => (
      /^(https:|http:|www\.)\S*/.test(value)
    ),
  },
  {
    name: "username",
    placeholder: "Invoice Documentary username",
    errorMessage: "Invoice Documentary username is invalid",
    type: "text",
    required: false,
    dispatchType: InputReducerActionType.INVOICE_DOCUMENTARY,
    visibility: [CompanyType.Serviceprovider]
  },
  {
    name: "password",
    placeholder: "Invoice Documentary password",
    errorMessage: "Invoice Documentary password is invalid",
    type: "text",
    required: false,
    dispatchType: InputReducerActionType.INVOICE_DOCUMENTARY,
    visibility: [CompanyType.Serviceprovider]
  },
  {
    name: "endpointType",
    placeholder: "Invoice Documentary endpoint type",
    errorMessage: "Invoice Documentary endpoint type is invalid",
    type: "select",
    required: false,
    options: ["Coredinate", "POP", "Plentymarkets"],
    dispatchType: InputReducerActionType.INVOICE_DOCUMENTARY,
    defaultValue:"",
    visibility: [CompanyType.Serviceprovider]
  },
  // Breakpoint 8 --> Documentaries (SP Only)
];
