
export type SelectOptionProps = {
  code?: string;
  text?: string;
  value?: any;
  image?: string;
  disabled?: boolean;
  className?: string;
};

/**
 * Reusable `SelectOption` component that can be styled with Tailwind CSS.
 * Used as an option within a `TwSelect` component.
 *
 * @param {SelectOptionProps} props - The properties for the select option.
 * @returns {JSX.Element} The rendered option element.
 *
 * @example
 * ```tsx
 * import TwSelectOption from "./TwSelectOption";
 * import TwSelectOption from "./TwSelect";
 *
 * function MyComponent() {
 *   return (
 *     <TwSelect>
 *       <TwSelectOption value="option1" text="Option 1" />
 *       <TwSelectOption value="option2" text="Option 2" disabled />
 *     </TwSelect>
 *   );
 * }
 * ```
 */
function TwSelectOption(props: SelectOptionProps): JSX.Element {
  return (
    <option
      data-cy="twselectoption"
      value={props.value}
      className={props.className}
      disabled={props.disabled}
    >
      {props.text}
    </option>
  );
}

export default TwSelectOption;
