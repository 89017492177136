import { useEditPasswordMutation } from "@/graphql/__generated__/graphql-operations";
import { t } from "i18next";
import { toast } from "react-toastify";

export default function useEditPasswordMutationHook() {
  const [ editPasswordMutation, { loading, error }] = useEditPasswordMutation({
    onCompleted(result) {
      result.editPassword === true ?
        toast.success(t("toasts:editUserPwdSuccess"), {toastId: "editUserPwdSuccess"}):
        toast.error(t("toasts:editUserPwdFailed"), {toastId: "editUserPwdFailed"});
    },
  });

  return {
    editPasswordMutation,
    loading,
    error
  };
}
