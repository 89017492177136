import { formatDateLocale, toPascalCase } from "@/utils/helpers";
import { TextInfoGroupAlt } from "@/components/shared";
import { CardContents } from "@/types";
import { useIntl } from "react-intl";
import { handleTableCardTagColour } from "./utils";

type Props = {
  cardContents: CardContents;
  dataType: string;
}

/**
 * TwTableCard Component
 *
 * This component displays a card with information based on the provided `cardContents` and `dataType`.
 * The card includes tags, a date, text info groups, and optional buttons.
 *
 * @component
 * @param {Props} props - Props object containing the data to display in the card.
 * @param {CardContents} props.cardContents - Object containing card data, including tags, date, text info groups, and buttons.
 * @param {string} props.dataType - Type of data to determine specific styling or logic for tags.
 * @returns {JSX.Element} - A React JSX Element containing the rendered `TwTableCard` component.
 *
 * @example
 * const cardData = {
 *   tags: [
 *     { label: 'urgent', value: 'red' },
 *     { label: 'completed', value: 'green' }
 *   ],
 *   date: '2024-01-01',
 *   TextInfoGroups: [
 *     { label: 'Name', content: 'John Doe', isLink: false },
 *     { label: 'Email', content: 'john.doe@example.com', isLink: true, linkText: 'Send Email' }
 *   ],
 *   buttons: [
 *     <button key="edit">Edit</button>,
 *     <button key="delete">Delete</button>
 *   ]
 * };
 *
 * <TwTableCard
 *   cardContents={cardData}
 *   dataType="example"
 * />
 */
function TwTableCard({ cardContents, dataType }: Props): JSX.Element {
  const intl = useIntl();

  return (
    // Card container
    <div className="flex flex-col gap-2 border-[1px] rounded-sm p-2">
      {/* Card header */}
      <div className="flex flex-wrap justify-between gap-2">
        {/* Tags */}
        <div className="flex flex-wrap gap-2">
          {
            cardContents.tags.map((tag) => (
              <div
                key={"tag" + tag.label}
                className={`
                  rounded-md text-sm border-[1px] p-1 select-none
                  ${handleTableCardTagColour(tag.value, dataType)}
                `}
              >
                {toPascalCase(tag.label)}
              </div>
            ))
          }
        </div>
        {/* Date */}
        {/* createdAt */}
        {
          cardContents.date && (
            <p className="text-sm font-semibold text-gray-800 dark:text-white">
              {formatDateLocale(intl, cardContents.date)}
            </p>
          )
        }
      </div>
      {/* Card body */}
      <div className="relative flex flex-col gap-2">
        {/* TextInfo container */}
        <div className="flex flex-wrap gap-2 break-all">
          {
            cardContents.TextInfoGroups.map((item) => (
              <TextInfoGroupAlt
                key={item.label}
                label={item.label}
                content={item.content}
                isLink={item.isLink}
                linkText={item.linkText}
                className="min-w-[48%]"
              />
            ))
          }
        </div>
        {/* Buttons container */}
        <div className="flex justify-end">
          {cardContents.buttons &&
            cardContents.buttons.map((button) => (
              <div key={button.key}>
                {button}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default TwTableCard;
