import { Pagination, PaginationContentScrollable } from "@/components/shared";
import { GetPaginatedTokenTransactionsQuery, GetPaginatedTokenTransactionsQueryVariables, TokenTransaction, TokenTransactionEdge, useGetPaginatedTokenTransactionsQuery } from "@/graphql/__generated__/graphql-operations";
import { useRef, useState} from "react";
import { SharedModals, TransactionsPageModals } from "@/constants/enums";
import { useUserData } from "@/hooks";
import { useTranslation } from "react-i18next";
import TokenTransactionsFilter from "./TransactionFilter";
import { TwTableCardList, DataTable } from "@/components/shared/table";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import useScrollToTop from "@/hooks/useScrollToTop";
import { ScrollToTopButton } from "@/components/shared";
import { TransactionDetailsModal } from "./modals";
import { getColumns } from "./columns";
import { useIntl } from "react-intl";
import { getColumnHeaderValues } from "@/components/shared/table/utils";
import { ActionButton } from "@/components/shared/table/Table";

interface PaginationRef {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedTokenTransactionsQueryVariables>) => void;
  getData: () => TokenTransactionEdge[];
}
function Transactions() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Hooks
  const { t } = useTranslation(["tables"]);
  const user = useUserData();
  const intl = useIntl();
  const mdBreakpoint = useMdBreakpoint();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });

  // States
  const [currentTokenTransaction, setCurrentTokenTransaction] = useState<TokenTransaction|undefined>(undefined);
  const [showModal, setShowModal] = useState<TransactionsPageModals|SharedModals>(TransactionsPageModals.NoModal);

  if(!user) {
    return (
      <p>We are currently loading....</p>
    );
  }

  // Pagination
  const paginationRef = useRef<PaginationRef | null>(null);
  const paginationName = "paginatedTokenTransactions";

  const onFilterSubmit = (variables: GetPaginatedTokenTransactionsQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    paginationRef.current?.triggerHandleFilterSubmit(variables);
  };

  /**
   * Handler function to open the transaction details modal
   * @param transaction TokenTransaction
   */
  const handleTokenTransactionDetails = (transaction: TokenTransaction) => {
    setCurrentTokenTransaction(transaction);
    setShowModal(TransactionsPageModals.TransactionDetails);
  };

  const getActionButtons = (index: number, dataObj: TokenTransaction) => {
    return [
      <ActionButton key={"transactions-action-btn-"+index} dataObj={dataObj} fn={handleTokenTransactionDetails} text={t("common:buttons.details")} />
    ];
  };

  // Table headers and sort fields for TwTableCardList
  const columnHeaderValues = getColumnHeaderValues(getColumns, intl, t, handleTokenTransactionDetails);

  return (
    <div className="mf-clr-main-container mt-4 mb-10 mf-flex-y-fill gap-2">
      {/* Filters component */}
      <TokenTransactionsFilter onFilterSubmit={onFilterSubmit} />

      {/* MODALS */}
      {
        // Transaction details modal
        showModal === TransactionsPageModals.TransactionDetails &&
          <TransactionDetailsModal
            setShowModal={setShowModal}
            transaction={currentTokenTransaction as TokenTransaction}
          />
      }
      <Pagination<TokenTransactionEdge, GetPaginatedTokenTransactionsQuery, GetPaginatedTokenTransactionsQueryVariables>
        ref={paginationRef} props={{queryHook: useGetPaginatedTokenTransactionsQuery, paginationName, className: "mx-4 mb-4"}}
      >
        {(dataSlice, loading, displayAmount) => {
          return (
            <PaginationContentScrollable ref={scrollRef}>
              <div className="mx-4">
                {
                  mdBreakpoint ?
                    <DataTable
                      loading={loading}
                      displayAmount={displayAmount as number}
                      data={dataSlice.map(edge => edge.node) ?? []}
                      columns={getColumns(intl, t, handleTokenTransactionDetails)}
                    />
                    :
                    <TwTableCardList
                      dataArray={dataSlice.map(edge => edge.node)}
                      displayAmount={displayAmount as number}
                      actionButtons={getActionButtons}
                      defaultSortField="createdAt"
                      tableHeaders={columnHeaderValues}
                      dataType="TokenTransaction"
                      dataLoading={loading}
                    />
                }
              </div>
            </PaginationContentScrollable>
          );
        }}
      </Pagination>

      <ScrollToTopButton isVisible={isVisible} scrollToTop={scrollToTop} />
    </div>
  );
}

export default Transactions;
