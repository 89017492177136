
type Props = {
  label: string;
  content: any;
  isLink?: boolean;
  linkText?: string;
  contentCSS?: string;
  className?: string;
}

/**
 * A React component that displays a labeled text information group, with the option to render the content as a link.
 * This component allows for customization through additional CSS classes for both the content and the container.
 *
 * @param {Object} props - The component props.
 * @param {string} props.label - The label text displayed above the content.
 * @param {any} props.content - The main content to be displayed. Can be text, a URL, or other JSX content.
 * @param {boolean} [props.isLink=false] - If true, the content is rendered as a clickable link.
 * @param {string} [props.linkText] - Optional text to display for the link; if not provided, the content is used as the link text.
 * @param {string} [props.contentCSS] - Optional CSS classes to apply to the content (text or link).
 * @param {string} [props.className] - Optional additional CSS classes to apply to the container.
 *
 * @returns {JSX.Element} The rendered TextInfoGroupAlt component.
 *
 * @example
 * // Usage of TextInfoGroupAlt with a link
 * <TextInfoGroupAlt
 *   label="Website"
 *   content="https://example.com"
 *   isLink={true}
 *   linkText="Visit Example"
 *   contentCSS="custom-content-class"
 *   className="custom-container-class"
 * />
 *
 * @example
 * // Usage of TextInfoGroupAlt with plain text content
 * <TextInfoGroupAlt
 *   label="Username"
 *   content="JohnDoe123"
 *   contentCSS="text-green-500"
 * />
 */
function TextInfoGroupAlt(props: Props): JSX.Element {
  const { label, content, isLink, linkText, contentCSS, className } = props;
  return (
    <div data-cy="textinfogroupalt" className={`gap-y-1 ${className}`}>
      <p data-cy="textinfogroupalt-label" className="text-xs text-gray-400 dark:text-gray-300">
        {label}
      </p>
      {isLink ? (
        <a
          data-cy="textinfogroupalt-link"
          className={`${contentCSS} text-sm text-blue-400`}
          href={content}
          target="_blank"
          rel="noreferrer"
        >
          {linkText ?? content}
        </a>
      ) : (
        <p data-cy="textinfogroupalt-content" className={`${contentCSS} text-sm font-gray-700 dark:text-white`}>
          {content}
        </p>
      )}
    </div>
  );
}

export default TextInfoGroupAlt;
