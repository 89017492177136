import { InMemoryCache } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";
import merge from "lodash/merge";

// Create inMemoryCache
export const cache = new InMemoryCache({
  possibleTypes: {
    // Add possible types here
    User: ["Developer", "Employee"],
    Company: ["Financier", "ServiceProvider"]
  },
  typePolicies: {
    Query: {
      fields: {
        adminPageState: {
          merge(existing, incoming) {
            return merge({}, existing, incoming);
          }
        },
        customersPageState: {
          merge(existing, incoming) {
            return merge({}, existing, incoming);
          }
        },
        investmentPageState: {
          merge(existing, incoming) {
            return merge({}, existing, incoming);
          }
        },
        paginationState: {
          merge(existing, incoming) {
            return merge({}, existing, incoming);
          }
        },
        blockchainData: {
          merge(existing, incoming) {
            return merge({}, existing, incoming);
          }
        },
        paginatedAssets: relayStylePagination(),
        paginatedAssetFinancingRounds: {
          keyArgs: ["poolId", "after", "status"],
        },
        paginatedAssetFinancingPools: {
          keyArgs: ["companyname"],
        },
        paginatedCustomers: relayStylePagination(),
        paginatedCompanies: relayStylePagination(),
        paginatedCreditLimitRequests: relayStylePagination(),
        paginatedInvestments: relayStylePagination(),
        paginatedTokenTransactions: relayStylePagination(),
        paginatedUsers: relayStylePagination(),
      }
    },
  }
});
