import { InputGroup } from "@/types/shared";

export const passwordInputFields: InputGroup[] = [
  {
    title: "New Password",
    type: "password",
    required: true,
    name: "newPassword",
    className: "mf-input-field text-gray-800",
    label: "Enter your new password",
    placeholder: "Enter your new password",
    errorMessage: "Password is invalid",
    autoFocus: true,
    usePasswordRequirements: true,
  },
  {
    title: "Confirm New Password",
    type: "password",
    required: true,
    name: "confirmNewPassword",
    className: "mf-input-field text-gray-800",
    label: "Confirm your new password",
    placeholder: "Confirm your new password",
    errorMessage: "Please confirm your new password",
  },
];
