import { useExecuteWithdrawalOnInvestmentMutation, Withdrawal, FinancierDetailsFragmentDoc } from "@/graphql/__generated__/graphql-operations";
import { useFinancierData } from "@/hooks";
import { client } from "@/client";
import { GroupedWithdrawals } from "@/types";


export default function useExecuteWithdrawalOnInvestmentMutationHook(props: { withdrawal: GroupedWithdrawals | null}) {
  const financierData = useFinancierData();

  const [executeWithdrawalOnInvestmentMutation, { loading, error }] = useExecuteWithdrawalOnInvestmentMutation({
    onCompleted(result) {
      if (result.executeWithdrawalOnInvestment) {
        if (financierData && props.withdrawal) {
          const availableWithdrawals = financierData.availableWithdrawals;

          // Get new available withdrawals without the withdrawal that was executed
          const newAvailableWithdrawals = availableWithdrawals.filter((withdrawal: Withdrawal) => {
            return withdrawal.investmentID !== props.withdrawal?.investmentID;
          });

          client.writeFragment({
            id: `Financier:${financierData._id}`,
            fragment: FinancierDetailsFragmentDoc,
            data: {
              ...financierData,
              availableWithdrawals: newAvailableWithdrawals
            }
          });
        }
      }
    }
  });

  return {
    executeWithdrawalOnInvestmentMutation,
    loading,
    error
  };
}
