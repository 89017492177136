import { useAddTokenTransactionMutation, GetPaginatedTokenTransactionsDocument } from "@/graphql/__generated__/graphql-operations";
import { ApolloError, FetchResult } from "@apollo/client";

/**
 * Custom hook for handling the addition of a token transaction.
 *
 * This hook provides a mutation function and manages the mutation's loading and error states. It also refetches the paginated token transactions after the mutation is completed to keep the data up-to-date.
 *
 * @returns {{
 *   addTokenTransactionMutation: (variables: any) => Promise<FetchResult>,
 *   loading: boolean,
 *   error: ApolloError | undefined
 * }} - Returns an object containing:
 *   - **addTokenTransactionMutation:** A function that executes the mutation to add a new token transaction.
 *   - **loading:** A boolean indicating whether the mutation is currently in progress.
 *   - **error:** An ApolloError object if there was an error during the mutation, or undefined if the mutation was successful.
 *
 * @example
 * ```javascript
 * import useAddTokenTransactionMutationHook from "@/hooks/useAddTokenTransactionMutationHook";
 *
 * function MyComponent() {
 *   const { addTokenTransactionMutation, loading, error } = useAddTokenTransactionMutationHook();
 *
 *   const handleAddTransaction = async () => {
 *     try {
 *       await addTokenTransactionMutation({
 *         variables: {
 *           // Your mutation variables here
 *         }
 *       });
 *     } catch (err) {
 *       console.error("Error adding token transaction:", err);
 *     }
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={handleAddTransaction} disabled={loading}>
 *         {loading ? "Adding..." : "Add Token Transaction"}
 *       </button>
 *       {error && <p>Error: {error.message}</p>}
 *     </div>
 *   );
 * }
 * ```
 */
export default function useAddTokenTransactionMutationHook(): {
  addTokenTransactionMutation: (_variables: any) => Promise<FetchResult>;
  loading: boolean;
  error: ApolloError | undefined;
  } {
  const [addTokenTransactionMutation, { loading, error }] = useAddTokenTransactionMutation({
    refetchQueries: [GetPaginatedTokenTransactionsDocument]
  });

  return {
    addTokenTransactionMutation,
    loading,
    error
  };
}
