import { client } from "@/client";
import { AddAssetFinancingPoolMutation, AssetFinancingPoolFragmentDoc, UpdatedServiceProviderFragmentDoc, useAddAssetFinancingPoolMutation } from "@/graphql/__generated__/graphql-operations";
import { ApolloError, FetchResult } from "@apollo/client";
import { t } from "i18next";
import { toast } from "react-toastify";

/**
 * Custom hook for handling the addition of an Asset Financing Pool.
 *
 * This hook provides a mutation function and manages the mutation's loading and error states. It also handles caching the new Asset Financing Pool data and updating the related ServiceProvider.
 *
 * @returns {{ addAssetFinancingPoolMutation: (variables: any) => Promise<FetchResult<AddAssetFinancingPoolMutation>>, loading: boolean, error: Apollo.ApolloError | undefined }}
 *   - The hook returns an object with the following properties:
 *     - **addAssetFinancingPoolMutation:** A function that executes the mutation to add a new Asset Financing Pool.
 *     - **loading:** A boolean indicating whether the mutation is currently in progress.
 *     - **error:** An Apollo.ApolloError object if there was an error during the mutation, or undefined if the mutation was successful.
 *
 * @example
 * ```javascript
 * import useAddAssetFinancingPoolMutationHook from "@/hooks/useAddAssetFinancingPoolMutationHook";
 *
 * function MyComponent() {
 *   const { addAssetFinancingPoolMutation, loading, error } = useAddAssetFinancingPoolMutationHook();
 *
 *   const handleAddPool = async () => {
 *     try {
 *       await addAssetFinancingPoolMutation({
 *         variables: {
 *           // Your mutation variables here
 *         }
 *       });
 *     } catch (err) {
 *       console.error("Error adding asset financing pool:", err);
 *     }
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={handleAddPool} disabled={loading}>
 *         {loading ? "Adding..." : "Add Asset Financing Pool"}
 *       </button>
 *       {error && <p>Error: {error.message}</p>}
 *     </div>
 *   );
 * }
 * ```
 */
export default function useAddAssetFinancingPoolMutationHook(): { addAssetFinancingPoolMutation: (_variables: any) => Promise<FetchResult<AddAssetFinancingPoolMutation>>; loading: boolean; error: ApolloError | undefined; } {
  const [addAssetFinancingPoolMutation, { loading, error }] = useAddAssetFinancingPoolMutation({
    onCompleted(result) {
      const { addAssetFinancingPool } = result;

      if (!addAssetFinancingPool) {
        return toast.error(t("toasts:companyUpdateFailure"), { toastId: "companyUpdateFailure" });
      }

      toast.success(t("toasts:companyUpdateSuccess"), { toastId: "companyUpdateSuccess" });

      // Construct the updated data for assetfinancingpool
      const updatedAssetFinancingPool = {
        __typename: "AssetFinancingPool",
        ...addAssetFinancingPool,
        tokenaddress: "0x0",
        pooladdress: "0x0",
      };

      // Write the AFP to cache
      client.writeFragment({
        id: `AssetFinancingPool:${addAssetFinancingPool._id}`,
        fragment: AssetFinancingPoolFragmentDoc,
        data: updatedAssetFinancingPool,
      });

      // Update the ServiceProvider to point to the new AssetFinancingPool
      client.writeFragment({
        id: `ServiceProvider:${addAssetFinancingPool.serviceproviderID}`,
        fragment: UpdatedServiceProviderFragmentDoc,
        data: {
          __typename: "ServiceProvider",
          _id: addAssetFinancingPool.serviceproviderID,
          assetfinancingpool: {
            __typename: "AssetFinancingPool",
            _id: addAssetFinancingPool._id,
          },
        },
      });
    },
  });

  return {
    addAssetFinancingPoolMutation,
    loading,
    error,
  };
}
