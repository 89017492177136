import { blockchainNumberToClient } from "@/utils/helpers";
import { getEURMSmartContract } from "../connectors";

/**
 * Getter of the residual Cash in an RFP
 * @param address of the RFP
 * @returns a formatted number
 */
export async function getResidualCash(address: string) {
  // Uses EURM smart contract balanceOf method
  const { contract } = await getEURMSmartContract();
  const residual = await contract.methods.balanceOf(address).call();

  return blockchainNumberToClient(residual);
}
