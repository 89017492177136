import { client } from "@/client";
import { AfpInvestFieldsFragmentDoc, FinancierDetailsFragmentDoc, InvestmentFieldsFragmentDoc, useAddInvestmentMutation } from "@/graphql/__generated__/graphql-operations";
import { ApolloError, FetchResult } from "@apollo/client";
import merge from "lodash/merge";

/**
 * Custom hook for handling the addition of an investment.
 *
 * This hook provides a mutation function and manages the mutation's loading and error states. It also handles caching the updated Asset Financing Pool data and updating the related Financier data.
 *
 * @param {Object} props - The hook props.
 * @param {string} props.financierID - The ID of the financier to be updated.
 * @returns {{
 *   addInvestmentMutation: (variables: any) => Promise<FetchResult>,
 *   loading: boolean,
 *   error: ApolloError | undefined
 * }} - Returns an object containing:
 *   - **addInvestmentMutation:** A function that executes the mutation to add a new investment.
 *   - **loading:** A boolean indicating whether the mutation is currently in progress.
 *   - **error:** An ApolloError object if there was an error during the mutation, or undefined if the mutation was successful.
 *
 * @example
 * ```javascript
 * import useAddInvestmentMutationHook from "@/hooks/useAddInvestmentMutationHook";
 *
 * function MyComponent() {
 *   const { addInvestmentMutation, loading, error } = useAddInvestmentMutationHook({ financierID: "123" });
 *
 *   const handleAddInvestment = async () => {
 *     try {
 *       await addInvestmentMutation({
 *         variables: {
 *           // Your mutation variables here
 *         }
 *       });
 *     } catch (err) {
 *       console.error("Error adding investment:", err);
 *     }
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={handleAddInvestment} disabled={loading}>
 *         {loading ? "Adding..." : "Add Investment"}
 *       </button>
 *       {error && <p>Error: {error.message}</p>}
 *     </div>
 *   );
 * }
 * ```
 */
export default function useAddInvestmentMutationHook(props: { financierID: string }): {
  addInvestmentMutation: (_variables: any) => Promise<FetchResult>;
  loading: boolean;
  error: ApolloError | undefined;
} {
  const { financierID } = props;

  const [addInvestmentMutation, { loading, error }] = useAddInvestmentMutation({
    onCompleted: (data) => {
      client.writeFragment({
        id: `AssetFinancingPool:${data.addInvestment.assetfinancingpoolID}`,
        fragment: AfpInvestFieldsFragmentDoc,
        data: {
          __typename: "AssetFinancingPool",
          amountinvested: data.addInvestment.totalInvestedAmount,
          status: data.addInvestment.status,
        }
      });

      // Fetch existing Financier data
      const financierData = client.readFragment({
        id: `Financier:${financierID}`,
        fragment: FinancierDetailsFragmentDoc
      });

      // Update cache information for Financier
      client.writeFragment({
        id: `Financier:${financierID}`,
        fragment: FinancierDetailsFragmentDoc,
        data: merge({}, financierData, {
          totalInvestedAmount: financierData.totalInvestedAmount + data.addInvestment.amount,
          currentWorthIncludingInterest: financierData.currentWorthIncludingInterest + data.addInvestment.amount
        })
      });

      /**
       * Looks for the exisiting Investments of the Financier and updates the amount of the Investment
       * matching the `serviceproviderID` of the Investment.
       */
      client.cache.modify({
        id: `Financier:${financierID}`,
        fields: {
          investments(existingInvestments = [], { readField }) {
            return existingInvestments.map((investmentRef: any) => {
              const serviceproviderID = readField("serviceproviderID", investmentRef);
              const amount = readField("amount", investmentRef);

              if (serviceproviderID === data.addInvestment.serviceproviderID) {
                const newAmount = Number(amount) + data.addInvestment.amount;
                client.cache.writeFragment({
                  id: client.cache.identify(investmentRef),
                  fragment: InvestmentFieldsFragmentDoc,
                  data: {
                    amount: newAmount,
                    serviceproviderID,
                  },
                });
              }

              return investmentRef;
            });
          },
        },
      });
    },
  });

  return {
    addInvestmentMutation,
    loading,
    error
  };
}
