import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { TextInfoGroup } from "@/components/shared";
import { CustomerPageModals } from "@/constants/enums";
import { CustomerOnServiceProvider } from "@/graphql/__generated__/graphql-operations";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type Props = {
  setShowModal: Dispatch<SetStateAction<CustomerPageModals>>;
  currentCustomer: CustomerOnServiceProvider;
}

/**
 * A modal component that displays the details of a Customer
 * @param {Props} props
 * @returns {JSX.Element} CustomerDetailsModal component
 */
function CustomerDetailsModal({setShowModal, currentCustomer }: Props): JSX.Element {

  // Hooks
  const { t } = useTranslation(["modals"]);
  const navigate = useNavigate();

  /**
   * OnClick handler for the History button
   */
  const handleHistoryClick = () => {
    // Close the modal
    setShowModal(CustomerPageModals.NoModal);
    // Navigate to CLR page
    navigate(`/user/creditlimitrequests?customerID=${currentCustomer._id}&customerName=${currentCustomer.name}`);
  };

  return (
    <BlankModal
      setShowModal={() => {
        setShowModal(CustomerPageModals.NoModal);
      }}
      title={t("customerdetails.title")}
      className="px-6"
      customContentCSS="mf-modal-content lg:min-w-[580px]"
    >
      <BlankModalContent>
        <div className="flex flex-wrap gap-2">
          <TextInfoGroup label={t("customerdetails.labels.name")} content={currentCustomer.name} />
          <TextInfoGroup label={t("customerdetails.labels.documentaryID")} content={currentCustomer.idFromDocumentary} />
          <TextInfoGroup label={t("customerdetails.labels.publickey")} content={currentCustomer.publickey} />
        </div>
      </BlankModalContent>

      <BlankModalFooter>
        {/* Action Buttons */}
        <div className="mt-4 flex items-center justify-between">
          <button
            onClick={() => {
              setShowModal(CustomerPageModals.NoModal);
            }}
            className="mf-btn-action-small-primary-outline"
          >
            {t("common:buttons.close")}
          </button>

          <button
            onClick={handleHistoryClick}
            className="mf-btn-action-small-primary-filled"
          >
            {t("customerdetails.buttons.history")}
          </button>
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default CustomerDetailsModal;
