import { InputReducerActionType } from "@/constants/enums";
import { AssetType, CompanyType } from "@/graphql/__generated__/graphql-operations";
import { useCreateCompanyMutationHook } from "@/hooks/mutations";
import CreateCompanyForm from "@/pages/CreateCompany/CreateCompanyForm";
import { CreateCompanyState } from "@/types";
import merge from "lodash.merge";
import { useReducer, useState, FormEvent } from "react";

function CreateCompany() {
  const initialState: CreateCompanyState = {
    name: "",
    phone: "",
    companytype: CompanyType.Financier,
    address: {
      street: "",
      housenumber: "",
      zipcode: "",
      country: "",
      city: "",
    },
    vatnumber: "",
    bankaccount: { iban: "", bic: "", name: "" },
  };

  function inputReducer(state: CreateCompanyState, action:{ type: string, payload: Partial<CreateCompanyState> & { [x: string]: string | boolean | number }}) {
    switch(action.type) {
    case InputReducerActionType.ADDRESS:
      return merge(state, { address: action.payload });
    case InputReducerActionType.BANK_ACCOUNT:
      return merge(state, { bankaccount: action.payload });
    case InputReducerActionType.COMPANY_DETAILS:
      return merge(state, { serviceproviderinput: { companydetails: action.payload }});
    case InputReducerActionType.ASSET_DOCUMENTARY:
      return merge(state, { serviceproviderinput: { assetdocumentary: action.payload }});
    case InputReducerActionType.INVOICE_DOCUMENTARY:
      return merge(state, { serviceproviderinput: { invoicedocumentary: action.payload }});
    case InputReducerActionType.ASSET_FINANCING_POOL:
      return merge(state, { serviceproviderinput: { assetfinancingpool: action.payload } });
    case InputReducerActionType.CUSTOMER_INPUT:
      return merge(state, { customerinput: action.payload });
    default:
      return merge(state, action.payload);
    }
  }

  const [createCompanyData, dispatch] = useReducer(
    inputReducer,
    initialState
  );

  const [companyVisibility, setCompanyVisibility] = useState(createCompanyData.companytype);
  const [assetTypeVisibility, setAssetTypeVisibility] = useState<(AssetType)[]>([]);

  // Hooks
  const { createCompanyMutation } = useCreateCompanyMutationHook("createCompanyForm");

  /**
 * onChange handler for input fields and switch
 * @param dispatchType: string
 * @param eventOrChecked: { name: string, value: string | boolean, isValid: boolean } | boolean
 */
  function handleOnChange(dispatchType: string, eventOrChecked: { name: string, value: string | boolean, isValid: boolean } | boolean) {
    if (typeof eventOrChecked === "boolean") {
      // Handle switch change
      dispatch({ type: dispatchType, payload: { uniqueitems: eventOrChecked } });
    } else {
    // Handle input field change
      const { name, value, isValid } = eventOrChecked;

      if (isValid) {
      // Handle company visibility logic
        if (name === "companytype") {
          setCompanyVisibility(value as CreateCompanyState["companytype"]);
          if (value === CompanyType.Serviceprovider) {
          // Set initial visibility when SP is selected
            setAssetTypeVisibility([AssetType.Receivable]);
          }
        }

        // Handle asset type visibility logic --> for AFC
        if (name === "assetType") {
          if (value === AssetType.Inventory) {
            setAssetTypeVisibility([AssetType.Receivable, AssetType.Inventory]);
          } else {
            setAssetTypeVisibility([AssetType.Receivable]);
          }
        // Return to not store value in state
        // return;
        }

        if (name === "uniqueitems") {
          if(value === "Yes") {
            return dispatch({ type: dispatchType, payload: { [name]: true } });
          }
          return dispatch({ type: dispatchType, payload: { [name]: false } });
        }

        // // Handle basis points
        // if(["floor", "ceiling", "interest"].includes(name)) {
        //   // Convert BPS to percentage
        //   dispatch({ type: dispatchType, payload: { [name]: Number(value) / 10000 } });
        //   return;
        // }

        dispatch({ type: dispatchType, payload: { [name]: value } });
      }
    }
  }


  function triggerVisibilityTransform() {
    if(!assetTypeVisibility.includes(AssetType.Inventory)) {
      delete createCompanyData?.serviceproviderinput?.assetfinancingpool?.floor;
      delete createCompanyData?.serviceproviderinput?.assetfinancingpool?.ceiling;
      delete createCompanyData?.serviceproviderinput?.assetfinancingpool?.uniqueitems;
    }
    if(companyVisibility !== CompanyType.Serviceprovider) {
      delete createCompanyData?.serviceproviderinput?.companydetails;
      delete createCompanyData?.serviceproviderinput?.assetfinancingpool;
      delete createCompanyData?.serviceproviderinput?.assetdocumentary;
      delete createCompanyData?.serviceproviderinput?.invoicedocumentary;
    }
  }

  function handleFormSubmit(event: FormEvent<Element>) {
    event.preventDefault();

    triggerVisibilityTransform();

    const createCompanyDataCopy = JSON.parse(
      JSON.stringify(createCompanyData)
    );

    if(createCompanyData.bankaccount) {
      createCompanyDataCopy.bankaccount =
        createCompanyData.bankaccount;
    }

    if(createCompanyData.serviceproviderinput?.assetdocumentary && createCompanyData.serviceproviderinput?.invoicedocumentary) {
      createCompanyDataCopy.serviceproviderinput.assetdocumentary =
        createCompanyData.serviceproviderinput.assetdocumentary
      ;
      createCompanyDataCopy.serviceproviderinput.invoicedocumentary =
        createCompanyData.serviceproviderinput.invoicedocumentary
      ;
    }

    createCompanyMutation({ variables: { companyInput: createCompanyDataCopy } });
  }

  return (
    <CreateCompanyForm
      currentVisibility={[companyVisibility, ...assetTypeVisibility]}
      handleFormSubmit={handleFormSubmit}
      handleOnChange={handleOnChange}
      createCompanyData={createCompanyData}
    />
  );
}

export default CreateCompany;
