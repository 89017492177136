function SPDetailsSkeleton() {
  return (
    <div data-cy="sp-skeleton" className="flex items-center justify-start gap-4 mr-4 w-[250px]">
      {/* Left container */}
      <div className="h-full">
        {/* Skeleton for logo */}
        <div className="w-[65px] h-[65px] min-w-[65px] mf-skeleton-base" />
      </div>
      {/* Right container */}
      <div>
        <div className="mb-1 space-y-2">
          {/* Skeleton for service provider name */}
          <div className="w-[160px] h-[20px] mf-skeleton-base" />
          {/* Skeleton for industry */}
          <div className="w-[100px] h-[12px] mf-skeleton-base" />
        </div>
        {/* Skeleton for tag */}
        <div>
          <div className="w-[50px] h-[12px] mf-skeleton-base" />
        </div>
      </div>
    </div>
  );
}

export default SPDetailsSkeleton;
