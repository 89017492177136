import { RefObject, useEffect, useState } from "react";

function useScrollToTop({ref}: {ref?: RefObject<HTMLElement>}) {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    if (ref?.current) {
      ref.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    if (ref?.current) {
      setIsVisible(ref.current.scrollTop > 10);
    } else {
      setIsVisible(window.scrollY > 10);
    }
  };

  useEffect(() => {
    if (ref?.current) {
      ref.current.addEventListener("scroll", handleScroll);
    } else {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (ref?.current) {
        ref.current.removeEventListener("scroll", handleScroll);
      } else {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref]);

  return { isVisible, scrollToTop };
}

export default useScrollToTop;
