import { FormEvent } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  id: string;
  handleSubmit: (_event: FormEvent<Element>) => void;
  children: JSX.Element;
  onClickReset: () => void;
  className?: string;
};

function FormWrapper(props: Props) {

  // Props
  const { handleSubmit, children, onClickReset, className, id } = props;

  // Hooks
  const { t } = useTranslation(["common"]);

  return (
    <form id={id} onSubmit={handleSubmit} className={className}>
      {children}
      <div className="space-x-2 mt-4">
        <button
          type="button"
          onClick={onClickReset}
          className="mf-btn-action-small-primary-outline"
        >
          {t("buttons.reset")}
        </button>
        <button
          className="mf-btn-action-small-primary-filled"
        >
          {t("buttons.submit")}
        </button>
      </div>
    </form>
  );
}

export default FormWrapper;
