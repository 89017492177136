import {
  AssetFinancingRoundEdge, AssetFinancingStatus, AssetType,
  GetPaginatedAssetFinancingRoundsQuery, GetPaginatedAssetFinancingRoundsQueryVariables,
  useGetPaginatedAssetFinancingRoundsQuery,
} from "@/graphql/__generated__/graphql-operations";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Pagination, PaginationContentScrollable, ServiceProviderDetails, ScrollToTopButton } from "@/components/shared";
import { useTranslation } from "react-i18next";
import { getResidualCash } from "@/blockchain";
import { useIntl } from "react-intl";
import { formatNumberLocale } from "@/utils/helpers";
import { TwTableCardList, DataTable } from "@/components/shared/table";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import { updatePaginationState } from "@/cache/appstate/WriteQueries";
import useScrollToTop from "@/hooks/useScrollToTop";
import { getColumns } from "./columns";
import { getColumnHeaderValues } from "@/components/shared/table/utils";
import { useServiceProviderDetails } from "@/hooks/cache/useServiceProviderDetails";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

interface PaginationRef {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedAssetFinancingRoundsQueryVariables>) => void;
}

function PoolActivity() {
  // Ref use for Scroll To Top
  const scrollRef = useRef<HTMLDivElement>(null);

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["tables", "common"]);
  const mdBreakpoint = useMdBreakpoint();
  const { isVisible, scrollToTop } = useScrollToTop({ ref: scrollRef });
  const navigate = useNavigate();

  // States
  const [blockchainData, setBlockchainData] = useState<[number, number]>([0, 0]);

  // Destructure params directly from searchParams
  const [searchParams] = useSearchParams();
  const poolID = searchParams.get("poolID");
  const spID = searchParams.get("spID");
  const poolStatus = searchParams.get("poolStatus");
  const poolAddress = searchParams.get("poolAddress");
  const financingLimit = Number(searchParams.get("financingLimit"));

  const serviceProviderDetails = useServiceProviderDetails(spID as string);

  // Pagination
  const paginationRef = useRef<PaginationRef | null>(null);
  const paginationName = "paginatedAssetFinancingRounds";

  const onFilterSubmit = (variables: GetPaginatedAssetFinancingRoundsQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    paginationRef.current?.triggerHandleFilterSubmit(variables);
  };

  useEffect(() => {
    updatePaginationState({
      paginatedAssetFinancingRounds: {
        first: 10,
        filters: {
          poolId: poolID as string,
        }
      }
    });

    onFilterSubmit({
      first: 10,
      poolId: poolID
    });
  }, [poolID]);

  // Function to go back to the previous page
  const previousPage = () => {
    // Reset the filter
    updatePaginationState({
      paginatedAssetFinancingRounds: {
        filters: {
          poolId: ""
        }
      }
    });
    navigate(-1);
  };

  /**
   * Fetches the residual cash from the blockchain and calculates the asset value
   * @returns Residual Cash and Asset Value
   */
  async function CalculateBlockchainValues() {
    const residualCash = await getResidualCash(poolAddress as string);
    const assetValue = financingLimit-residualCash;

    setBlockchainData([residualCash, assetValue]);
  }

  useEffect(() => {
    CalculateBlockchainValues();
  }, []);

  // Table headers and sort fields for TwTableCardList
  const columnHeaderValues = getColumnHeaderValues(getColumns, intl, t, serviceProviderDetails?.assettype as AssetType);

  return (
    <div className="mf-table-container mf-flex-y-fill bg-white dark:bg-mfdarklight">
      {/* SP Details */}
      <div className="bg-white dark:bg-mfdarklight rounded-md p-4 justify-start flex items-start md:justify-between">
        <div className="w-full md:w-auto flex-grow">
          {
            spID !== "" &&
              <ServiceProviderDetails
                financingStatus={poolStatus as AssetFinancingStatus ?? ""}
                serviceProviderId={spID as string}
              />
          }
        </div>
        <div className="flex w-full justify-end md:w-auto">
          <button
            className="mf-btn-action-small-primary-outline px-1 md:px-[12px]"
            onClick={previousPage}
          >
            <span className="hidden md:inline">
              {t("common:buttons.back")}
            </span>
            <ChevronLeftIcon className="w-5 h-5 md:hidden" />
          </button>
        </div>
      </div>

      {/* Addititional values */}
      <div className="mx-4">
        {
          serviceProviderDetails?.assettype === AssetType.Receivable &&
            <div className="flex space-x-4 dark:text-white">
              <div>
                <p className="text-sm font-gray-400">{t("common:text.residualcash")}</p>
                <p className="font-semibold">{formatNumberLocale(intl, blockchainData[0] ?? 0, "currency")}</p>
              </div>
              <div>
                <p className="text-sm font-gray-400">{t("common:text.assetvalue")}</p>
                <p className="font-semibold">{formatNumberLocale(intl, blockchainData[1] ?? 0, "currency")}</p>
              </div>
            </div>
        }
      </div>

      {/* Paginated Asset Financing Rounds */}
      <Pagination<AssetFinancingRoundEdge, GetPaginatedAssetFinancingRoundsQuery, GetPaginatedAssetFinancingRoundsQueryVariables>
        ref={paginationRef} props={{queryHook: useGetPaginatedAssetFinancingRoundsQuery, paginationName, className: "mx-4 mb-4"}}
      >
        {(dataSlice, loading, displayAmount) => {
          return (
            <PaginationContentScrollable ref={scrollRef}>
              <div className="mx-4 mt-4">
                {
                  mdBreakpoint ?
                    <DataTable
                      loading={loading}
                      displayAmount={displayAmount as number}
                      data={dataSlice.map(edge => edge.node) ?? []}
                      columns={getColumns(intl, t, serviceProviderDetails?.assettype as AssetType)}
                    />
                    :
                    <TwTableCardList
                      dataArray={dataSlice.map(edge => edge.node)}
                      displayAmount={displayAmount as number}
                      dataLoading={loading}
                      defaultSortField="createdAt"
                      tableHeaders={columnHeaderValues}
                      dataType="AssetFinancingRound"
                    />
                }
              </div>
            </PaginationContentScrollable>
          );
        }}
      </Pagination>

      <ScrollToTopButton scrollToTop={scrollToTop} isVisible={isVisible} />
    </div>
  );
}

export default PoolActivity;
