import { TwCircle } from "@/components/shared";
import { ActionButton, TableColumnHeader } from "@/components/shared/table/Table";
import { CreditLimitRequest } from "@/graphql/__generated__/graphql-operations";
import { formatDateLocale, formatNumberLocale, handleCircleRobotColour } from "@/utils/helpers";
import { ColumnDef, Row } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { IntlShape } from "react-intl";

export const getColumns = (intl: IntlShape, t: TFunction | undefined, actionFn?: any): ColumnDef<CreditLimitRequest, string>[] => {

  const CircleAndValueWrapper = (value: any, row: Row<CreditLimitRequest>) => {
    return (
      <div className="flex items-center">
        <TwCircle
          custom={`${handleCircleRobotColour("CLR", 0, row.original.status)} mr-2 w-[15px] h-[15px] flex-shrink-0`}
        />
        {value}
      </div>
    );
  };

  return (
    [
      {
        accessorKey: "serviceprovider.name",
        header: ({ column }) => <TableColumnHeader text={t!("tables:creditlimitrequests.headers.serviceprovider")} column={column} />,
        cell: ({ row }) => CircleAndValueWrapper(row.original.serviceprovider.name, row),
      },
      {
        accessorKey: "customer.name",
        header: ({ column }) => <TableColumnHeader text={t!("tables:creditlimitrequests.headers.customer")} column={column} />,
      },
      {
        accessorKey: "status",
        header: ({ column }) => <TableColumnHeader text={t!("tables:creditlimitrequests.headers.status")} column={column} />,
      },
      {
        accessorKey: "requestedAmount",
        header: ({ column }) => <TableColumnHeader text={t!("tables:creditlimitrequests.headers.amount")} column={column} />,
        cell: ({ row }) => formatNumberLocale(intl, row.getValue("requestedAmount") as number, "currency"),
      },
      {
        accessorKey: "createdAt",
        header: ({ column }) => <TableColumnHeader text={t!("tables:creditlimitrequests.headers.date")} column={column} />,
        cell: ({ row }) => formatDateLocale(intl, row.getValue("createdAt")),
      },
      {
        id: "actions",
        cell: ({ row }) => <ActionButton dataObj={row.original} fn={actionFn} />,
      },
    ]
  );
};
