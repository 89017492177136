import { balanceOf, totalSupply as getTotalSupply } from "@/blockchain/tokens/EuroMyron";
import { client } from "@/client";
import { AssetType, LoginUserQuery } from "@/graphql/__generated__/graphql-operations";
import {
  CLIENT_GET_ADMIN_STATE, CLIENT_GET_BLOCKCHAIN, CLIENT_GET_CUSTOMERS_STATE,
  CLIENT_GET_INVESTMENT_STATE, CLIENT_GET_PAGINATION_STATE,
} from "@/graphql/operations/client.queries";
import {
  emptyAdminState, emptyBlockchainData, emptyCustomersState,
  emptyInvestmentState, emptyPaginationState,
} from "./EmptyValues";
import { cacheInterestData } from "./Mutations";
import { criticalInventoryValue, getResidualCash, isEndPaymentDue, lastInventoryValue } from "@/blockchain";

/**
 * Initializes the cache for a Developer user by setting the admin page state to an empty value.
 * This function is specific to Developer role.
 */
const initialiseCacheDeveloper = () => {
  client.writeQuery({
    query: CLIENT_GET_ADMIN_STATE,
    data: {
      adminPageState: emptyAdminState
    }
  });
};

/**
 * Initializes the cache for a Financier user by setting the investment page state to an empty value.
 * This function is specific to Financier role.
 */
const initialiseCacheFinancier = () => {
  client.writeQuery({
    query: CLIENT_GET_INVESTMENT_STATE,
    data: {
      investmentPageState: emptyInvestmentState
    }
  });
};

/**
 * Initializes the cache for a ServiceProvider user by setting the customers page state to an empty value.
 * This function is specific to ServiceProvider role.
 */
const initialiseCacheServiceProvider = () => {
  client.writeQuery({
    query: CLIENT_GET_CUSTOMERS_STATE,
    data: {
      customersPageState: emptyCustomersState
    }
  });
};

/**
 * Initializes the cache for all users by setting the pagination state to an empty value.
 * This function is applicable to all user roles.
 */
const initialiseCacheAllUsers = () => {
  client.writeQuery({
    query: CLIENT_GET_PAGINATION_STATE,
    data: {
      paginationState: emptyPaginationState
    }
  });
};

/**
 * Initializes the cache with empty values based on the user's role.
 * The cache is initialized for blockchain data, pagination, and specific page states
 * depending on whether the user is a Developer, Financier, or ServiceProvider.
 *
 * @param {LoginUserQuery["loginUser"]["user"]} user - The logged-in user whose cache needs initialization.
 *
 * @example
 * const user = { __typename: "Developer", ... };
 * initializeEmptyCache(user);
 */
export function initializeEmptyCache(user: LoginUserQuery["loginUser"]["user"]) {
  client.writeQuery({
    query: CLIENT_GET_BLOCKCHAIN,
    data: {
      blockchainData: emptyBlockchainData
    }
  });

  // All 3 users have access
  initialiseCacheAllUsers();

  // Developer
  if(user.__typename === "Developer") {
    initialiseCacheDeveloper();
  }

  // Has employer
  if(user?.__typename === "Employee") {
    // Financier
    if(user.employer?.__typename === "Financier") {
      initialiseCacheFinancier();
    }
    // ServiceProvider
    if(user.employer?.__typename === "ServiceProvider") {
      initialiseCacheServiceProvider();
    }
  }
}

/**
 * Loads blockchain data into the cache based on the logged-in user's role.
 * This function handles different scenarios for Developers, Financiers, and ServiceProviders,
 * fetching and updating the cache with relevant blockchain data.
 *
 * @param {LoginUserQuery["loginUser"]["user"]} user - The logged-in user whose blockchain data needs to be loaded into the cache.
 *
 * @returns {Promise<void>} A promise that resolves when the cache is updated.
 *
 * @example
 * const user = { __typename: "Developer", ... };
 * await loadBlockchainDataToCache(user);
 */
export async function loadBlockchainDataToCache(user: LoginUserQuery["loginUser"]["user"]): Promise<void> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const { loading, ...restBlockchainData } = emptyBlockchainData;
  // First set loading to true in cache
  client.writeQuery({
    query: CLIENT_GET_BLOCKCHAIN,
    data: {
      blockchainData: { loading: true, ...restBlockchainData }
    }
  });

  const updateCacheWith = emptyBlockchainData;
  if(user.__typename === "Developer") {
    const supply = await getTotalSupply();
    updateCacheWith.admin.totalSupply = supply;
  } else {
    const employer = user.employer;
    updateCacheWith.balance = await balanceOf(user.employer.publickey);

    // Exit if the user is an employee of a ServiceProvider
    if(employer.__typename === "ServiceProvider") {
      const address = employer.assetfinancingpool.pooladdress as string;

      // Fetch the interest data
      updateCacheWith.serviceProvider = await cacheInterestData(user.employer.publickey, address);

      // Fetch ServiceProvider data based on asset type
      if(employer.assetfinancingpool.assetType === AssetType.Inventory) {
        const criticalValue = await criticalInventoryValue(address);
        const lastValue = await lastInventoryValue(address);
        const isDue = await isEndPaymentDue(address);
        updateCacheWith.inventoryProvider = {
          criticalInventoryValue: criticalValue,
          lastInventoryValue: lastValue,
          isEndPaymentDue: isDue
        };
      } else {
        const residual = await getResidualCash(address);
        updateCacheWith.receivablesProvider = {
          residualCash: residual
        };
      }
    }
  }

  updateCacheWith.initialized = true;
  updateCacheWith.loading = false;

  client.writeQuery({
    query: CLIENT_GET_BLOCKCHAIN,
    data: {
      blockchainData: updateCacheWith
    }
  });
}
