import { gql } from "@apollo/client";

// Local state schema definitions
export const typeDefs = gql`
  extend type Query {
    blockchainData: BlockchainData
    wallet: Wallet
  }

  type Wallet {
    address: String
    balance: Float
    networkID: Int
    networkName: String
    isConnected: Boolean
  }

  type BlockchainData {
    initialized: Boolean
    loading: Boolean
    serviceProvider: ServiceProviderData
    inventoryProvider: InventoryProviderData
    receivablesProvider: ReceivablesProviderData
    admin: AdminData
  }

  type ServiceProviderData {
    balance: Float
    interestPaymentDue: Boolean
    currentlyOwedInterestAmount: Float
    nextOwedInterestAmount: Float
    nextPaymentDate: Int
    lastPaymentDate: Int
  }

  type InventoryProviderData {
    criticalInventoryValue: Float
    isEndPaymentDue: Boolean
    lastInventoryValue: Float
  }

  type ReceivablesProviderData {
    residualCash: Float
  }

  type AdminData {
    totalSupply: Float
  }
`;
