import { CreateCompanyFormField } from "@/types";
import { webPresenceForm } from "../../inputfields";
import { TwInput } from "@/components/shared";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import merge from "lodash.merge";
import { CompanyDetailsState } from "./CompanyDetails";
import { client } from "@/client";
import { CompanyDetailsOnlyFragmentDoc } from "@/graphql/__generated__/graphql-operations";
import { useCompanyData } from "@/hooks";

type Props = {
  setHasErrors: Dispatch<SetStateAction<boolean>>;
  newData: CompanyDetailsState|undefined;
  setNewData: Dispatch<SetStateAction<CompanyDetailsState|undefined>>;
}

function WebPresence({ setHasErrors, newData, setNewData }: Props) {

  // Hooks
  const { t } = useTranslation();
  const company = useCompanyData();

  // Read from cache
  const companyDetails = client.readFragment({
    id: `${company?.__typename}:${company?._id}`,
    fragment: CompanyDetailsOnlyFragmentDoc,
  });

  /**
  * Handler for web presence form submit event
  * @param event: FormEvent<Element>
  */
  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    // Get the updated user input
    const { name, value, isValid } = event;
    setHasErrors(isValid ? false: true);

    if(isValid) {
      const dataToWrite = merge({}, newData, { serviceproviderinput: { companydetails: { [name]: value }}});
      setNewData(dataToWrite);
    }
  }

  function getWebPresenceValue(fieldName: string | number) {

    if (!companyDetails?.companydetails) {
      return null; // If companydetails is not defined, return null
    }
    // Check if the field name corresponds to a property in webpresence
    if (companyDetails?.companydetails?.webpresence && companyDetails?.companydetails?.webpresence?.[fieldName] !== undefined) {
      return companyDetails.companydetails.webpresence[fieldName];
    }

    return null; // If the field name doesn't match any property, return null
  }

  return (
    <div className="w-full">
      <h3 className="font-semibold text-sm mb-4 select-none">{t("settings.webpresence.title")}</h3>
      <div>
        {webPresenceForm.map((field:CreateCompanyFormField) => {
          return (
            <div key={field.name}>
              <TwInput
                key={field.name}
                className="mf-input-field mb-2"
                onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
                required={field.required}
                name={field.name}
                type={field.type}
                label={t(field.placeholder ?? "")}
                labelPlacement="stacked"
                errorMessage={t(field.errorMessage)}
                useErrorText
                defaultValue={getWebPresenceValue(field.name)}
                additionalValidation={field.additionalValidation}
              />
            </div>
          );
        })
        }
      </div>
    </div>
  );
}

export default WebPresence;
