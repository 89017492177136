import { TransactionsModals, TxResult } from "@/constants/enums";
import { formatNumberLocale } from "@/utils/helpers";
import { useIntl } from "react-intl";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";

type Props = {
  referencenumber: string;
  amount: number;
  setAmount: Dispatch<SetStateAction<number>>;
  transactionResultType: TxResult;
  setShowModal: Dispatch<SetStateAction<TransactionsModals>>;
}

function TransactionResultModal(props: Props) {
  // Props
  const {
    amount,
    setAmount,
    setShowModal,
    referencenumber,
    transactionResultType,
  } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["modals"]);

  // Constants
  const isSuccess = transactionResultType === TxResult.SUCCESS;

  const transactionDetails = [
    {
      title: t("transactionresult.amount"),
      value: formatNumberLocale(intl, amount, "currency"),
    },
    {
      title: t("transactionresult.reference"),
      value: referencenumber,
    }
  ];

  return (
    <BlankModal
      setShowModal={() => {setShowModal(TransactionsModals.NoModal); setAmount(0);}}
      className="px-6"
      customContentCSS="mf-modal-content p-8 lg:min-w-[640px]"
      title={
        isSuccess ?
          t("transactionresult.titles.withdrawalsuccess") :
          t("transactionresult.titles.withdrawalerror")
      }
      titleCSS={`text-xl text-center font-bold
        ${isSuccess ? "text-green-500 dark:text-green-500" : "text-red-500 dark:text-red-500"}
      `}
    >
      <BlankModalContent>
        {/* Main Content */}
        <div className="flex flex-col justify-between h-full">
          <div>
            {/* Image / animation */}
            <div className="mb-6">
              {
                isSuccess ?
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" className="w-28 h-28 mx-auto mt-2 text-green-500">
                    <circle className="animated-icon-path animated-icon-circle" fill="none" stroke="currentColor" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                    <polyline className="animated-icon-path animated-icon-check" fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                  </svg>
                  :
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" className="w-28 h-28 mx-auto mt-2 text-red-500">
                    <circle className="animated-icon-path animated-icon-circle" fill="none" stroke="currentColor" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                    <line className="animated-icon-path animated-icon-line" fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                    <line className="animated-icon-path animated-icon-line" fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
                  </svg>
              }
            </div>

            {/* Subtext */}
            <div className="mb-4">
              {
                isSuccess ?
                  <p className="dark:text-gray-100 text-lg">
                    {t("transactionresult.withdrawalsubtext")}
                    <span className="font-semibold">
                    &nbsp;{formatNumberLocale(intl, amount, "currency")}
                    </span>
                  </p>
                  :
                  <p className="dark:text-gray-100">
                    {t("transactionresult.withdrawalerrormessage")}
                  </p>
              }
            </div>

            {/* Transaction Details */}
            <div>
              <p className="text-lg font-semibold mb-1 dark:text-gray-100">
                {t("transactionresult.titles.transactiondetails")}
              </p>
              {
                isSuccess ?
                  <div className="rounded-md bg-gray-100 dark:bg-mfdarklighter p-4 space-y-1 select-none">
                    {
                      transactionDetails.map((item) => (
                        <div
                          key={`accountdetails:${item.title}`}
                          className="flex items-center justify-between"
                        >
                          {/* Details */}
                          <div>
                            <p className="text-sm text-gray-400 pb-0">{item.title}</p>
                            <p className="font-semibold break-all dark:text-gray-100">{item.value}</p>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  :
                  <div>
                    <p className="text-sm text-gray-400 pb-0">
                      {t("transactionresult.transactionamount")}
                    </p>
                    <p className="font-semibold break-all dark:text-gray-100">
                      {formatNumberLocale(intl, amount, "currency")}
                    </p>
                  </div>
              }
            </div>
          </div>

          {/* Disclaimer */}
          <div className="mt-4 mb-8 dark:text-gray-100 text-sm">
            <div>
              {
                isSuccess ?
                  <div>
                    <p>
                      {t("transactionresult.disclaimer.queries")}
                      <a href="mailto:contact@myron.finance" className="text-blue-500 cursor-pointer">
                      contact@myron.finance
                      </a>
                    </p>
                  </div>
                  :
                  <>
                    <p className="mb-2">
                      {t("transactionresult.disclaimer.contact")}
                    </p>
                    <p>
                      {t("transactionresult.disclaimer.contactsubtext")}
                      <a href="mailto:contact@myron.finance" className="text-blue-500 cursor-pointer">
                      contact@myron.finance
                      </a>
                    </p>
                  </>
              }
            </div>
          </div>
        </div>
      </BlankModalContent>

      <BlankModalFooter>
        {/* Buttons */}
        <div className={`flex items-center ${isSuccess ? "justify-center" : "justify-between"}`}>
          {isSuccess && (
            <button
              onClick={() => {
                setShowModal(TransactionsModals.NoModal);
                setAmount(0);
              }}
              className="mf-btn-action-small-primary-filled"
            >
              {t("common:buttons.close")}
            </button>
          )}
          {!isSuccess && (
            <>
              <button
                onClick={() => {
                  setShowModal(TransactionsModals.NoModal);
                }}
                className="mf-btn-action-small-primary-outline"
              >
                {t("common:buttons.cancel")}
              </button>
              <button
                onClick={() => setShowModal(TransactionsModals.AddTransaction)}
                className="mf-btn-action-small-primary-filled"
              >
                {t("common:buttons.retry")}
              </button>
            </>
          )}
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default TransactionResultModal;
