import { ValueSkeleton } from "@/components/skeletons";
import { AssetFinancingStatus } from "@/graphql/__generated__/graphql-operations";
import { formatNumberLocale } from "@/utils/helpers";
import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { calculateTimeLeft } from "./utils";
import { useTranslation } from "react-i18next";


type Props = {
  className?: string;
  amount: string | number;
  paymentDate: number;
  isLoading: boolean;
  financingStatus: AssetFinancingStatus;
}

function InterestPayment(props: Props) {

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["components"]);

  // States
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    if(!props.isLoading && props.paymentDate !== 0) {
      setTimeLeft(calculateTimeLeft(t, props.paymentDate));
    }
  }, [props.paymentDate]);

  return (
    <div className="h-full rounded-xl shadow-lg bg-white dark:bg-mfdarklight p-4 dark:text-white
                    col-span-2 flex flex-col"
    >
      {/* Title */}
      <div>
        <p className="font-semibold lg:text-lg">
          {t("translation:dashboard.financecardssp.nextpaymentdate.title")}
        </p>
      </div>
      <div>
        {
          props.financingStatus === AssetFinancingStatus.Active &&
            <div className="flex justify-between">
              {/* Amount */}
              <div className="w-full">
                <div className="text-xs lg:text-sm text-gray-400 dark:text-gray-300 font-thin">
                  {t("translation:dashboard.serviceprovider.amount")}
                </div>
                {
                  props.isLoading ? (
                    <ValueSkeleton className="max-w-[50%] -ml-1 mt-1" />
                  ) : (
                    <p className="text-base lg:text-lg">
                      {formatNumberLocale(intl, props.amount, "currency", false, false)}
                    </p>
                  )
                }
              </div>
              {/* Days left and date*/}
              <div className="flex flex-col justify-end w-full">
                <p
                  className="text-xs lg:text-sm text-gray-400 dark:text-gray-300 font-thin
                            flex justify-start"
                >
                  {`${t("financecard.timeleft")}`}
                </p>
                {
                  props.isLoading ? (
                    <ValueSkeleton className="max-w-[50%] -ml-1 mt-1" />
                  ) : (
                    <p
                      className="text-base lg:text-lg"
                    >
                      {`${timeLeft}`}
                    </p>
                  )
                }
              </div>
            </div>
        }
        {
          props.financingStatus === AssetFinancingStatus.Closed &&
            <div>
              <p className="text-xs lg:text-sm font-thin text-mforange">
                {t("translation:dashboard.serviceprovider.poolclosed")}
              </p>
            </div>
        }
        {
          (props.financingStatus === AssetFinancingStatus.Onboarding ||
            props.financingStatus === AssetFinancingStatus.Open) &&
              <div>
                <p className="text-xs lg:text-sm font-thin text-mforange">
                  {t("translation:dashboard.serviceprovider.poolinactive")}
                </p>
              </div>
        }
      </div>
    </div>
  );
}

export default InterestPayment;
