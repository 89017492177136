/* eslint-disable no-unused-vars */
export enum ContractType {
  EuroMyronToken = "EuroMyronToken",
  AssetFinPool = "AssetFinancingPool",
  CreditManager = "CreditManager",
  InventoryFinPool= "InventoryFinancingPool",
  ReceivableFinPool= "ReceivableFinancingPool",
  ProofOfAsset = "ProofOfAsset"
}

export enum AssetType {
  Inventory = "InventoryAsset",
  Receivable = "ReceivableAsset"
}

export enum CompanyType {
  ServiceProvider = "ServiceProvider",
  Financier = "Financier",
  Customer = "Customer",
  Both = "Both"
}

export enum UserType {
  Developer = "Developer",
  Employee = "Employee",
  Both = "Both"
}

export enum EURMOperation {
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW"
}

export enum PaymentType {
  INTEREST = "INTEREST",
  IFP = "IFP"
}

export enum EditUserType {
  ManageUsers = "ManageUsers",
  Settings = "Settings"
}

export enum EditCompanyType {
  Normal = "Normal",
  Settings = "Settings"
}

export enum TxResult {
  SUCCESS = "success",
  ERROR = "error"
}

export enum SetPasswordType {
  ResetPassword = "reset",
  ActivateAccount = "activate"
}

export enum SetPasswordResult {
  None = "none",
  Successful = "successful",
  Failed = "failed",
  Invalid = "invalid"
}

export enum TransactionsPageModals {
  NoModal = "noModal",
  TransactionDetails = "transactionDetails",
}

export enum TransactionsModals {
  NoModal = "noModal",
  AddTransaction = "addTransaction",
  WithdrawalSummary = "withdrawalSummary",
  TransactionResult = "transactionResult",
  Loading = "loading"
}

export enum WithdrawalCardModals {
  NoModal = "noModal",
  MakeWithdrawal = "makeWithdrawal",
  WithdrawalSummary = "withdrawalSummary",
  WithdrawalResult = "withdrawalResult",
  Loading = "loading"
}

export enum InvestmentTransactionModals {
  NoModal = "noModal",
  MakeInvestment = "makeInvestment",
  InvestmentTransactionSummary = "investmentTransactionSummary",
  InvestmentTransactionResult = "investmentTransactionResult",
  Loading = "loading"
}

export enum SetWalletSetupModals {
  NoModal = "noModal",
  Welcome = "welcome",
  Setup = "setup",
  PublicKey = "publicKey",
}

export enum CLRPageModals {
  NoModal = "noModal",
  CLRDetails = "clrDetails",
  NewRequest = "newRequest",
}

export enum AdminPageModals {
  NoModal = "noModal",
  CLRDetails = "clrDetails",
}

export enum CustomerPageModals {
  NoModal = "noModal",
  CustomerDetails = "customerDetails",
}

export enum DeployPageModals {
  NoModal = "noModal",
  ServiceProviderDetails = "serviceProviderDetails",
}

export enum UsersPageModals {
  NoModal = "noModal",
  CreateUser = "createUser",
  ManageUser = "manageUser",
}

export enum AssetsPageModals {
  NoModal = "noModal",
  Details = "details",
}

export enum PaymentModals {
  NoModal = "noModal",
  PaymentSummary = "paymentSummary",
  PaymentResult = "paymentResult",
  Loading = "loading"
}

export enum SharedModals {
  NoModal = "noModal",
}

// Only used for CreateCompany form
export enum InputReducerActionType {
  EMPTY = "",
  UPDATE = "UPDATE",
  ADDRESS = "ADDRESS",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  CUSTOMER_INPUT = "CUSTOMER_INPUT",
  COMPANY_DETAILS = "COMPANYDETAILS",
  ASSET_DOCUMENTARY = "ASSET_DOCUMENTARY",
  INVOICE_DOCUMENTARY = "INVOICE_DOCUMENTARY",
  ASSET_FINANCING_POOL = "ASSET_FINANCING_POOL"
}
