import { client } from "@/client";
import { CompanyDetailsFragment, CompanyDetailsFragmentDoc } from "@/graphql/__generated__/graphql-operations";

/**
 * Hook for reading the details of the user's Employer from Cache
 * @param user logged in User
 * @returns the details of the companies logged in User
 */
export function useCompanyData() {
  const companyId = sessionStorage.getItem("companyId");
  const companyType = sessionStorage.getItem("companyType");
  const companyData: CompanyDetailsFragment | null = client.readFragment({
    id: `${companyType}:${companyId}`,
    fragment: CompanyDetailsFragmentDoc,
  });

  return companyData;
}
