import { isDarkModeVar } from "@/client";
import { Investment } from "@/graphql/__generated__/graphql-operations";
import { formatNumberLocale } from "@/utils/helpers";
import {
  Chart as ChartJS,
  DoughnutController,
  ArcElement as arc,
  Tooltip
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useIntl } from "react-intl";

// Graph options
ChartJS.register(
  arc,
  DoughnutController,
  Tooltip
);

const shadesOfPurple = [
  "#512DA8",
  "#673AB7",
  "#9575CD",
  "#B39DDB",
  "#D1C4E9",
  "#E1BEE7",
  "#E2BCE9",
  "#AB83A1",
  "#7E57C2",
  "#4A148C",
];

const shadesOfOrange = [
  "#FF5722",
  "#FF7043",
  "#FF8A65",
  "#FFAB91",
  "#FFCCBC",
  "#FBE9E7",
  "#FFAB40",
  "#FF9100",
  "#FF6D00",
  "#FF3D00",
];

type Props = {
  receivableInvestments: Investment[];
  inventoryInvestments: Investment[];
}

const DoughnutChart = ({receivableInvestments, inventoryInvestments}:Props) => {
  const intl = useIntl();

  // Create inventory dataset, fill with null values equal to receivableInvestments length
  const receivableInvestmentsDataSet = Array(inventoryInvestments.length).fill(null);
  receivableInvestmentsDataSet.push(...receivableInvestments);

  const data = {
    labels: [
      ...inventoryInvestments.map(investment => investment.serviceproviderName),
      ...receivableInvestments.map(investment => investment.serviceproviderName),
    ],
    datasets: [
      {
        data: [
          ...inventoryInvestments.map(investment => investment.amount),
        ],
        backgroundColor: shadesOfPurple,
        hoverBackgroundColor: shadesOfPurple,
      },
      {
        data: [
          ...receivableInvestmentsDataSet.map((investment) => {
            if (investment) {
              return investment.amount;
            }
            return null;
          }),
        ],
        backgroundColor: generateColorArray(receivableInvestmentsDataSet),
        hoverBackgroundColor: generateColorArray(receivableInvestmentsDataSet),
      },
    ],
  };

  /**
   * function to generate the color array for the doughnut chart inner tier
   * chartjs maps colours to null values as well, this function corrects the colour index
   * @param dataSet
   * @returns
   */
  function generateColorArray(dataSet) {
    let colorIndex = 0;
    return dataSet.map((investment) => {
      if (investment) {
        return shadesOfOrange[colorIndex++];
      }
      // For null values, use a transparent color
      return "transparent";
    });
  }

  // Graph options
  const options: any = {
    responsive: true,
    resizeDelay: 0,
    maintainAspectRatio: true,
    intersect: false,
    borderColor: isDarkModeVar() === "true" ?  "#161A1F": "#fff",
    cutout: "50%",
    plugins: {
      emptyDoughnut: {
        color: "rgba(255, 128, 0, 0.5)",
        width: 2,
        radiusDecrease: 20
      },
      tooltip: {
        titleColor: isDarkModeVar() === "true" ? "#fff" : "#212121",
        bodyColor: isDarkModeVar() === "true" ? "#fff" : "rgb(0,0,0)",
        borderColor: isDarkModeVar() === "true" ? "#2b2b2b" : "rgb(223, 224, 224, 1)",
        borderWidth: 1,
        backgroundColor: isDarkModeVar() === "true" ? "#2b2b2b" : "rgb(255, 255, 255)",
        callbacks: {
          label: (context: any) => {
            return formatNumberLocale(intl, context.parsed, "currency");
          },
          afterLabel: (context: any) => {
            // compare label with inventoryInvestments array with the servieeProviderName,
            //if so return "Iventory" else "Receivables"
            const label = context.label;
            return inventoryInvestments.some(investment => investment.serviceproviderName === label) ? "Inventory" : "Receivables";
          }
        },
      },
      legend: {
        display: false,
        labels: {
          color: isDarkModeVar() === "true" ? "#fff": "#2b2b2b",
          fontColor: isDarkModeVar() === "true" ? "#2b2b2b": "#fff",
          font: {
            size: 14,
            weight: "normal",
          }
        },
      },
      htmlLegend: {
        containerID: "legend-container",
      },
    }
  };

  /**
   * Overrided function of chartjs generateLabels to correctly return dynamic labels with correct colours
   * @param chart
   * @param datasets
   * @returns
   */
  function customGenerateLabels (chart: any, datasets: any[]) {
    // Get the default label list
    const original = ChartJS.overrides.doughnut.plugins.legend.labels.generateLabels;
    const labelsOriginal = original.call(this, chart);
    const labels: any[] = [];

    datasets.forEach((dataset, datasetIndex) => {
      // filter dataset specific colour palette
      const colourPalette = chart.data.datasets[datasetIndex].backgroundColor;
      // remove all "transparent" in the palette
      const filteredColourPalette = colourPalette.filter(color => color !== "transparent");

      // filter dataset specific labels
      const datasetLabels = labelsOriginal.splice(0, dataset.data.length);

      datasetLabels.forEach((label: { datasetIndex: number; fillStyle: any; }, index: string | number) => {
        label.datasetIndex = datasetIndex;
        label.fillStyle = filteredColourPalette[index];
        labels.push(label);
      });
    });

    return labels;
  }

  // HtmlLegend List container
  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer!.querySelector("ul");

    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.style.display = "flex";
      listContainer.style.flexDirection = "column";
      listContainer.style.margin = "0";
      listContainer.style.padding = "0";
      listContainer.style.maxHeight = "165px";
      listContainer.style.overflowY = "auto";
      listContainer.style.width = "100%";
      listContainer.style.minWidth = "150px";

      legendContainer!.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart, args, options) {
      const ul = getOrCreateLegendList(chart, options.containerID);

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      const items = customGenerateLabels(chart, data.datasets);

      items.forEach(item => {
        const li = document.createElement("li");
        li.style.alignItems = "center";
        li.style.cursor = "pointer";
        li.style.display = "flex";
        li.style.flexDirection = "row";
        li.style.marginLeft = "10px";

        li.onclick = () => {
          const {type} = chart.config;
          if (type === "pie" || type === "doughnut") {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement("span");
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.flexShrink = "0";
        boxSpan.style.height = "20px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "20px";
        boxSpan.style.borderRadius = "5px";

        // Text
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = "0";
        textContainer.style.padding = "0";
        textContainer.style.opacity = item.hidden ? "0.5" : "1";

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  };

  return (
    <div className="flex-grow md:flex-grow-0" data-cy="doughnut-graph-outer-container">
      <div className="flex flex-wrap">
        <div className="p-4 h-[200px]">
          <Doughnut
            data-cy="doughnut-graph"
            data={data}
            options={options}
            plugins={[htmlLegendPlugin]}
          />
        </div>
        <div className="flex-grow py-4" id="legend-container" />
      </div>
    </div>
  );
};

DoughnutChart.displayName = "DoughnutChart";
export default DoughnutChart;
