import { useDeveloperData } from "./useDeveloperData";
import { useEmployeeData } from "./useEmployeeData";


/**
 * Helper Hook that should only be used if unsure about the logged in user's type
 * @returns the data of the logged in User
 */
export function useUserData() {
  const employee = useEmployeeData();
  const developer = useDeveloperData();
  return employee || developer;
}
