import { AssetFinancingRound } from "@/graphql/__generated__/graphql-operations";
import { formatDateLocale, formatNumberLocale } from "@/utils/helpers";
import { ServiceProviderDetails } from "@/components/shared";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";

type Props = {
  assetfinancingrounds: AssetFinancingRound[];
}

function RecentActivity(props: Props) {

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();

  return (
    <div
      className="bg-white dark:bg-mfdarklight rounded-md dark:text-white px-2 pt-4
                  pb-2 w-full select-none shadow-lg"
    >
      {/* Top container */}
      <div className="flex items-center justify-between">
        <p className="text-lg font-semibold px-2 -mb-2">
          {
            t("dashboard.recenttransactions.transactionstitle")
          }
        </p>
      </div>
      {/* Main content container */}
      {
        props.assetfinancingrounds.length === 0 &&
          <div className="flex flex-col items-center justify-center h-52">
            <p className="text-base font-thin mb-2 px-2">
              {
                t("dashboard.recenttransactions.placeholderSP")
              }
            </p>
          </div>
      }
      {
        <div className="mt-4 flex flex-col gap-y-2 overflow-y-auto md:max-h-[250px] lg:max-h-[300px] overflow-x-clip">
          {
            props.assetfinancingrounds.map((afr) => (
              <div
                className={`flex flex-col hover:border-mforange rounded-lg overflow-visible
                            transition-all duration-300 ease-linear py-1 px-2 cursor-pointer group
                            odd:bg-gray-100 dark:odd:bg-mfdarkbase border-2 border-transparent`}
                key={`recent-${afr._id}`}
              >
                <div className="flex flex-row w-full">
                  {
                    // *** Inventory
                    afr.inventoryBought !== null && afr.inventorySold !== null ?
                      <div className="flex flex-col xl:flex-row items-center w-full p-2 gap-2">
                        <div className="flex gap-2 flex-col md:flex-row items-end md:items-center w-full md:mr-4 justify-between">
                          {/* SP Name */}
                          <div className="justify-start flex max-lg:w-full">
                            <ServiceProviderDetails
                              serviceProviderId={afr.spdetails?._id}
                            />
                          </div>
                          {/* Date */}
                          <div className="max-md:hidden">
                            <p className="text-sm text-gray-500 dark:text-gray-200 font-thin">
                              {formatDateLocale(intl, afr.createdAt)}
                            </p>
                          </div>
                        </div>
                        {/* Transactions */}
                        <div className="flex gap-2 max-md:flex-wrap w-full mt-2 md:mt-0">
                          {/* Inventory value */}
                          <div className="items-center min-w-[140px]">
                            <p className="text-sm dark:text-gray-400">
                              {t("dashboard.serviceprovider.inventoryvalue")}
                            </p>
                            <p className="flex items-center text-sm font-thin text-green-500">
                              {formatNumberLocale(intl, afr.amountFinanced ?? 0, "currency")}
                            </p>
                          </div>
                          {/* Inventory bought */}
                          <div className="items-center min-w-[140px]">
                            <p className="text-sm dark:text-gray-400">
                              {t("dashboard.recenttransactions.inventorybought")}
                            </p>
                            <p className="flex items-center text-sm font-thin text-green-500">
                              {formatNumberLocale(intl, afr.inventoryBought ?? 0, "currency")}
                            </p>
                          </div>
                          {/* Inventory sold */}
                          <div className="items-center min-w-[140px]">
                            <p className="text-sm dark:text-gray-400">
                              {t("dashboard.recenttransactions.inventorysold")}
                            </p>
                            <p className="flex items-center text-sm font-thin text-red-500">
                              {formatNumberLocale(intl, afr.inventorySold ?? 0, "currency")}
                            </p>
                          </div>
                        </div>
                        {/* Date */}
                        <div className="md:hidden w-full text-end">
                          <p className="text-sm text-gray-500 dark:text-gray-200 font-thin">
                            {formatDateLocale(intl, afr.createdAt)}
                          </p>
                        </div>
                      </div>
                      :
                    // *** Receivable
                      <div className="flex flex-col xl:flex-row items-center w-full p-2 gap-2">
                        <div className="flex gap-2 flex-col md:flex-row items-end md:items-center w-full md:mr-4 justify-between">
                          {/* SP Name */}
                          <div className="justify-start flex max-lg:w-full">
                            <ServiceProviderDetails
                              serviceProviderId={afr.spdetails?._id}
                            />
                          </div>
                          {/* Date */}
                          <div className="max-md:hidden">
                            <p className="text-sm text-gray-500 dark:text-gray-200 font-thin">
                              {formatDateLocale(intl, afr.createdAt)}
                            </p>
                          </div>
                        </div>
                        {/* AF/ANF container */}
                        <div className="flex gap-2 max-md:flex-wrap w-full mt-2 md:mt-0">
                          {/* Amount financed */}
                          <div data-cy="amountFinancedRecentActivity" className="dark:text-gray-400 text-gray-600 items-center justify-between min-w-[140px]">
                            <p className="text-sm">
                              {t("dashboard.recenttransactions.amountfinanced")}
                            </p>
                            <p className="flex items-center text-sm font-thin text-green-500">
                              {formatNumberLocale(intl, afr.amountFinanced ?? 0, "currency")}
                            </p>
                          </div>
                          {/* Amount not financed */}
                          <div data-cy="amountNotFinancedRecentActivity" className="dark:text-gray-400 text-gray-600 items-center justify-between min-w-[140px]">
                            <p className="text-sm">
                              {t("dashboard.recenttransactions.amountnotfinanced")}
                            </p>
                            <p className="flex items-center text-sm font-thin text-red-500">
                              {formatNumberLocale(intl, afr.amountNotFinanced?? 0, "currency")}
                            </p>
                          </div>
                        </div>
                        {/* Date */}
                        <div data-cy="receivableDateRecentActivity" className="md:hidden w-full text-end">
                          <p className="text-sm text-gray-500 dark:text-gray-200 font-thin">
                            {formatDateLocale(intl, afr.createdAt)}
                          </p>
                        </div>
                      </div>
                  }
                </div>
              </div>
            ))
          }
        </div>
      }
    </div>
  );
}

export default RecentActivity;
