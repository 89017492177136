import { handleBlockchainExplorerLink } from "@/blockchain/helpers";
import { TwCircle } from "@/components/shared";
import { AssetFinancingStatus } from "@/graphql/__generated__/graphql-operations";
import { formatNumberLocale, handleCircleRobotColour } from "@/utils/helpers";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";

type Props = {
  className?: string;
  financingPoolStatus: AssetFinancingStatus;
  poolAddress: string;
  alreadyDeposited: string | number;
}

function FinancingPoolStats(props: Props) {

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();

  return (
    <div
      className={`${props.className} justify-center flex flex-col
        mf-gradient-1 rounded-lg shadow-lg text-white px-4 py-2 mt-4 select-none min-h-[50px]
      `}
    >
      <div className="md:flex items-center justify-between">
        {/* Financing pool status */}
        <div className="md:flex items-center md:space-x-2">
          <p className="font-semibold select-none">
            {t("dashboard.poolstats.status")}
          </p>
          <div className="flex items-center">
            <TwCircle custom={`${handleCircleRobotColour("AFC_STATUS", 0, props.financingPoolStatus)} mr-1`} />
            <p>
              {props.financingPoolStatus}
            </p>
          </div>
        </div>
        {/* Smart contract address */}
        {
          props.financingPoolStatus === AssetFinancingStatus.Active &&
          <div className="md:flex items-center md:space-x-2">
            <p className="font-semibold select-none">
              {t("dashboard.poolstats.address")}
            </p>
            <a
              className="hover:text-mforange transition-all duration-200"
              href={handleBlockchainExplorerLink("address", props.poolAddress)}
              target="_blank"
              rel="noreferrer"
            >
              Explorer
            </a>
          </div>
        }
      </div>
      {/* Deposited amount */}
      {
        props.financingPoolStatus === AssetFinancingStatus.Open &&
          <div className="md:flex items-center md:space-x-2">
            <p className="font-semibold select-none">
              {t("dashboard.poolstats.depositedamount")}
            </p>
            <p>
              {formatNumberLocale(intl, props.alreadyDeposited, "currency")}
            </p>
          </div>
      }
      {
        props.financingPoolStatus === AssetFinancingStatus.Closed &&
          <div>
            {t("dashboard.serviceprovider.financingpoolstats")}
          </div>
      }
    </div>
  );
}

export default FinancingPoolStats;
