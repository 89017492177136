import { useState, useEffect } from "react";

function useLocale() {
  const [locale, setLocale] = useState(localStorage.getItem("i18nextLng"));

  useEffect(() => {
    const handleStorageChange = () => {
      setLocale(localStorage.getItem("i18nextLng"));
    };

    window.addEventListener("languagechange", handleStorageChange);
    return () => {
      window.removeEventListener("languagechange", handleStorageChange);
    };
  }, []);

  return locale || "en";
}

export default useLocale;
