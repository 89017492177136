import { client } from "@/client";
import { AssetFinancingPoolFragmentDoc, useEditAssetFinancingPoolMutation } from "@/graphql/__generated__/graphql-operations";
import { t } from "i18next";
import { toast } from "react-toastify";


export default function useEditAssetFinancingPoolMutationHook() {
  const [ editAssetFinancingPoolMutation, { loading, error } ] = useEditAssetFinancingPoolMutation({
    onCompleted(result) {
      const { editAssetFinancingPool } = result;

      // Return if mutation was unsuccessful
      if (!editAssetFinancingPool) {
        return toast.error(t("toasts:companyUpdateFailure"), { toastId: "companyUpdateFailure" });
      }

      toast.success(t("toasts:companyUpdateSuccess"), { toastId: "companyUpdateSuccess" });

      // Update cache
      client.writeFragment({
        id: `AssetFinancingPool:${editAssetFinancingPool._id}`,
        fragment: AssetFinancingPoolFragmentDoc,
        data: editAssetFinancingPool
      });
    }
  });

  return {
    editAssetFinancingPoolMutation,
    loading,
    error
  };
}
