import { client } from "@/client";
import { AssetFinancingStatus, ContractAddressesFragmentDoc, useDeployAssetFinancingPoolContractMutation } from "@/graphql/__generated__/graphql-operations";
import { t } from "i18next";
import { toast } from "react-toastify";

export default function useDeployAssetFinancingPoolContractMutationHook() {
  const [deployAssetFinancingPoolContractMutation, { loading, error }] = useDeployAssetFinancingPoolContractMutation();

  // Define a function that will be called to execute the mutation
  const deployMutation = (afpId: string, serviceProviderId: string) => {
    return deployAssetFinancingPoolContractMutation({
      variables: {
        serviceProviderId
      },
      onCompleted(result: { deployAssetFinancingPoolContract: any }) {
        const { deployAssetFinancingPoolContract } = result;
        toast.dismiss("deployLoadingToast");

        if (!deployAssetFinancingPoolContract) {
          toast.error(t("toasts:deploySCFailure"), { toastId: "deploySCFailure" });
          return;
        }

        toast.success(t("toasts:deploySCSuccess"), { toastId: "deploySCSuccess" });

        // Update cache
        client.writeFragment({
          id: `AssetFinancingPool:${afpId}`,
          fragment: ContractAddressesFragmentDoc,
          data: {
            __typename: "AssetFinancingPool",
            pooladdress: deployAssetFinancingPoolContract.pooladdress,
            tokenaddress: deployAssetFinancingPoolContract.tokenaddress,
            status: AssetFinancingStatus.Open,
          }
        });
      }
    });
  };

  return {
    deployMutation,
    loading,
    error
  };
}
