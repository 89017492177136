import { TwInput, TwTextArea } from "@/components/shared";
import { TwSelect, TwSelectOption } from "@/components/shared/selector";
import { CreditLimitRequest, CreditLimitRequestStatus } from "@/graphql/__generated__/graphql-operations";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { updateAdminState } from "@/cache/appstate/WriteQueries";
import { useAdminPageState } from "@/hooks/cache/appState";
import { useEditCreditLimitRequestMutationHook } from "@/hooks/mutations";
import { AdminPageState } from "@/types";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { AdminPageModals } from "@/constants/enums";
import { useTranslation } from "react-i18next";

type Props = {
  setShowModal: Dispatch<SetStateAction<AdminPageModals>>;
  creditLimiRequest: CreditLimitRequest;
}

function CreditLimitAdmin({ setShowModal, creditLimiRequest }: Props) {
  // States
  const [hasErrors, setHasErrors] = useState(false);

  // Hooks
  const state: AdminPageState = useAdminPageState();
  const { t } = useTranslation();

  // Mutation hooks
  const { editCreditLimitRequestMutation } = useEditCreditLimitRequestMutationHook();

  /**
  * onChange handler for input fields
  * @param {object} event - input event
  */
  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    // get the updated user input
    const { name, value, isValid } = event;

    if(isValid) {
      updateAdminState({...state, creditLimitRequestInput: {[name]: value}});
      setHasErrors(false);
    }
    else {
      setHasErrors(true);
    }
  }

  /**
   * Handler function for editCreditLimitRequestMutation
   * @param {FormEvent<Element>} event
   */
  function handleSubmitResponse(event: FormEvent<Element>) {
    event.preventDefault();
    editCreditLimitRequestMutation({
      variables: {
        id: creditLimiRequest?._id ?? "",
        creditLimitRequestInput: {...state.creditLimitRequestInput}
      },
    }).then(() => setShowModal(AdminPageModals.NoModal));
  }

  return (
    <BlankModal
      setShowModal={() => setShowModal(AdminPageModals.NoModal)}
      className="px-6"
      customContentCSS="mf-modal-content min-w-[80%] md:min-w-[400px] lg:min-w-[580px]"
      title="Request Details"
      titleCSS="text-lg dark:text-white"
    >
      <form
        onSubmit={handleSubmitResponse}
      >
        <BlankModalContent>
          <div className="flex flex-col select-none">
            <TwSelect
              name="status"
              defaultValue={creditLimiRequest.status}
              className="dark:bg-mfdarklight mb-2"
              onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
            >
              <TwSelectOption
                value=""
                text={"Select status"}
                disabled
              />
              {
                [
                  CreditLimitRequestStatus.Approved,
                  CreditLimitRequestStatus.Pending,
                  CreditLimitRequestStatus.Denied
                ].map((statusEnum) => (
                  <TwSelectOption
                    key={statusEnum}
                    value={statusEnum}
                    text={statusEnum}
                  />
                ))}
            </TwSelect>
            <TwInput
              className="mf-input-field"
              onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
              placeholder={t("customeroverview.labels.requestedAmount").toString()}
              name="requestedAmount"
              type="number"
              labelBefore="dark:text-gray-200"
              errorMessage={t("customeroverview.errorMessages.amountInvalid").toString()}
              defaultValue={creditLimiRequest.requestedAmount}
              required
            />
            <TwTextArea
              className="mf-input-field rounded-sm"
              onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
              placeholder={t("customeroverview.labels.reasonForReq").toString()}
              name="reasonForRequest"
              type="text"
              labelBefore="dark:text-gray-200"
              errorMessage={t("customeroverview.errorMessages.reasonInvalid").toString()}
              defaultValue={creditLimiRequest.reasonForRequest}
              required={false}
            />
            <TwTextArea
              className="mf-input-field rounded-sm"
              onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
              placeholder={"Reason for denial"}
              name="reasonForDenial"
              type="text"
              labelBefore="dark:text-gray-200"
              errorMessage={"Reason for denial is invalid"}
              defaultValue={creditLimiRequest.reasonForDenial as string}
              required={false}
            />
          </div>
        </BlankModalContent>

        <BlankModalFooter>
          <div className="flex justify-end">
            <button
              disabled={hasErrors}
              className="mf-btn-action-large-primary-filled"
            >
              {t("common:buttons.submit")}
            </button>
          </div>
        </BlankModalFooter>
      </form>
    </BlankModal>
  );
}

export default CreditLimitAdmin;
