import { CreditLimitRequestStatus, GetPaginatedCreditLimitRequestsDocument, GetPaginatedCreditLimitRequestsQuery, useEditCreditLimitRequestMutation } from "@/graphql/__generated__/graphql-operations";
import { toast } from "react-toastify";

export default function useEditCreditLimitRequestMutationHook() {

  const [editCreditLimitRequestMutation, { loading, error }] = useEditCreditLimitRequestMutation({
    onCompleted(result) {
      result.editCreditLimitRequest &&
      toast.success("Credit limit request has been updated",{toastId: "adminCLRUpdateSuccess"});
    },
    update(cache, { data }) {
    // remove updated request if status is not pending and add 1 new pending CLR
      if (data?.editCreditLimitRequest?.status !== CreditLimitRequestStatus.Pending) {
        const paginatedCreditLimitRequestsData = cache.readQuery<GetPaginatedCreditLimitRequestsQuery>({ query: GetPaginatedCreditLimitRequestsDocument });
        if (!paginatedCreditLimitRequestsData) return;

        cache.writeQuery<GetPaginatedCreditLimitRequestsQuery>({
          query: GetPaginatedCreditLimitRequestsDocument,
          data: {
            paginatedCreditLimitRequests: {
              __typename: paginatedCreditLimitRequestsData.paginatedCreditLimitRequests.__typename,
              edges: paginatedCreditLimitRequestsData.paginatedCreditLimitRequests.edges.filter((edge) => {
                return edge.node._id !== data?.editCreditLimitRequest?._id;
              }),
              pageInfo: paginatedCreditLimitRequestsData.paginatedCreditLimitRequests.pageInfo,
            }
          }
        });
      }
    }
  });

  return {
    editCreditLimitRequestMutation,
    loading,
    error,
  };
}
