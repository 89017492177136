import { ReactNode } from "react";

type Props = {
  className?: string;
  children?: ReactNode | ReactNode[] | undefined;
}

/**
 * A reusable card component that can be styled with Tailwind CSS.
 * This component acts as a container for other elements and can be customized with additional CSS classes.
 *
 * @param {Object} props - The component props.
 * @param {string} [props.className] - Additional custom CSS classes to apply to the card.
 * @param {ReactNode | ReactNode[] | undefined} [props.children] - The content to be displayed inside the card. Can be a single React node or an array of nodes.
 *
 * @returns {JSX.Element} The rendered TwCard component.
 *
 * @example
 * // Usage of TwCard with custom styling and content
 * <TwCard className="p-4 shadow-lg">
 *   <h1 className="text-xl font-bold">Card Title</h1>
 *   <p>This is some content inside the card.</p>
 * </TwCard>
 */
function TwCard(props: Props): JSX.Element {
  return (
    <div data-cy="twcard" className={`mf-card-bg ${props.className}`}>
      {props.children}
    </div>
  );
}

export default TwCard;
