import { ArrowPathIcon, CheckIcon } from "@heroicons/react/24/outline";
import { TwGlobalFilter } from "@/components/shared";
import { toast } from "react-toastify";
import { ChangeEvent } from "react";
import { usePaginationState } from "@/hooks/cache/appState/usePaginationState";
import { updatePaginationState } from "@/cache/appstate/WriteQueries";
import { PaginationState } from "@/types";
import { useTranslation } from "react-i18next";
import { UsersPageModals } from "@/constants/enums";
import { propsManageUsersComponent } from "./props";

function UsersFilterComponent(props: propsManageUsersComponent) {

  // Props
  const { onFilterSubmit, displayAmount, setShowModal } = props;

  // Hooks
  const { t } = useTranslation();
  const paginationState: PaginationState["paginatedUsers"] = usePaginationState("paginatedUsers");
  const { filters: { filter } } = paginationState;

  /**
   * OnChange Handler for Filter input field.
   */
  function handleFilterOnChange(event: ChangeEvent<HTMLInputElement>) {
    updatePaginationState({ paginatedUsers: { filters: { filter: event.target.value } } });
  }

  /**
   * OnClick handler for Reset button
   */
  const handleResetClick = () => {
    onFilterSubmit({
      first: displayAmount,
      after: null,
      filter: "",
    });
    updatePaginationState({ paginatedUsers: { filters: { filter: "" } } });
    toast.success(t("common:text.filterremoved"), {toastId: "userFilterRemoved"});
  };

  /**
   * OnClick handler for Submit button
   */
  const handleSubmitClick = () => {
    onFilterSubmit({
      first: displayAmount,
      filter,
    });
    toast.success(t("common:text.filterapplied"), {toastId: "userFilterApplied"});
  };

  return (
    <div className="mb-4">
      <div className="mt-4">
        <div className="flex flex-col-reverse gap-2 md:flex-row md:items-center md:justify-between md:mb-4">
          <TwGlobalFilter
            value={filter}
            placeholder={t("manageusers.filter").toString()}
            onChange={handleFilterOnChange}
            className="mf-global-filter"
          />
          {/* Toggle create user visibility */}
          <div className="flex justify-end whitespace-nowrap">
            <button
              onClick={() => {
                setShowModal(UsersPageModals.CreateUser);
              }}
              className="mf-btn-action-large-primary-filled"
            >
              <p className="select-none">
                {t("manageusers.buttons.createuser")}
              </p>
            </button>
          </div>
        </div>
        {/* Right container --> Action buttons */}
        <div className="flex items-center gap-2 justify-end mt-2">
          {/* Reset button */}
          <button
            onClick={handleResetClick}
            className="mf-btn-action-small-primary-outline"
          >
            <div><ArrowPathIcon data-cy="users-filter-reset-icon" className="w-5 h-5 md:hidden" /></div>
            <p className="hidden md:flex">
              {t("manageusers.buttons.reset")}
            </p>
          </button>

          {/* Submit button */}
          <button
            onClick={handleSubmitClick}
            className="mf-btn-action-small-primary-filled"
          >
            <div><CheckIcon data-cy="users-filter-submit-icon" className="w-5 h-5 md:hidden" /></div>
            <p className="hidden md:flex">
              {t("manageusers.buttons.submit")}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}

export default UsersFilterComponent;
