import { Switch } from "@/components/ui/Switch";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEditUserMutationHook } from "@/hooks/mutations";
import { DeveloperDetailsFragment, EmployeeDetailsFragment } from "@/graphql/__generated__/graphql-operations";

type Props = {
  user: EmployeeDetailsFragment | DeveloperDetailsFragment | null;
}

function Notifications({ user }: Props) {
  // States
  const [allowMailsState, setAllowMailsState] = useState(user?.allowMails || false);

  // Hooks
  const { t } = useTranslation();
  const { editUser } = useEditUserMutationHook();

  // Handlers
  function handleEmailNotificationOnChange(checked: boolean) {
    editUser(user!._id, user, {
      allowMails: checked
    });
  }

  return (
    // Container
    <div>
      {/* Header */}
      <div className="flex space-x-2 items-center mb-4">
        <h2 className="font-semibold text-lg mb-2 select-none">{t("settings.notifications.title")}</h2>
      </div>
      {/* Content */}
      <div>
        {/* Email */}
        <div className="flex items-center justify-between md:w-1/2 md:pr-2">
          <div>
            <h3 className="font-semibold text-sm mb-2">{t("settings.notifications.email.title")}</h3>
            <p className="text-sm mb-2">
              {t("settings.notifications.email.description")}
            </p>
          </div>
          <Switch
            checked={allowMailsState}
            onClick={() => setAllowMailsState((prev) => !prev)}
            onCheckedChange={(checked) => {
              handleEmailNotificationOnChange(checked);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Notifications;
