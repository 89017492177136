/**
 * Pagination utility functions.
 */

/**
 * Basic shape of the pagination object.
 */
// interface Pagination {
//   maxData: number; // Total amount of data fetched
//   currentPage: number; // Current page number index
//   maxPage: number; // Index of the maximum page visited
// }

/**
 * Calculates the amount of data items to fetch for the next pagination request.
 *
 * This function determines how many additional data items should be fetched based on the current pagination state and the desired amount of data to fetch.
 *
 * @param {number} newAmount The desired amount of data to fetch.
 * @param {number} maxData The total amount of data available.
 * @param {number} displayAmount The number of data items to display per page.
 * @returns {number} The amount of data items to fetch for the next request.
 *
 * @example
 * ```javascript
 * const newAmount = 10;
 * const maxData = 100;
 * const displayAmount = 20;
 * const amountToFetch = calculateAmountToFetch(newAmount, maxData, displayAmount);
 * console.log(amountToFetch); // Output: 0 (since newAmount is less than maxData and divisible by it)
 * ```
 */
export function calculateAmountToFetch(newAmount: number, maxData: number, displayAmount: number): number {
  let difference = 0; // Initialize difference variable

  // If the new amount is less than the maximum pagination value
  if (newAmount < maxData) {
    // If the max pagination value is divisible by the new amount
    if (maxData % newAmount === 0) {
      return 0;
    }
    // Calculate difference for non divisible newAmount values
    difference = newAmount - (maxData % newAmount);
  } else {
    // Calculate the max value between displayAmount and max
    const maxPaginationAmount = displayAmount > maxData ? displayAmount : maxData;
    // Calculate difference for newAmount values greater than or equal to max
    difference = newAmount - maxPaginationAmount;
  }

  return difference;
}

/**
 * Determines if more data should be fetched based on the calculated difference and hasNextPage flag.
 *
 * This function checks if there's a remaining difference in data to be fetched and if there's a next page available.
 *
 * @param {number} difference The calculated difference in data items.
 * @param {boolean | undefined} hasNextPage A flag indicating if there's a next page.
 * @returns {boolean} True if more data should be fetched, false otherwise.
 *
 * @example
 * ```javascript
 * const difference = 5;
 * const hasNextPage = true;
 * const shouldFetch = shouldFetchMore(difference, hasNextPage);
 * console.log(shouldFetch); // Output: true
 * ```
 */
export function shouldFetchMore(difference: number, hasNextPage: boolean | undefined): boolean {
  // Update max pagination value
  if (difference === 0) return false;
  if (!hasNextPage) return false;
  return true;
}

/**
 * Checks if the next page should be fetched based on the current page, max page visited, and hasNextPage flag.
 *
 * This function determines if the next page should be loaded, considering the current page number, the maximum page visited, and the availability of a next page.
 *
 * @param {number} maxPageVisited The index of the maximum page visited.
 * @param {number} currentPageNumber The current page number.
 * @param {boolean} hasNextPage A flag indicating if there's a next page.
 * @returns {boolean} True if the next page should be fetched, false otherwise.
 *
 * @example
 * ```javascript
 * const maxPageVisited = 2;
 * const currentPageNumber = 2;
 * const hasNextPage = true;
 * const shouldFetchNext = shouldFetchNext(maxPageVisited, currentPageNumber, hasNextPage);
 * console.log(shouldFetchNext); // Output: false (since maxPageVisited is equal to currentPageNumber)
 * ```
 */
export function shouldFetchNext(maxPageVisited: number, currentPageNumber: number, hasNextPage: boolean): boolean {
  if (!hasNextPage) return false;
  if (maxPageVisited > currentPageNumber) return false;
  return true;
}
