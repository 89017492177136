
export const optionsLight = {
  responsive: true,
  resizeDelay: 0,
  maintainAspectRatio: true,
  plugins: {
    tooltip: {
      titleColor: "#212121",
      bodyColor: "rgb(0,0,0)",
      borderColor: "rgb(223, 224, 224, 1)",
      borderWidth: 1,
      backgroundColor: "rgb(255, 255, 255)",
    },
  },
  legend: {
    display: true,
    position: "bottom",
    labels: {
      fontColor: "#212121"
    }
  },
  layout: {
    autoPadding: false,
    padding: 4
  }
};
