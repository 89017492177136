import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getWeb3 } from "../libs/web3";
import magic from "@/libs/magic";
import { getProvider } from "@/libs/provider";
import { toast } from "react-toastify";
import { isConnectedVar } from "@/client";
import { cacheEURMBalance } from "@/cache/Mutations";
import { isValidWalletAddress } from "@/blockchain/helpers";
import { useTranslation } from "react-i18next";
import Web3Singleton from "@/blockchain/Web3Singleton";

declare global {
  interface Window {
    connectWallet: () => void;
  }
}

const Web3Context = createContext<any>([]);

export const Web3ContextProvider = ({ children }: any) => {
  // Hooks
  const { t } = useTranslation(["components"]);

  // States
  const [web3, setWeb3] = useState<any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [wallet, setWallet] = useState<string | null>(sessionStorage.getItem("wallet"));
  // const [isThirdPartyWallet, setIsThirdPartyWallet] = useState<boolean>(false);

  /**
   * Connect to Web3 wallet using Magic-SDK
   */
  const connectWallet = async () => {
    try {
      setDisabled(true);
      const accounts = await magic.wallet.connectWithUI();
      setDisabled(false);

      // Once user is logged in, reset the Web3 instance
      Web3Singleton.resetInstance();

      // Validate User's wallet
      if(!isValidWalletAddress(accounts[0])) {
        toast.error(t("header.wallet.invalidwallet"), {toastId: "invalidWallet", autoClose: 3000});
        await magic.user.logout();
        return;
      }

      // Initialize Web3 instance in Singleton pattern
      const web3 = await Web3Singleton.getInstance();

      // Update states and localStorage
      sessionStorage.setItem("wallet", accounts[0]);
      setWeb3(web3);
      setWallet(accounts[0]);
      setIsConnected(true);
      localStorage.setItem("isConnected", "true");
      isConnectedVar("true");
      // Update cache
      cacheEURMBalance(accounts[0]);
      // Display toast
      toast.dismiss("loadingConnect");
      toast.success(t("header.wallet.successfulconnection"), {toastId: "walletConnectSuccess"});
    } catch (error) {
      setIsConnected(false);
      setDisabled(false);
      console.error(error);
    }
  };

  /**
   * Disconnect from Web3 wallet
   */
  const disconnectWallet = async () => {
    try {
      setDisabled(true);
      await magic.user.logout();
      setDisabled(false);

      // Update states and localStorage
      localStorage.removeItem("wallet");
      // Reset the Singleton instance
      Web3Singleton.resetInstance();
      const web3 = await Web3Singleton.getInstance();

      setWeb3(web3);
      setWallet(null);
      setIsConnected(false);
      localStorage.setItem("isConnected", "false");
      isConnectedVar("false");
    } catch (error) {
      setIsConnected(false);
      setDisabled(false);
      console.error(error);
    }
  };

  /**
   * This function is called when the page loads.
   * It checks if the user has a wallet connected and updates the states accordingly.
   */
  const handleWalletOnPageLoad = async () => {
    setIsConnected(false);
    const provider = await getProvider();
    const accounts = await provider.request({ method: "eth_accounts" });
    // If user wallet is no longer connected, logout
    if (!accounts[0] || !wallet) {
      disconnectWallet();
      return;
    }
    // Update cache if wallet connected
    cacheEURMBalance(accounts[0]);
    // Update states and localStorage
    sessionStorage.setItem("wallet", accounts[0]);
    setWallet(accounts[0]);
    setIsConnected(true);
    localStorage.setItem("isConnected", "true");
    isConnectedVar("true");
  };

  const showUI = () => {
    magic.wallet.showUI();
  };

  const value = useMemo(
    () => ({
      web3,
      setWeb3,
      disabled,
      connectWallet,
      disconnectWallet,
      isConnected,
      showUI,
      wallet,
      setWallet,
      handleWalletOnPageLoad
      // isThirdPartyWallet,
    }),
    [
      web3,
      setWeb3,
      disabled,
      connectWallet,
      disconnectWallet,
      isConnected,
      showUI,
      wallet,
      setWallet,
      handleWalletOnPageLoad
      // isThirdPartyWallet
    ],
  );

  useEffect(() => {
    getWeb3().then(setWeb3);
  }, []);

  // Expose `connectWallet` globally for Cypress tests
  if (window.Cypress) {
    window.connectWallet = connectWallet;
  }

  return <Web3Context.Provider value={{ ...value }}>{children}</Web3Context.Provider>;
};

export function useWeb3() {
  return useContext(Web3Context);
}
