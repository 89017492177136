import EuroMyronABI from "@/blockchain/abi/EuroMyron.json";
import AssetFinancingPoolABI from "@/blockchain/abi/AssetFinancingPool.json";
import InventoryFinancingPoolABI from "@/blockchain/abi/InventoryFinancingPool.json";
import ProofOfAssetABI from "@/blockchain/abi/ProofOfAsset.json";
import ReceivableFinancingPoolABI from "@/blockchain/abi/ReceivableFinancingPool.json";
import AssetFinancingTokenABI from "@/blockchain/abi/AssetFinancingToken.json";
import CreditManagerABI from "@/blockchain/abi/CreditManager.json";
import Web3Singleton from "./Web3Singleton";

export const POA_ADDRESS = import.meta.env.VITE_PROOF_OF_ASSET_ADDRESS;
export const EURM_ADDRESS = import.meta.env.VITE_EURO_MYRON_ADDRESS;
export const CM_ADDRESS = import.meta.env.VITE_CREDIT_MANAGER_ADDRESS;
export const NETWORK_ID = import.meta.env.VITE_NETWORK_ID;
export const RPC_URL = import.meta.env.VITE_RPC_URL;
export const OWNER_ADDRESS = import.meta.env.VITE_OWNER_ADDRESS;

export type ContractResponse = {
  contract: any;
  gasPrice: bigint;
};

export async function getEURMSmartContract(): Promise<ContractResponse> {
  const web3 = await Web3Singleton.getInstance();
  const gasPrice = await web3.eth.getGasPrice();
  return { contract: new web3.eth.Contract(EuroMyronABI, EURM_ADDRESS), gasPrice };
}

export async function getAFTSmartContract(address: string): Promise<ContractResponse> {
  const web3 = await Web3Singleton.getInstance();
  const gasPrice = await web3.eth.getGasPrice();
  return { contract: new web3.eth.Contract(AssetFinancingTokenABI, address), gasPrice };
}

export async function getAFPSmartContract(address: string): Promise<ContractResponse> {
  const web3 = await Web3Singleton.getInstance();
  const gasPrice = await web3.eth.getGasPrice();
  return { contract: new web3.eth.Contract(AssetFinancingPoolABI, address), gasPrice };
}

export async function getIFPSmartContract(address: string): Promise<ContractResponse> {
  const web3 = await Web3Singleton.getInstance();
  const gasPrice = await web3.eth.getGasPrice();
  return { contract: new web3.eth.Contract(InventoryFinancingPoolABI, address), gasPrice };
}

export async function getPOASmartContract(): Promise<ContractResponse> {
  const web3 = await Web3Singleton.getInstance();
  const gasPrice = await web3.eth.getGasPrice();
  return { contract: new web3.eth.Contract(ProofOfAssetABI, POA_ADDRESS), gasPrice };
}

export async function getCreditManagerSmartContract(): Promise<ContractResponse> {
  const web3 = await Web3Singleton.getInstance();
  const gasPrice = await web3.eth.getGasPrice();
  return { contract: new web3.eth.Contract(CreditManagerABI, CM_ADDRESS), gasPrice };
}

export async function getRFPSmartContract(address: string): Promise<ContractResponse> {
  const web3 = await Web3Singleton.getInstance();
  const gasPrice = await web3.eth.getGasPrice();
  return { contract: new web3.eth.Contract(ReceivableFinancingPoolABI, address), gasPrice };
}
