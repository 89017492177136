import { GetPaginatedUsersDocument, useDeleteUserMutation } from "@/graphql/__generated__/graphql-operations";
import { UserBasic } from "@/types";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";
import { toast } from "react-toastify";


export default function useDeleteUserMutationHook(currentUserID: string, setCurrentUserID: Dispatch<SetStateAction<UserBasic | null>>) {

  const [ deleteUserMutation, { loading, error }] = useDeleteUserMutation({
    onCompleted(result) {
      setCurrentUserID(null);
      if (result.deleteUser === true) {
        toast.success(t("toasts:deleteUser"), {toastId: "deleteUser"});
      }
      else {
        toast.error(t("toasts:deleteUserFailed"), {toastId: "deleteUserFailed"});
      }
      // Remove all toasts manually
      setTimeout(() => {
        toast.dismiss();
      },2000);
    },
    update(client) {
      // rebuild query without deleted user
      const paginatedUsersQuery: any = client.readQuery({ query: GetPaginatedUsersDocument });
      if (!paginatedUsersQuery) return;

      client.evict({
        id: `Employee:${currentUserID}`,
      });
    }
  });

  return {
    deleteUserMutation,
    loading,
    error,
  };
}
