import { client } from "@/client";
import {
  CLIENT_GET_ADMIN_STATE, CLIENT_GET_CUSTOMERS_STATE,
  CLIENT_GET_INVESTMENT_STATE, CLIENT_GET_PAGINATION_STATE,
} from "@/graphql/operations/client.queries";
import {
  AdminPageState, CustomersPageState, InvestmentPageState,
  PartialDeep, PaginationState,
} from "@/types";
import { DocumentNode } from "graphql";
import merge from "lodash/merge";

/**
 * Generic function to fetch cache and do a merge before writing query to ensure cache is valid
 * @param query
 * @param stateKey
 * @param dataPayload
 */
export const updateState = <T>(query: DocumentNode, stateKey: string, dataPayload: PartialDeep<T>) => {
  const existingState = client.readQuery({
    query,
  });

  const newState = merge({}, existingState?.[stateKey] as T, dataPayload);

  client.writeQuery({
    query,
    data: {
      [stateKey]: newState,
    },
  });
};

export const updateAdminState = (dataPayload: PartialDeep<AdminPageState>) => {
  updateState<AdminPageState>(CLIENT_GET_ADMIN_STATE, "adminPageState", dataPayload);
};

export const updateCustomersState = (dataPayload: PartialDeep<CustomersPageState>) => {
  updateState<CustomersPageState>(CLIENT_GET_CUSTOMERS_STATE, "customersPageState", dataPayload);
};

export const updatePaginationState = (dataPayload: PartialDeep<PaginationState>) => {
  updateState<PaginationState>(CLIENT_GET_PAGINATION_STATE, "paginationState", dataPayload);
};

export const updateInvestmentState = (dataPayload: PartialDeep<InvestmentPageState>) => {
  updateState<InvestmentPageState>(CLIENT_GET_INVESTMENT_STATE, "investmentPageState", dataPayload);
};
