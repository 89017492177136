import { TableColumnHeader } from "@/components/shared/table/Table";
import {
  AssetFinancingRound,
  AssetType,
} from "@/graphql/__generated__/graphql-operations";
import { formatNumberLocale, formatDateLocale } from "@/utils/helpers";
import { ColumnDef } from "@tanstack/react-table";
import { TFunction } from "i18next";
import { IntlShape } from "react-intl";

export const getColumns = (
  intl: IntlShape,
  t: TFunction,
  spAssetType: AssetType | undefined
): ColumnDef<AssetFinancingRound, string>[] => {
  const isInventory = spAssetType === AssetType.Inventory;

  const columns: ColumnDef<AssetFinancingRound, string>[] = [
    {
      accessorKey: "createdAt",
      header: ({ column }) => (
        <TableColumnHeader
          text={t("tables:poolactivity.headers.date")}
          column={column}
        />
      ),
      cell: ({ row }) => formatDateLocale(intl, row.getValue("createdAt")),
    },
  ];

  if (isInventory) {
    columns.push(
      {
        accessorKey: "amountFinanced",
        header: ({ column }) => (
          <TableColumnHeader
            text={t("tables:poolactivity.headers.inventoryvalue")}
            column={column}
          />
        ),
        cell: ({ row }) => formatNumberLocale(intl, row.getValue("amountFinanced") as number, "currency"),
      },
      {
        accessorKey: "inventoryBought",
        header: ({ column }) => (
          <TableColumnHeader
            text={t("tables:poolactivity.headers.inventorybought")}
            column={column}
          />
        ),
        cell: ({ row }) => formatNumberLocale(intl, row.getValue("inventoryBought") as number, "currency"),
      },
      {
        accessorKey: "inventorySold",
        header: ({ column }) => (
          <TableColumnHeader
            text={t("tables:poolactivity.headers.inventorysold")}
            column={column}
          />
        ),
        cell: ({ row }) => formatNumberLocale(intl, row.getValue("inventorySold") as number, "currency"),
      }
    );
  } else {
    columns.push(
      {
        accessorKey: "amountFinanced",
        header: ({ column }) => (
          <TableColumnHeader
            text={t("tables:poolactivity.headers.amountfinanced")}
            column={column}
          />
        ),
        cell: ({ row }) => formatNumberLocale(intl, row.getValue("amountFinanced") as number, "currency"),
      },
      {
        accessorKey: "amountNotFinanced",
        header: ({ column }) => (
          <TableColumnHeader
            text={t("tables:poolactivity.headers.amountnotfinanced")}
            column={column}
          />
        ),
        cell: ({ row }) => formatNumberLocale(intl, row.getValue("amountNotFinanced") as number, "currency"),
      }
    );
  }

  return columns;
};
