import { TwInput } from "@/components/shared";
import { useUserData } from "@/hooks";
import { useEditPasswordMutationHook } from "@/hooks/mutations";
import { FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { passwordInputFields } from "../inputfields";
import { useTranslation } from "react-i18next";
import FormWrapper from "./FormWrapper";
import merge from "lodash.merge";
import { clearForm } from "@/utils/helpers";

type PasswordState = {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

function Password() {

  // States
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [newData, setNewData] = useState<PasswordState | undefined>(undefined);
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);

  // Hooks
  const user = useUserData();
  const { t } = useTranslation();

  // Mutation hooks
  const { editPasswordMutation } = useEditPasswordMutationHook();

  /**
  * Handler for change password form submit event
  * @param event: FormEvent<Element>
  */
  function handleChangePasswordSubmit(event: FormEvent<Element>) {
    event.preventDefault();

    if(!user || hasErrors) {
      return toast.error("Unable to submit data", { toastId: "addressSubmitError"});
    }

    editPasswordMutation(
      {
        variables: {
          id: user._id,
          passwordInput: {
            currentPassword: newData?.password as string,
            newPassword: newData?.newPassword as string,
          },
        }
      }
    );
    setNewData(undefined);
    setHasErrors(true);
  }

  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    const { name, value, isValid } = event;
    setHasErrors(isValid ? false : true);

    if(isValid) {
      const toWrite = merge({}, newData, { [name]: value });
      setNewData(toWrite);

      // Check if new password fields match
      if( name === "confirmNewPassword" && newData &&
        value !== newData.newPassword
      ) {
        setHasErrors(true);
        setConfirmPasswordError(true);
        return;
      }
      setHasErrors(false);
      setConfirmPasswordError(false);
      return;
    }

    setHasErrors(true);
  }

  return (
    <div>
      <div className="mb-4 select-none">
        <h2 className="font-semibold text-lg mb-2">{t("settings.password.title")}</h2>
        <p>{t("settings.password.subtitle")}</p>
      </div>

      <FormWrapper
        id="passwordForm"
        onClickReset={() => { clearForm("passwordForm"); setNewData(undefined); }}
        handleSubmit={handleChangePasswordSubmit}
      >
        <>
          <div className="w-full md:w-1/2 md:pr-2">
            <TwInput
              className={"mf-input-field text-gray-800"}
              required
              onChange={(event: { name: string; value: string; isValid: boolean; }) => handleOnChange(event)}
              placeholder={t(passwordInputFields[0].placeholder ?? "")}
              type="password"
              name="password"
              label={t(passwordInputFields[0].label ?? "")}
              errorMessage={passwordInputFields[0].errorMessage ? t(passwordInputFields[0].errorMessage) : ""}
              doNotUseRegex
              labelPlacement="stacked"
              autoFocus={false}
              useErrorText
              autoComplete={passwordInputFields[0].autoComplete}
            />
          </div>

          <div className="flex flex-col md:flex-row justify-between md:gap-4">
            {passwordInputFields.slice(1).map((field) => (
              <div key={field.name} className="w-full">
                <TwInput
                  className={"mf-input-field text-gray-800"}
                  required
                  onChange={(event: { name: string; value: string; isValid: boolean }) => handleOnChange(event)}
                  placeholder={t(field.placeholder ?? "")}
                  type="password"
                  name={field.name}
                  label={t(field.label ?? "")}
                  errorMessage={t(field.errorMessage ?? "")}
                  labelPlacement="stacked"
                  autoFocus={false}
                  useErrorText
                  usePasswordRequirements={field.usePasswordRequirements}
                  autoComplete={field.autoComplete}
                  customHasError={field.name === "confirmNewPassword" ? confirmPasswordError : null}
                  customErrorMessage={field.name === "confirmNewPassword" ? t("toasts:passwdsDoNotMatch") : null}
                />
              </div>
            ))}
          </div>
        </>
      </FormWrapper>
    </div>
  );
}

export default Password;
