import { AssetType, CompanyType } from "@/graphql/__generated__/graphql-operations";
import { TwInput, TwTextArea } from "@/components/shared";
import { TwSelect, TwSelectOption } from "@/components/shared/selector";
import {
  generalInformationForm,
  addressForm,
  bankDetailsForm,
  companyDetailsForm,
  webPresenceForm,
  assetFinancingDetailsForm,
  assetDocumentaryForm,
  invoiceDocumentaryForm,
} from "@/pages/CreateCompany/companydata";
import { propsCreateCompanyForm } from "./props";

const formFieldsAll = [
  {title:"General Information", fields: generalInformationForm, visibility: "ALL"},
  {title:"Address", fields: addressForm, visibility: "ALL"},
  {title:"Bank Details", fields: bankDetailsForm, visibility: "ALL"},
  {title:"Company Details", fields: companyDetailsForm, visibility: CompanyType.Serviceprovider},
  {title:"Web Presence", fields: webPresenceForm, visibility: CompanyType.Serviceprovider},
  {title:"Asset Financing Pool", fields: assetFinancingDetailsForm, visibility: CompanyType.Serviceprovider},
  {title:"Asset Documentary", fields: assetDocumentaryForm, visibility: CompanyType.Serviceprovider},
  {title:"Invoice Documentary", fields: invoiceDocumentaryForm, visibility: CompanyType.Serviceprovider},
];

function CreateCompanyForm(props: propsCreateCompanyForm ) {

  return (
    <>
      {/* Form container */}
      <div className={`mb-[8rem] mx-4 md:mx-0 mt-6 ${props.className}`}>
        <form
          className=" rounded-lg h-auto"
          onSubmit={props.handleFormSubmit}
          id="createCompanyForm"
          noValidate={false}
        >
          <div className="mf-create-company-main-container">
            {
              formFieldsAll.map(group => (
                (group.visibility === "ALL" || props.currentVisibility.includes(group.visibility)) &&
                <div className="mf-create-company-input-container" key={group.title}>
                  {
                    <p className="mf-create-company-input-header">
                      {group.title}
                    </p>}
                  {
                    group.fields.map((field) => {
                      // "ALL" --> always shown
                      if ((field.visibility.includes("ALL") ||
                            // Checks if the current visibility includes the field visibility
                            field.visibility.every(v => props.currentVisibility.includes(v))) ||
                              field.visibility.includes(AssetType.Receivable))
                      {
                        if (field.type === "select") {
                          return (
                            <div
                              className="my-2"
                              key={`${field.name}-1`}
                            >
                              <TwSelect
                                name={field.name}
                                key={field.name}
                                label={field.placeholder}
                                defaultValue={field.defaultValue?.toString()}
                                className="dark:bg-mfdarklight"
                                onChange={(event: { name: string; value: string; isValid: boolean; }) => props.handleOnChange(field.dispatchType as string, event)}
                              >
                                <TwSelectOption
                                  value=""
                                  text="Select an option"
                                  disabled
                                />
                                {field.options?.map((option: string) => (
                                  <TwSelectOption
                                    key={`${field.name}-${option}-option`}
                                    value={option}
                                    text={option}
                                  />
                                ))}
                              </TwSelect>
                            </div>
                          );
                        }
                        else if (field.isMultiline) {
                          return (
                            <TwTextArea
                              key={field.name}
                              className="mf-input-field rounded-xl mb-2 mf-text-primary"
                              onChange={(event: { name: string; value: string; isValid: boolean; }) => props.handleOnChange(field.dispatchType as string, event)}
                              required={field.required}
                              name={field.name}
                              placeholder={field.placeholder}
                              type={field.type}
                              labelBefore="dark:text-gray-200"
                              errorMessage={field.errorMessage}
                              useErrorText
                            />
                          );
                        }
                        else {
                          return (
                            <TwInput
                              key={field.name}
                              className="mf-input-field mb-2 mf-text-primary"
                              onChange={(event: { name: string; value: string; isValid: boolean; }) => props.handleOnChange(field.dispatchType as string, event)}
                              required={field.required}
                              name={field.name}
                              placeholder={field.placeholder}
                              type={field.type}
                              errorMessage={field.errorMessage}
                              labelBefore="dark:text-gray-200"
                              min="0"
                              useErrorText
                              additionalValidation={field.additionalValidation}
                            />
                          );
                        }
                      }})
                  }
                </div>
              ))
            }
          </div>
          <div className="flex w-full justify-center">
            <button
              className="mf-button mf-primary min-w-[200px] mt-6 hover:opacity-70"
              type="submit"
            >
                Create company
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateCompanyForm;
