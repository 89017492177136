
type Props = {
  className?: string;
}

function SPCardSkeleton(props: Props) {
  const { className } = props;
  return (
    <div
      className={`${className}
        flex w-full flex-1 h-[150px] p-1 mf-skeleton-base
      `}
    />
  );
}

export default SPCardSkeleton;
