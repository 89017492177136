import { Dispatch, SetStateAction } from "react";
import { BlankModal } from "@/components/modals/BlankModal";
import { Oval } from "react-loader-spinner";
import { SharedModals } from "@/constants/enums";

// Define Props as a generic type
type Props = {
  loadingText: string;
  setShowModal: Dispatch<SetStateAction<SharedModals>>;
};

// Make LoadingModal component generic
function LoadingModal({ setShowModal, loadingText }: Props) {
  return (
    <BlankModal
      setShowModal={() => setShowModal(SharedModals.NoModal)}
      className="px-6"
      customContentCSS="mf-modal-content"
      doNotShowCloseIcon
    >
      {/* Main Content */}
      <div data-cy="loadingmodal-content" className="-mt-4 p-4">
        {/* Subtext */}
        <div className="mb-4">
          <div className="w-full flex justify-center">
            <Oval
              height={80}
              width={80}
              color="#EE5300"
              wrapperStyle={{}}
              wrapperClass=""
              visible
              ariaLabel='oval-loading'
              secondaryColor="#EE5300"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          </div>
          {/* Loading text */}
          <p data-cy="loadingmodal-text" className="dark:text-white text-sm mt-6 text-center">
            {loadingText}
          </p>
        </div>
      </div>
    </BlankModal>
  );
}

export default LoadingModal;
