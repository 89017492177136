import { inputFields } from "@/pages/ManageUsers/inputfields";
import { InputGroup } from "@/types/shared";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals";
import { TwInput } from "@/components/shared";
import { useTranslation } from "react-i18next";
import { TwSelect, TwSelectOption } from "../../../components/shared/selector";
import { CompanyType, UserRole, useGetPaginatedCompaniesQuery } from "@/graphql/__generated__/graphql-operations";
import { UserType, UsersPageModals } from "@/constants/enums";
import { Dispatch, FormEvent, SetStateAction, useState } from "react";
import { toast } from "react-toastify";
import { useCreateUserMutationHook } from "@/hooks/mutations";

type Props = {
  user: any;
  showModal?: boolean;
  hasErrors: boolean;
  setShowModal: Dispatch<SetStateAction<UsersPageModals>>;
  setHasErrors: (_value: boolean) => void;
}

function CreateUserModal(props: Props) {
  // Hooks
  const { t } = useTranslation();

  // Props
  const {
    user,
    hasErrors,
    setShowModal,
    setHasErrors
  } = props;

  const [newUserInput, setNewUserInput] = useState(
    {
      firstname: "",
      lastname: "",
      mail: "",
      password: "",
      role: "",
      employerID: user.employer ? user.employer._id : "",
    }
  );

  const GetCompanies = () => {
    if(user?.__typename !== UserType.Developer) return;

    const {
      data: companiesData,
      loading: companiesLoading,
      error: companiesError
    }
    = useGetPaginatedCompaniesQuery({
      variables: { first: 100 },
    });

    return {companiesData, companiesLoading, companiesError};
  };

  const componentData = GetCompanies();
  const { createUserMutation } = useCreateUserMutationHook(setShowModal);

  const userRoles = {
    dev: [UserRole.User, UserRole.Admin],
    user: [UserRole.User, UserRole.Superuser]
  };

  /**
  * Handler for form submit event
  * @param event: FormEvent<Element>
  */
  async function handleFormSubmit(event: FormEvent<Element>) {
    if(!user || hasErrors) return;
    toast.loading("Creating user", {toastId: "createUserLoading"});
    event.preventDefault();
    // Destructure to exclude 'employerID' from input state
    const { employerID, role, ...userPartial } = newUserInput;
    if(newUserInput.role === "") {
      toast.dismiss("createUserLoading");
      toast.error(t("toasts:selectUserRole"), {toastId: "selectUserRole"});
      return;
    }
    if(newUserInput.employerID === "") {
      toast.dismiss("createUserLoading");
      toast.error(t("toasts:selectEmployer"), {toastId: "selectEmployer"});
      return;
    }
    if (user.__typename !== "Developer") {
      await createUserMutation({
        variables: { createUserInput: { employerID: employerID, role: role as UserRole, ...userPartial } }
      })
        .then(()=> {
          setHasErrors(false);
        });
    }
    else {
      newUserInput.employerID === "DEVELOPER" ?
        await createUserMutation({
          variables: { createUserInput: { role: role as UserRole, ...userPartial, } }
        })
          .then(()=> {
            setHasErrors(false);
          }):
        await createUserMutation(
          { variables: { createUserInput: { role: role as UserRole, employerID: employerID, ...userPartial, } } })
          .then(()=> {
            setHasErrors(false);
          });
    }
    toast.dismiss("createUserLoading");
  }

  /**
  * onChange handler for input fields
  * @param event: { name: string, value: string, isValid: boolean }
  */
  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    const { name, value, isValid } = event;

    if(isValid) {
      setHasErrors(false);
      setNewUserInput({ ...newUserInput, [name]: value });
    }
    else {
      setHasErrors(true);
    }
  }

  return (
    <BlankModal
      setShowModal={() => setShowModal(UsersPageModals.NoModal)}
      title="Create User"
      className="px-6"
      customContentCSS="mf-modal-content lg:min-w-[580px]"
    >
      <form
        onSubmit={handleFormSubmit}
      >
        <BlankModalContent>
          <div className="pt-2">
            {
              inputFields.map((field: InputGroup) => {
                if(field.name === "employerID" && props.user.__typename !== "Developer") return;
                else if(field.name === "employerID" && (props.user.__typename === "Developer" || props.user.role === "ADMIN")) {
                  return (
                    <TwSelect
                      name={field.name}
                      key={field.name}
                      label={field.placeholder}
                      defaultValue="DEFAULT"
                      onChange={handleOnChange}
                      className="mb-4 dark:bg-mfdarklight"
                    >
                      <TwSelectOption
                        value="DEFAULT"
                        text="Select a company"
                        disabled
                      />
                      {
                        props.user.__typename === UserType.Developer && !componentData?.companiesLoading &&
                          componentData?.companiesData?.paginatedCompanies?.edges.map((edge) => edge.node).map((company) => {
                            return (
                              company.companytype !== CompanyType.Customer &&
                                <TwSelectOption
                                  key={`${field.name}-option`}
                                  value={company._id}
                                  text={company.name}
                                />
                            );
                          })
                      }
                      <TwSelectOption
                        value="DEVELOPER"
                        text="Myron Developer"
                        disabled={false}
                      />
                    </TwSelect>
                  );
                }
                else if(field.name === "role") {
                  return (
                    <TwSelect
                      name={field.name}
                      key={field.name}
                      className="mb-1 dark:bg-mfdarklight dark:placeholder:text-gray-200 outline-none"
                      label={field.placeholder}
                      defaultValue="DEFAULT"
                      onChange={handleOnChange}
                      required
                    >
                      <TwSelectOption
                        value="DEFAULT"
                        text="Select a role"
                        disabled
                      />
                      {
                        newUserInput.employerID === "DEVELOPER" ?
                          userRoles.dev.map((role) => {
                            return (
                              <TwSelectOption
                                key={`${field.name}-option`}
                                value={role}
                                text={role}
                              />
                            );
                          }):
                          userRoles.user.map((role) => {
                            return (
                              <TwSelectOption
                                key={`${field.name}-option`}
                                value={role}
                                text={role}
                              />
                            );
                          })}
                    </TwSelect>
                  );
                }
                else {
                  return (
                    <TwInput
                      key={`registration-field-${field.name}`}
                      type={field.type}
                      required={field.required}
                      name={field.name}
                      autoComplete="new-password"
                      errorMessage={field.errorMessage}
                      placeholder={field.placeholder}
                      labelBefore="dark:text-gray-200"
                      className={`${field.className} mf-text-primary`}
                      onChange={handleOnChange}
                    />
                  );
                }
              })
            }
          </div>
        </BlankModalContent>
        <BlankModalFooter>
          <div className="flex justify-between">
            <button
              onClick={() => setShowModal(UsersPageModals.NoModal)}
              className="mf-btn-action-large-primary-outline"
            >
              {t("common:buttons.cancel")}
            </button>
            <button
              id="createUserAdminButton"
              className="mf-btn-action-large-primary-filled"
              type={props.hasErrors ? "button" : "submit"}
            >
              {t("manageusers.buttons.createuser")}
            </button>
          </div>
        </BlankModalFooter>
      </form>
    </BlankModal>
  );
}

export default CreateUserModal;
