import { logout } from "@/utils/authentication";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SessionContext = createContext<any>([]);

export const SessionProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [loggedInTime, setLoggedInTime] = useState<number | null>(null);

  useEffect(() => {
    // Use loggedIn from session to determine if user is logged in
    const storedLoggedInTime = sessionStorage.getItem("loggedIn");
    if (storedLoggedInTime) {
      setLoggedInTime(Number(storedLoggedInTime));
      // Reseting the last activity time
      setLastActivity(Date.now());
    } else {
      // Clear loggedInTime if user is not logged in to stop checking for inactivity
      setLoggedInTime(null);
    }
    // Check session on page load
  }, [location.pathname]);

  // Update last activity time when user interacts with the page
  useEffect(() => {
    if (loggedInTime) {
      // List of events to listen for to update last activity time
      const activityEvents = ["mousemove", "keydown", "scroll"];

      const activityHandler = () => setLastActivity(Date.now());

      // Add event listeners to events in the event list to update last activity time
      activityEvents.forEach((event) => {
        window.addEventListener(event, activityHandler);
      });

      // Remove event listeners when component unmounts
      return () => {
        activityEvents.forEach((event) => {
          window.removeEventListener(event, activityHandler);
        });
      };
    }
  }, [loggedInTime]);

  useEffect(() => {
    if (loggedInTime) {
      // Check if user has been inactive for 20 minutes
      const checkActivity = setInterval(() => {
        const elapsed = Date.now() - lastActivity;
        // console.log("Elapseed time", elapsed/1000);
        const twentyMinutes = 20 * 60 * 1000; // 20 minutes in milliseconds

        if (elapsed >= twentyMinutes) {
          sessionLogout(); // User has been inactive for 20 minutes, logout user
          clearInterval(checkActivity);
        }
      }, 1000); // Check every minute

      // Clear interval if user logs out
      return () => clearInterval(checkActivity);
    }
  }, [lastActivity, loggedInTime]);


  const sessionLogout = () => {
    logout(true);
    navigate("/login");
  };

  return (
    <SessionContext.Provider value={{}}>
      {children}
    </SessionContext.Provider>
  );
};
