import { formatDateLocale } from "@/utils/helpers";
import { addDays, differenceInDays } from "date-fns";
import { t } from "i18next";
import { IntlShape } from "react-intl";

/**
 * Calculate the closing date of the pool
 * @param {Date} activationDate
 * @param {number} dayslocked
 * @returns
 */
export function calculateClosingDate(intl: IntlShape, activationDate: string | Date, dayslocked: number) {
  // Ensure activationDate is a valid date string
  if (!activationDate) {
    return t("portfoliopage.portfoliocard.notavailable"); // Return if activationDate is not a string
  }

  // Calculate the closing date by adding days equivalent to the number of days locked
  const closingDate = addDays(new Date(activationDate), dayslocked);
  // Format the closing date as a locale-specific string
  return formatDateLocale(intl, closingDate);
}

/**
 * Calculate the percentage of the pool owned by the Financier
 * @param amount number
 * @param financingLimit number
 * @returns
 */
export function calculatePercentageOwned(amount: number, financingLimit: number) {
  // Calculate the percentage owned
  const percentageOwned = (amount / financingLimit) * 100;

  // Return the percentage as a string with two decimal places
  return `| ${percentageOwned.toFixed(2)}% ${t("portfoliopage.portfoliocard.share")}`;
}

/**
   * Calculate the remaining time until the pool closes
   * @param {Date} activationDate
   * @param {number} dayslocked
   * @returns
   */
export function calculatePoolCloseTime(activationDate: Date | string, dayslocked: number): string {
  // Convert activationDate to a Date object if it's a string
  const activationDateTime = typeof activationDate === "string" ? new Date(activationDate) : activationDate;

  // Calculate the expiration date based on the activation date and days locked
  const expirationDate = addDays(activationDateTime, dayslocked);

  // Calculate the remaining time in days
  const remainingDays = differenceInDays(expirationDate, new Date());

  // Return the remaining time string
  // TODO Use this key
  return remainingDays === 1 ? `${remainingDays} ${t("portfoliopage.portfoliocard.day")}` : `${remainingDays} ${t("portfoliopage.portfoliocard.days")}`;
}
