import { XMarkIcon } from "@heroicons/react/24/outline";

type BlankModalContentProps = {
  children: React.ReactNode | React.ReactNode[];
}

type BlankModalFooterProps = {
  children: React.ReactNode | React.ReactNode[];
}

type BlankModalProps = {
  className?: string;
  showModal?: boolean;
  setShowModal: (_value: boolean) => void;
  customContentCSS?: string;
  children: React.ReactNode | React.ReactNode[];
  title?: string;
  additionalCloseAction?: () => void;
  doNotShowCloseIcon?: boolean;
  titleCSS?: string;
  doNotShowTopContainer?: boolean;
}

function BlankModalContent(props: BlankModalContentProps) {
  return (
    <div data-cy="blankmodal-content" className="flex-1 pt-2 overflow-y-auto">
      {props.children}
    </div>
  );
}

function BlankModalFooter(props: BlankModalFooterProps) {
  return (
    <div data-cy="blankmodal-footer" className="pt-2">
      {props.children}
    </div>
  );
}

function BlankModal(props: BlankModalProps) {
  return (
    // Modal container - overlay
    <div data-cy="blankmodal-container" className={`mf-modal-main-container ${props.className} max-md:pb-[1rem]`}>
      {/* Modal card */}
      <div data-cy="blankmodal-card" className={`max-md:min-w-full max-md:max-h-[90vh] flex flex-col ${props.customContentCSS ? props.customContentCSS: "mf-modal-inner-container"} relative`}>
        {/* Header */}
        {
          props.doNotShowTopContainer ? null :
            <div data-cy="blankmodal-card-top-container" className={`w-full items-center flex mb-4 ${props.title? "justify-between":"justify-end"}`}>
              {
                props.title &&
                <p data-cy="modal-title" className={`${props.titleCSS ? props.titleCSS : "font-base font-semibold select-none"} mt-6 w-full dark:text-white`}>
                  {props.title}
                </p>
              }
              {
                props.doNotShowCloseIcon ? null :
                  <XMarkIcon
                    onClick={() => {props.setShowModal(false); props.additionalCloseAction && props.additionalCloseAction();}}
                    className="mf-modal-close-icon absolute top-3 right-3"
                  />
              }
            </div>
        }
        {/* Content + Footer */}
        {props.children}
      </div>
    </div>
  );
}

export { BlankModal, BlankModalContent, BlankModalFooter };
