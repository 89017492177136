// Function to calculate time left
export const calculateTimeLeft = (t: any, paymentDate: number): string => {
  const currentDate = new Date();
  const paymentDateTime = new Date(paymentDate);
  const timeDifference = paymentDateTime.getTime() - currentDate.getTime();

  // More than 24 hours left
  if (timeDifference > 24 * 60 * 60 * 1000) {
    const daysLeft = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    return `${daysLeft} ${daysLeft === 1 ? t("financecard.countdownLabels.day") : t("financecard.countdownLabels.days")}`;
  }
  // Less than 24 hours, but more than 1 hour left
  else if (timeDifference > 60 * 60 * 1000) {
    const hoursLeft = Math.floor(timeDifference / (60 * 60 * 1000));
    return `${hoursLeft} ${hoursLeft === 1 ? t("financecard.countdownLabels.hour") : t("financecard.countdownLabels.hours")}`;
  }
  // Less than 1 hour left
  else {
    const minutesLeft = Math.floor(timeDifference / (60 * 1000));
    return `${minutesLeft} ${minutesLeft === 1 ? t("financecard.countdownLabels.minute") : t("financecard.countdownLabels.minutes")}`;
  }
};
