import { updatePaginationState } from "@/cache/appstate/WriteQueries";
import { RadioButton, TagItem } from "@/components/shared";
import { AssetFinancingStatus, GetPaginatedCompaniesQueryVariables } from "@/graphql/__generated__/graphql-operations";
import { usePaginationState } from "@/hooks/cache/appState/usePaginationState";
import { PaginationState } from "@/types";
import { ArrowPathIcon, CheckIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

type Props = {
  className?: string;
  displayAmount: number;
  onFilterSubmit: (_variables: GetPaginatedCompaniesQueryVariables) => void;
}

type RadioOption = {
  label: string;
  value: AssetFinancingStatus;
}


function CompanyFilter(props: Props) {
  // Props
  const {
    displayAmount,
    onFilterSubmit
  } = props;

  // Hooks
  const { t } = useTranslation(["common"]);

  // States
  const [showFilterBox, setshowFilterBox] = useState<boolean>(false);

  // Ref
  const filterRef = useRef<HTMLDivElement>(null);

  const paginationState: PaginationState["paginatedCompanies"] = usePaginationState("paginatedCompanies");
  const { filters: { status } } = paginationState;

  const afpStatusOptions: RadioOption[] = [
    { label: "Active", value: AssetFinancingStatus.Active },
    { label: "Open", value: AssetFinancingStatus.Open },
    { label: "Onboarding", value: AssetFinancingStatus.Onboarding },
    { label: "Closed", value: AssetFinancingStatus.Closed },
  ];

  /**
   * OnChange Handler for Radio buttons
   */
  const onChangeRadioButton = (key: string, value: string) => {
    if (key === "status") {
      updatePaginationState({
        paginatedCompanies: {
          filters: {
            status: value as AssetFinancingStatus
          }
        }
      });
    }
  };

  /**
   * OnClick handler for Reset button
   */
  const handleResetClick = () => {
    props.onFilterSubmit({
      first: displayAmount,
      after: null,
      status: null,
    });
    updatePaginationState({ paginatedCompanies: { filters: { status: null } } });
    toast.success(t("common:text.filterremoved"), {toastId: "userFilterRemoved"});
  };

  /**
   * OnClick handler for Submit button
   */
  const handleSubmitClick = () => {
    onFilterSubmit({
      first: displayAmount,
      status,
    });
    toast.success(t("common:text.filterapplied"), {toastId: "userFilterApplied"});
  };

  // Hide filter menu when clicking outside of the menu
  function hideOnClickOutside (e: SyntheticEvent | any) {
    if( filterRef.current && !filterRef?.current?.contains(e.target) ) {
      setshowFilterBox(false);
    }
  }

  // Listener for ref to hide filter menu
  useEffect(() => {
    document.addEventListener("click", hideOnClickOutside, true);
  }, [showFilterBox]);


  return (
    <div className="mb-1 bg-white dark:bg-mfdarklight">
      {/* Filters container */}
      <div className="md:flex items-center mt-2 justify-between">
        {/* Filter box */}
        <div
          className="border-[1px] border-gray-200 rounded-md flex item-center h-[40px] max-md:w-full md:w-[200px] px-2 py-1
                  dark:border-white relative select-none"
        >
          <div className="mr-2 h-full items-center flex">
            <FunnelIcon
              onClick={() => setshowFilterBox((prev) => !prev)}
              className="w-5 h-5 flex-shrink-0 dark:text-white cursor-pointer active:scale-95"
            />
            {
              status === null &&
              <p className="text-sm text-gray-400 pl-2">
                {t("translation:portfoliopage.filter.selectstatus")}
              </p>
            }
          </div>
          <div className="flex items-center space-x-2 select-none">
            {
              status !== null &&
              <TagItem label={status as string} />
            }
          </div>
          {
            <div
              ref={filterRef}
              className={`absolute bg-white dark:bg-gray-600 shadow-lg h-auto w-full left-0 top-[42px] p-6 max-w-[200px]
                      rounded-md dark:text-white ${showFilterBox ? "scale-100":"scale-0"}
                      transition-transform duration-200 ease-linear origin-top-left z-[200]`}
            >
              {/* Status filters */}
              {afpStatusOptions.map(option => (
                <RadioButton
                  key={option.value}
                  option={option}
                  checked={status === option.value}
                  onChange={(value) => onChangeRadioButton("status", value as string)}
                />
              ))}
            </div>
          }
        </div>

        {/* Right container --> Action buttons */}
        <div className="flex justify-end mt-4 md:mt-0 md:items-center gap-x-1 md:space-x-2">
          {/* Reset button */}
          <button
            onClick={handleResetClick}
            className="mf-btn-action-small-primary-outline mr-1 md:mr-0"
          >
            <div><ArrowPathIcon className="w-5 h-5 md:hidden" /></div>
            <div className="hidden md:flex">
              {t("common:buttons.reset")}
            </div>
          </button>

          {/* Submit button */}
          <button
            onClick={handleSubmitClick}
            className="mf-btn-action-small-primary-filled"
          >
            <div><CheckIcon className="w-5 h-5 md:hidden" /></div>
            <div className="hidden md:flex">
              {t("common:buttons.submit")}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CompanyFilter;
