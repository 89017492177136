import { Asset } from "@/graphql/__generated__/graphql-operations";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormattedNumber } from "react-intl";

type Props = {
  dataSlice: Asset[];
  loading: boolean;
}

/**
 * A component that displays the sum of the Assets that are currently visible in the table
 * @param {Props} props
 * @returns {JSX.Element} SumOfAssets component
 */
function SumOfAssets(props: Props): JSX.Element {

  // Hooks
  const { t } = useTranslation();

  // States
  const [currentPageAssetsSum, setCurrentPageAssetsSum] = useState(0);

  /**
   * This function calculates the sum of the Assets that are currently visible in the table
   * @param {Asset[]} assets array of Assets
   * @returns {number} the sum of the Assets
   */
  function calculateAssetsSum(assets: Asset[]): number {
    return assets.reduce((total, asset) => {
      return total + asset.value;
    }, 0);
  }

  // Sets the sum of the current page of Assets
  useEffect(() => {
    if(!props.loading) {
      const sumOfAssets = calculateAssetsSum(props.dataSlice as Asset[] ?? []);
      setCurrentPageAssetsSum(sumOfAssets);
    }
  }, [props.dataSlice]);

  return (
    <div className="flex items-center border-t-[1px] border-b-[1px] py-2 my-2 md:my-0 md:border-b-0">
      <p className="font-light mr-2 mf-text-primary">
        {t("assetsoverview.statusFilter.sum")}
      </p>
      <p className="font-medium mr-2 mf-text-primary">
        {
          <FormattedNumber
            value={currentPageAssetsSum}
            style="currency"
            currency={"EUR"}
            // currency={code}
            currencyDisplay="symbol"
          />
        }
      </p>
    </div>
  );
}

export default SumOfAssets;
