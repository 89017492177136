import { InputReducerActionType } from "@/constants/enums";
import { useLoginUserLazyQueryHook } from "@/hooks/queries";
import LoginComponent from "@/pages/Login/LoginComponent";
import { typeStateLogin } from "@/types/login";
import merge from "lodash.merge";
import { useState, useReducer, useEffect, FormEvent } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

let navigateGlobal: NavigateFunction | ((_arg0: string) => void); // used in authentication.tsx when logging out a user after 2 hours
function Login() {
  navigateGlobal = useNavigate();

  const [errors] = useState({});
  const [hasErrors, setHasErrors] = useState(false);

  const initialState:typeStateLogin = {
    mail: "",
    password: "",
  };

  function inputReducer(state: typeStateLogin, action:{ type: string, payload: Partial<typeStateLogin> }) {
    return merge({}, state, action.payload);
  }
  const [loginData, dispatch] = useReducer(inputReducer, initialState);

  // Need to use "useLazyQuery" as only this hook and useMutation
  // return a function that can be executed on button press
  const { loginQuery, loading, error } = useLoginUserLazyQueryHook();

  useEffect(() => {
    if (errors) {
      setHasErrors(true);
    }
  }, [errors]);

  function handleFormSubmit(event: FormEvent<Element>) {
    event.preventDefault();
    loginQuery({variables: loginData});
  }

  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    // get the updated user input
    const { name, value, isValid } = event;
    if(value === "") {
      dispatch({ type: InputReducerActionType.UPDATE, payload: { [name]: "" } });
    }
    else if(isValid) {
      dispatch({type: InputReducerActionType.UPDATE, payload: {[name]: value}});
      setHasErrors(false);
    }
    else {
      setHasErrors(true);
    }
  }

  return (
    <LoginComponent
      className="w-full md:w-1/2 lg:w-1/3 md:mx-42 lg:mx-56 mb-[8rem] md:mb-0"
      handleFormSubmit={handleFormSubmit}
      handleOnChange={handleOnChange}
      hasErrors={hasErrors}
      loading={loading}
      error={error}
    />
  );
}

export default Login;
export { navigateGlobal };
