import { AssetFinancingRound, Investment } from "@/graphql/__generated__/graphql-operations";
import { formatDateLocale, formatNumberLocale } from "@/utils/helpers";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

type Props = {
  transactions?: AssetFinancingRound[];
  isInventory?: boolean;
}

function RecentTransactions({transactions, isInventory }: Props) {

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleTransactionClick = (transaction: AssetFinancingRound | Investment) => {
    navigate(`/user/assetoverview?date=${transaction.createdAt}`);
  };

  return (
    <div
      className="bg-white dark:bg-mfdarklight rounded-xl shadow-lg dark:text-white px-2 pt-4
                  pb-2 w-full select-none"
    >
      {/* Top container */}
      <div className="flex items-center justify-between">
        <p className="text-lg font-semibold mb-2 px-2">
          {
            t("dashboard.recenttransactions.transactionstitle")
          }
        </p>
      </div>
      {/* Main content container */}
      {
        transactions?.length === 0 &&
          <div className="flex flex-col items-center justify-center h-52">
            <p className="text-base font-thin mb-2 px-2">
              {
                t("dashboard.recenttransactions.placeholderSP")
              }
            </p>
          </div>
      }
      {
        <div className="space-y-2 mt-4 flex flex-col justify-center">
          {
            transactions?.map((transaction) => (
              <div
                className="flex flex-col space-y-2 hover:border-mforange rounded-lg
                          transition-all duration-300 ease-linear py-1 px-2 cursor-pointer group
                          border-2 border-transparent"
                key={`recent-${transaction._id}`}
                onClick={() => handleTransactionClick(transaction)}
              >
                {/* Main content */}
                <div
                  className={"flex flex-col items-center"}
                >
                  {/* Receivable/Inventory titles and values */}
                  <div className="flex flex-row w-full">
                    {
                      // *** Inventory
                      isInventory ?
                        <div className="w-full">
                          <div className="lg:flex items-center justify-between">
                            <p className="text-sm lg:text-base">
                              {t("dashboard.recenttransactions.inventorybought")}
                            </p>
                            <p className="flex items-center text-base font-thin text-green-500">
                              {formatNumberLocale(intl, transaction.inventoryBought ?? 0, "currency")}
                            </p>
                          </div>
                          <div className="lg:flex items-center justify-between">
                            <p className="text-sm lg:text-base">
                              {t("dashboard.recenttransactions.inventorysold")}
                            </p>
                            <p className="flex items-center text-base font-thin text-red-500">
                              {formatNumberLocale(intl, transaction.inventorySold ?? 0, "currency")}
                            </p>
                          </div>
                        </div>:
                      // *** Receivable
                        <div className="w-full">
                          <div className="lg:flex items-center justify-between">
                            <p className="text-sm lg:text-base">
                              {t("dashboard.recenttransactions.amountfinanced")}
                            </p>
                            <p className="flex items-center text-base font-thin text-green-500">
                              {formatNumberLocale(intl, transaction.amountFinanced ?? 0, "currency")}
                            </p>
                          </div>
                          <div className="lg:flex items-center justify-between">
                            <p className="text-sm lg:text-base">
                              {t("dashboard.recenttransactions.amountnotfinanced")}
                            </p>
                            <p className="flex items-center text-base font-thin text-red-500">
                              {formatNumberLocale(intl, transaction.amountNotFinanced?? 0, "currency")}
                            </p>
                          </div>
                        </div>
                    }
                  </div>
                  {/* Date */}
                  <div className="flex justify-end items-start w-full">
                    <p className="text-sm lg:text-base text-gray-400 dark:text-gray-200">
                      {formatDateLocale(intl, transaction.createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      }
    </div>
  );
}

export default RecentTransactions;
