import { formatNumberLocale } from "@/utils/helpers";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";
import { ValueSkeleton } from "@/components/skeletons";

type Props = {
  currentlyWithdrawableCash: number;
  residualCash: number;
  isLoading: boolean;
}

function ReceivablesStats(props:Props) {

  // Props
  const { isLoading, currentlyWithdrawableCash, residualCash } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();

  return (
    <div className="h-full rounded-xl shadow-lg bg-white dark:bg-mfdarklight p-4 dark:text-white
                    col-span-3 flex flex-col"
    >
      {/* Main container */}
      <div className="flex flex-col lg:grid grid-cols-2 lg:space-x-6">
        {/* Left side */}
        <div className="col-span-3 flex flex-col">
          {/* Title */}
          <p className="font-semibold lg:text-lg mb-2 md:mb-0">
            {t("dashboard.serviceprovider.monetarytitle")}
          </p>
          {/* Content */}
          <div className="md:flex md:justify-between w-full">
            {/* Current withdrawable cash */}
            <div className="">
              <p className="text-xs lg:text-sm text-gray-400 dark:text-gray-400 font-thin">
                {t("dashboard.serviceprovider.withdrawablecash")}
              </p>
              {
                !isLoading ? (
                  <p className="text-base lg:text-lg">
                    {formatNumberLocale(intl, currentlyWithdrawableCash, "currency", false, false)}
                  </p>
                ) : (
                  <ValueSkeleton className="min-w-[100px] max-h-[20px]" />
                )
              }
            </div>
            {/* Residual cash */}
            <div className="">
              <p className="text-xs lg:text-sm text-gray-400 dark:text-gray-400 font-thin">
                {t("dashboard.serviceprovider.residualcash")}
              </p>
              {
                !isLoading ? (
                  <p className="text-base lg:text-lg">
                    {formatNumberLocale(intl, residualCash, "currency", false, false)}
                  </p>
                ) : (
                  <ValueSkeleton className="min-w-[100px] max-h-[20px]" />
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReceivablesStats;
