import { LogoFragmentDoc, useChangeLogoMutation } from "@/graphql/__generated__/graphql-operations";
import { ApolloError, FetchResult } from "@apollo/client";
import { t } from "i18next";
import { toast } from "react-toastify";
import { useCompanyData } from "../cache/useCompanyData";
import { client } from "@/client";

/**
 * Custom hook for handling the change of a company's logo.
 *
 * This hook provides a mutation function and manages the mutation's loading and error states. Upon completion, it updates the cache with the new logo and shows a success toast notification.
 *
 * @returns {{
 *   changeLogoMutation: (variables: any) => Promise<FetchResult>,
 *   loading: boolean,
 *   error: ApolloError | undefined
 * }} - Returns an object containing:
 *   - **changeLogoMutation:** A function that executes the mutation to change the company's logo.
 *   - **loading:** A boolean indicating whether the mutation is currently in progress.
 *   - **error:** An ApolloError object if there was an error during the mutation, or undefined if the mutation was successful.
 *
 * @example
 * ```javascript
 * import useChangeLogoMutationHook from "@/hooks/useChangeLogoMutationHook";
 *
 * function MyComponent() {
 *   const { changeLogoMutation, loading, error } = useChangeLogoMutationHook();
 *
 *   const handleChangeLogo = async () => {
 *     try {
 *       await changeLogoMutation({
 *         variables: {
 *           // Your mutation variables here
 *         }
 *       });
 *     } catch (err) {
 *       console.error("Error changing logo:", err);
 *     }
 *   };
 *
 *   return (
 *     <div>
 *       <button onClick={handleChangeLogo} disabled={loading}>
 *         {loading ? "Changing Logo..." : "Change Logo"}
 *       </button>
 *       {error && <p>Error: {error.message}</p>}
 *     </div>
 *   );
 * }
 * ```
 */
export default function useChangeLogoMutationHook(): {
  changeLogoMutation: (_variables: any) => Promise<FetchResult>;
  loading: boolean;
  error: ApolloError | undefined;
  } {
  const company = useCompanyData();

  const [changeLogoMutation, { loading, error }] = useChangeLogoMutation({
    onCompleted(result) {
      if (result.changeLogo) {
        toast.success(t("toasts:editCmpnyStngsSuccess"), { toastId: "editCmpnyStngsSuccess" });
      }

      // Update cache
      client.writeFragment({
        id: `${company?.__typename}:${company?._id}`,
        fragment: LogoFragmentDoc,
        data: {
          logo: result.changeLogo
        }
      });
    },
  });

  return {
    changeLogoMutation,
    loading,
    error
  };
}
