import { FinancierDashboard } from "./Financier/FinancierDashboard";
import { ServiceProviderDashboard } from "./ServiceProvider/SPDashboard";
import { useEmployeeData } from "@/hooks/cache/useEmployeeData";

function Dashboard() {
  const user = useEmployeeData();

  if(!user) {
    return (
      <p>We are currently loading....</p>
    );
  }

  if(user.employer.__typename === "Financier") {
    return (
      <FinancierDashboard />
    );
  }

  if(user.employer.__typename === "ServiceProvider") {
    return (
      <ServiceProviderDashboard />
    );
  }
}

export default Dashboard;
