import { blockchainNumberToClient } from "@/utils/helpers";
import {  getCreditManagerSmartContract } from "./connectors";

export async function creditOf(address: string) {
  const { contract } = await getCreditManagerSmartContract();
  const credit = await contract.methods.creditOf(address).call();
  return blockchainNumberToClient(credit);
}

export async function creditLimitOf(address: string) {
  const { contract } = await getCreditManagerSmartContract();
  const creditLimit = await contract.methods.creditLimitOf(address).call();
  return blockchainNumberToClient(creditLimit);
}
