import { TxResult, WithdrawalCardModals } from "@/constants/enums";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals/BlankModal";
import { ServiceProviderDetails } from "@/components/shared";
import { formatNumberLocale } from "@/utils/helpers";
import { handleBlockchainExplorerLink } from "@/blockchain/helpers";
import { useCompanyData } from "@/hooks";
import { useIntl } from "react-intl";
import { propsWithdrawalResultModal } from "../props";
import { useTranslation } from "react-i18next";

function WithdrawalResultModal(props: propsWithdrawalResultModal) {
  // Props
  const {
    transactionHash,
    transactionResultType,
    activeGroupedWithdrawals,
    setShowModal,
    activeTotalWithdrawalAmount
  } = props;

  // Hooks
  const intl = useIntl();
  const company = useCompanyData();
  const { t } = useTranslation(["modals", "components", "common"]);

  // Constants
  const isError = TxResult.ERROR === transactionResultType;

  const transactionDetailsError = [
    {
      label: t("withdrawalresult.transactiondetails.txamount"),
      content: formatNumberLocale(intl, activeTotalWithdrawalAmount, "currency")
    },
    {
      label: t("withdrawalresult.transactiondetails.fromaddress"),
      content: company?.publickey
    },
  ];

  return (
    <BlankModal
      setShowModal={() => { setShowModal(WithdrawalCardModals.NoModal); }}
      className="px-6"
      customContentCSS="mf-modal-content"
      title=""
      titleCSS="text-lg dark:text-white"
    >
      <BlankModalContent>
        {/* Heading */}
        <div className={`text-lg font-bold text-center ${isError ? "text-red-500" : "text-green-500"}`}>
          {isError ? t("withdrawalresult.fail.heading") :
            t("withdrawalresult.success.heading")}
        </div>

        {/* Image / animation */}
        <div className="mb-6">
          {!isError && (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" className="w-28 h-28 mx-auto mt-2 text-green-500">
              <circle className="animated-icon-path animated-icon-circle" fill="none" stroke="currentColor" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <polyline className="animated-icon-path animated-icon-check" fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
            </svg>
          )}
          {isError && (
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" className="w-28 h-28 mx-auto mt-2 text-red-500">
              <circle className="animated-icon-path animated-icon-circle" fill="none" stroke="currentColor" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
              <line className="animated-icon-path animated-icon-line" fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
              <line className="animated-icon-path animated-icon-line" fill="none" stroke="currentColor" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
          )}
        </div>

        {/* Subtext */}
        <div className="mb-4">
          <p className="dark:text-white text-wrap">
            {isError ? t("withdrawalresult.fail.text") :
              (
                <span>
                  {t("withdrawalresult.success.textbefore")}
                  <span className="pl-1 font-semibold">
                    {formatNumberLocale(intl, activeTotalWithdrawalAmount, "currency")}
                  </span> {t("withdrawalresult.success.textafter")}:
                </span>
              )}
          </p>
        </div>

        {/* SP Details */}
        <ServiceProviderDetails
          serviceProviderId={activeGroupedWithdrawals?.spid ?? ""}
        />

        {/* Transaction Details */}
        <div className="flex flex-col gap-2 mt-4 mb-8">
          <h2 className="font-semibold dark:text-white">{t("withdrawalresult.transactiondetails.title")}</h2>
          {
            isError &&
            <>
              {
                transactionDetailsError.map((item) => (
                  <div key={`companydetails:${item.label}`}>
                    <p className="text-xs text-gray-400 mb-1 dark:text-gray-300">{item.label}</p>
                    <p className="text-xs dark:text-white">{item.content}</p>
                  </div>
                ))
              }
            </>
          }
          {
            transactionHash !== "" &&
            <div>
              <p className="text-xs text-gray-400 mb-1 dark:text-gray-300">{t("withdrawalresult.transactiondetails.txhash")}</p>
              <p className="text-xs dark:text-white break-all">{transactionHash}</p>
            </div>
          }
        </div>

        {/* Disclaimer */}
        <div className="mb-8 dark:text-white">
          {
            transactionHash !== "" &&
            <p className="text-xs">
              {t("withdrawalresult.notes.viewtx")}
              <a href={handleBlockchainExplorerLink("tx", transactionHash)} rel="noreferrer" target="_blank" className="text-blue-400"> Explorer</a>
            </p>
          }
          {
            isError &&
            <>
              <p className="text-xs my-2">{t("withdrawalresult.notes.errormsg")}</p>
              <p className="text-xs">{t("withdrawalresult.notes.contactsupport")} <a href="mailto:contact@myron.finance" className="text-blue-400">contact@myron.finance</a></p>
            </>
          }
        </div>
      </BlankModalContent>

      <BlankModalFooter>
        {/* Buttons */}
        <div className={`flex items-center ${!isError ? "justify-center" : "justify-between"}`}>
          {!isError && (
            <button
              onClick={() => setShowModal(WithdrawalCardModals.NoModal)}
              className="mf-btn-action-small-primary-filled"
            >
              {t("common:buttons.close")}
            </button>
          )}
          {isError && (
            <>
              <button
                onClick={() => setShowModal(WithdrawalCardModals.NoModal)}
                className="mf-btn-action-small-primary-outline"
              >
                {t("common:buttons.cancel")}
              </button>
              <button
                onClick={() => setShowModal(WithdrawalCardModals.MakeWithdrawal)}
                className="mf-btn-action-small-primary-filled"
              >
                {t("common:buttons.retry")}
              </button>
            </>
          )}
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default WithdrawalResultModal;
